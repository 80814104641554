import React from 'react';
import Header from '../../../../component/header'
import {
    Button,
    Grid,
    Notify,
    Tag,
    previewImage,
    Form,
    FormStrategy,
    FormDateRangeQuickPickerField,
    FormInputField,
    FormSelectField,
    Tabs,
    ClampLines
} from 'zent'
import Service from "../../../../config/Service";
import Utils from "../../../../config/Utils";
const TabPanel=Tabs.TabPanel;
export default class MainCustomerMerchantIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            shzGridPageCurrent:1,
            shzGridPageTotal:0,
            shzGridLoading:false,
            shzGridPageSize:15,
            shzDataList:[],
            wtgGridPageCurrent:1,
            wtgGridPageTotal:0,
            wtgGridLoading:false,
            wtgGridPageSize:15,
            wtgDataList:[],
            ytgGridPageCurrent:1,
            ytgGridPageTotal:0,
            ytgGridLoading:false,
            ytgGridPageSize:15,
            ytgDataList:[],
            shenheActive:'1',
            searchDianpuList:[],
            searchData:{
                text:'',
                state:0,
                idcardimg:0,
                shouquan:0,
                images:0,
                dianpu:0,
                time:['','']
            }
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(120200);
        this.onUrlSearch()
        this.searchDianpuBind();
    }
    onUrlSearch=()=>{
        const search=this.props.location.search;
        const urldianpu=Utils.getUrlParams('dianpu',search);
        if(urldianpu!=null)
        {
            let searchData=JSON.parse(JSON.stringify(this.state.searchData));
            const urlShenhe=Utils.getUrlParams('shenhe',search)
            const urlType=Utils.getUrlParams('type',search);
            if(urlType!=null)
            {
                if(urlType=="state")
                {
                    searchData.state=1;
                }
                if(urlType=="day")
                {
                    searchData.time=Utils.returndates(1,true);
                }
                if(urlType=="week")
                {
                    searchData.time=Utils.returndates(2,true)
                }
                if(urlType=="month")
                {
                    searchData.time=Utils.returndates(3,true)
                }
                if(urlType=="yyzz")
                {
                    searchData.idcardimg=1;
                }
                if(urlType=="sqs")
                {
                    searchData.shouquan=1;
                }
                if(urlType=="images")
                {
                    searchData.images=1;
                }
                this.setState({
                    searchData:searchData
                })
            }
            searchData.dianpu=urldianpu;
            this.setState({
                shenheActive:urlShenhe,
                searchData:searchData
            })
            this.onDataBind(urlShenhe);
        }
        else
        {
            this.onDataBind(1);
        }
    }
    onDataBind=(index)=>{
        if(index==1)
        {
            setTimeout(()=>{
                this.onShzDataBind()
            },100)
        }
        if(index==2)
        {
            setTimeout(()=>{
                this.onWtgDataBind()
            },100)
        }
        if(index==3)
        {
            setTimeout(()=>{
                this.onYtgDataBind()
            },100)
        }
    }
    onShzDataBind=()=>{
        const _this=this;
        const {shzGridPageSize,shzGridPageCurrent,searchData}=this.state;
        this.setState({
            shzGridLoading:true
        })
        Service.Post({
            name:"webmerchants",
            action:"pagelist",
            data:{
                pageindex:shzGridPageCurrent,
                pagesize: shzGridPageSize,
                search:searchData,
                shenhe:3
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        shzDataList:result.list,
                        shzGridPageTotal:result.count,
                        shzGridLoading:false
                    })
                }
                else
                {
                    _this.setState({
                        shzDataList:[],
                        shzGridPageTotal:0,
                        shzGridLoading:false
                    })
                }
            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    shzDataList:[],
                    shzGridPageTotal:0,
                    shzGridLoading:false
                })
            }
        })
    }
    onWtgDataBind=()=>{
        const _this=this;
        const {wtgGridPageSize,wtgGridPageCurrent,searchData}=this.state;
        this.setState({
            wtgGridLoading:true
        })
        Service.Post({
            name:"webmerchants",
            action:"pagelist",
            data:{
                pageindex:wtgGridPageCurrent,
                pagesize: wtgGridPageSize,
                search:searchData,
                shenhe:2
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        wtgDataList:result.list,
                        wtgGridPageTotal:result.count,
                        wtgGridLoading:false
                    })
                }
                else
                {
                    _this.setState({
                        wtgDataList:[],
                        wtgGridPageTotal:0,
                        wtgGridLoading:false
                    })
                }
            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    wtgDataList:[],
                    wtgGridPageTotal:0,
                    wtgGridLoading:false
                })
            }
        })
    }
    onYtgDataBind=()=>{
        const _this=this;
        const {ytgGridPageSize,ytgGridPageCurrent,searchData}=this.state;
        this.setState({
            ytgGridLoading:true
        })
        Service.Post({
            name:"webmerchants",
            action:"pagelist",
            data:{
                pageindex:ytgGridPageCurrent,
                pagesize: ytgGridPageSize,
                search:searchData,
                shenhe:1
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        ytgDataList:result.list,
                        ytgGridPageTotal:result.count,
                        ytgGridLoading:false
                    })
                }
                else
                {
                    _this.setState({
                        ytgDataList:[],
                        ytgGridPageTotal:0,
                        ytgGridLoading:false
                    })
                }
            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    ytgDataList:[],
                    ytgGridPageTotal:0,
                    ytgGridLoading:false
                })
            }
        })
    }
    searchDianpuBind=()=>{
        const _this=this;
        Service.Post({
            name:"admindianpu",
            action:"listbyadmin",
            data:{},
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        searchDianpuList:result.list
                    })
                }
            }
        })
    }
    onGridItemImageClick=(src)=>{
        let images=[];
        if(typeof src =="string")
        {
            images.push(src);
        }
        else
        {
            images=src;
        }
        previewImage({
            images: images,
            index: 0,
            parentComponent: this
        });
    }
    onAddMerchantClick=()=>{
        this.props.history.push('merchant/edit/0');
    }
    onGridItemEditClick=(id)=>{
        this.props.history.push('merchant/edit/'+id);
    }
    onGridItemStatusClick=(id,state)=>{
        const index=this.state.shenheActive;
        this.onGridSetStateLoadingChange(index,true);
        const _this=this;
        Service.Post({
            name:"webmerchants",
            action:"state",
            data:{
                id,
                state
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    Notify.success("操作成功");
                    _this.onDataBind(index)
                }
                else
                {
                    Notify.warn(result.retmsg);
                    _this.onGridSetStateLoadingChange(index,false);
                }
            },
            fail(){
                _this.onGridSetStateLoadingChange(index,false);
            }
        })
    }
    onGridChange=(index,e)=>{
        const current=e.current;
        if(index==1)
        {
            this.setState({
                shzGridPageCurrent:current
            })
        }
        if(index==2)
        {
            this.setState({
                wtgGridPageCurrent:current
            })
        }
        if(index==3)
        {
            this.setState({
                ytgGridPageCurrent:current
            })
        }
        this.onDataBind(index)
    }
    onTabsChange=(id)=>{
        this.setState({
            shenheActive:id
        })
        if(id==1)
        {
            if(this.state.shzDataList.length<=0)
            {
                this.onDataBind(1);
            }
        }
        if(id==2)
        {
            if(this.state.wtgDataList.length<=0)
            {
                this.onDataBind(2);
            }
        }
        if(id==3)
        {
            if(this.state.ytgDataList.length<=0)
            {
                this.onDataBind(3);
            }
        }
    }
    onSearchChange=(e)=>{
        const index=this.state.shenheActive;
        if(index==1)
        {
            this.setState({
                shzGridPageCurrent:1,
                wtgGridPageCurrent:1,
                wtgDataList:[],
                ytgGridPageCurrent:1,
                ytgDataList:[],
                searchData:e.search
            })
        }
        if(index==2)
        {
            this.setState({
                shzGridPageCurrent:1,
                shzDataList:[],
                wtgGridPageCurrent:1,
                ytgGridPageCurrent:1,
                ytgDataList:[],
                searchData:e.search
            })
        }
        if(index==3)
        {
            this.setState({
                shzGridPageCurrent:1,
                shzDataList:[],
                wtgGridPageCurrent:1,
                wtgDataList:[],
                ytgGridPageCurrent:1,
                searchData:e.search
            })
        }
        this.onDataBind(index);
    }
    onGridSetStateLoadingChange=(index,value)=>{
        if(index==1)
        {
            this.setState({
                shzGridLoading:value
            })
        }
        else if(index==2)
        {
            this.setState({
                wtgGridLoading:value
            })
        }
        else if(index==3)
        {
            this.setState({
                ytgGridLoading:value
            })
        }
    }
    getGridColumns=(index)=>{
        let listColumns=[
            {
                title:'商户名称',
                name:'name',
            },
            {
                title:'联系方式',
                width:'300px',
                bodyRender:data=>{
                    return(
                        <div>
                            {data.contacts.map((item,index)=>(
                                <div key={index}><span>联系人：{item.name}</span><span style={{paddingLeft:'15px'}}>{item.type==1?"电话":"微信"}：{item.content}</span></div>
                            ))}
                        </div>
                    )
                },
            },
            {
                title:'所属类型',
                name:'class',
                width: '240px'
            },
            {
                title:'所属店铺',
                name:'dianpu',
                width: '100px'
            },
            {
                title:'营业执照',
                width: '120px',
                textAlign: 'center',
                bodyRender:(data)=>{
                    return(
                        <div>
                            {data.idcardnumber.length>0&&(
                                <div><Tag>{data.idcardnumber}</Tag></div>
                            )}
                            {data.idcardimg.length>0&&(
                                <div><Tag onClick={this.onGridItemImageClick.bind(this,data.idcardimg)} style={{cursor:'pointer'}} theme={"blue"} outline>已上传</Tag></div>
                            )}
                            {data.idcardimg.length<=0&&(
                                <div><Tag outline>未上传</Tag></div>
                            )}
                        </div>
                    )
                }
            },
            {
                title:'授权书',
                width: '100px',
                textAlign: 'center',
                bodyRender:(data)=>{
                    if(data.sqimg.length>0)
                    {
                        return <Tag onClick={this.onGridItemImageClick.bind(this,data.sqimg)} style={{cursor:'pointer'}} theme={"blue"} outline>已上传</Tag>
                    }
                    else
                    {
                        return <Tag>未上传</Tag>
                    }
                }
            },
            {
                title:'店内环境',
                width: '100px',
                textAlign: 'center',
                bodyRender:(data)=>{
                    if(data.images.length>0)
                    {
                        return <Tag onClick={this.onGridItemImageClick.bind(this,data.images)} style={{cursor:'pointer'}} theme={"blue"} outline>3张</Tag>
                    }
                    else
                    {
                        return <Tag>未上传</Tag>
                    }
                }
            },
            {
                title:'注册时间',
                name:'time',
                width: '100px',
                textAlign: 'center'
            }
        ];
        if(index==2)
        {
            const data=[
                {
                    title:'驳回原因',
                    width: '180px',
                    bodyRender:(data)=>{
                        if(data.shenhe==2)
                        {
                            return <ClampLines
                                lines={1}
                                showPop={true}
                                popWidth={"300px"}
                                text={data.shenhetext}
                                extra={(
                                    <a>更多</a>
                                )}
                            />
                        }else
                        {
                            return <></>
                        }
                    }
                },
                {
                    title:'操作',
                    width:'100px',
                    textAlign:'center',
                    bodyRender:(data)=>{
                        return <div className="font12">
                            <Button type="default" onClick={this.onGridItemEditClick.bind(this,data.id)}>编辑</Button>
                        </div>
                    }
                }]
            listColumns.push(...data)
        }
        if(index==3)
        {
            const data=[
                {
                    title:'已发布',
                    name:'count',
                    width: '90px',
                    textAlign: 'center'
                },
                {
                    title:'状态',
                    width: '70px',
                    bodyRender:(data)=>{
                        return <div className="font12">
                            <Tag theme={data.state==1?"green":"yellow"}>{data.state==1?"正常":"停用"}</Tag>
                        </div>
                    }
                },
                {
                    title:'操作',
                    width:'180px',
                    textAlign:'center',
                    bodyRender:(data)=>{
                        return <div className="font12">
                            <Button type="default" onClick={this.onGridItemEditClick.bind(this,data.id)}>编辑</Button>
                            {data.state==1&&(
                                <Button type="warning" onClick={this.onGridItemStatusClick.bind(this,data.id,2)}>停用</Button>
                            )}
                            {data.state==2&&(
                                <Button type="success" onClick={this.onGridItemStatusClick.bind(this,data.id,1)}>启用</Button>
                            )}
                        </div>
                    }
                }]
            listColumns.push(...data)
        }
        return listColumns;
    }
    render() {
        return(
            <div>
                <Header data={[{name:'用户'},{name:'商户管理'}]}/>
                <div className="page-right-body">
                    <SearchFormView onChange={this.onSearchChange} data={this.state.searchData} dianpu={this.state.searchDianpuList}/>
                    <div className="page-right-body-bd" style={{paddingTop:'15px'}}>
                        <Tabs
                            activeId={this.state.shenheActive}
                            onChange={this.onTabsChange}
                            navExtraContent={
                                <Button onClick={this.onAddMerchantClick} type="primary">添加商户</Button>
                            }
                            type="card"
                        >
                            <TabPanel key="1" tab="审核中" id="1">
                                <Grid
                                    columns={this.getGridColumns(1)}
                                    datasets={this.state.shzDataList}
                                    pageInfo={{
                                        current: this.state.shzGridPageCurrent,
                                        pageSize: this.state.shzGridPageSize,
                                        total: this.state.shzGridPageTotal,
                                        pageSizeOptions: [this.state.shzGridPageSize],
                                    }}
                                    loading={this.state.shzGridLoading}
                                    onChange={this.onGridChange.bind(this,1)}
                                    ellipsis
                                    size={"medium"}
                                    rowKey="id"/>
                            </TabPanel>,
                            <TabPanel key="2" tab="未通过审核" id="2">
                                <Grid
                                    columns={this.getGridColumns(2)}
                                    datasets={this.state.wtgDataList}
                                    pageInfo={{
                                        current: this.state.wtgGridPageCurrent,
                                        pageSize: this.state.wtgGridPageSize,
                                        total: this.state.wtgGridPageTotal,
                                        pageSizeOptions: [this.state.wtgGridPageSize],
                                    }}
                                    loading={this.state.wtgGridLoading}
                                    onChange={this.onGridChange.bind(this,2)}
                                    ellipsis
                                    size={"medium"}
                                    rowKey="id"/>
                            </TabPanel>,
                            <TabPanel key="3" tab="已审核" id="3">
                                <Grid
                                    columns={this.getGridColumns(3)}
                                    datasets={this.state.ytgDataList}
                                    pageInfo={{
                                        current: this.state.ytgGridPageCurrent,
                                        pageSize: this.state.ytgGridPageSize,
                                        total: this.state.ytgGridPageTotal,
                                        pageSizeOptions: [this.state.ytgGridPageSize],
                                    }}
                                    loading={this.state.ytgGridLoading}
                                    onChange={this.onGridChange.bind(this,3)}
                                    ellipsis
                                    size={"medium"}
                                    rowKey="id"/>
                            </TabPanel>,
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}
function SearchFormView(props){
    const form=Form.useForm(FormStrategy.View);
    const editData=props.data;
    const dianuData=props.dianpu;
    const [showsearch,setShowsearch]=React.useState(true);
    const onSubmit=React.useCallback(form => {
        const value = form.getValue();
        const idcardimg=value.idcardimg;
        const shouquan=value.shouquan;
        const state=value.state;
        const dianpu=value.dianpu;
        const images=value.images;
        const searchdata={
            text:value.text,
            idcardimg:idcardimg==null?0:idcardimg.key,
            shouquan:shouquan==null?0:shouquan.key,
            images:images==null?0:images.key,
            state:state==null?0:state.key,
            dianpu:dianpu==null?0:dianpu.key,
            time:value.time,
        }
        if(typeof props.onChange=="function")
        {
            props.onChange({
                search:searchdata,
            });
        }
    },[])
    const onShowSearchClick=()=>{
        setShowsearch(!showsearch);
    }
    return(
        <div className={showsearch?"page-right-body-search":"page-right-body-search packup"}>
            <Form form={form} layout="horizontal" onSubmit={onSubmit}>
                <div className="page-right-body-search-cells">
                    <FormInputField
                        name="text"
                        label="关键字："
                        props={{
                            placeholder:"商户名称、手机号、联系人，为空全部",
                            width:'544px',
                            showClear:true
                        }}
                        initialValue={editData.text}
                    />
                    <div className="page-right-body-search-box">
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="dianpu"
                                label="所属店铺："
                                props={{
                                    options:dianuData,
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                                initialValue={Utils.returnItem(editData.dianpu,dianuData)}
                            />
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="state"
                                label="状态："
                                props={{
                                    width:'120px',
                                    options:[{key:1,text:'已启用'},{key:2,text:'已停用'}],
                                    clearable:true,
                                    placeholder:'为空全部',
                                }}
                                initialValue={Utils.returnItem(editData.state,[{key:1,text:'已启用'},{key:2,text:'已停用'}])}
                            />
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="idcardimg"
                                label="营业执照："
                                props={{
                                    width:'120px',
                                    options:[{key:1,text:'已上传'},{key:2,text:'未上传'}],
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                                initialValue={Utils.returnItem(editData.idcardimg,[{key:1,text:'已上传'},{key:2,text:'未上传'}])}
                            />
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="shouquan"
                                label="授权文件："
                                props={{
                                    width:'120px',
                                    options:[{key:1,text:'已上传'},{key:2,text:'未上传'}],
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                                initialValue={Utils.returnItem(editData.shouquan,[{key:1,text:'已上传'},{key:2,text:'未上传'}])}
                            />
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="images"
                                label="店内环境："
                                props={{
                                    width:'120px',
                                    options:[{key:1,text:'已上传'},{key:2,text:'未上传'}],
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                                initialValue={Utils.returnItem(editData.images,[{key:1,text:'已上传'},{key:2,text:'未上传'}])}
                            />
                        </div>
                    </div>

                    <FormDateRangeQuickPickerField
                        name="time"
                        label="注册时间："
                        props={{
                            width:'195px',
                            format:"YYYY-MM-DD HH:mm:ss",
                            chosenDays:7,
                            preset:[
                                {
                                    text: '今天',
                                    value: 0
                                },
                                {
                                    text: '昨天',
                                    value: 1
                                },
                                {
                                    text: '近7天',
                                    value: 7,
                                },
                                {
                                    text: '近30天',
                                    value: 30,
                                },
                            ]
                        }}
                        defaultValue={['','']}
                        initialValue={editData.time}
                    />
                    <div className="page-right-body-search-buttons">
                        <Button icon={"refresh"}  htmlType="reset">重置</Button>
                        <Button icon="search" type="primary" htmlType={"submit"} >搜索</Button>
                    </div>
                </div>
            </Form>
            <div className="page-right-body-search-packup" onClick={onShowSearchClick}></div>
        </div>

    )
}
