import React from 'react';
import {Grid, CombinedDateRangePicker, Button, Notify, Tag} from "zent";
import Service from "../config/Service";
export default class DataWorkListView extends React.Component{
    constructor(props, context) {
        super(props, context);
        this.state={
            gridPageCurrent:1,
            gridPageTotal:0,
            gridLoading:false,
            gridPageSize:10,
            dataList:[],
            timeData:[],
            gridAllCount:0
        }
    }
    onDataBind=(pageindex)=>{
        const _this=this;
        if(this.props.dianpu>0&&this.state.timeData.length===2)
        {
            this.setState({
                gridLoading:true
            });
            Service.Post({
                name:"DataStatistical",
                action:"workpagelist",
                data:{
                    dianpu:_this.props.dianpu,
                    youxiao:_this.props.youxiao,
                    startdate:_this.state.timeData[0],
                    enddate:_this.state.timeData[1],
                    pageindex:pageindex,
                    pagesize:_this.state.gridPageSize
                },
                success(result){
                    if(result.retcode==200)
                    {
                        _this.setState({
                            dataList:result.list,
                            gridPageTotal:result.count,
                            gridLoading:false,
                            gridAllCount:result.allcount
                        })
                    }
                    else
                    {
                        _this.setState({
                            dataList:[],
                            gridPageTotal:0,
                            gridAllCount:0,
                            gridLoading:false
                        })
                    }
                },
                fail(){
                    _this.setState({
                        dataList:[],
                        gridPageTotal:0,
                        gridAllCount:0,
                        gridLoading:false
                    })
                }
            })
        }else
        {
            _this.setState({
                dataList:[],
                gridPageTotal:0,
                gridAllCount:0,
                gridLoading:false
            })
        }
    }

    onGridChange=(e)=>{
        const current=e.current;
        this.setState({
            gridPageCurrent:current
        })
        this.onDataBind(current)
    }

    getGridColumns(){
        const Columns=[
            {
                title:'日期',
                name:'time',
                textAlign:'center',
                bodyRender:data=>{
                    return <div>{data.time}<Tag>{data.week}</Tag></div>
                }
            },
            {
                title:'新增',
                name:'count',
                textAlign:'center'
            }
        ]
        return Columns;
    }
    onTimeChange=val=>{
        this.setState({
            timeData:val
        })
    }
    onSearchClick=()=>{
        if(this.state.timeData.length!=2)
        {
            Notify.warn("请选择日期");
            return false;
        }
        this.onDataBind(0);
    }
    render() {
        return(
            <div>
                <div className="datamerchant-header">
                    <div className="datamerchant-header-hd">
                        <CombinedDateRangePicker
                            className="zent-datepicker-demo"
                            dateSpan={62}
                            value={this.state.timeData}
                            onChange={this.onTimeChange}
                        />
                    </div>
                    <div className="datamerchant-header-bd">
                        <Button type={"primary"} icon={"search"} onClick={this.onSearchClick}>搜索</Button>
                    </div>
                </div>
                <div className="datamerchant-body">
                    <div className="datamerchant-body-footer">共{this.state.gridAllCount}个职位</div>
                    <Grid
                    columns={this.getGridColumns()}
                    datasets={this.state.dataList}
                    pageInfo={{
                        current: this.state.gridPageCurrent,
                        pageSize:this.state.gridPageSize,
                        total: this.state.gridPageTotal,
                        pageSizeOptions: [this.state.gridPageSize],
                    }}
                    onChange={this.onGridChange}
                    loading={this.state.gridLoading}
                    ellipsis
                    size={"medium"}
                    rowKey="id"/>
                </div>
            </div>
        )
    }
}
