import React from 'react';
import Header from '../../../../component/header'
import {
    Button,
    Form,
    FormDateRangeQuickPickerField,
    FormInputField,
    FormSelectField,
    FormStrategy,
    Grid,
    Notify,
    Sweetalert,
    Tag,
} from 'zent'
import Service from "../../../../config/Service";

export default class MainSystemUserIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            gridPageCurrent:1,
            gridPageTotal:0,
            gridLoading:false,
            gridPageSize:20,
            dataList:[],
            searchRoleDataList:[],
            searchDianpuDataList:[],
            searchData:{
                text:'',
                role:0,
                dianpu:0,
                starttime:'',
                endtime:''
            }
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(110200);
        this.searchRoleBind();
        this.searchDianpuBind();
        this.onDataBind({
            pageindex:this.state.gridPageCurrent,
            search:this.state.searchData
        });
    }
    onDataBind=(res)=>{
        const _this=this;
        const {gridPageSize}=this.state;
        this.setState({
            gridLoading:true
        })
        Service.Post({
            name:"adminusers",
            action:"pagelist",
            data:{
                pageindex:res.pageindex,
                pagesize: gridPageSize,
                search:res.search
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        dataList:result.list,
                        gridPageTotal:result.count
                    })
                }
            },
            fail(){
                Notify.error("网络连接失败");
            },
            complete(){
                _this.setState({
                    gridLoading:false
                })
                if(typeof res.com=="function")
                {
                    res.com();
                }
            }
        })

    }
    searchRoleBind(){
        const _this=this;
        Service.Post({
            name:"adminrole",
            action:"list",
            data:{},
            success(result){
                if(result.retcode==200)
                {
                    const list=result.list;
                    _this.setState({
                        searchRoleDataList:list
                    })
                }
                else
                {
                    _this.setState({
                        searchRoleDataList:[]
                    })
                }
            },
            fail(){
                _this.setState({
                    searchRoleDataList:[]
                })
            }
        })
    }
    searchDianpuBind(){
        const _this=this;
        Service.Post({
            name:"admindianpu",
            action:"listbyadmin",
            data:{},
            success(result){
                if(result.retcode==200)
                {
                    const list=result.list;
                    _this.setState({
                        searchDianpuDataList:list
                    })
                }
                else
                {
                    _this.setState({
                        searchDianpuDataList:[]
                    })
                }
            },
            fail(){
                _this.setState({
                    searchDianpuDataList:[]
                })
            }
        })
    }
    onAddUserClick=()=>{
        this.props.history.push('user/edit/0');
    }
    onGridItemEditClick=(id)=>{
        this.props.history.push('user/edit/'+id);
    }
    onGridItemDeleteClick=(id)=>{
        const _this=this;
        Sweetalert.confirm({
            content:'操作成功后将无法恢复，请确认',
            title: '请确认操作',
            onConfirm: () => new Promise((resolve,reject) => {
                Service.Post({
                    name:"adminusers",
                    action:"delete",
                    data:{
                        id:id
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("已删除");
                            resolve();
                            _this.onDataBind({
                                pageindex: _this.state.gridPageCurrent,
                                search:_this.state.searchData
                            })
                        }
                        else
                        {
                            Notify.warn(result.retmsg);
                            reject();
                        }
                    },
                    fail(){
                        Notify.error("网络链接失败");
                        reject();
                    }
                })
            }),
            parentComponent:this
        });
    }
    onGridItemStatusClick=(id,state)=>{
        this.setState({
            gridLoading:true
        })
        const _this=this;
        Service.Post({
            name:"adminusers",
            action:"state",
            data:{
                id,
                state
            },
            success(result)
            {
                _this.setState({
                    gridLoading:false
                })
                if(result.retcode==200)
                {
                    Notify.success("操作成功");
                    _this.onDataBind({
                        pageindex:_this.state.gridPageCurrent,
                        search:_this.state.searchData
                    });
                }
                else
                {
                    Notify.warn(result.retmsg);
                }
            },
            fail(){
                _this.setState({
                    gridLoading:false
                })
            }
        })
    }
    onGridChange=(e)=>{
        const current=e.current;
        this.setState({
            gridPageCurrent:current
        })
        this.onDataBind({
            pageindex: current,
            search: this.state.searchData
        })
    }
    getGridColumns=()=>{
        const listColumns=[
            {
                title:'姓名',
                name:'name',
                width: '150px'
            },
            {
                title:'手机',
                name:'tel',
                width: '150px'
            },
            {
                title:'账号',
                name:'username',
                width: '150px'
            },
            {
                title:'角色',
                name:'role',
                width: '150px'
            },
            {
                title:'最后登录',
                name:'time',
                width: '200px'
            },
            {
                title:'负责店铺',
                name:'dianpu'
            },
            {
                title:'状态',
                width: '80px',
                bodyRender:(data)=>{
                    let statusView=null;
                    if(data.state==1)
                    {
                        statusView=<Tag theme={"green"}>正常</Tag>
                    }
                    else
                    {
                        statusView=<Tag theme={"yellow"}>禁用</Tag>
                    }
                    return <div className="font12">
                        {statusView}
                    </div>
                }
            },
            {
                title:'操作',
                width:'250px',
                textAlign:'center',
                bodyRender:(data,res)=>{
                    let statusView=null;
                    if(data.state==1)
                    {
                        statusView=<Button type="warning" onClick={this.onGridItemStatusClick.bind(this,data.id,2)}>禁用</Button>
                    }
                    else
                    {
                        statusView=<Button type="success" onClick={this.onGridItemStatusClick.bind(this,data.id,1)}>启用</Button>
                    }
                    return <div className="font12">
                        <Button type="default" onClick={this.onGridItemEditClick.bind(this,data.id)}>编辑</Button>
                        {statusView}
                        <Button type="error" onClick={this.onGridItemDeleteClick.bind(this,data.id)}>删除</Button>
                    </div>
                }
            }
        ];
        return listColumns;
    }
    render() {
        return(
            <div>
                <Header data={[{name:'系统'},{name:'员工管理'}]}/>
                <div className="page-right-body">
                    <SearchFormView parent={this} role={this.state.searchRoleDataList} dianpu={this.state.searchDianpuDataList}/>
                    <div className="page-right-body-hd">
                        <div className="page-right-body-hd-hd">
                        </div>
                        <div className="page-right-body-hd-bd">
                            <Button onClick={this.onAddUserClick} type="primary">添加员工</Button>
                        </div>
                    </div>
                    <div className="page-right-body-bd">
                        <Grid
                            columns={this.getGridColumns()}
                            datasets={this.state.dataList}
                            pageInfo={{
                                current: this.state.gridPageCurrent,
                                pageSize: this.state.gridPageSize,
                                total: this.state.gridPageTotal,
                                pageSizeOptions: [this.state.gridPageSize],
                            }}
                            loading={this.state.gridLoading}
                            onChange={this.onGridChange.bind(this)}
                            ellipsis
                            size={"medium"}
                            rowKey="id"/>
                    </div>
                </div>
            </div>
        )
    }
}
function SearchFormView(props){
    const form=Form.useForm(FormStrategy.View);
    const roleData= props.role;
    const dianpuData= props.dianpu;
    const [loading,setLoading]=React.useState(false);
    const [showsearch,setShowsearch]=React.useState(true);
    const onSubmit=React.useCallback(form => {
        setLoading(true);
        const value = form.getSubmitValue();
        const role=value.role;
        const dianpu=value.dianpu;
        return new Promise(resolve => {
            setLoading(true);
            const pageindex=1;
            const searchdata={
                text:value.search,
                role:role==null?0:role.key,
                dianpu:dianpu==null?0:dianpu.key,
                starttime:value.time[0],
                endtime:value.time[1]
            }
            props.parent.setState({
                gridPageCurrent: pageindex,
                searchData:searchdata
            })
            props.parent.onDataBind({
                pageindex,
                search:searchdata,
                com(){
                    setLoading(false);
                }
            });
        });
    },[])
    const onShowSearchClick=()=>{
        setShowsearch(!showsearch);
    }
    return(
        <div className={showsearch?"page-right-body-search":"page-right-body-search packup"}>
            <Form form={form} layout="horizontal" onSubmit={onSubmit} disabled={loading}>
                <div className="page-right-body-search-cells">
                    <FormInputField
                        name="search"
                        label="关键字："
                        props={{
                            placeholder:"姓名、手机号、账号",
                            width:'520px',
                            showClear:true
                        }}/>
                    <div className="page-right-body-search-box">
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="role"
                                label="角色："
                                props={{
                                    width:'200px',
                                    options:roleData,
                                    clearable:true,
                                    placeholder:'所属角色'
                                }}

                            />
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="dianpu"
                                label="负责店铺："
                                props={{
                                    width:'200px',
                                    options:dianpuData,
                                    clearable:true,
                                    placeholder:'负责店铺'
                                }}
                            />
                        </div>
                    </div>
                    <FormDateRangeQuickPickerField
                        name="time"
                        label="最后登录时间："
                        props={{
                            width:'195px',
                            format:"YYYY-MM-DD HH:mm:ss",
                            chosenDays:7,
                            preset:[
                                {
                                    text: '今天',
                                    value: 0
                                },
                                {
                                    text: '昨天',
                                    value: 1
                                },
                                {
                                    text: '近7天',
                                    value: 7,
                                },
                                {
                                    text: '近30天',
                                    value: 30,
                                },
                            ]
                        }}
                        defaultValue={['','']}
                    />
                    <div className="page-right-body-search-buttons">
                        <Button icon={"refresh"}  htmlType="reset">重置</Button>
                        <Button icon="search" type="primary" htmlType={"submit"} loading={loading}>搜索</Button>
                    </div>
                </div>
            </Form>
            <div className="page-right-body-search-packup" onClick={onShowSearchClick}></div>
        </div>

    )
}
