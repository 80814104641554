import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter,Switch,Route} from "react-router-dom";
import './index.css';
import 'zent/css/index.css';

import Login from './pages/Login'
import Main from './pages/Main'
import reportWebVitals from './reportWebVitals';
ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path="/login" exact component={Login}/>
            <Route render={(props)=>(
                <Main {...props}/>
            )}/>
        </Switch>
    </BrowserRouter>,document.getElementById('root'))
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
