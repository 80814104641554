import React from 'react';
import Header from '../../../../component/header'
import {
    Button,
    Grid,
    Notify,
    Sweetalert,
    Tag,
    Select,
    Pop,
    ClampLines
} from 'zent'
import Service from "../../../../config/Service";
import Utils from "../../../../config/Utils";

export default class MainConstantWorkContentIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            gridPageCurrent:1,
            gridPageTotal:0,
            gridLoading:false,
            gridPageSize:20,
            dataList:[],
            searchClassValue:0,
            searchClassList:[]
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(160500);
        this.dataBind(1,0);
        this.onClassListBind();
    }
    dataBind=(pageindex,workclass)=>{
        const _this=this;
        const {gridPageSize}=this.state;
        this.setState({
            gridLoading:true
        })
        Service.Post({
            name:"adminzhiweicontent",
            action:"pagelist",
            data:{
                pageindex:pageindex,
                pagesize: gridPageSize,
                workclass:workclass
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        dataList:result.list,
                        gridPageTotal:result.count
                    })
                }
            },
            fail(){
                Notify.error("网络连接失败");
            },
            complete(){
                _this.setState({
                    gridLoading:false
                })
            }
        })

    }
    onClassListBind=()=>{
        const _this=this;
        Service.Post({
            name:"adminzhiweiclass",
            action:"list",
            data:{},
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        searchClassList:result.list
                    })
                }
            }
        })
    }
    onAddWorkContentClick=()=>{
        this.props.history.push('workcontent/edit/0');
    }
    onGridItemEditClick=(id)=>{
        this.props.history.push('workcontent/edit/'+id);
    }
    onGridItemDeleteClick=(id)=>{
        const _this=this;
        Sweetalert.confirm({
            content:'操作成功后将无法恢复，请确认',
            title: '请确认操作',
            onConfirm: () => new Promise((resolve,reject) => {
                Service.Post({
                    name:"adminzhiweicontent",
                    action:"delete",
                    data:{
                        id:id
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("已删除");
                            resolve();
                            _this.dataBind(_this.state.gridPageCurrent,_this.state.searchClassValue)
                        }
                        else
                        {
                            Notify.warn(result.retmsg);
                            reject();
                        }
                    },
                    fail(){
                        Notify.error("网络链接失败");
                        reject();
                    }
                })
            }),
            parentComponent:this
        });
    }
    onGridItemStatusClick=(id,state)=>{
        this.setState({
            gridLoading:true
        })
        const _this=this;
        Service.Post({
            name:"adminzhiweicontent",
            action:"state",
            data:{
                id,
                state
            },
            success(result)
            {
                _this.setState({
                    gridLoading:false
                })
                if(result.retcode==200)
                {
                    Notify.success("操作成功");
                    _this.dataBind(_this.state.gridPageCurrent,_this.state.searchClassValue);
                }
                else
                {
                    Notify.warn(result.retmsg);
                }
            },
            fail(){
                _this.setState({
                    gridLoading:false
                })
            }
        })
    }
    onGridChange=(e)=>{
        const current=e.current;
        this.setState({
            gridPageCurrent:current
        })
        this.dataBind(current,this.state.searchClassValue)
    }
    onSearchSelectChange=(e)=>{
        let value=0;
        if(e!=null)
        {
            value=e.key;
        }
        this.setState({
            searchClassValue:value,
            gridPageCurrent:1,
        })
        this.dataBind(1,value);
    }
    getGridColumns=()=>{
        const listColumns=[
            {
                title:'所属类别',
                name:'workclass',
                width:'150px'
            },
            {
                title:'内容',
                bodyRender:data => {
                    return(
                        <div style={{maxWidth:'360px'}}>
                            <ClampLines
                          lines={1}
                          popWidth={360}
                          showPop={true}
                          text={data.text}
                          renderPop={()=>{
                              return(
                                  <pre>{data.text}</pre>
                              )
                          }}
                        />
                        </div>
                    )
                }
            },
            {
                title:'状态',
                width: '150px',
                bodyRender:(data)=>{
                    return <div className="font12">
                        {data.state==1&&(<Tag theme={"green"}>正常</Tag>)}
                        {data.state==2&&(<Tag theme={"yellow"}>停用</Tag>)}
                    </div>
                }
            },
            {
                title:'操作',
                width:'250px',
                textAlign:'center',
                bodyRender:(data,res)=>{
                    return <div className="font12">
                        <Button type="default" onClick={this.onGridItemEditClick.bind(this,data.id)}>编辑</Button>
                        {data.state==1&&(<Button type="warning" onClick={this.onGridItemStatusClick.bind(this,data.id,2)}>停用</Button>)}
                        {data.state==2&&(<Button type="success" onClick={this.onGridItemStatusClick.bind(this,data.id,1)}>启用</Button>)}
                        <Button type="error" onClick={this.onGridItemDeleteClick.bind(this,data.id)}>删除</Button>
                    </div>
                }
            }
        ];
        return listColumns;
    }
    render() {
        return(
            <div>
                <Header data={[{name:'常量'},{name:'工作介绍模板'}]}/>
                <div className="page-right-body">
                    <div className="page-right-body-hd">
                        <div className="page-right-body-hd-hd">
                            <Select
                                placeholder={"所属类别，为空全部"}
                                options={this.state.searchClassList}
                                clearable
                                value={Utils.returnItem(this.state.searchClassValue,this.state.searchClassList)}
                                onChange={this.onSearchSelectChange}
                            />
                        </div>
                        <div className="page-right-body-hd-bd">
                            <Button onClick={this.onAddWorkContentClick} type="primary">添加工作介绍模板</Button>
                        </div>
                    </div>
                    <div className="page-right-body-bd">
                        <Grid
                            columns={this.getGridColumns()}
                            datasets={this.state.dataList}
                            pageInfo={{
                                current: this.state.gridPageCurrent,
                                pageSize: this.state.gridPageSize,
                                total: this.state.gridPageTotal,
                                pageSizeOptions: [this.state.gridPageSize],
                            }}
                            loading={this.state.gridLoading}
                            onChange={this.onGridChange.bind(this)}
                            ellipsis
                            size={"medium"}
                            rowKey="id"/>
                    </div>
                </div>
            </div>
        )
    }
}