import React from 'react';
import Header from '../../../../component/header'
import { Form, FormStrategy, Notify, FormInputField,Button,
    FormSelectField,BlockLoading,FormSwitchField,FormNumberInputField} from 'zent';
import Service from "../../../../config/Service";
import Utils from "../../../../config/Utils";
export default class MainConstantDianpuEdit extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            dianpuId:0,
            editData:{},
            cityList:[],
            loading:false
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(160200);
        const id=this.props.match.params.id||0;
        this.setState({
            dianpuId:id
        })
        this.cityBind();
        this.dataBind(id);
    }
    cityBind(){
        const _this=this;
        Service.Post({
            name:"admincity",
            action:"list",
            data:{},
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        cityList:result.list
                    })
                }
            }
        })
    }

    dataBind(id){
        if(id<=0)
        {
            return false;
        }
        const _this=this;
        this.setState({
            loading:true
        })
        Service.Post({
            name:"admindianpu",
            action:"news",
            data:{
                id
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        editData:result.data,
                        loading:false
                    })
                }
                else
                {
                    Notify.error("没有找到相关信息",1500,()=>{
                        _this.setState({
                            loading:false
                        })
                        _this.props.history.goBack();
                    });
                }

            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    loading:false
                })
            }
        })
    }
    FormView=()=>{
        const _this=this;
        const editData=this.state.editData;
        const stateLoading=this.state.loading;
        const cityList=this.state.cityList;
        const form = Form.useForm(FormStrategy.View);
        const [loading,setLoading]=React.useState(false);
        const onSubmit = React.useCallback(form => {
            setLoading(true);
            const value = form.getSubmitValue();
            return new Promise(resolve => {
                const id=_this.state.dianpuId;
                Service.Post({
                    name:"admindianpu",
                    action:"edit",
                    data:{
                        id,
                        name:value.name,
                        city:value.city.key,
                        address:value.address,
                        lxrname:value.lxrname,
                        lxrtel:value.lxrtel,
                        kftel:value.kftel,
                        sort:value.sort,
                        type:value.type?1:2,
                        state:value.state?1:2,
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("提交成功",1000,()=>{
                                setLoading(false);
                                _this.props.history.goBack();
                            })
                        }
                        else
                        {
                            Notify.error(result.retmsg);
                            setLoading(false);
                        }
                    },
                    fail(){
                        Notify.error("网络连接失败");
                        setLoading(false);
                    }
                })
            });
        }, []);
        return(
            <Form
                disabled={loading}
                form={form}
                layout="horizontal"
                onSubmit={onSubmit}>
                <FormInputField
                    name="name"
                    label="名称："
                    required
                    props={{
                        maxLength:15,
                        placeholder:"店铺名称",
                    }}
                    initialValue={editData.name}
                />
                <FormInputField
                    name="address"
                    label="地址："
                    required
                    props={{
                        maxLength:15,
                        placeholder:"店铺地址",
                        width:'400px'
                    }}
                    initialValue={editData.address}
                />
                <FormInputField
                    name="lxrname"
                    label="联系人："
                    required
                    props={{
                        maxLength:15,
                        placeholder:"店铺联系人姓名",
                    }}
                    initialValue={editData.lxrname}
                />
                <FormInputField
                    name="lxrtel"
                    label="联系电话："
                    required
                    props={{
                        maxLength:11,
                        placeholder:"店铺联系电话",
                    }}
                    initialValue={editData.lxrtel}
                />
                <FormInputField
                    name="kftel"
                    label="客服电话："
                    required
                    props={{
                        maxLength:20,
                        placeholder:"会员联系客服时的电话",
                    }}
                    initialValue={editData.kftel}
                />
                <FormSelectField
                    name="city"
                    label={"所属城市"}
                    required
                    props={{
                        options:cityList,
                        placeholder:"所属城市",
                    }}
                    initialValue={Utils.returnItem(editData.city,cityList)}
                />
                <FormNumberInputField
                    name="sort"
                    label="排序："
                    required
                    props={{
                        max:99999,
                        min:0,
                        placeholder:"排序数字"
                    }}
                    helpDesc={"数字越大排序越靠前"}
                    initialValue={editData.sort||0}
                />
                <FormSwitchField
                    name="type"
                    label="前端展示："
                    initialValue={editData.type==2?false:true}
                />
                <FormSwitchField
                    name="state"
                    label="启用："
                    initialValue={editData.state==2?false:true}
                />
                {!stateLoading&&(
                    <div className="form-submit app-fixed-container">
                        <Button type="default" onClick={()=>{_this.props.history.goBack();}}>取消</Button>
                        <Button htmlType="submit" type="primary" loading={loading}>保存</Button>
                    </div>)
                }
            </Form>
        )
    }

    render() {
        const FormView=this.FormView.bind();
        const {loading}=this.state;
        return(
            <div>
                <Header data={[{name:'常量'},{name:'店铺管理',href:'/main/constant/dianpu'},{name:'编辑店铺'}]}/>
                <BlockLoading loading={loading}>
                    <div className="page-right-body">
                        <div className="page-right-body-hd"></div>
                        <div className="page-right-body-bd">
                            <FormView/>
                        </div>
                    </div>
                </BlockLoading>
            </div>
        )
    }
}
