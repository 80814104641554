import React, { useEffect } from 'react';
import {BlockLoading, Icon, Button, Input, Notify, Select} from 'zent';
import Service from "../config/Service";
import '../style/css/workmap.css'
let _map=null;
let _marker=null;
export default function (props)
{
    const _location=props.location||{lat:0,lng:0};
    const _city=props.city;
    const _address=props.address;
    const [loading,setLoading]=React.useState(false);
    const [value,setValue]=React.useState('');
    const [dataList,setDataList]=React.useState([]);
    const [location,setLocation]=React.useState({lat:0,lng:0})
    const [options,setOptions]=React.useState([]);
    const [cityValue,setCityValue]=React.useState({});
    const onLoad=()=> {
        _map = new window.TMap.Map("mapcontainer", {
            zoom: 16,//设置地图缩放级别
        });
        _map.on("click", (e) => {
            const lng = e.latLng.lng;
            const lat = e.latLng.lat;
            onAddMarker({lat, lng});
        })
        if(_location.lat!=0&&_location.lng!=0)
        {
            onAddMarker(_location);
        }
        setLoading(false);
    }
    const onAddMarker=(location)=>{
        onClearMarker();
        _marker=new window.TMap.MultiMarker({
            map: _map,  //指定地图容器
            geometries: [
                {
                    "id": "1",
                    "position": new window.TMap.LatLng(location.lat, location.lng),
                }
            ]
        });
        _map.panTo(new window.TMap.LatLng(location.lat,location.lng));
        setLocation(location);
    }
    const onClearMarker=()=>{
        if (_marker!=null) {
            _marker.setGeometries([]);
            _marker=null;
        }
    }
    const onInput=(e)=>{
        setValue(e.target.value);
    }
    const onSearch=()=>{
        let city=_city;
        if(cityValue.key)
        {
            city=cityValue.key;
        }
        placeSearchBind(city,value);
    }
    const onItemClick=(lat,lng)=>{
        onAddMarker({lat, lng});
    }
    const onClose=()=>{
        if(typeof props.onClose=="function")
        {
            props.onClose();
        }
    }
    const onSubmit=()=>{
        if(value.length<=0)
        {
            Notify.warn('请输入工作详细地址');
            return false;
        }
        if(location.lat==0||location.lng==0)
        {
            Notify.warn('请在地图上进行标记');
            return false;
        }
        if(typeof props.onClose=="function")
        {
            props.onSubmit({
                address:value,
                location:location
            });
        }
    }
    const placeSearchBind=(city,text)=>{
        if(text.length>0)
        {
            Service.Post({
                name:"qqmap",
                action:"listbysearch",
                data:{
                    city:city,
                    text:text
                },
                success(result)
                {
                    if(result.retcode==200)
                    {
                        const retlist=result.list;
                        if(retlist.length)
                        {
                            const location=retlist[0].location;
                            onAddMarker(location);
                        }
                        setDataList(retlist);
                    }
                    else
                    {
                        setDataList([])
                    }
                },
                fail(){
                    setDataList([])
                }
            })
        }
    }
    const onSelectChange=(e)=>{
        setCityValue(e);
    }
    useEffect(() => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://map.qq.com/api/gljs?v=1.exp&key=UMVBZ-CGCW6-5HMSB-M24JD-TX3HS-4IF4G";
        script.async = true;
        document.body.appendChild(script);
        setLoading(true);
        setTimeout(()=>{
            onLoad();
        },1000);
        setValue(props.address);
        if(_city.indexOf('太原')>-1)
        {
            setCityValue({key:'太原市',text:'太原市'});
            setOptions([{key:'太原市',text:'太原市'},{key:'晋中市',text:'晋中市'}])
        }
        // 组件卸载时移除script
        return () => {
            document.body.removeChild(script);
        };
    }, []); // 空依赖数组确保只在组件挂载时运行一次
    return (
        <BlockLoading loading={loading}>
            <div className="medialogaddress">
                <div className="medialogaddress-hd">
                    <div className="medialogaddress-hd-title">
                        工作地址
                    </div>
                    <div className="medialogaddress-hd-close" onClick={onClose}>
                        <Icon type="close" />
                    </div>
                </div>
                <div className="medialogaddress-bd">
                    <div id="mapcontainer" className="map" />
                    {!loading&&(
                        <>
                            <div id="me-search">
                                {options.length>1&&(
                                    <div className="me-search-se">
                                    <Select options={options} width={100} value={cityValue} onChange={onSelectChange}/>
                                </div>
                                )}
                                <div className="me-search-box">
                                    <Input onInput={onInput} value={value}  placeholder="请输入工作地址"/>
                                </div>
                                <div className="me-search-button">
                                    <Button type="primary" onClick={onSearch}>确定</Button>
                                </div>
                            </div>
                            <div id="me-searchlist">
                                {dataList.map((item,index)=>(
                                    <div className="cell" key={index} onClick={onItemClick.bind(this,item.location.lat,item.location.lng)}>
                                        <div className="cell-hd">
                                            <div className="cell-hd-title">{item.title}</div>
                                            <div className="cell-hd-label">{item.address}</div>
                                        </div>
                                        <div className="cell-bd"></div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}

                </div>
                <div className="medialogaddress-ft">
                    <Button onClick={onClose}>关闭</Button>
                    <Button type="primary" onClick={onSubmit}>保存</Button>
                </div>
            </div>
        </BlockLoading>
    )
}