import React from 'react';
import Header from '../../../../component/header'
import {
    Form,
    FormStrategy,
    Notify,
    FormInputField,
    Button,
    FormRadioGroupField,
    Radio,
    FormSelectField,
    FormNumberInputField,
    FormCheckboxGroupField,
    Validators,
    Checkbox,
    BlockLoading,
    FormSwitchField,
    ValidatorMiddlewares
} from 'zent';
import Service from "../../../../config/Service";
import Utils from "../../../../config/Utils";
const { FieldValue } = Form;
export default class MainSystemProductEdit extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            productId:0,
            editData:{},
            dianpuList:[],
            productList:[],
            loading:false
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(110300);
        const id=this.props.match.params.id||0;
        this.setState({
            productId:id
        })
        this.dianpuBind();
        this.productBind();
        this.dataBind(id);
    }
    dianpuBind(){
        const _this=this;
        Service.Post({
            name:"admindianpu",
            action:"listbyadmin",
            data:{
                type:1
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        dianpuList:result.list
                    })
                }
            }
        })
    }
    productBind(){
        const _this=this;
        Service.Post({
            name:"adminproduct",
            action:"listbytype",
            data:{
                type:1
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        productList:result.list
                    })
                }
            }
        })
    }
    dataBind(id){
        if(id<=0)
        {
            return false;
        }
        const _this=this;
        this.setState({
            loading:true
        })
        Service.Post({
            name:"adminproduct",
            action:"news",
            data:{
                id
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        editData:result.data,
                        loading:false
                    })
                }
                else
                {
                    Notify.error("没有找到相关信息",1500,()=>{
                        _this.setState({
                            loading:false
                        })
                        _this.props.history.goBack();
                    });
                }

            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    loading:false
                })
            }
        })
    }
    FormView=()=>{
        const _this=this;
        const editData=this.state.editData;
        const stateLoading=this.state.loading;
        const dianpulist=this.state.dianpuList;
        const form = Form.useForm(FormStrategy.View);
        const [loading,setLoading]=React.useState(false);
        const [dianpuchecked,setDianpuchecked]=React.useState(false);
        const [indeterminate,setIndeterminate]=React.useState(false);
        const onSubmit = React.useCallback(form => {
            setLoading(true);
            const value = form.getSubmitValue();
            const time=value.time?value.time:0;
            const jjmoney=value.jjmoney?value.jjmoney:0;
            const kfmoney=value.kfmoney?value.kfmoney:0;
            const product=value.product?value.product.key:0;
            return new Promise(resolve => {
                const id=_this.state.productId;
                Service.Post({
                    name:"adminproduct",
                    action:"edit",
                    data:{
                        id,
                        name:value.name,
                        money:value.money,
                        time:value.type==1?time:0,
                        sort:value.sort,
                        product:value.type==2?product:0,
                        dianpu:JSON.stringify(value.dianpu),
                        jjmoney:jjmoney,
                        kfmoney:kfmoney,
                        state:value.state?1:2,
                        type:value.type
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("提交成功",1000,()=>{
                                setLoading(false);
                                _this.props.history.goBack();
                            })
                        }
                        else
                        {
                            Notify.error(result.retmsg);
                            setLoading(false);
                        }
                    },
                    fail(){
                        Notify.error("网络连接失败");
                        setLoading(false);
                    }
                })
            });
        }, []);
        const dianpuonChange=(e)=>{
            setIndeterminate(false)
            const checked=e.target.checked;
            if(checked)
            {
                const items=dianpulist.map((item)=>{
                    return item.key;
                })
                form.patchValue({
                    dianpu:items
                })
                setDianpuchecked(true);
            }
            else
            {
                form.patchValue({
                    dianpu:[]
                })
                setDianpuchecked(false);
            }
        }
        const dianpuboxonChange=(e)=>{
            setIndeterminate(e.length>0&&e.length!==dianpulist.length)
            setDianpuchecked(e.length>0 && e.length === dianpulist.length);
        }
        React.useEffect(() => {
            const filedianpu=form.model.get('dianpu');
            const $=filedianpu.value$.subscribe(value => {
                if(value&&value.length) {
                    setIndeterminate(value.length>0&&value.length!==dianpulist.length)
                    setDianpuchecked(value.length>0 && value.length === dianpulist.length);
                }
            });
            return () =>{
                $.unsubscribe();
            };
        }, [form]);
        return(
            <Form
                disabled={loading}
                form={form}
                layout="horizontal"
                onSubmit={onSubmit}>
                <FormRadioGroupField
                    name={"type"}
                    label={"产品类型："}
                    required={"请选择类型"}
                    initialValue={editData.type||1}
                >
                    <Radio value={1}>商品</Radio>
                    <Radio value={2}>定金</Radio>
                    <Radio value={3}>补费</Radio>
                </FormRadioGroupField>
                <FormInputField
                    name="name"
                    label="产品名称："
                    required
                    props={{
                        maxLength:10,
                        placeholder:"请输入产品名称",
                    }}
                    initialValue={editData.name}
                />

                <FormNumberInputField
                    name="money"
                    label="产品金额："
                    required
                    props={{
                        decimal:2,
                        max:99999,
                        min:0,
                        placeholder:"请输入产品金额"
                    }}
                    initialValue={editData.money}
                />
                <FieldValue name={"type"}>
                    {value=>(value==1&&(
                        <>
                            <FormNumberInputField
                                name="time"
                                label="增加天数："
                                required
                                props={{
                                    max:9999,
                                    min:0,
                                    placeholder:"请输增加天数"
                                }}
                                validators={[
                                    ValidatorMiddlewares.when(
                                        ctx => ctx.getSectionValue("type").type==1?true:false
                                    )(Validators.required('请输入增加天数')),
                                ]}
                                initialValue={editData.time}
                            />
                            <FormNumberInputField
                                name="jjmoney"
                                label="业务员奖金："
                                required
                                props={{
                                    decimal:2,
                                    max:99999,
                                    min:0,
                                    placeholder:"请输入业务员奖金"
                                }}
                                initialValue={editData.jjmoney}
                            />
                            <FormNumberInputField
                                name="kfmoney"
                                label="客服部奖金："
                                required
                                props={{
                                    decimal:2,
                                    max:99999,
                                    min:0,
                                    placeholder:"请输入客服部奖金"
                                }}
                                initialValue={editData.kfmoney}
                            />
                        </>
                    ))}
                </FieldValue>
                <FormNumberInputField
                    name="sort"
                    label="排序："
                    required
                    props={{
                        max:99999,
                        min:0,
                        placeholder:"请输入排序数字"
                    }}
                    helpDesc={"数字越大排序越靠前"}
                    initialValue={editData.sort||0}
                />
                <div className="page-right-body-form">
                    <div className="page-right-body-form-hd">
                        售卖店铺：
                    </div>
                    <div className="page-right-body-form-bd page-border-bottom">
                        <Checkbox
                            checked={dianpuchecked}
                            indeterminate={indeterminate}
                            onChange={dianpuonChange}
                        >全部</Checkbox>
                    </div>
                </div>
                <FormCheckboxGroupField
                    name="dianpu"
                    required="请选择店铺"
                    initialValue={editData.dianpu}
                    validators={[Validators.minLength(1, '至少选择一个店铺')]}
                    onChange={dianpuboxonChange}
                >
                    {dianpulist.map((item,index)=>(
                        <Checkbox key={index} value={item.key}>{item.text}</Checkbox>
                    ))}
                </FormCheckboxGroupField>

                <FormSwitchField
                    name="state"
                    label="启用："
                    initialValue={editData.state==2?false:true}
                />
                {!stateLoading&&(
                    <div className="form-submit app-fixed-container">
                        <Button type="default" onClick={()=>{_this.props.history.goBack();}}>取消</Button>
                        <Button htmlType="submit" type="primary" loading={loading}>保存</Button>
                    </div>)
                }
            </Form>
        )
    }

    render() {
        const FormView=this.FormView.bind();
        const {loading}=this.state;
        return(
            <div>
                <Header data={[{name:'系统'},{name:'产品管理',href:'/main/system/product'},{name:'编辑产品'}]}/>
                <BlockLoading loading={loading}>
                    <div className="page-right-body">
                        <div className="page-right-body-hd"></div>
                        <div className="page-right-body-bd">
                            <FormView/>
                        </div>
                    </div>
                </BlockLoading>
            </div>
        )
    }
}
