const Parameter={
    name:'兼途99后端',
    usersession:"admimess",
    server:'https://webbackadminapi.jiantu99.com',
    //server:'https://jt99backadminapi.jiantu99.com',
    uploadServer:'https://image.jiantu99.com'
    //jt99backadminapi
    //webbackadminapi
}
export default Parameter
