import {Link} from "react-router-dom";
import {Icon,Notify, Pop, Tooltip, Dialog, Form, FormStrategy, FormInputField, Validators,Button,Badge,Sweetalert,Notice,Switch} from "zent";
import React,{useState} from "react";
import Service from "../config/Service";
import Parameter from "../config/Config";

export default class MainLeftView extends React.Component{
    constructor(props, context) {
        super(props, context);
        this.props.onRef(this)
        this.state={
            badgeCount:0,
            menuList:[],
            menuId:0
        }
    }
    componentWillMount() {
        this.onDataBind();
        this.onNotice();
        this.onRemind();
        setInterval(()=>{
            this.onNotice();
        },5000)
        setInterval(()=>{
            this.onRemind();
        },9000)
    }
    onNotice=()=>{
        const _this=this;
        Service.Post({
            name:"AdminMessages",
            action:"count",
            data:{},
            success(result){
                if(result.retcode==200){
                    _this.setState({
                        badgeCount:result.count
                    })
                }
            }
        })
    }
    onRemind=()=>{
        const _this=this;
        Service.Post({
            name:"Remind",
            action:"remind",
            data:{},
            success(result){
                if(result.retcode==200){
                    const reList=result.list;
                   let list=JSON.parse(JSON.stringify(_this.state.menuList));
                    list.map((item,index)=>{
                        const itemid=(item.id+'').substring(0,2);
                        reList.map((iit)=>{
                            const iitid=(iit.id+'').substring(0,2);
                            if(itemid==iitid)
                            {
                                item.badge=iit.count
                            }
                        })
                    })
                    _this.setState({
                        menuList:list
                    })
                }
            }
        })
    }
    onSetMenuId(id){
        this.setState({
            menuId:id
        })
    }
    onDataBind(){
        const _this=this;
        Service.Post({
            name:"AdminMenus",
            action:"listbyadmin",
            data:{},
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        menuList:result.list
                    })
                }
            }
        })
    }
    render() {
        const {badgeCount,badgeShenhe,menuList,menuId}=this.state;
        return(
            <div className="page-left">
                <div className="left-nav">
                    <div className="left-nav-header theme-border-bottom">
                        <Link to={"/main/notice/notice"} className="left-nav-header-hd">
                            <Badge count={badgeCount}>
                                <Icon type="bell" className="demo-cont" />
                            </Badge>
                        </Link>
                        <div className="left-nav-header-bd">{JSON.parse(sessionStorage.getItem(Parameter.usersession)).name}</div>
                    </div>

                    <div className="left-nav-body">
                        <div className="left-nav-body-cells">
                            {NavView(menuList,menuId,badgeShenhe)}
                        </div>
                    </div>
                    <div className="left-nav-footer">
                        <FooterView/>
                    </div>
                </div>
                {MenuView(menuList,menuId)}
            </div>
        )
    }
}
function equalsPassword(value, ctx){
    if (value !== ctx.getSectionValue('npwd').npwd) {
        return {
            name: 'passwordEqual',
            message: '两次填写的密码不一致',
        };
    }
    return null;
}
function FooterView(){
    const form = Form.useForm(FormStrategy.View);
    const [showDialog,setShowDialog]=useState(false);
    const [alertLoading,setAlertLoading]=useState(false);
    const [alertValue,setAlertValue]=useState(false);
    const [showAlert,setShowAlert]=useState(false);
    const [loading,setLoading]=useState(false);
    const onShowDialog=()=>{
        form.resetValue();
        setShowDialog(true);
    }
    const onShowAlert=()=>{
        setShowAlert(true);
        setAlertLoading(true);
        Service.Post({
            name:"AdminUsers",
            action:"alertnews",
            data:{},
            success(result)
            {
                if(result.retcode==200)
                {
                   setAlertValue(result.alert==1?true:false)
                }
            },
            complete(){
                setAlertLoading(false);
            }
        })
    }
    const onCloseAlert=()=>{
        setShowAlert(false);
    }
    const onAlertChange=(e)=>{
        setAlertLoading(true);
        Service.Post({
            name:"AdminUsers",
            action:"alert",
            data:{
                alert:e?1:2
            },
            success(result)
            {
                if(result.retcode==200)
                {
                  setAlertValue(e);
                }
                else
                {
                    Notify.error(result.retmsg);
                }
            },
            fail(){
                Notify.error('网络连接失败');
            },
            complete(){
                setAlertLoading(false);
            }
        })
    }
    const onCloseDialog=()=>{
        form.resetValue();
        form.model.clearError();
        setShowDialog(false);
    }
    const onSetPwdSubmit=React.useCallback(form => {
        setLoading(true);
        const value = form.getSubmitValue();
        Service.Post({
            name:"AdminUsers",
            action:"setpwd",
            data:{
                opwd:value.opwd,
                npwd:value.npwd
            },
            success(result){
                if(result.retcode==200)
                {
                    Notify.success("操作成功");
                    setLoading(false);
                    setShowDialog(false);
                }
                else
                {
                    Notify.warn(result.retmsg);
                    setLoading(false);
                }
            },
            fail(){
                Notify.error("网络连接失败");
                setLoading(false);
            }
        })
    }, []);
    const onExitLoginClick=()=>{
        Sweetalert.confirm({
            content: <p>确定要退出登录吗？</p>,
            onConfirm: ()=>{
                sessionStorage.clear();
                window.location.replace("/login")
            },
            parentComponent: this
        });
    }
    const PopSystemView = Pop.withPop(function Content({ pop }) {
      return (
       <div className="left-nav-footer-list" onClick={pop.close}>
           <div className="left-nav-footer-list-cell" onClick={onShowDialog}>修改密码</div>
           <div className="left-nav-footer-list-cell" onClick={onShowAlert}>报名提醒</div>
       </div>
      );
    });
    return(
        <div className="left-nav-footer-cells">
            <div className="left-nav-footer-cell">
                <span>
                        <Pop className="left-nav-footer-list-pop" trigger="click" position={"top-left"} content={<PopSystemView/>}>
                            <Icon type="settings-o" spin />
                        </Pop>
                    </span>
                <Dialog  maskClosable={false}
                    className="zent-dialog-demo-basic-dialog"
                    visible={showDialog}
                    onClose={onCloseDialog}
                    title="修改密码"
                >
                    <Form
                        disabled={loading}
                        form={form}
                        layout="horizontal"
                        onSubmit={onSetPwdSubmit}
                    >
                        <FormInputField
                            name="opwd"
                            label="原密码："
                            required
                            props={{
                                type:"password",
                                maxLength:16,
                                placeholder:"请输入原密码",
                                width:'249px'
                            }}
                            validators={[
                                Validators.pattern(/^((?=.*[a-zA-Z])(?=.*\d).{6,16})?$/, '原密码错误')
                            ]}
                            defaultValue={''}
                        />
                        <FormInputField
                            name="npwd"
                            label="新密码："
                            required
                            props={{
                                type:"password",
                                maxLength:16,
                                placeholder:"请输入原密码",
                                width:'249px'
                            }}
                            validators={[
                                Validators.pattern(/^((?=.*[a-zA-Z])(?=.*\d).{6,16})?$/, '至少包含字母、数字，6-16位')
                            ]}
                            defaultValue={''}
                        />
                        <FormInputField
                            name="qpwd"
                            label="确认密码："
                            required
                            validators={[equalsPassword]}
                            props={{
                                type:"password",
                                maxLength:16,
                                placeholder:"请输入原密码",
                                width:'249px'
                            }}
                            defaultValue={''}
                        />
                        <div style={{
                            textAlign:'right'
                        }}>
                            <Button onClick={onCloseDialog}>
                                关闭
                            </Button>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                修改
                            </Button>
                        </div>
                    </Form>
                </Dialog>
                <Dialog maskClosable={false}
                    visible={showAlert}
                    onClose={onCloseAlert}
                    title="报名提醒设置"
                >
                    <div className="defcell">
                        <div className="defcell-hd">设置报名提醒</div>
                        <div className="defcell-bd">
                            <Switch
                                checked={alertValue}
                                loading={alertLoading}
                                onChange={onAlertChange}
                            />
                        </div>
                    </div>
                    <div className="defcell">
                        <div className="defcell-hd"></div>
                        <div className="defcell-bd">
                            当前状态为：{alertValue?'开启':'关闭'}
                        </div>
                    </div>
                </Dialog>
            </div>
            <div className="left-nav-footer-cell">
                <Tooltip trigger="hover" title="退出登录">
                    <span onClick={onExitLoginClick}><Icon type="sign-out" /></span>
                </Tooltip>
            </div>
        </div>
    )
}
function NavView(menuList,menuId,badgeCount=0){
    const menucode=(menuId+'').substring(0,2);
    const view=menuList.map((item,index)=>{
        const idcode=(item.id+'').substring(0,2);
        const cellActiveClass=idcode==menucode?'left-nav-body-cell active':'left-nav-body-cell';
        const icon=idcode==menucode?item.icon:item.icon+'-o'
        const url=item.children?item.children[0].url:item.url;
        const IconView=()=>{
            if(item.badge)
            {
                return(
                    <Badge dot={item.badge>0}>
                        <Icon type={icon} />
                    </Badge>
                )
            }
            else
            {
                return (
                    <Icon type={icon} />
                )
            }
        }
        return (
            <div className={cellActiveClass} key={'nav'+index}>
                <NavPopView list={idcode==menucode?[]:item.children} current={index}>
                    <Link to={{pathname:url}} className="left-nav-body-cell-box">
                        <div className="left-nav-body-cell-hd">
                            <IconView/>
                        </div>
                        <div className="left-nav-body-cell-bd">
                            {item.title}
                        </div>
                    </Link>
                </NavPopView>
            </div>
        )
    })
    return view;
}
function NavPopView(e){
    const list=e.list;
    const current=e.current;
    let view;
    if(list&&list.length>0)
    {
        const ItemView=list.map((item,index)=>{
            return(
                <div className="left-nav-pop-cell" key={'navpop'+current+'item'+index}>
                    <Link to={item.url}>{item.title}</Link>
                </div>
            )
        })
        view= <Pop trigger="hover" className="left-nav-pop" position="right-top" cushion={20} centerArrow content={
            <div className="left-nav-pop-cells">
                {ItemView}
            </div>
        }>
            {e.children}
        </Pop>
    }
    else
    {
        view=e.children;
    }
    return view;
}
function MenuView(menuList,menuId){
    let list=[];
    let activeIndex=0;
    const menuCode=(menuId+'').substring(0,2);
    menuList.map((item,index)=>{
        const idcode=(item.id+'').substring(0,2);
        if(idcode==menuCode)
        {
            list=item.children||[];
            activeIndex=index;
        }
    });
    if(list&&list.length>0)
    {
        const ListView=list.map((item,index)=>{
            const cellActiveClass=item.id==menuId?'left-menu-body-cell active':'left-menu-body-cell';
            return(
                <Link className={cellActiveClass} to={item.url} key={'menu'+activeIndex+''+index}>
                    {item.title}
                </Link>
            )
        })
        return (
            <div className="left-menu">
                <div className="left-menu-header">
                    {menuList[activeIndex].title}
                </div>
                <div className="left-menu-body">
                    <div className="left-menu-body-cells">
                        {ListView}
                    </div>
                </div>
            </div>
        );
    }
    else
    {
        return <></>
    }
}
