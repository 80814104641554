import React,{useCallback} from 'react';
import Header from '../../../../component/header'
import {
    Form, FormStrategy, Notify, FormInputField, Button,
    FormNumberInputField,
    FormCheckboxGroupField,
    Checkbox,
    Validators,
    FormCheckboxField,
    FormCombinedDateRangePickerField,
    FormTimePickerField,
    FieldSet,
    FieldUtils,
    ValidatorMiddlewares,
    FormRadioGroupField,
    Radio,
    FormSelectField, BlockLoading, Icon,
    Dialog,
    Tag
} from 'zent';
import Service from "../../../../config/Service";
import Utils from "../../../../config/Utils";
import AddressMapComponent from "../../../../component/AddressMapComponent";
import "../../../../style/css/main/work/work/edit.css";
const { FieldValue } = Form;
const { openDialog,closeDialog} = Dialog;
const TagsOptions=[
    {
        key:'交通补助',
        text:'交通补助'
    },
    {
        key:'包住宿',
        text:'包住宿'
    },
    {
        key:'包工作餐',
        text:'包工作餐'
    },
    {
        key:'加班补助',
        text:'加班补助'
    },
    {
        key:'有提成',
        text:'有提成'
    },
    {
        key:'奖金奖励',
        text:'奖金奖励'
    },
    {
        key:'转正机会',
        text:'转正机会'
    }
];

export default class MainWorkWorkEdit extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            workId:0,
            editData:{},
            dianpuList:[],
            loading:false
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(130000);
        const workid=this.props.match.params.id||0;
        const copyid=this.props.match.params.copy||0
        this.setState({
            workId:workid,
        })
        this.dianpuBind();
        this.dataBind(copyid);
    }
    dianpuBind(){
        const _this=this;
        Service.Post({
            name:"admindianpu",
            action:"listbyadmin",
            data:{},
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        dianpuList:result.list
                    })
                }
            }
        })
    }
    dataBind(id){
        if(id<=0)
        {
            return false;
        }
        const _this=this;
        this.setState({
            loading:true
        })
        Service.Post({
            name:"adminzhiwei",
            action:"news",
            data:{
                id
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        editData:result.data,
                        loading:false
                    })
                }
                else
                {
                    Notify.error("没有找到相关信息",1500,()=>{
                        _this.setState({
                            loading:false
                        })
                        _this.props.history.goBack();
                    });
                }

            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    loading:false
                })
            }
        })
    }
    FormView=()=>{
        const _this=this;
        const workId=this.state.workId;
        const editData=this.state.editData;
        const stateLoading=this.state.loading;
        const dianpulist=this.state.dianpuList;
        const form = Form.useForm(FormStrategy.View);
        const [loading,setLoading]=React.useState(false);
        const [gongsiList,setGongsiList]=React.useState([]);
        const [textRefreshList,setTextRefreshList]=React.useState([]);
        const [classList,setClassList]=React.useState([]);
        const [textRefreshIndex,setTextRefreshIndex]=React.useState(-1);
        const [merchantkeyword,onMerchantKeywordChange]=React.useState('');
        const [merchantloading, setMerchantLoading] = React.useState(false);
        const [showPhone,setShowPhone]=React.useState(true);
        const [searchText,setSearchText]=React.useState('');
        const [cityName,setCityName]=React.useState('');
        const onSubmit = React.useCallback(form => {
            setLoading(true);
            const value = form.getSubmitValue();
            const address=value.addresslocation.address;
            const location=value.addresslocation.location;
            return new Promise(resolve => {
                Service.Post({
                    name:"adminzhiwei",
                    action:"edit",
                    data:{
                        id:workId,
                        title:value.title,
                        workclass:value.workclass.key,
                        dianpu:value.dianpu.key,
                        city:value.dianpu.city,
                        ontop:value.ontop==true?1:2,
                        zeren:value.zeren==true?1:2,
                        money:value.money,
                        moneyunit:value.moneyunit.key,
                        jiesuantime:value.jiesuantime.key,
                        sex:value.sex.key,
                        renshu:value.renshu,
                        workhits:value.workhits==true?1:2,
                        xianzhi:value.xianzhi==true?1:2,
                        signdate:value.signdate,
                        signtimestart:value.signtimestart,
                        signtimeend:value.signtimeend,
                        mianshi:value.mianshi==true?1:2,
                        mianshiaddress:value.mianshi?value.mianshiaddress:'',
                        mianshitime:value.mianshi?value.mianshitime:'',
                        workdate:value.workdate,
                        worktimestart:value.worktimestart,
                        worktimeend:value.worktimeend,
                        workwuxiu:value.workwuxiu,
                        sort:value.sort,
                        gongsi:value.gongsi.key,
                        lxrname:value.lxrname,
                        lxrtel:value.lxrtel,
                        lxrtype:value.lxrtype,
                        address:address,
                        tags:JSON.stringify(Utils.returnKeys(value.tags,'text')),
                        text:value.text,
                        locationlng:value.workhits?location.lng:0,
                        locationlat:value.workhits?location.lat:0,
                        zsdianpu:JSON.stringify(dianpulist.length>1?value.zsdianpu:Utils.returnKeys(dianpulist))
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("提交成功",1000,()=>{
                                setLoading(false);
                                //_this.props.history.goBack();
                                window.location.href="/main/work/work"
                            })
                        }
                        else
                        {
                            Notify.error(result.retmsg);
                            setLoading(false);
                        }
                    },
                    fail(){
                        Notify.error("网络连接失败");
                        setLoading(false);
                    }
                })
            });
        }, []);
        const onRefreshTextClick=()=>{
            const index=textRefreshIndex+1;
            if(index<textRefreshList.length)
            {
                setTextRefreshIndex(index);
                form.patchValue({
                    text:textRefreshList[index].text
                })
            }
            else
            {
                setTextRefreshIndex(0);
                form.patchValue({
                    text:textRefreshList[0].text
                })
            }
        }
        const onChongzTextClick=()=>{
            if(editData.text)
            {
                form.patchValue({
                    text:editData.text
                })
            }
            else
            {
                form.patchValue({
                    text:''
                })
            }
        }
        const MrchantRrenderOptionContent=(item)=>{
            const { key, text,contact} = item;
            return <>{text}({contact.name}-{contact.content})<span style={{paddingLeft:'5px'}}><Tag theme={contact.type==1?"blue":"green"}>{contact.type==1?"电话":"微信"}</Tag></span></>;
        }
        React.useEffect(() => {
            if(merchantkeyword.length>2)
            {
                const dianpu=form.getValue().dianpu.key;
                setMerchantLoading(true);
                setGongsiList([]);
                Service.Post({
                    name: "webmerchants",
                    action:"listbytext",
                    data: {
                        dianpu:dianpu,
                        text:merchantkeyword
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            setGongsiList(result.list);
                        }
                        else
                        {
                            setGongsiList([]);
                        }
                        setMerchantLoading(false);
                    },
                    fail()
                    {
                        setGongsiList([]);
                        setMerchantLoading(false)
                    }
                })
            }

        }, [merchantkeyword]);
        React.useEffect(()=>{
            const $=form.model.get('workclass').value$.subscribe(value => {
                if(value!=null&&value.key) {
                    Service.Post({
                        name:"adminzhiweicontent",
                        action:"listbyclass",
                        data:{
                            workclass:value.key
                        },
                        success(result)
                        {
                            if(result.retcode==200)
                            {

                                const list=result.list;
                                setTextRefreshList(list);
                                if(list.length>0&&workId<=0)
                                {
                                    setTextRefreshIndex(0);
                                    if(!editData.text)
                                    {
                                        form.patchValue({
                                            text:list[0].text
                                        })
                                    }
                                }
                                else
                                {
                                    onChongzTextClick()
                                }
                            }
                            else
                            {
                                onChongzTextClick()
                            }
                        },
                        fail(){
                            onChongzTextClick()
                        }
                    })
                }
                else
                {
                    onChongzTextClick();
                }
            });
            const $1=form.model.get('gongsi').value$.subscribe(value=>{
                if(value!=null&&value.key)
                {
                    const gongsivalue=value.key;
                    form.patchValue({
                        lxrname:value.contact.name,
                        lxrtel:value.contact.content,
                        lxrtype:value.contact.type,
                        workclass:null
                    })
                    Service.Post({
                        name:"adminzhiweiclass",
                        action:"listbygongsi",
                        data:{
                            gongsi:gongsivalue
                        },
                        success(result)
                        {
                            if(result.retcode==200)
                            {
                                setClassList(result.list)
                            }
                            else
                            {
                                setClassList([])
                            }
                        },
                        fail(){
                            setClassList([])
                        }
                    })
                }
            });
            const $2=form.model.get('dianpu').value$.subscribe(value=>{
                if(value&&value.key)
                {
                    if(value.key!=editData.dianpu)
                    {
                        form.patchValue({
                            gongsi:null
                        })
                    }
                    const cityname=value.cityname||'';
                    setCityName(cityname);
                }
            });
            return () =>{
                $.unsubscribe();
                $1.unsubscribe();
                $2.unsubscribe();
            };
        },[form])
        const onAddressBlur=(e)=>{
            if(workId==0)
            {
                const address=e.target.value;
                let cityname="";
                const dianpu=form.getValue().dianpu;
                if(dianpu!=null)
                {
                    cityname=dianpu.cityname||"";
                }
                setSearchText(address);
            }
        }
        return(
            <Form
                disabled={loading}
                form={form}
                layout="vertical"
                onSubmit={onSubmit}>
                <div className="page-right-body-form-cells">
                    <div className="page-right-body-form-cell">
                        <FormInputField
                            name="title"
                            label={<p className="mee__xx"><span>*</span>职位标题</p>}
                            required
                            props={{
                                width:'500px',
                                maxLength:40,
                                placeholder:"请输入职位标题。例：万达招聘服务员",
                            }}
                            initialValue={editData.title}
                        />
                    </div>

                    <div className="page-right-body-form-cell">
                        <FormSelectField
                            name="dianpu"
                            label={<p className="mee__xx"><span>*</span>所属店铺</p>}
                            required
                            props={{
                                width:'200px',
                                options:dianpulist,
                                placeholder:"请选择所属店铺",
                            }}
                            initialValue={Utils.returnItem(editData.dianpu,dianpulist)}
                        />
                    </div>
                    <div className="page-right-body-form-cell padding-right-5">
                        <FormNumberInputField
                            name="sort"
                            label={<p className="mee__xx"><span>*</span>排序</p>}
                            required
                            props={{
                                width:'90px',
                                min:1,
                                max:999999,
                                maxLength:6,
                                placeholder:"排序数值",
                            }}
                            defaultValue={"1"}
                            initialValue={editData.sort}
                        />
                    </div>
                    <div className="page-right-body-form-cell">
                        <FormCheckboxField
                            name="ontop"
                            label="置顶"
                            initialValue={editData.ontop}
                        />
                    </div>
                    <div className="page-right-body-form-cell">
                        <FormCheckboxField
                            name="zeren"
                            label="无责底薪"
                            initialValue={editData.zeren}
                        />
                    </div>
                </div>
                <div className="page-right-body-form-cells">
                    <div className="page-right-body-form-cell padding-right-5">
                        <FormNumberInputField
                            name="money"
                            label={<p className="mee__xx"><span>*</span>薪资待遇</p>}
                            required
                            props={{
                                width:'191px',
                                min:0.01,
                                max:999999,
                                maxLength:9,
                                decimal:2,
                                placeholder:"请输入薪资待遇",
                            }}
                            initialValue={editData.money}
                        />
                    </div>
                    <div className="page-right-body-form-cell padding-right-5">
                        <FormSelectField
                            name="moneyunit"
                            label={<p className="page-right-body-form-cell-nan"></p>}
                            required
                            props={{
                                options:[
                                    {key:'日',text:'日'},
                                    {key:'小时',text:'小时'},
                                    {key:'周',text:'周'},
                                    {key:'月',text:'月'},
                                    {key:'次',text:'次'},
                                    {key:'单',text:'单'}
                                ],
                                width:'80px',
                            }}
                            defaultValue={{key:'日',text:'日'}}
                            initialValue={editData.moneyunit}
                        />
                    </div>
                    <div className="page-right-body-form-cell">
                        <FormSelectField
                            name="jiesuantime"
                            label={<p className="page-right-body-form-cell-nan"></p>}
                            required
                            props={{
                                options:[
                                    {key:'日结',text:'日结'},
                                    {key:'周结',text:'周结'},
                                    {key:'月结',text:'月结'},
                                    {key:'任务完结',text:'任务完结'},
                                    {key:'随时结',text:'随时结'}
                                ],
                                width:'100px',
                                placeholder:"请选择",
                            }}
                            defaultValue={{key:'日结',text:'日结'}}
                            initialValue={editData.jiesuantime}
                        />
                    </div>
                    <div className="page-right-body-form-cell">
                        <FormSelectField
                            name="sex"
                            label={<p className="mee__xx"><span>*</span>性别要求</p>}
                            required
                            props={{
                                options:[{key:1,text:'限男生'},{key:2,text:'限女生'},{key:3,text:'男女不限'}],
                                width:'120px',
                                placeholder:"请选择",
                            }}
                            initialValue={editData.sex}
                        />
                    </div>
                    <div className="page-right-body-form-cell">
                        <FormNumberInputField
                            name="renshu"
                            label={<p className="mee__xx"><span>*</span>招聘人数</p>}
                            required
                            props={{
                                width:'140px',
                                min:1,
                                max:999,
                                maxLength:3,
                                placeholder:"请输入招聘人数",
                            }}
                            initialValue={editData.renshu}
                        />
                    </div>
                    <div className="page-right-body-form-cell">
                        <FormCheckboxField
                            name="xianzhi"
                            label="同时段限制报名"
                            defaultValue={true}
                            initialValue={editData.xianzhi}
                        />
                    </div>
                    <div className="page-right-body-form-cell">
                        <FormCheckboxField
                            name="workhits"
                            label="计算工作次数"
                            defaultValue={true}
                            initialValue={editData.workhits}
                        />
                    </div>
                </div>
                <div className="page-right-body-form-cells">
                    <div className="page-right-body-form-cell">
                        <FormCombinedDateRangePickerField
                            name="signdate"
                            label={<p className="mee__xx"><span>*</span>报名日期范围</p>}
                            validators={[
                                function required(value) {
                                    if (!value[0] || !value[1]) {
                                        return {
                                            name: 'required',
                                            message: '请选择报名日期范围',
                                        };
                                    }
                                },
                            ]}
                            props={{
                                disabledDate:{
                                    min:new Date()
                                }
                            }}
                            defaultValue={['','']}
                            initialValue={editData.signdate}
                        />
                    </div>
                    <div className="page-right-body-form-cell">
                        <FormTimePickerField
                            name="signtimestart"
                            required
                            label={<p className="mee__xx"><span>*</span>每日报名开始</p>}
                            props={{
                                width:'100px',
                                format:'HH:mm',
                                placeholder:"请选择",
                                minuteStep:5,
                                disabledTime:()=>({disabledHours:()=>[0,1]})
                            }}
                            defaultValue={'09:00'}
                            initialValue={editData.signtimestart}
                        />
                    </div>
                    <div className="page-right-body-form-cell">
                        <FormTimePickerField
                            name="signtimeend"
                            required
                            label={<p className="mee__xx"><span>*</span>每日报名结束</p>}
                            props={{
                                width:'100px',
                                format:'HH:mm',
                                placeholder:"请选择",
                                minuteStep:5
                            }}
                            defaultValue={'17:00'}
                            initialValue={editData.signtimeend}
                        />
                    </div>
                    <div className="page-right-body-form-cell">
                        <FormCheckboxField
                            name="mianshi"
                            label="需要面试"
                            initialValue={editData.mianshi}
                        />
                    </div>
                    <FieldValue name="mianshi">
                        {mianshi=>(
                            mianshi&&(
                                <>
                                    <div className="page-right-body-form-cell">
                                        <FormInputField
                                            name="mianshitime"
                                            label={<p className="mee__xx"><span>*</span>面试时间</p>}
                                            required
                                            props={{
                                                maxLength:40,
                                                placeholder:"请选择面试时间",
                                            }}
                                            validators={[
                                                ValidatorMiddlewares.when(
                                                    ctx => ctx.getSectionValue("mianshi")?.mianshi
                                                )(Validators.required('请选择面试时间')),
                                            ]}
                                            initialValue={editData.mianshitime}
                                        />
                                    </div>
                                    <div className="page-right-body-form-cell">
                                        <FormInputField
                                            name="mianshiaddress"
                                            label={<p className="mee__xx"><span>*</span>面试地址</p>}
                                            required
                                            props={{
                                                maxLength:80,
                                                placeholder:"请输入面试地址",
                                            }}
                                            validators={[
                                                ValidatorMiddlewares.when(
                                                    ctx => ctx.getSectionValue("mianshi")?.mianshi
                                                )(Validators.required('请选择面试时间')),
                                            ]}
                                            initialValue={editData.mianshiaddress}
                                        />
                                    </div>
                                </>
                            )
                        )}
                    </FieldValue>
                </div>
                <div className="page-right-body-form-cells">
                    <div className="page-right-body-form-cell">
                        <FormCombinedDateRangePickerField
                            name="workdate"
                            label={<p className="mee__xx"><span>*</span>工作日期范围</p>}
                            validators={[
                                function required(value) {
                                    if (!value[0] || !value[1]) {
                                        return {
                                            name: 'required',
                                            message: '请选择工作日期范',
                                        };
                                    }
                                },
                            ]}
                            props={{
                                disabledDate:{
                                    min:new Date()
                                }
                            }}
                            defaultValue={['','']}
                            initialValue={editData.workdate}
                        />
                    </div>

                    <div className="page-right-body-form-cell">
                        <FormTimePickerField
                            name="worktimestart"
                            required
                            label={<p className="mee__xx"><span>*</span>每日工作开始</p>}
                            props={{
                                width:'100px',
                                format:'HH:mm',
                                placeholder:"请选择",
                                minuteStep:5,
                                disabledTime:()=>({disabledHours:()=>[0,1]})
                            }}
                            defaultValue={'09:30'}
                            initialValue={editData.worktimestart}
                        />
                    </div>
                    <div className="page-right-body-form-cell">
                        <FormTimePickerField
                            name="worktimeend"
                            required
                            label={<p className="mee__xx"><span>*</span>每日工作结束</p>}
                            props={{
                                width:'100px',
                                format:'HH:mm',
                                placeholder:"请选择",
                                minuteStep:5
                            }}
                            defaultValue={'18:00'}
                            initialValue={editData.worktimeend}
                        />
                    </div>
                    <div className="page-right-body-form-cell">
                        <FormInputField
                            name="workwuxiu"
                            label="午休"
                            props={{
                                placeholder:"没有休息不填。例如：2小时",
                            }}
                            defaultValue={"2小时"}
                            initialValue={editData.workwuxiu}
                        />
                    </div>
                </div>
                <div className="page-right-body-form-cells">
                    <div className="page-right-body-form-cell">
                        <FieldValue name={"dianpu"}>
                            {dianpu=>(
                                <FormSelectField
                                    name="gongsi"
                                    label={<p className="mee__xx"><span>*</span>所属商户</p>}
                                    required
                                    props={{
                                        width:'360px',
                                        options:gongsiList,
                                        disabled:dianpu==null?true:false,
                                        placeholder:"输入商户名称、姓名、电话搜索,最少3个字符",
                                        keyword:merchantkeyword,
                                        loading:merchantloading,
                                        onKeywordChange:onMerchantKeywordChange,
                                        filter:false,
                                        clearable:true,
                                        renderOptionContent:MrchantRrenderOptionContent
                                    }}
                                    initialValue={editData.gongsi}
                                />
                            )}
                        </FieldValue>
                    </div>
                    <div className="page-right-body-form-cell">
                        <FormInputField
                            name="lxrname"
                            label={<p className="mee__xx"><span>*</span>联系人姓名</p>}
                            required
                            props={{
                                placeholder:"请输入联系人姓名",
                                disabled:true
                            }}
                            initialValue={editData.lxrname}
                        />
                    </div>
                    <div className="page-right-body-form-cell">
                        <FormInputField
                            name="lxrtel"
                            label={<p className="mee__xx"><span>*</span>联系人电话/微信</p>}
                            required
                            props={{
                                placeholder:"请输入联系人电话/微信",
                                disabled:true
                            }}
                            initialValue={editData.lxrtel}
                        />
                    </div>
                    <div className="page-right-body-form-cell">
                        <FormRadioGroupField
                            name="lxrtype"
                            label={<p className="mee__xx"><span>*</span>联系方式</p>}
                            required
                            initialValue={editData.lxrtype}
                            defaultValue={"1"}
                            props={{
                                disabled:true
                            }}
                        >
                            <Radio value={"1"}>电话</Radio>
                            <Radio value={"2"}>微信</Radio>
                        </FormRadioGroupField>
                    </div>
                </div>
                <div className="page-right-body-form-cells">
                    <div className="page-right-body-form-cell">
                        <FieldValue name={"gongsi"}>
                            {gongsi=>(
                                <FormSelectField
                                    name="workclass"
                                    label={<p className="mee__xx"><span>*</span>职位类别</p>}
                                    required
                                    props={{
                                        disabled:gongsi==null?true:false,
                                        width:'130px',
                                        options:classList,
                                        placeholder:"请选择"
                                    }}
                                    initialValue={Utils.returnItem(editData.class,classList)}
                                />
                            )}
                        </FieldValue>
                    </div>
                    <div className="page-right-body-form-cell">
                        <FieldSet name={"addresslocation"} validators={[addresslocationValidator]}>
                            <AddressLocationView city={cityName} address={editData.address} location={{lat:editData.locationlat,lng:editData.locationlng}}/>
                        </FieldSet>
                    </div>
                </div>
                <div className="page-right-body-form-cells">
                    <div className="page-right-body-form-cell">
                        <FormSelectField
                            name="tags"
                            label="福利待遇"
                            props={{
                                options:TagsOptions,
                                width:'700px',
                                clearable:true,
                                creatable:true,
                                multiple:true,
                                placeholder:"选择、输入福利待遇",
                            }}
                            initialValue={editData.tags}
                        />
                    </div>
                </div>
                <div className="page-right-body-form-cells">
                    <div className="page-right-body-form-cell">
                        <FormInputField
                            name="text"
                            className={"mee__title"}
                            label={<div className="mee__title">
                                <div className="mee__title-hd">
                                    <p className="mee__xx"><span>*</span>工作介绍</p>
                                </div>
                                <div className="mee__title-bd">
                                    <Button type={"text"} onClick={onChongzTextClick}>重置</Button>
                                </div>
                                {textRefreshList.length>0&&(
                                    <div className="mee__title-bd margin-left-10 color-link">
                                        <Button onClick={onRefreshTextClick} icon={"refresh"} type={"text"}>更换模板</Button>
                                    </div>
                                )}
                            </div>}
                            required
                            props={{
                                width:'360px',
                                autoSize:true,
                                type:"textarea",
                                placeholder:"请输入工作介绍",
                                maxLength:1000,
                                showCount:true,
                            }}
                            initialValue={editData.text}
                        />
                    </div>
                </div>
                {dianpulist.length>1&&(
                    <div className="page-right-body-form-cells">
                        <div className="page-right-body-form-cell">
                            <FormCheckboxGroupField
                                name="zsdianpu"
                                label={<p className="mee__xx"><span>*</span>展示店铺</p>}
                                required={"至少选择一项"}
                                validators={[
                                    ValidatorMiddlewares.when(
                                        ctx => dianpulist.length>1
                                    )(Validators.required('至少选择一项')),
                                ]}
                                defaultValue={Utils.returnKeys(dianpulist)}
                                initialValue={editData.zsdianpu}
                            >
                                {dianpulist.map((item,index)=>(
                                    <Checkbox key={index} value={item.key}>{item.text}</Checkbox>
                                ))}
                            </FormCheckboxGroupField>
                        </div>
                    </div>
                )}
                <div className="phonePPBtn">
                    <Button onClick={()=>setShowPhone(!showPhone)}>{showPhone==true?"隐藏展示":"显示展示"}</Button>
                </div>
                {showPhone&&(
                    <div className="phonePP">
                        <div className="phonePP-hd"></div>
                        <div className="phonePP-bd">
                            <div className="phonemain">
                                <div className="phonemain-header">
                                    <div className="phonemain-header-title">
                                        <FieldValue name="title"/>
                                    </div>
                                    <div className="phonemain-header-money">
                                        <div className="phonemain-header-money-hd">
                                            <FieldValue name="money"/>
                                            <span>
                                                元/
                                                <FieldValue name="moneyunit">
                                                      {value => `${value.text}`}
                                                </FieldValue>
                                            </span>
                                        </div>
                                        <div className="phonemain-header-money-bd">
                                            <FieldValue name="jiesuantime">
                                                {value =>`${value.text}`}
                                            </FieldValue>
                                        </div>
                                        <div className="phonemain-header-money-ft">
                                            <FieldValue name="sex">
                                                {value => (value!=null&&value.key&&(value.text))}
                                            </FieldValue>
                                        </div>
                                        <div className="phonemain-header-money-bd">
                                            <FieldValue name="zeren">
                                                {value => (value==true&&('无责底薪'))}
                                            </FieldValue>
                                        </div>
                                    </div>
                                    <FieldValue name="tags">
                                        {value=>(value.length>0&&(
                                            <div className="phonemain-header-tags">
                                                {value.map((item,index)=>(
                                                    <div className="phonemain-header-tags-cell" key={index}>
                                                        {item.text}
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </FieldValue>
                                    <div className="phonemain-header-list">
                                        <div className="phonemain-header-list-title">
                                            还没有小伙伴发现本职位哦~！
                                        </div>
                                    </div>
                                </div>
                                <div className="phonemain-body">
                                    <div className="phonemain-body-box">
                                        <div className="phonemain-body-box-cell">
                                            <div className="phonemain-body-box-cell-hd">
                                                <Icon type="checkin-o" style={{color:'#ED8384'}}/>
                                            </div>
                                            <div className="phonemain-body-box-cell-bd">
                                                <div className="phonemain-body-box-cell-bd-hd">报名日期</div>
                                                <div className="phonemain-body-box-cell-bd-bd">
                                                    <FieldValue name="signdate">
                                                        {value=>(value[0]+'~'+value[1])}
                                                    </FieldValue>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="phonemain-body-box-cell">
                                            <div className="phonemain-body-box-cell-hd">
                                                <Icon type="clock-o" style={{color:'#18CBF1'}}/>
                                            </div>
                                            <div className="phonemain-body-box-cell-bd">
                                                <div className="phonemain-body-box-cell-bd-hd">报名时间段</div>
                                                <div className="phonemain-body-box-cell-bd-bd">
                                                    <FieldValue name="signtimestart"/>
                                                    -
                                                    <FieldValue name="signtimeend"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="phonemain-body-box">
                                        <div className="phonemain-body-box-cell">
                                            <div className="phonemain-body-box-cell-hd">
                                                <Icon type="checkin-o" style={{color:'#ED8384'}}/>
                                            </div>
                                            <div className="phonemain-body-box-cell-bd">
                                                <div className="phonemain-body-box-cell-bd-hd">工作日期</div>
                                                <div className="phonemain-body-box-cell-bd-bd">
                                                    <FieldValue name="workdate">
                                                        {value=>(value[0]+'~'+value[1])}
                                                    </FieldValue>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="phonemain-body-box-cell">
                                            <div className="phonemain-body-box-cell-hd">
                                                <Icon type="clock-o" style={{color:'#18CBF1'}}/>
                                            </div>
                                            <div className="phonemain-body-box-cell-bd">
                                                <div className="phonemain-body-box-cell-bd-hd">工作时间段</div>
                                                <div className="phonemain-body-box-cell-bd-bd">
                                                    <FieldValue name="worktimestart"/>
                                                    -
                                                    <FieldValue name="worktimeend"/>
                                                </div>
                                            </div>
                                        </div>
                                        <FieldValue name="addresslocation">
                                            {value=>(value.address.length>0&&(
                                                <div className="phonemain-body-box-cell">
                                                    <div className="phonemain-body-box-cell-hd">
                                                        <Icon type="expand-customer-o" style={{color:'#867ae3'}}/>
                                                    </div>
                                                    <div className="phonemain-body-box-cell-bd">
                                                        <div className="phonemain-body-box-cell-bd-hd">
                                                            {value.address}
                                                        </div>
                                                        <div className="phonemain-body-box-cell-bd-bd">
                                                            <span>距我 00.00km</span>
                                                        </div>
                                                    </div>
                                                    <div className="phonemain-body-box-cell-ft">
                                                        <Icon type="right" />
                                                    </div>
                                                </div>
                                            ))}
                                        </FieldValue>
                                    </div>
                                    <FieldValue name={"mianshi"}>
                                        {value=>(value&&(
                                            <div className="phonemain-body-title">
                                                需面试
                                            </div>
                                        ))}
                                    </FieldValue>
                                    <FieldValue name={"mianshi"}>
                                        {value=>(value&&(
                                            <div className="phonemain-body-box">
                                                <div className="phonemain-body-box-cell">
                                                    <div className="phonemain-body-box-cell-hd">
                                                        <Icon type="clock" style={{color:'#F79B15'}}/>
                                                    </div>
                                                    <div className="phonemain-body-box-cell-bd">
                                                        <div className="phonemain-body-box-cell-bd-hd">面试时间</div>
                                                        <div className="phonemain-body-box-cell-bd-bd">
                                                            <FieldValue name={"mianshitime"}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="phonemain-body-box-cell">
                                                    <div className="phonemain-body-box-cell-hd">
                                                        <Icon type="expand-customer" style={{color:'#00A9E7'}}/>
                                                    </div>
                                                    <div className="phonemain-body-box-cell-bd">
                                                        <div className="phonemain-body-box-cell-bd-hd">面试地址</div>
                                                        <div className="phonemain-body-box-cell-bd-bd">
                                                            <FieldValue name={"mianshiaddress"}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </FieldValue>
                                    <div className="phonemain-body-title">
                                        联系信息
                                    </div>
                                    <div className="phonemain-body-box">
                                        <div className="phonemain-body-box-cell">
                                            <div className="phonemain-body-box-cell-hd">
                                                <Icon type="customer-o" style={{color:'#9DB8FC'}}/>
                                            </div>
                                            <div className="phonemain-body-box-cell-bd">
                                                <div className="phonemain-body-box-cell-bd-hd">联系人</div>
                                                <div className="phonemain-body-box-cell-bd-bd">
                                                    <FieldValue name={"lxrname"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <FieldValue name={"lxrtype"}>
                                            {value=>(value==2&&(
                                                <div className="phonemain-body-box-cell">
                                                    <div className="phonemain-body-box-cell-hd">
                                                        <Icon type="wechat" style={{color:'#43AD0D'}}/>
                                                    </div>
                                                    <div className="phonemain-body-box-cell-bd">
                                                        <div className="phonemain-body-box-cell-bd-hd">商家微信</div>
                                                        <div className="phonemain-body-box-cell-bd-bd" style={{color:'#666AFF'}}>
                                                            <FieldValue name={"lxrtel"}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </FieldValue>
                                        <FieldValue name={"lxrtype"}>
                                            {value=>(value==1&&(
                                                <div className="phonemain-body-box-cell">
                                                    <div className="phonemain-body-box-cell-hd">
                                                        <Icon type="hotline-o" style={{color:'#00A9E7'}}/>
                                                    </div>
                                                    <div className="phonemain-body-box-cell-bd">
                                                        <div className="phonemain-body-box-cell-bd-hd">联系电话</div>
                                                        <div className="phonemain-body-box-cell-bd-bd" style={{color:'#666AFF'}}>
                                                            <FieldValue name={"lxrtel"}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </FieldValue>

                                    </div>
                                    <div className="phonemain-body-title">
                                        职位详情
                                    </div>
                                    <div className="phonemain-body-box">
                                        <div className="phonemain-body-box-content">
                                            <FieldValue name="text">
                                                {text=> <pre>{text}</pre>}
                                            </FieldValue>
                                        </div>
                                    </div>
                                    <FieldValue name="workhits">
                                        {workhits=>(
                                            <FieldValue name={"xianzhi"}>
                                                {xianzhi=>((!workhits||!xianzhi)&&(
                                                    <div className="phonemain-body-warning">
                                                        <div className="phonemain-body-warning-title">
                                                            <Icon type="voice" style={{color:'orange',fontSize:'20px'}}/>
                                                            <span>报名提醒</span>
                                                        </div>
                                                        {!workhits&&(
                                                            <div className="phonemain-body-warning-cell">
                                                                <div></div>本职位不计算工作次数与不良记录，无需签到。
                                                            </div>
                                                        )}
                                                        {!xianzhi&&(
                                                            <div className="phonemain-body-warning-cell">
                                                                <div></div>本职位不会与已报名职位产生时间段冲突的情况。
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </FieldValue>
                                        )}
                                    </FieldValue>
                                    <div className="phonemain-body-tishi">
                                        <div className="phonemain-body-tishi-hd">
                                            <div className="phonemain-body-tishi-hd-hd">
                                                <Icon type="bell" style={{color:'#fff'}} />
                                            </div>
                                            <div className="phonemain-body-tishi-hd-bd">小贴士</div>
                                        </div>
                                        <div className="phonemain-body-tishi-bd">兼职过程请拒绝向商家缴纳任何费用。若收费请<span>及时电话联系客服</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {!stateLoading&&(
                    <div className="form-submit app-fixed-container">
                        <Button type="default" onClick={()=>{window.location.href="/main/work/work"}}>取消</Button>
                        <Button htmlType="submit" type="primary" loading={loading}>保存</Button>
                    </div>)
                }
            </Form>
        )
    }
    render() {
        const FormView=this.FormView.bind();
        const {loading}=this.state;
        return(
            <div>
                <Header data={[{name:'用户'},{name:'职位管理',href:'/main/work/work'},{name:'编辑职位'}]}/>
                <BlockLoading loading={loading}>
                    <div className="page-right-body">
                        <div className="page-right-body-hd"></div>
                        <div className="page-right-body-bd">
                            <FormView/>
                        </div>
                    </div>
                </BlockLoading>
            </div>
        )
    }
}
function addresslocationValidator(values,ctx) {
    if (values.address.length<=0) {
        return {
            name: 'error',
            message: '请输入详细工作地址',
        };
    }
    if (values.location.lat==0||values.location.lng==0) {
        return {
            name: 'error',
            message: '请标记地图坐标',
        };
    }
    return null;
}
function AddressLocationView(props)
{
    const addressField = Form.useField('address', '');
    const locationField = Form.useField('location', {lat:0,lng:0});
    const [address,setAddress]=React.useState('');
    const [location,setLocation]=React.useState({lat:0,lng:0});
    const onChange=FieldUtils.useMulti(
        React.useCallback(value=> {
            addressField.isTouched = true;
            locationField.isTouched=true;
        }, [addressField,locationField]),
        FieldUtils.usePipe(
            (e)=>{
                return  e.address;
            },
            FieldUtils.useChangeHandler(addressField, Form.ValidateOption.Default),
        ),
        FieldUtils.usePipe(
            (e)=>{
                return  e.location
            },
            FieldUtils.useChangeHandler(locationField,Form.ValidateOption.Default)
        ),
    )
    const onInputClick=()=>{
        const city=props.city
        if(!city)
        {
            Notify.warn('填写详细地址前请先选择所属店铺！');
            return false;
        }
        const onClose=()=>{
            closeDialog('dialogaddress');
        }
        const onSubmit=(data)=>{
            setAddress(data.address);
            setLocation(data.location);
            onChange(data);
            closeDialog('dialogaddress');
        }
        const dialogid='dialogaddress';
        openDialog({
            parentComponent:this,
            dialogId:dialogid,
            maskClosable:false,
            closeBtn:false,
            children:(
                <AddressMapComponent city={city} address={address} onClose={onClose} onSubmit={onSubmit} location={location}/>
            )
        })
    }
    React.useEffect(()=>{
        if(props.address&&props.location)
        {
            setAddress(props.address);
            setLocation(props.location);
            onChange({
                address:props.address,
                location:props.location
            });
        }
    },[])
    return(
        <div className="meformlay">
            <div className="meformlay-hd">
                <p className="mee__xx"><span>*</span>工作地址</p>
            </div>
            <div className="meformlay-bd">
                <div className="meformlay-bd-box" onClick={onInputClick}>
                    <div className="meformlay-bd-box-hd">
                        {address.length<=0&&(
                            <div className="meformlay-bd-box-hd-prom">
                                请选择所在位置并填写详细工作地址
                            </div>
                        )}
                        {address}
                    </div>
                    <div className="meformlay-bd-box-bd">
                        <Icon type="right" />
                    </div>
                </div>
            </div>
        </div>
    )
}