import React,{useCallback} from 'react';
import Header from '../../../../component/header'
import {
    Button,
    Grid,
    Form,
    Validators,
    Select,
    Checkbox,
    BlockLoading,MonthPicker,
    CombinedDateRangePicker, openDialog,
    FormInputField, Notify, closeDialog, FormNumberInputField, FormStrategy
} from 'zent'
import Service from "../../../../config/Service";
import '../../../../style/css/main/system/report/index.css'
const { form, field, array } = Form;
export default class MainSystemReportIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            monthDataList:[],
            gridPageCurrent:1,
            gridPageTotal:0,
            gridLoading:false,
            gridPageSize:20,
            dataList:[],
            subDataList:[],
            dianpuList:[],
            dianpuData:{},
            monthLoading:false,
            monthValue:"",
            timeValue:[],
            setDataRole:false
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(110800);
        const date=new Date();
        const year=date.getFullYear();
        const month=date.getMonth()+1;
        const day=date.getDate();
        const starttime=year+'-'+month+'-01';
        const endtime=year+'-'+month+'-'+day;
        const timevalue=[starttime,endtime]
        const yearmonth=year+'年'+month+"月";
        this.setState({
            monthValue:yearmonth,
            timeValue:timevalue
        })
        this.dianpuBind();
        setTimeout(()=>{
            this.dataBind();
        },100)
    }
    dataBind(){
        const _this=this;
        Service.Post({
            name:'AdminReport',
            action:'reportdata',
            data:{},
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        subDataList:result.data,
                        setDataRole:result.role
                    })
                }
            }
        })
    }
    dianpuBind(){
        const _this=this;
        Service.Post({
            name:"admindianpu",
            action:"listbyadmin",
            data:{},
            success(result)
            {
                if(result.retcode==200)
                {
                    let data={};
                    if(result.list.length)
                    {
                        data=result.list[0];
                    }
                    _this.setState({
                        dianpuList:result.list,
                        dianpuData:data
                    })
                    setTimeout(()=>{
                        _this.monthDataList();
                        _this.timeDataList();
                    },100)
                }
            }
        })
    }
    monthDataList(){
        const {dianpuData,monthValue}=this.state;
        const _this=this;
        this.setState({
            monthLoading:true
        })
        Service.Post({
            name:'AdminReport',
            action:'listbymonth',
            data:{
                date:monthValue,
                dianpu:dianpuData.key
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        monthDataList:result.list
                    })
                }
            },
            complete(){
                _this.setState({
                    monthLoading:false
                })
            }
        })
    }
    timeDataList(){
        const _this=this;
        const {dianpuData,timeValue}=this.state;
        this.setState({
            gridLoading:true
        })
        Service.Post({
            name:'AdminReport',
            action:'listbytime',
            data:{
                dianpu:dianpuData.key,
                time:timeValue
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        dataList:result.list
                    })
                }
            },
            complete(){
                _this.setState({
                    gridLoading:false
                })
            }
        })
    }
    onMonthChange=(e)=>{
        this.setState({
            monthValue:e
        })
        setTimeout(()=>{
            this.monthDataList();
        },100)
    }
    onGridChange=(e)=>{
        const current=e.current;
        this.setState({
            gridPageCurrent:current
        })
        this.timeDataList(current)
    }
    onSelectChange=(e)=>{
        this.setState({
            dianpuData:e
        })
        setTimeout(()=>{
            this.monthDataList();
            this.timeDataList();
        },100)
    }
    onTimeDateChange=(e)=>{
        this.setState({
            timeValue:e
        })
        setTimeout(()=>{
            this.timeDataList();
        },100)
    }
    onSetDataClick=()=>{
        const formBuilder=form({
             members: array(
                field('').validators(Validators.required('请填写数据名称'))
              ).defaultValue(this.state.subDataList)
        })
        const onchange=({list,clear})=>{
            closeDialog("setdatadialog");
            this.setState({
                subDataList:list
            })
            if(clear)
            {
                this.monthDataList();
                this.timeDataList();
            }
        }
        openDialog({
             maskClosable:false,
              dialogId:'setdatadialog',
              parentComponent: this,
              title: '设置数据格式',
              children: (
                <div className="page-report-setdata">
                    <SetDataView form={formBuilder} onChange={onchange}/>
                </div>
              )
            });
    }
    onBaogaoCellClick=(e,index)=>{
        if(e.click)
        {
            if(e.edit||e.list.length>0)
            {
                const {monthDataList,dianpuData}=this.state;
                const defList=this.state.subDataList;
                const onchange=(data)=>{
                    let dataList=JSON.parse(JSON.stringify(monthDataList));
                    dataList[index]=data;
                    this.setState({
                        monthDataList:dataList
                    })
                    this.timeDataList();
                }
                 openDialog({
                  parentComponent: this,
                  title: e.date+' 数据',
                  children: (
                    <MonthDayDataView data={e} dianpu={dianpuData.key} onChange={onchange} deflist={defList}/>
                  )
                });
            }
            else
            {
                Notify.info("已过时效，不可操作");
            }
        }
    }
    BaoGaoView=()=>{
        const listview=this.state.monthDataList.map((item,index)=>{
            return <div className={"page-report-date-table-cell"+(item.disabled?" disabled":"")} key={index}>
                <div className={"page-report-date-table-cell-box"+(item.now?" now":"")+(item.click?" click":"")} onClick={this.onBaogaoCellClick.bind(this,item,index)}>
                    <div className="page-report-date-table-cell-hd">
                        {item.day}
                    </div>
                    <div className={"page-report-date-table-cell-bd"+(item.list.length?"":" warning")}>
                        {item.click?item.list.length?'已报':'未报':''}
                    </div>
                </div>
            </div>
        })
        return(
            <div className="page-report-date-table-cells">
                {listview}
            </div>
        )
    }
    getGridColumns=()=>{
        const listColumns=[
            {
                title:'名称',
                name:'name',
            },
            {
                title:'数值',
                name:'value',
                textAlign:'center'
            }
        ];
        return listColumns;
    }
    render() {
        const BaoMingView=this.BaoGaoView.bind(this);
        const {dianpuList,dianpuData,monthLoading,monthValue,timeValue,setDataRole}=this.state;
        return(
            <div>
                <Header data={[{name:'系统'},{name:'报告管理'}]}/>
                <div className="page-right-body">
                    <div className="page-right-body-hd">
                       <div className="page-report-dianpuselect">
                           {dianpuList.length>1&&(
                               <Select
                                        className="simple-header-bd-select"
                                        placeholder={"店铺选择"}
                                        options={dianpuList}
                                        value={dianpuData}
                                        onChange={this.onSelectChange}
                                    />
                           )}
                           {setDataRole&&(
                             <div className="page-report-dianpuselect-add">
                                   <Button type="primary" icon={"edit"} onClick={this.onSetDataClick}>设置数据格式</Button>
                               </div>
                           )}
                       </div>
                    </div>
                    <div className="page-right-body-bd">
                        <div className="page-report-body">
                            <div className="page-report-date">
                                <BlockLoading loading={monthLoading}>
                                    <div className="page-report-date-table">
                                        <div className="page-report-date-table-title">
                                            <MonthPicker
                                                  width={"180px"}
                                                  value={monthValue}
                                                  format={"YYYY年MM月"}
                                                  disabledDate={{max:new Date(),min:new Date("2024-03-01")}}
                                                  canClear={false}
                                                  className={"page-report-date-table-title-picker"}
                                                  onChange={this.onMonthChange}
                                                />
                                        </div>
                                        <div className="page-report-date-table-header">
                                            <div className="page-report-date-table-cells">
                                                <div className="page-report-date-table-cell">一</div>
                                                <div className="page-report-date-table-cell">二</div>
                                                <div className="page-report-date-table-cell">三</div>
                                                <div className="page-report-date-table-cell">四</div>
                                                <div className="page-report-date-table-cell">五</div>
                                                <div className="page-report-date-table-cell">六</div>
                                                <div className="page-report-date-table-cell">日</div>
                                            </div>
                                        </div>
                                        <div className="page-report-date-table-body">
                                            <BaoMingView/>
                                        </div>
                                    </div>
                                </BlockLoading>
                            </div>
                            <div className="page-report-list">
                                <div className="page-report-list-header">
                                    <CombinedDateRangePicker width="auto" disabledDate={{max:new Date()}} value={timeValue} onChange={this.onTimeDateChange} className="page-report-list-header-date"/>
                                </div>
                                <div className="page-report-list-body">
                                    <Grid
                                        columns={this.getGridColumns()}
                                        datasets={this.state.dataList}
                                        loading={this.state.gridLoading}
                                        ellipsis
                                        size={"medium"}
                                        rowKey="name"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function SetDataView({form:formBuilder,onChange})
{
    const form = Form.useForm(formBuilder);
    const { members } = form.model.children;
    const [loading,setLoading]=React.useState(false);
    const [clearState,setClearState]=React.useState(false);
    const onClearStateChange = React.useCallback(
        evt => {
          setClearState(evt.target.checked);
        },
        [setClearState]
      );
    const onSubmit = React.useCallback(form => {
        return new Promise(resolve =>{
            const value = form.getSubmitValue();
            const memberlist=value.members;
            if(memberlist.length>0)
            {
                setLoading(true);
                const dataText=JSON.stringify(memberlist);
                Service.Post({
                    name:'AdminReport',
                    action:'setreport',
                    data:{
                        text:dataText,
                        clear:clearState?1:0
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            onChange({
                                list:memberlist,
                                clear:clearState
                            });
                            Notify.success("提交成功");
                        }
                        else
                        {
                            Notify.warn(result.retmsg)
                        }
                        setLoading(false);
                    },
                    fail(){
                        Notify.error('网络连接失败');
                    },
                    complete(){
                        setLoading(false);
                    }
                })
            }
            else {
                Notify.error("至少添加一个数据项")
            }
        })
    }, [form,clearState]);
    return(
        <Form form={form} layout="horizontal" onSubmit={onSubmit} disabled={loading}>
            <div className="page-report-setdata">
                <MembersView model={members}/>
                <div className="page-report-setdata-btn">
                    <div className="page-report-setdata-btn-hd">
                        <Checkbox
                          checked={clearState}
                          onChange={onClearStateChange}
                        >
                          清除今日数据
                        </Checkbox>
                    </div>
                    <div className="page-report-setdata-btn-bd">
                        <Button type="default" htmlType="reset">重置</Button>
                    <Button type="primary" loading={loading} htmlType="submit">提交</Button>
                    </div>
                </div>
            </div>
        </Form>
    )
}
function MembersView({model:propsModel})
{
    const model = Form.useFieldArray(propsModel);
    const onAddClick = useCallback(() => {
         model.push('');
    }, []);
    return(
        <>
            <div className="page-report-setdata-inputs">
                {model.children.map((item,index)=>(
                    <div className="page-report-setdata-input" key={index}>
                         <FormInputField
                          model={item}
                          label={`数据名称：`}
                          props={{
                              maxLength:20
                         }}
                        />
                        <span onClick={() => model.splice(index, 1)}>删除该项</span>
                    </div>
                ))}
            </div>
            <div className="page-report-setdata-add">
                <Button type="default" icon={"plus"} onClick={onAddClick}>添加</Button>
            </div>
        </>
    )
}
function MonthDayDataView({data,deflist:propsList,onChange,dianpu})
{
    const form = Form.useForm(FormStrategy.View);
    const [updateState,setUpdateState]=React.useState(false);
    const [propsData,setPropsData]=React.useState(data);
    const [loading,setLoading]=React.useState(false);

    const onSubmit=React.useCallback(form => {
        return new Promise(resolve =>{
            const value = form.getSubmitValue();
            let insval=[];
            for(let key in value)
            {
                insval.push({
                    name:key,
                    value:value[key]
                })
            }
            let list=JSON.parse(JSON.stringify(propsData));
            list.list=insval;
            setLoading(true);
            Service.Post({
                name:'AdminReport',
                action:'add',
                data:{
                    dianpu:dianpu,
                    date:data.date,
                    list:insval
                },
                success(result)
                {
                    if(result.retcode==200)
                    {
                        Notify.success(result.retmsg);
                        setPropsData(list);
                        setUpdateState(false);
                        onChange(list);
                    }
                    else{
                        Notify.warn(result.retmsg);
                    }
                },
                fail(){
                    Notify.error('网络连接失败');
                },
                complete(){
                    setLoading(false);
                }
            })
        })
    }, [form]);
    return(
        <div className="page-report-daydata">
            {propsData.list.length>0&&(
                <>
                    {!updateState&&(
                        <>
                            <div className="page-report-daydata-show">
                                <div className="page-report-daydata-show-cells">
                                    {propsData.list.map((item,index)=>(
                                        <div className="page-report-daydata-show-cell" key={index}>
                                            <div className="page-report-daydata-show-cell-hd">{item.name}</div>
                                            <div className="page-report-daydata-show-cell-bd">{item.value}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {propsData.edit&&(
                                <div className="page-report-daydata-btns">
                                    <Button type="primary" icon="edit" onClick={()=>{setUpdateState(true)}}>更改</Button>
                                </div>
                            )}
                        </>
                    )}
                    {updateState&&(
                        <div className="page-report-daydata-edit">
                            <Form form={form} layout="horizontal" onSubmit={onSubmit} disabled={loading}>
                                {propsData.list.map((item,index)=>(
                                        <FormNumberInputField
                                              key={index}
                                              name={item.name}
                                              label={item.name+'：'}
                                              required
                                              props={{
                                                  min:0,
                                                  max:9999999
                                              }}
                                              validators={[Validators.required('请填写'+item.name)]}
                                              initialValue={item.value}
                                          />
                                    ))
                                }
                                <div className="page-report-daydata-btn">
                                    <Button icon="refresh" onClick={()=>{setUpdateState(false)}}>返回</Button>
                                    <Button htmlType="submit" loading={loading} type="primary">提交</Button>
                                </div>
                            </Form>
                        </div>
                    )}
                </>
            )}
            {(propsData.list.length<=0&&propsData.edit)&&(
                <div className="page-report-daydata-edit">
                    <Form form={form} layout="horizontal" onSubmit={onSubmit} disabled={loading}>
                        {propsList.map((item,index)=>(
                            <FormNumberInputField
                                key={index}
                                name={item}
                                label={item+'：'}
                                required
                                props={{
                                    min:0,
                                    decimal:2,
                                    max:99999999,
                                    maxLength:8
                                }}
                                validators={[Validators.required('请填写'+item)]}
                            />
                        ))}
                        <div className="page-report-daydata-btn">
                            <Button htmlType="submit" type="primary" loading={loading}>提交</Button>
                        </div>
                    </Form>
                </div>
            )}
        </div>
    )
}
