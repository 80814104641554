import React from 'react';
import {Grid,Button,Dialog} from "zent";
import Service from "../config/Service";
import YewuyuanResultListView from "./YewuyuanResultListView";
const {openDialog}=Dialog;
export default class DianpuResultView extends React.Component{
    constructor(props, context) {
        super(props, context);
        this.state={
            gridLoading:false,
            dataList:[]
        }
    }
    componentWillMount() {
        this.onDataBind();
    }
    onDataBind=()=>{
        const _this=this;
        if(this.props.dianpu>0)
        {
            this.setState({
                gridLoading:true
            });
            Service.Post({
                name:"adminresults",
                action:"listbydianpu",
                data:{
                    id:_this.props.dianpu,
                    startdate:_this.props.time[0],
                    enddate:_this.props.time[1]
                },
                success(result){
                    if(result.retcode==200)
                    {
                        _this.setState({
                            dataList:result.list,
                            gridLoading:false
                        })
                    }
                    else
                    {
                        _this.setState({
                            dataList:[],
                            gridLoading:false
                        })
                    }
                },
                fail(){
                    _this.setState({
                        dataList:[],
                        gridLoading:false
                    })
                }
            })
        }else
        {
            _this.setState({
                dataList:[],
                gridLoading:false
            })
        }
    }
    onGridItemClick=(id,name)=>{
        const dialogid='ywydialog'+id;
        openDialog({
            parentComponent:this,
            title:'【'+name+'】业绩总览【'+this.props.time[0]+' 至 '+this.props.time[1]+'】',
            dialogId:dialogid,
            maskClosable:false,
            closeBtn:true,
            style:{
                width:'900px'
            },
            children:(
                <YewuyuanResultListView dataid={id} time={this.props.time}/>
            )
        })
    }
    getGridColumns(){
        const Columns=[
            {
                title:'业务员',
                name:'name',
                width: '100px'
            },
            {
                title:'业绩总额',
                name:'money',
                textAlign: 'center',
                bodyRender:data => {
                    return<>￥{data.money}</>
                }
            },
            {
                title:'订单数',
                name:'count',
                textAlign: 'center',
                width: '70px'
            },
            {
                title:'整卡数',
                name:'zkcount',
                textAlign: 'center',
                width: '70px'
            },
            {
                title:'定金数',
                name:'djcount',
                textAlign: 'center',
                width: '70px'
            },
            {
                title:'补费数',
                name:'bfcount',
                textAlign: 'center',
                width: '70px'
            },
            {
                title:'退卡数',
                name:'tkcount',
                textAlign: 'center',
                width: '70px'
            },
            {
                title:'市场奖金',
                name:'jjmoney',
                bodyRender:data => {
                    return<>￥{data.jjmoney}</>
                },
                textAlign: 'center',
                width: '100px'
            },
            {
                title:'客服奖金',
                name:'kfmoney',
                bodyRender:data => {
                    return<>￥{data.kfmoney}</>
                },
                textAlign: 'center',
                width: '100px'
            },
            {
                title:'操作',
                width:'100px',
                textAlign:'center',
                bodyRender:(data)=>{
                    return <Button type="text" onClick={this.onGridItemClick.bind(this,data.id,data.name)}>查看详情</Button>
                }
            }
        ]
        return Columns;
    }
    render() {
        return(
            <div>
                <Grid
                    columns={this.getGridColumns()}
                    datasets={this.state.dataList}
                    loading={this.state.gridLoading}
                    ellipsis
                    size={"medium"}
                    rowKey="id"/>
            </div>
        )
    }
}
