import React from 'react';
import Header from '../../../../component/header'
import {
    Button,
    Grid,
    Notify,
    Dialog,
    previewImage,
    ClampLines,
    Tabs,
    Sweetalert,
    Form,
    FormStrategy,
    FormSelectField,
    FormInputField,
    FormDateRangeQuickPickerField, Tooltip
} from 'zent'
import Service from "../../../../config/Service";
import Utils from "../../../../config/Utils";
const {closeDialog,openDialog}=Dialog;
const TabPanel = Tabs.TabPanel;
export default class MainFinancialReimbursementIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            dbxGridLoading:false,
            dbxDataList:[],
            dbxGridPageCurrent:1,
            dbxGridPageTotal:0,
            dbxGridPageSize:15,
            dbxGridSortBy:'time',
            dbxGridSortType:'desc',
            wtgGridLoading:false,
            wtgDataList:[],
            wtgGridPageCurrent:1,
            wtgGridPageTotal:0,
            wtgGridPageSize:15,
            wtgGridSortBy:'time',
            wtgGridSortType:'desc',
            ybxGridLoading:false,
            ybxDataList:[],
            ybxGridPageCurrent:1,
            ybxGridPageTotal:0,
            ybxGridPageSize:15,
            ybxGridSortBy:'time',
            ybxGridSortType:'desc',
            dayMoney:'0.00',
            weekMoney:'0.00',
            monthMoney:'0.00',
            yearMoney:'0.00',
            searchMoney:'0.00',
            searchDianpuList:[],
            searchClassList:[],
            stateActive:'1',
            searchData:{
                time:['',''],
                dianpuid:0,
                classid:0
            }
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(140200);
        this.moneyBind();
        this.onDianpuBind();
        this.onClassBind();
        this.dataBind(1);
        this.onDataBind();
    }
    onDataBind=()=>{
        const search=this.props.location.search;
        const active=Utils.getUrlParams('active',search);
        if(active==null)
        {
            this.dataBind(1)
        }
        else
        {
            this.setState({
                stateActive:active
            })
            this.dataBind(active);
        }
    }
    moneyBind=()=>{
        const _this=this;
        Service.Post({
            name:"adminbaoxiao",
            action:"moneybytime",
            data:{},
            success(result)
            {
                if(result.retcode==200)
                {
                    const data=result.data;
                    _this.setState({
                        dayMoney:data.day,
                        weekMoney:data.week,
                        monthMoney:data.month,
                        yearMoney:data.year
                    })
                }
            }
        })
    }
    dataBind=(active,com)=>{
        if(active==1)
        {
            setTimeout(()=>{
                this.onDBXDataBind(com);
            },100)
        }
        if(active==2)
        {
            setTimeout(()=>{
                this.onWTGDataBind(com);
            },100)
        }
        if(active==3)
        {
            setTimeout(()=>{
                this.onYBXDataBind(com);
            },100)
        }
    }
    onDBXDataBind=(com)=>{
        const _this=this;
        const {dbxGridPageSize,dbxGridPageCurrent,dbxGridSortBy,dbxGridSortType,searchData}=this.state;
        this.setState({
            dbxGridLoading:true
        })
        Service.Post({
            name:"adminbaoxiao",
            action:"pagelist",
            data:{
                pageindex:dbxGridPageCurrent,
                pagesize:dbxGridPageSize,
                startdate:searchData.time[0],
                enddate:searchData.time[1],
                dianpuid:searchData.dianpuid,
                classid:searchData.classid,
                sortby:dbxGridSortBy,
                sorttype:dbxGridSortType,
                state:1
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        dbxDataList:result.list,
                        dbxGridPageTotal:result.count,
                        dbxGridLoading:false
                    })
                }
                else
                {
                    _this.setState({
                        dbxDataList:[],
                        dbxGridPageTotal:0,
                        dbxGridLoading:false
                    })
                }
            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    dbxDataList:[],
                    dbxGridPageTotal:0,
                    dbxGridLoading:false
                })
            },
            complete(){
                if(com&&typeof com=="function")
                {
                    com();
                }
            }
        })
    }
    onWTGDataBind=(com)=>{
        const _this=this;
        const {wtgGridPageSize,wtgGridPageCurrent,wtgGridSortBy,wtgGridSortType,searchData}=this.state;
        this.setState({
            wtgGridLoading:true
        })
        Service.Post({
            name:"adminbaoxiao",
            action:"pagelist",
            data:{
                pageindex:wtgGridPageCurrent,
                pagesize:wtgGridPageSize,
                startdate:searchData.time[0],
                enddate:searchData.time[1],
                dianpuid:searchData.dianpuid,
                classid:searchData.classid,
                sortby:wtgGridSortBy,
                sorttype:wtgGridSortType,
                state:2
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        wtgDataList:result.list,
                        wtgGridPageTotal:result.count,
                        wtgGridLoading:false
                    })
                }
                else
                {
                    _this.setState({
                        wtgDataList:[],
                        wtgGridPageTotal:0,
                        wtgGridLoading:false
                    })
                }
            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    wtgDataList:[],
                    wtgGridPageTotal:0,
                    wtgGridLoading:false
                })
            },
            complete(){
                if(typeof com=="function")
                {
                    com();
                }
            }
        })
    }
    onYBXDataBind=(com)=>{
        const _this=this;
        const {ybxGridPageSize,ybxGridPageCurrent,ybxGridSortBy,ybxGridSortType,searchData}=this.state;
        this.setState({
            ybxGridLoading:true
        })
        Service.Post({
            name:"adminbaoxiao",
            action:"pagelist",
            data:{
                pageindex:ybxGridPageCurrent,
                pagesize:ybxGridPageSize,
                startdate:searchData.time[0],
                enddate:searchData.time[1],
                dianpuid:searchData.dianpuid,
                classid:searchData.classid,
                sortby:ybxGridSortBy,
                sorttype:ybxGridSortType,
                state:3
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        ybxDataList:result.list,
                        searchMoney:result.money,
                        ybxGridPageTotal:result.count,
                        ybxGridLoading:false
                    })
                }
                else
                {
                    _this.setState({
                        ybxDataList:[],
                        ybxGridPageTotal:0,
                        searchMoney:'0.00',
                        ybxGridLoading:false
                    })
                }
            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    ybxDataList:[],
                    searchMoney:'0.00',
                    ybxGridPageTotal:0,
                    ybxGridLoading:false
                })
            },
            complete(){
                if(typeof com=="function")
                {
                    com();
                }
            }
        })
    }
    onDianpuBind=()=>{
        const _this=this;
        Service.Post({
            name:"admindianpu",
            action:"listbyadmin",
            data:{
                type:0
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        searchDianpuList:result.list
                    })
                }
            }
        })
    }
    onClassBind=()=>{
        const _this=this;
        Service.Post({
            name:"adminbaoxiaoclass",
            action:"list",
            data:{},
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        searchClassList:result.list
                    })
                }
            }
        })
    }
    onGridItemSetClick=(data,settype)=>{
        const _this=this;
        const dialogid='baoxiaodialog'+data.id;
        openDialog({
            parentComponent:this,
            title:settype==3?'报销通过':'报销拒绝',
            dialogId:dialogid,
            maskClosable:false,
            closeBtn:false,
            children:(
                <BaoXiaoSetView data={data} settype={settype} onChange={()=>{
                    _this.dataBind(1);
                    _this.setState({
                        wtgDataList:[],
                        ybxDataList:[]
                    })
                }}/>
            )
        })
    }
    onGridItemDeleteClick=(id)=>{
        const _this=this;
        Sweetalert.confirm({
            content:'操作成功后将无法恢复，请确认',
            title: '请确认操作',
            onConfirm: () => new Promise((resolve,reject) => {
                Service.Post({
                    name:"adminbaoxiao",
                    action:"delete",
                    data:{
                        id:id
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("已删除");
                            resolve();
                            _this.dataBind(2);
                        }
                        else
                        {
                            Notify.warn(result.retmsg);
                            reject();
                        }
                    },
                    fail(){
                        Notify.error("网络链接失败");
                        reject();
                    }
                })
            }),
            parentComponent:this
        });
    }
    onGridItemEditClick=(id)=>{
        this.props.history.push('reimbursement/edit/'+id);
    }
    onDBXGridChange=(e)=>{
        if(e.current)
        {
            const current=e.current;
            this.setState({
                dbxGridPageCurrent:current
            })
        }
        if(e.sortBy)
        {
            const sortBy=e.sortBy;
            const sortType=e.sortType;
            this.setState({
                dbxGridSortBy:sortBy,
                dbxGridSortType:sortType
            })
        }
        this.dataBind(1)
    }
    onWTGGridChange=(e)=>{
        if(e.current)
        {
            const current=e.current;
            this.setState({
                wtgGridPageCurrent:current
            })
        }
        if(e.sortBy)
        {
            const sortBy=e.sortBy;
            const sortType=e.sortType;
            this.setState({
                wtgGridSortBy:sortBy,
                wtgGridSortType:sortType
            })
        }
        this.dataBind(2)
    }
    onYBXGridChange=(e)=>{
        if(e.current)
        {
            const current=e.current;
            this.setState({
                ybxGridPageCurrent:current
            })
        }
        if(e.sortBy)
        {
            const sortBy=e.sortBy;
            const sortType=e.sortType;
            this.setState({
                ybxGridSortBy:sortBy,
                ybxGridSortType:sortType
            })
        }
        this.dataBind(3)
    }
    onTabsChange=(id)=>{
        this.setState({
            stateActive:id,
            searchMoney:'0.00'
        })
        if(id==1)
        {
            if(this.state.dbxDataList.length<=0)
            {
                this.dataBind(1);
            }
        }
        if(id==2)
        {
            if(this.state.wtgDataList.length<=0)
            {
                this.dataBind(2);
            }
        }
        if(id==3)
        {
            if(this.state.ybxDataList.length<=0)
            {
                this.dataBind(3);
            }
        }
    }
    onSearch=(res)=>{
        this.setState({
            dbxDataList:[],
            wtgDataList:[],
            ybxDataList:[],
            dbxGridPageCurrent:1,
            dbxGridPageTotal:0,
            wtgGridPageCurrent:1,
            wtgGridPageTotal:0,
            ybxGridPageCurrent:1,
            ybxGridPageTotal:0,
            dbxGridSortBy:'time',
            dbxGridSortType:'desc',
            wtgGridSortBy:'time',
            wtgGridSortType:'desc',
            ybxGridSortBy:'time',
            ybxGridSortType:'desc',
            searchData:{
                time:res.time,
                dianpuid:res.dianpuid,
                classid:res.classid,
            }
        })
        this.dataBind(this.state.stateActive,res.com)
    }
    onImagesClick=(imgs)=>{
        let images=imgs;
        previewImage({
            images: images,
            index: 0,
            parentComponent: this
        });
    }
    getGridColumns=(state)=>{
        let listColumns=[
            {
                title:'报销编号',
                name:'id',
                width: '100px',
            },
            {
                title:'报销金额',
                name:'money',
                width:'120px',
                needSort: true,
                bodyRender:data => {
                    return<>￥{data.money}</>
                }
            },
            {
                title:'报销人',
                name:'name',
                width: '120px',
                needSort: true,
            },
            {
                title:'所属店铺',
                name:'dianpu',
                width:'230px',
                bodyRender:data => {
                    return(
                        <ClampLines
                            lines={1}
                            popWidth={230}
                            text={data.dianpu}
                            extra={(
                                <a>全部</a>
                            )}
                        />
                    )
                }
            },
            {
                title:'类别',
                name:'leibie',
                width: '120px',
            },
            {
                title:'凭证',
                name:'img',
                width:'120px',
                textAlign: 'center',
                bodyRender:data => {
                    return(
                        <Button onClick={this.onImagesClick.bind(this,data.img)} type={"text"}>查看{data.img.length}张图片</Button>
                    )
                }
            },
            {
                title:'备注',
                name:'text',
                bodyRender:data => {
                    return(
                        <ClampLines
                            lines={1}
                            text={data.text}
                            extra={(
                                <a>全部</a>
                            )}
                        />
                    )
                }
            },
            {
                title:'申请时间',
                name:'time',
                width: '140px',
                textAlign: 'center',
                needSort: true,
            }
        ];
        if(state==1)
        {
            const data={
                title:'操作',
                width:'200px',
                textAlign:'center',
                bodyRender:(data)=>{
                    if(data.bxrole)
                    {
                        return <div>
                            <Button type="warning" onClick={this.onGridItemSetClick.bind(this,data,2)}>拒绝</Button>
                            <Button type="success" onClick={this.onGridItemSetClick.bind(this,data,3)}>通过</Button>
                        </div>
                    }
                    else
                    {
                        return <>不可操作</>
                    }

                }
            }
            listColumns.push(data);
        }
        if(state==2)
        {

            const textdata={
                title:'拒绝原因',
                width:'170px',
                bodyRender:data => {
                    return(
                        <ClampLines
                            lines={1}
                            popWidth={140}
                            text={data.statecontent}
                            extra={(
                                <a>全部</a>
                            )}
                        />
                    )
                }
            }
            const timedata={
                title:'被拒时间',
                textAlign:'center',
                name:'uptime',
                width:'140px',
                needSort: true
            }
            const data={
                title:'操作',
                width:'120px',
                textAlign:'center',
                bodyRender:(data)=>{
                    if(data.subbtn)
                    {
                        return <div>
                            <Tooltip title={"重新提交"}>
                                <Button type="icon" icon={"edit"} onClick={this.onGridItemEditClick.bind(this,data.id)} />
                            </Tooltip>
                            <Tooltip title={"删除"}>
                                <Button type="icon" icon={"remove"} onClick={this.onGridItemDeleteClick.bind(this,data.id)} />
                            </Tooltip>
                        </div>
                    }
                    else
                    {
                        return <>不可操作</>
                    }
                }
            }
            listColumns.push(textdata);
            listColumns.push(timedata);
            listColumns.push(data);
        }
        if(state==3)
        {
            const timedata={
                title:'报销时间',
                textAlign:'center',
                name:'uptime',
                width:'140px',
                needSort: true
            }
            listColumns.push(timedata);
        }
        return listColumns;
    }
    render() {
        const {dayMoney,weekMoney,monthMoney,yearMoney,searchMoney}=this.state;
        const panels = [
            <TabPanel key="1" tab={"待报销"} id="1">
                <Grid
                    columns={this.getGridColumns(1)}
                    datasets={this.state.dbxDataList}
                    loading={this.state.dbxGridLoading}
                    ellipsis
                    pageInfo={{
                        current: this.state.dbxGridPageCurrent,
                        pageSize: this.state.dbxGridPageSize,
                        total: this.state.dbxGridPageTotal,
                        pageSizeOptions: [this.state.dbxGridPageSize],
                    }}
                    onChange={this.onDBXGridChange.bind(this)}
                    sortBy={this.state.dbxGridSortBy}
                    sortType={this.state.dbxGridSortType}
                    size={"medium"}
                    rowKey="id"/>
            </TabPanel>,
            <TabPanel key="2" tab="未通过" id="2">
                <Grid
                    columns={this.getGridColumns(2)}
                    datasets={this.state.wtgDataList}
                    loading={this.state.wtgGridLoading}
                    ellipsis
                    pageInfo={{
                        current: this.state.wtgGridPageCurrent,
                        pageSize: this.state.wtgGridPageSize,
                        total: this.state.wtgGridPageTotal,
                        pageSizeOptions: [this.state.wtgGridPageSize],
                    }}
                    sortBy={this.state.wtgGridSortBy}
                    sortType={this.state.wtgGridSortType}
                    onChange={this.onWTGGridChange.bind(this)}
                    size={"medium"}
                    rowKey="id"/>
            </TabPanel>,
            <TabPanel key="3" tab="已报销" id="3">
                <Grid
                    columns={this.getGridColumns(3)}
                    datasets={this.state.ybxDataList}
                    loading={this.state.ybxGridLoading}
                    ellipsis
                    pageInfo={{
                        current: this.state.ybxGridPageCurrent,
                        pageSize: this.state.ybxGridPageSize,
                        total: this.state.ybxGridPageTotal,
                        pageSizeOptions: [this.state.ybxGridPageSize],
                    }}
                    sortBy={this.state.ybxGridSortBy}
                    sortType={this.state.ybxGridSortType}
                    onChange={this.onYBXGridChange.bind(this)}
                    size={"medium"}
                    rowKey="id"/>
            </TabPanel>,
        ];
        return(
            <>
                <Header data={[{name:'财务'},{name:'财务报销'}]}/>
                <div className="page-right-body">
                    <div className="page-right-body-hd">
                        <div className="page-right-body-hd-hd">
                            <div className="page-financial-cells">
                                <div className="page-financial-cell cursor-default">
                                    <div className="page-financial-cell-hd">今日报销</div>
                                    <div className="page-financial-cell-bd">
                                        <span>￥</span><b>{dayMoney}</b>
                                    </div>
                                </div>
                                <div className="page-financial-cell cursor-default">
                                    <div className="page-financial-cell-hd">本周报销</div>
                                    <div className="page-financial-cell-bd">
                                        <span>￥</span><b>{weekMoney}</b>
                                    </div>
                                </div>
                                <div className="page-financial-cell cursor-default">
                                    <div className="page-financial-cell-hd">本月报销</div>
                                    <div className="page-financial-cell-bd">
                                        <span>￥</span><b>{monthMoney}</b>
                                    </div>
                                </div>
                                <div className="page-financial-cell cursor-default">
                                    <div className="page-financial-cell-hd">本年报销</div>
                                    <div className="page-financial-cell-bd">
                                        <span>￥</span><b>{yearMoney}</b>
                                    </div>
                                </div>
                                <div className="page-financial-cell cursor-default">
                                    <div className="page-financial-cell-hd">时间范围已报销</div>
                                    <div className="page-financial-cell-bd">
                                        <span>￥</span><b>{searchMoney}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-right-body-bd" style={{paddingBottom:'10px'}}>
                        <div className="page-financial-cells">
                            <SearchFormView dianpulist={this.state.searchDianpuList} classlist={this.state.searchClassList} onSearch={this.onSearch}/>
                            {new Date().getHours()<17&&(
                                <div style={{flex:'1',textAlign:'right'}}>
                                <Button type={"primary"} onClick={this.onGridItemEditClick.bind(this,0)} icon={"withdraw-cash-o"}>我要报销</Button>
                            </div>
                            )}
                        </div>
                    </div>
                    <div className="page-right-body-bd">
                        <Tabs
                            activeId={this.state.stateActive}
                            onChange={this.onTabsChange}
                        >
                            {panels}
                        </Tabs>
                    </div>
                </div>
            </>
        )
    }
}
function SearchFormView(props) {
    const form = Form.useForm(FormStrategy.View);
    const dianpuList=props.dianpulist;
    const classList=props.classlist;
    const [loading,setLoading]=React.useState(false)
    const onSubmit = React.useCallback(form => {
        setLoading(true);
        const value = form.getSubmitValue();
        return new Promise(resolve => {
            if(typeof props.onSearch=="function")
            {
                const dianpuvalue=value.dianpuvalue;
                const classvalue=value.classvalue;
                props.onSearch({
                    time:value.timevalue,
                    dianpuid:dianpuvalue==null?0:dianpuvalue.key,
                    classid:classvalue==null?0:classvalue.key,
                    com:()=>{
                        setLoading(false)
                    }
                })
            }
        });
    }, []);
    return(
        <div>
            <Form form={form} disabled={loading} onSubmit={onSubmit} layout="horizontal" className="margin-all-0">
                <div className="page-reimbursement-cells">
                    <div className="page-reimbursement-cell" style={{minWidth:'645px'}}>
                        <FormDateRangeQuickPickerField
                            label={"时间范围："}
                            name={"timevalue"}
                            props={{
                                format:"YYYY-MM-DD",
                                canClear:true,
                                preset:loading?[]:[
                                    {
                                        text: '今天',
                                        value: 0,
                                    },
                                    {
                                        text: '昨天',
                                        value: 1,
                                    },
                                    {
                                        text: '本周',
                                        value: Utils.returndates(2),
                                    },
                                    {
                                        text: '本月',
                                        value: Utils.returndates(3),
                                    },
                                    {
                                        text: '本年',
                                        value: Utils.returndates(4),
                                    },
                                    {
                                        text: '上月',
                                        value: Utils.returndates(5),
                                    }
                                ]
                            }}
                            defaultValue={['','']}
                        />

                    </div>
                    <div className="page-reimbursement-cell">
                        <FormSelectField
                            label={"所属店铺："}
                            name={"dianpuvalue"}
                            props={{
                                clearable:true,
                                width:'200px',
                                options:dianpuList,
                                placeholder:'为空全部'
                            }}
                        />
                    </div>
                    <div className="page-reimbursement-cell">
                        <FormSelectField
                            label={"所属类别："}
                            name={"classvalue"}
                            props={{
                                clearable:true,
                                width:'150px',
                                options:classList,
                                placeholder:'为空全部'
                            }}
                        />
                    </div>
                    <div className="page-right-body-form-cell">
                        <Button icon={"search"} type={"primary"}  htmlType="submit" loading={loading}>搜索</Button>
                    </div>
                </div>
            </Form>
        </div>
    )
}
function BaoXiaoSetView(props)
{
    const baoxiaoData=props.data;
    const baoxiaoType=props.settype;
    const dialogid='baoxiaodialog'+baoxiaoData.id;
    const form=Form.useForm(FormStrategy.View);
    const [loading,setLoading]=React.useState(false);
    const onSubmit=React.useCallback(form => {
         const value = form.getSubmitValue();
         const text=value.text;
        Sweetalert.confirm({
            content:'操作成功后将无法恢复，请确认',
            title: '请确认操作',
            onConfirm: () => {
                setLoading(true)
                Service.Post({
                    name:"adminbaoxiao",
                    action:"state",
                    data:{
                        id:baoxiaoData.id,
                        state:baoxiaoType,
                        text:text?text:'',
                        uid:baoxiaoData.uid
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("操作成功");
                            props.onChange();
                            closeDialog(dialogid);
                        }
                        else
                        {
                            Notify.warn(result.retmsg);
                        }
                    },
                    fail(){
                        Notify.error("网络连接失败");
                    },
                    complete(){
                        setLoading(false);
                    }
                })
            },
            parentComponent:this
        });
    },[]);
    return(
        <Form form={form} layout="horizontal" onSubmit={onSubmit} disabled={loading}>
            <div className="page-reimbursement-baoxiao-cells">
                <div className="page-reimbursement-baoxiao-cell">
                    <div className="page-reimbursement-baoxiao-cell-hd">
                        报销编号：
                    </div>
                    <div className="page-reimbursement-baoxiao-cell-bd">
                        <b>{baoxiaoData.id}</b>
                    </div>
                </div>
                <div className="page-reimbursement-baoxiao-cell">
                    <div className="page-reimbursement-baoxiao-cell-hd">
                        报销金额：
                    </div>
                    <div className="page-reimbursement-baoxiao-cell-bd">
                        ￥<b>{baoxiaoData.money}</b>
                    </div>
                </div>
                <div className="page-reimbursement-baoxiao-cell">
                    <div className="page-reimbursement-baoxiao-cell-hd">
                        报销详情：
                    </div>
                    <div className="page-reimbursement-baoxiao-cell-bd">
                        {baoxiaoData.text}
                    </div>
                </div>
            </div>
            {baoxiaoType==2&&(
                <FormInputField
                    label={"拒绝原因："}
                    name={"text"}
                    required
                    props={{
                        width:'320px',
                        maxLength:40,
                        showClear:true,
                        placeholder:"请输入拒绝原因，40字以内",
                    }}
                />
            )}
            <div style={{textAlign:'right'}}>
                <Button onClick={()=>closeDialog(dialogid)}>取消</Button>
                <Button type="primary" htmlType={"submit"} loading={loading}>提交</Button>
            </div>
        </Form>
    )
}
