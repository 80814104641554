import React from 'react';
import Header from '../../../../component/header'
import {
    Button,
    Grid,
    Notify,
    Tag,
    Sweetalert,
    Form,
    FormStrategy,
    FormDateRangeQuickPickerField,
    FormInputField,
    FormSelectField, Drawer,
    Tabs
} from 'zent'
import Utils from "../../../../config/Utils";
import Service from "../../../../config/Service";
import WorkMesslistView from "../../../../component/WorkMesslistView";
import WorkNewsView from "../../../../component/WorkNewsView";
const TabPanel = Tabs.TabPanel;
export default class MainWorkWorkIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            gridPageCurrent:1,
            gridPageTotal:0,
            gridLoading:false,
            gridPageSize:10,
            dataList:[],
            searchDianpuList:[],
            searchClassList:[],
            searchData:{
                text:'',
                state:0,
                ontop:0,
                mianshi:0,
                sex:0,
                jiesuantime:'',
                workhits:0,
                xianzhi:0,
                workclass:0,
                gongsi:0,
                workstate:0,
                dianpu:0,
                youxiao:0,
                time:['','']
            },
            dataSort:{
                sortby:'',
                sorttype:''
            },
            showWorkDrawer:false,
            workDrawerId:0,
            showWorkNewsDrawer:false,
            workNewsDrawerId:0,
            activeID:'1'
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(130000);
        this.onUrlSearch();
        this.onDianpuBind();
        this.searchClassBind();
    }
    onUrlSearch=()=>{
        const search=this.props.location.search;
        const urlDianpu=Utils.getUrlParams('dianpu',search);
        const urlType=Utils.getUrlParams('type',search);
        const urlYouxiao=Utils.getUrlParams('youxiao',search);
        const urlText=Utils.getUrlParams('text',search);
        let searchData=JSON.parse(JSON.stringify(this.state.searchData));
        if(urlDianpu!=null)
        {
            searchData.dianpu=urlDianpu;
        }
        if(urlType!=null)
        {
            if(urlType=="zp")
            {
                searchData.workstate=2;
            }
            if(urlType=="day")
            {
                searchData.time=Utils.returndates(1,true);
            }
            if(urlType=="week")
            {
                searchData.time=Utils.returndates(2,true);
            }
            if(urlType=="month")
            {
                searchData.time=Utils.returndates(3,true);
            }
            searchData.state=1;
        }
        if(urlYouxiao!=null)
        {
            searchData.youxiao=urlYouxiao;
            searchData.state=1;
        }
        if(urlText!=null)
        {
            searchData.text=urlText
        }
        this.setState({
            searchData:searchData
        })
        this.onDataBind();
    }
    onDataBind=()=>{
        setTimeout(()=>{
            this.dataBind({
                pageindex:this.state.gridPageCurrent,
                search:this.state.searchData,
                sort:this.state.dataSort
            })
        },100)
    }
    dataBind=(res)=>{
        const _this=this;
        const {gridPageSize,activeID}=this.state;
        this.setState({
            gridLoading:true
        })
        Service.Post({
            name:"adminzhiwei",
            action:"pagelist",
            data:{
                pageindex:res.pageindex,
                pagesize: gridPageSize,
                search:res.search,
                sort:res.sort,
                shenhe:activeID
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        dataList:result.list,
                        gridPageTotal:result.count
                    })
                }
            },
            fail(){
                Notify.error("网络连接失败");
            },
            complete(){
                _this.setState({
                    gridLoading:false
                })
            }
        })

    }
    onDianpuBind=()=>{
        const _this=this;
        Service.Post({
            name:"admindianpu",
            action:"listbyadmin",
            data:{},
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        searchDianpuList:result.list
                    })
                }
            }
        })
    }
    searchCityBind=()=>{
        const _this=this;
        Service.Post({
            name:"admindianpu",
            action:"citylistbyadmin",
            data:{},
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        searchDianpuList:result.list
                    })
                }
            }
        })
    }
    searchClassBind=()=>{
        const _this=this;
        Service.Post({
            name:"adminzhiweiclass",
            action:"list",
            data:{},
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        searchClassList:result.list
                    })
                }
            }
        })
    }
    onCallBackBind=()=>{
        this.onDataBind();
    }
    onAddWorkClick=()=>{
        this.props.history.push('work/edit/0/0');
    }
    onGridItemEditClick=(id,copy)=>{
        this.props.history.push('work/edit/'+id+'/'+copy);
    }
    onGridItemOpenDrawerClick=(id)=>{
        this.setState({
            workDrawerId:id,
            showWorkDrawer:true
        })
    }
    onDrawerClose=()=>{
        this.setState({
            workDrawerId:0,
            showWorkDrawer:false
        })
    }
    onGridItemStatusClick=(id,state)=>{
        this.setState({
            gridLoading:true
        })
        const _this=this;
        Service.Post({
            name:"adminzhiwei",
            action:"state",
            data:{
                id,
                state
            },
            success(result)
            {
                _this.setState({
                    gridLoading:false
                })
                if(result.retcode==200)
                {
                    Notify.success("操作成功");
                    _this.onDataBind()
                }
                else
                {
                    Notify.warn(result.retmsg);
                }
            },
            fail(){
                _this.setState({
                    gridLoading:false
                })
            }
        })
    }
    onGridItemDeleteClick=(id)=>{
        const _this=this;
        Sweetalert.confirm({
            content:'操作成功后将无法恢复，请确认',
            title: '请确认操作',
            onConfirm: () => new Promise((resolve,reject) => {
                Service.Post({
                    name:"adminzhiwei",
                    action:"delete",
                    data:{
                        id:id
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("已删除");
                            resolve();
                            _this.onDataBind()
                        }
                        else
                        {
                            Notify.warn(result.retmsg);
                            reject();
                        }
                    },
                    fail(){
                        Notify.error("网络链接失败");
                        reject();
                    }
                })
            }),
            parentComponent:this
        });
    }
    onGridItemShenheClick=(id)=>{
         const _this=this;
        Sweetalert.confirm({
            content:'操作成功后将无法恢复，请确认',
            title: '请确认操作',
            onConfirm: () => new Promise((resolve,reject) => {
                Service.Post({
                    name:"adminzhiwei",
                    action:"shenhe",
                    data:{
                        id:id
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("已驳回");
                            resolve();
                            _this.onDataBind()
                        }
                        else
                        {
                            Notify.warn(result.retmsg);
                            reject();
                        }
                    },
                    fail(){
                        Notify.error("网络链接失败");
                        reject();
                    }
                })
            }),
            parentComponent:this
        });
    }
    onGridItemTitleClick=(id)=>{
        this.setState({
            workNewsDrawerId:id,
            showWorkNewsDrawer:true
        })
    }
    onGridItemYouxiaoClick=(id,youxiao)=>{
        this.setState({
            gridLoading:true
        })
        const _this=this;
        Service.Post({
            name:"adminzhiwei",
            action:"youxiao",
            data:{
                id,
                youxiao
            },
            success(result)
            {
                _this.setState({
                    gridLoading:false
                })
                if(result.retcode==200)
                {
                    Notify.success("操作成功");
                    _this.onDataBind()
                }
                else
                {
                    Notify.warn(result.retmsg);
                }
            },
            fail(){
                _this.setState({
                    gridLoading:false
                })
            }
        })
    }
    onTitleDrawerClose=()=>{
        this.setState({
            workNewsDrawerId:0,
            showWorkNewsDrawer:false
        })
    }
    onGridChange=(e)=>{
        if(e.current)
        {
            const current=e.current;
            this.setState({
                gridPageCurrent:current
            })
            this.onDataBind()
        }
        if(e.sortBy)
        {
            const sortBy=e.sortBy;
            const sortType=e.sortType;
            const dataSort={
                sortby:sortBy,
                sorttype:sortType
            }
            this.setState({
                dataSort:dataSort
            })
            this.onDataBind()
        }
    }
    getGridColumns=()=>{
        const listColumns=[
            {
                title:'职位编号',
                width:'120px',
                name:'id',
                textAlign: 'center',
                bodyRender:data => {

                    return(
                        <div>
                            <div><b className="link-ss" onClick={this.onGridItemTitleClick.bind(this,data.id)}>【{data.id}】</b></div>
                            <div>
                                {data.ontop==1&&(<Tag className={"margin-left-5"} theme={"blue"}>顶</Tag>)}
                                {data.youxiao==1&&(<Tag className={"margin-left-5"} theme={"green"}>效</Tag>)}
                            </div>
                            <div>
                                <Tag>{data.workstate}</Tag>
                            </div>
                        </div>
                    )
                }
            },
            {
                title:'职位标题',
                bodyRender:(data)=>{
                    return(
                        <div>
                            <div className={"font16"}>
                                <b className="link-ss" onClick={this.onGridItemTitleClick.bind(this,data.id)}>{data.title}</b>
                                {data.workhits==2&&(
                                    <Tag theme={"yellow"} className={"margin-left-5"} outline>不计算工作次数</Tag>
                                )}
                                {data.xianzhi==2&&(
                                    <Tag theme={"red"} className={"margin-left-5"} outline>不限制报名</Tag>
                                )}
                            </div>
                            <div>
                                <Tag theme={"blue"}>{data.sex}</Tag>
                                {data.mianshi==1&&(
                                    <Tag theme={"blue"} className={"margin-left-5"}>需要面试</Tag>
                                )}
                                {data.tags&&data.tags.map((item,index)=>{
                                    return(<Tag key={index} theme={"blue"} className={"margin-left-5"} outline>{item}</Tag>)
                                })}
                                {data.zeren==1&&(<Tag className={"margin-left-5"} theme={"green"}>无责底薪</Tag>)}
                            </div>
                            <div><Tag>{data.gongsi}</Tag></div>
                        </div>
                    )
                }
            },
            {
                title:'工资待遇',
                name:'money',
                width: '180px',
                needSort: true,
                bodyRender:data => {
                    return(
                        <div>
                            <b className={"color-red"}>{data.money.money}</b>元/{data.money.unit}/{data.money.time}
                        </div>
                    )
                }
            },
            {
                title:'招聘人数',
                name:'renshu',
                width: '100px',
                textAlign: 'center',
                needSort: true,
                bodyRender:data=>{
                    return(
                        <span className={"color-link"} onClick={this.onGridItemOpenDrawerClick.bind(this,data.id)}>{data.signrenshu}/{data.renshu}</span>
                    )
                }
            },
            {
                title:'职位类别',
                name:'class',
                textAlign: 'center',
                width: '100px'
            },
            {
                title:'联系信息',
                width: '120px',
                bodyRender:(data) => {
                    return(
                        <div>
                            <div>{data.lxrname}</div>
                            <div>{data.lxrtel}<Tag theme="blue" outline>{data.lxrtype==1?'电话':'微信'}</Tag></div>
                        </div>
                    )
                }
            },
            {
                title:'所属店铺',
                name:'dianpu',
                width: '120px',
            },
            {
                title:'发布时间',
                name:'addtime',
                width: '100px',
                needSort: true,
                textAlign: 'center'
            },
            {
                title:'发布人',
                name:'admin',
                width: '100px',
                textAlign: 'center'
            },
            {
                title:'状态',
                name:'state',
                width: '75px',
                bodyRender:(data)=>{
                    return <div className="font12">
                        <Tag theme={data.state==1?"green":"yellow"} >{data.state==1?"正常":"关闭"}</Tag>
                        <div>
                            {data.shenhe==1&&data.shrole&&(
                            <Button type="error" size="small" onClick={this.onGridItemShenheClick.bind(this,data.id)}>驳回</Button>
                        )}
                        </div>
                    </div>
                }
            },
            {
                title:'操作',
                width:'325px',
                textAlign:'center',
                bodyRender:(data)=>{
                     let YouxiaoBtn="";
                    if(data.setyouxiao)
                    {
                        if(data.youxiao==1)
                        {
                            YouxiaoBtn=<Button type={"error"} onClick={this.onGridItemYouxiaoClick.bind(this,data.id,2)}>设为无效</Button>
                        }
                        else
                        {
                            YouxiaoBtn=<Button type={"primary"} onClick={this.onGridItemYouxiaoClick.bind(this,data.id,1)}>设为有效</Button>
                        }
                   }
                    return <div className="font12 margin-bottom-5">
                        {YouxiaoBtn}
                        {data.shenhe==1&&(
                            <Button type="text" onClick={this.onGridItemEditClick.bind(this,0,data.id)}>再发一遍</Button>
                        )}
                        <Button type="default" onClick={this.onGridItemEditClick.bind(this,data.id,data.id)}>编辑</Button>
                        {data.state==1&&(
                            <Button type="warning" onClick={this.onGridItemStatusClick.bind(this,data.id,2)}>关闭</Button>
                        )}
                        {data.state==2&&(
                            <Button type="success" onClick={this.onGridItemStatusClick.bind(this,data.id,1)}>启用</Button>
                        )}
                        <Button type="error" onClick={this.onGridItemDeleteClick.bind(this,data.id)}>删除</Button>
                    </div>
                }
            }
        ];
        return listColumns;
    }
    onTabsChange=id=>{
        this.setState({
            activeID: id,
            gridPageCurrent:1
        });
        this.onDataBind();
    }
    render() {
        return(
            <div>
                <Header data={[{name:'职位'},{name:'职位管理'}]}/>
                <div className="page-right-body">
                    <SearchFormView parent={this} data={this.state.searchData} classlist={this.state.searchClassList} dianpulist={this.state.searchDianpuList}/>
                    <div className="paddingtb10rl20">
                        <Tabs
                            activeId={this.state.activeID}
                            onChange={this.onTabsChange}
                            type={"card"}
                            navExtraContent={
                                <Button onClick={this.onAddWorkClick} type="primary">发布职位</Button>
                            }
                        >
                            <TabPanel key="1" tab={<span>已发布</span>} id="1">
                            </TabPanel>
                            <TabPanel key="2" tab="被驳回" id="2">
                            </TabPanel>
                        </Tabs>
                    </div>
                    <div className="page-right-body-bd">
                        <Grid
                            columns={this.getGridColumns()}
                            datasets={this.state.dataList}
                            pageInfo={{
                                current: this.state.gridPageCurrent,
                                pageSize: this.state.gridPageSize,
                                total: this.state.gridPageTotal,
                                pageSizeOptions: [this.state.gridPageSize],
                            }}
                            sortBy={this.state.dataSort.sortby}
                            sortType={this.state.dataSort.sorttype}
                            loading={this.state.gridLoading}
                            onChange={this.onGridChange.bind(this)}
                            ellipsis
                            size={"large"}
                            rowKey="id"/>
                    </div>
                    <Drawer
                        visible={this.state.showWorkDrawer}
                        title="报名列表"
                        onClose={this.onDrawerClose.bind(this)}
                        maskClosable
                        closeOnESC
                    >
                        <WorkMesslistView viewtype={1} viewid={this.state.workDrawerId} onChange={this.onCallBackBind}/>
                    </Drawer>
                    <Drawer
                        visible={this.state.showWorkNewsDrawer}
                        title="详情"
                        width={400}
                        onClose={this.onTitleDrawerClose.bind(this)}
                        maskClosable
                        closeOnESC
                    >
                        <WorkNewsView viewid={this.state.workNewsDrawerId} onClose={this.onTitleDrawerClose}/>
                    </Drawer>
                </div>
            </div>
        )
    }
}
function SearchFormView(props){
    const form=Form.useForm(FormStrategy.View);
    const dianpuList=props.dianpulist;
    const classList=props.classlist;
    const editData=props.data;
    const [gongsiList,setGongsiList]=React.useState([]);
    const [showsearch,setShowsearch]=React.useState(true);
    const [merchantkeyword,onMerchantKeywordChange]=React.useState('');
    const [merchantloading, setMerchantLoading] = React.useState(false);
    const [merchantdisabled,setMerchantDisabled]=React.useState(true);
    const onSubmit=React.useCallback(form => {
        const value = form.getSubmitValue();
        const state=value.state;
        const ontop=value.ontop;
        const mianshi=value.mianshi;
        const sex=value.sex;
        const jiesuantime=value.jiesuantime;
        const workhits=value.workhits;
        const xianzhi=value.xianzhi;
        const workclass=value.workclass;
        const dianpu=value.dianpu;
        const youxiao=value.youxiao;
        const gongsi=value.gongsi;
        const workstate=value.workstate;
        return new Promise(resolve => {
            const pageindex=1;
            const searchdata={
                text:value.text,
                state:state==null?0:state.key,
                ontop:ontop==null?0:ontop.key,
                mianshi:mianshi==null?0:mianshi.key,
                sex:sex==null?0:sex.key,
                jiesuantime:jiesuantime==null?'':jiesuantime.text,
                workhits:workhits==null?0:workhits.key,
                xianzhi:xianzhi==null?0:xianzhi.key,
                workclass:workclass==null?0:workclass.key,
                gongsi:gongsi==null?0:gongsi.key,
                workstate:workstate==null?0:workstate.key,
                dianpu:dianpu==null?0:dianpu.key,
                youxiao:youxiao==null?0:youxiao.key,
                time:value.time
            }
            const datasort={
                sortby:'',
                sorttype:''
            }
            props.parent.setState({
                gridPageCurrent: pageindex,
                searchData:searchdata,
                dataSort:datasort
            })

            props.parent.onDataBind();
        });
    },[])
    const onShowSearchClick=()=>{
        setShowsearch(!showsearch);
    }
    const MrchantRrenderOptionContent=(item)=>{
        const { key, text,contact} = item;
        return <>{text}({contact.name}-{contact.content})</>;
    }
    React.useEffect(() => {
        if(merchantkeyword.length>2)
        {
            const dianpu=form.getValue().dianpu.key;
            setMerchantLoading(true);
            setGongsiList([]);
            Service.Post({
                name: "webmerchants",
                action:"listbytext",
                data: {
                    dianpu:dianpu,
                    text:merchantkeyword
                },
                success(result)
                {
                    if(result.retcode==200)
                    {
                        setGongsiList(result.list);
                    }
                    else
                    {
                        setGongsiList([]);
                    }
                    setMerchantLoading(false);
                },
                fail()
                {
                    setGongsiList([]);
                    setMerchantLoading(false)
                }
            })
        }

    }, [merchantkeyword]);
    React.useEffect(()=>{
        const filedianpu=form.model.get('dianpu');
        const $=filedianpu.value$.subscribe(value => {
            if(value&&value.key) {
                setMerchantDisabled(false);
                setGongsiList([]);
                form.patchValue({
                    gongsi:null
                })
            }
            else
            {
                setMerchantDisabled(true)
            }
        })
        return () =>{
            $.unsubscribe();
        };
    },[form])
    return(
        <div className={showsearch?"page-right-body-search":"page-right-body-search packup"}>
            <Form form={form} layout="horizontal" onSubmit={onSubmit}>
                <div className="page-right-body-search-cells">
                    <div className="page-right-body-search-box">
                        <div className="page-right-body-search-cell">
                            <FormInputField
                                name="text"
                                label="关键字："
                                props={{
                                    placeholder:"职位编号、名称、联系人姓名、联系电话，为空全部",
                                    width:'544px',
                                    showClear:true
                                }}
                                initialValue={editData.text}
                            />
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="state"
                                label="职位状态："
                                props={{
                                    width:'120px',
                                    options:[{key:1,text:'正常'},{key:2,text:'关闭'}],
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                            />
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="youxiao"
                                label="岗位判定："
                                props={{
                                    width:'120px',
                                    options:[{key:1,text:'有效岗位'},{key:2,text:'无效岗位'}],
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                                initialValue={Utils.returnItem(editData.youxiao,[{key:1,text:'有效岗位'},{key:2,text:'无效岗位'}])}
                            />
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="ontop"
                                label="置顶："
                                props={{
                                    width:'120px',
                                    options:[{key:1,text:'已置顶'},{key:2,text:'未置顶'}],
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                            />
                        </div>
                    </div>
                    <div className="page-right-body-search-box">
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="mianshi"
                                label="面试："
                                props={{
                                    width:'120px',
                                    options:[{key:1,text:'需要'},{key:2,text:'无需'}],
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                            />
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="sex"
                                label="性别要求："
                                props={{
                                    width:'120px',
                                    options:[{key:1,text:'限男生'},{key:2,text:'限女生'},{key:3,text:'男女不限'}],
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                            />
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="jiesuantime"
                                label="结算时长："
                                props={{
                                    width:'120px',
                                    options:[{key:1,text:'日结'},{key:2,text:'周结'},{key:3,text:'月结'},{key:4,text:'任务完结'},{key:5,text:'随时结'}],
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                            />
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="workhits"
                                label="计算次数："
                                props={{
                                    width:'120px',
                                    options:[{key:1,text:'计算'},{key:2,text:'不计算'}],
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                            />
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="xianzhi"
                                label="同时段限制报名："
                                props={{
                                    width:'120px',
                                    options:[{key:1,text:'限制'},{key:2,text:'不限制'}],
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                            />
                        </div>
                    </div>
                    <div className="page-right-body-search-box">
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="workclass"
                                label="职位类别："
                                props={{
                                    width:'130px',
                                    options:classList,
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                            />
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="dianpu"
                                label="所属店铺："
                                props={{
                                    width:'200px',
                                    options:dianpuList,
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                                initialValue={Utils.returnItem(editData.dianpu,dianpuList)}
                            />
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="gongsi"
                                label="所属商家："
                                props={{
                                    width:'300px',
                                    options:gongsiList,
                                    keyword:merchantkeyword,
                                    disabled:merchantdisabled,
                                    loading:merchantloading,
                                    onKeywordChange:onMerchantKeywordChange,
                                    filter:false,
                                    clearable:true,
                                    placeholder:'为空全部，搜索最少3个字符',
                                    renderOptionContent:MrchantRrenderOptionContent
                                }}
                            />

                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="workstate"
                                label="职位进度："
                                props={{
                                    width:'120px',
                                    options:[{key:1,text:'未开始报名'},{key:2,text:'报名中'},{key:3,text:'待开始工作'},{key:4,text:'工作中'},{key:5,text:'已结束'}],
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                                initialValue={Utils.returnItem(editData.workstate,[{key:1,text:'未开始报名'},{key:2,text:'报名中'},{key:3,text:'待开始工作'},{key:4,text:'工作中'},{key:5,text:'已结束'}])}
                            />
                        </div>

                    </div>

                    <FormDateRangeQuickPickerField
                        name="time"
                        label="发布时间："
                        props={{
                            width:'195px',
                            format:"YYYY-MM-DD HH:mm:ss",
                            chosenDays:7,
                            preset:[
                                {
                                    text: '今天',
                                    value: 0
                                },
                                {
                                    text: '昨天',
                                    value: 1
                                },
                                {
                                    text: '近7天',
                                    value: 7,
                                },
                                {
                                    text: '近30天',
                                    value: 30,
                                },
                            ]
                        }}
                        defaultValue={['','']}
                        initialValue={editData.time}
                    />
                    <div className="page-right-body-search-buttons">
                        <Button icon={"refresh"}  htmlType="reset">重置</Button>
                        <Button icon="search" type="primary" htmlType={"submit"}>搜索</Button>
                    </div>
                </div>
            </Form>
            <div className="page-right-body-search-packup" onClick={onShowSearchClick}></div>
        </div>

    )
}
