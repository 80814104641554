import React from 'react';
import Header from '../../../../component/header'
import {
    Button,
    Grid,
    Notify,
    Form,
    FormStrategy,
    FormSelectField,
    FormInputField,
    FormDateRangeQuickPickerField,
    Tabs,
    Tag,
    Dialog,
} from 'zent'
import Service from "../../../../config/Service";
const TabPanel = Tabs.TabPanel;
const {openDialog,closeDialog}=Dialog
export default class MainCustomerAgentIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            wclGridPageCurrent:1,
            wclGridPageTotal:0,
            wclGridLoading:false,
            wclGridPageSize:20,
            wclDataList:[],
            wclSearchData:{
                text:'',
                vip:0,
                city:0,
                time:['','']
            },
            yclGridPageCurrent:1,
            yclGridPageTotal:0,
            yclGridLoading:false,
            yclGridPageSize:20,
            yclDataList:[],
            yclSearchData:{
                text:'',
                vip:0,
                dianpu:0,
                time:['',''],
                uptime:['','']
            },
            stateActive:'1',
            searchDianpuList:[],
            searchCityList:[]
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(120300);
        this.wclDataBind();
        this.cityBind();
        this.dianpuBind();
    }
    cityBind=()=>{
        const _this=this;
        Service.Post({
            name:"admindianpu",
            action:"citylistbyadmin",
            data:{},
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        searchCityList:result.list
                    })
                }
            }
        })
    }
    dianpuBind=()=>{
        const _this=this;
        Service.Post({
            name:"admindianpu",
            action:"listbyadmin",
            data:{},
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        searchDianpuList:result.list
                    })
                }
            }
        })
    }
    onDataBind=(index,com)=>{
        if(index==1)
        {
            setTimeout(()=>{
                this.wclDataBind(com);
            },100)
        }
        if(index==2)
        {
            setTimeout(()=>{
                this.yclDataBind(com);
            },100)
        }
    }
    wclDataBind=(com)=>{
        const _this=this;
        const {wclGridPageSize,wclGridPageCurrent,wclSearchData}=this.state;
        this.setState({
            wclGridLoading:true
        })
        Service.Post({
            name:"agent",
            action:"wclpagelist",
            data:{
                pageindex:wclGridPageCurrent,
                pagesize: wclGridPageSize,
                search:wclSearchData
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        wclDataList:result.list,
                        wclGridPageTotal:result.count
                    })
                }
                else
                {
                    _this.setState({
                        wclDataList:[],
                        wclGridPageTotal:0
                    })
                }
            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    wclDataList:[],
                    wclGridPageTotal:0
                })
            },
            complete(){
                _this.setState({
                    wclGridLoading:false
                })
                if(typeof com=="function")
                {
                    com();
                }
            }
        })

    }
    yclDataBind=(com)=>{
        const _this=this;
        const {yclGridPageSize,yclGridPageCurrent,yclSearchData}=this.state;
        this.setState({
            yclGridLoading:true
        })
        Service.Post({
            name:"agent",
            action:"yclpagelist",
            data:{
                pageindex:yclGridPageCurrent,
                pagesize: yclGridPageSize,
                search:yclSearchData
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        yclDataList:result.list,
                        yclGridPageTotal:result.count
                    })
                }
                else
                {
                    _this.setState({
                        yclDataList:[],
                        yclGridPageTotal:0
                    })
                }
            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    yclDataList:[],
                    yclGridPageTotal:0
                })
            },
            complete(){
                _this.setState({
                    yclGridLoading:false
                })
                if(typeof com=="function")
                {
                    com();
                }
            }
        })
    }
    onGridItemStatusClick=(id)=>{
        const _this=this;
        const {searchDianpuList}=this.state;
        if(searchDianpuList.length==1)
        {
            _this.onSetStatus(id,searchDianpuList[0].key)
        }
        else
        {
            const _this=this;
            const dialogid='cldialog'+id;
            openDialog({
                parentComponent:this,
                title:'设为已抄录',
                dialogId:dialogid,
                maskClosable:false,
                closeBtn:false,
                children:(
                    <ChaoLuSetView dataid={id} dianpulist={searchDianpuList} onChange={(dianpu)=>{
                        _this.onSetStatus(id,dianpu)
                    }}/>
                )
            })
        }
    }
    onSetStatus=(id,dianpu)=>{
        this.setState({
            wclGridLoading:true
        })
        const _this=this;
        Service.Post({
            name:"agent",
            action:"state",
            data:{
                id,
                dianpu
            },
            success(result)
            {
                _this.setState({
                    wclGridLoading:false
                })
                if(result.retcode==200)
                {
                    Notify.success("操作成功");
                    _this.onDataBind(_this.state.wclGridPageCurrent);
                    _this.setState({
                        yclDataList:[]
                    })
                }
                else
                {
                    Notify.warn(result.retmsg);
                }
            },
            fail(){
                _this.setState({
                    wclGridLoading:false
                })
            }
        })
    }
    onWclGridChange=(e)=>{
        const current=e.current;
        this.setState({
            wclGridPageCurrent:current
        })
        this.onDataBind(1)
    }
    onYclGridChange=(e)=>{
        const current=e.current;
        this.setState({
            yclGridPageCurrent:current
        })
        this.onDataBind(2)
    }
    onTabsChange=(id)=>{
        this.setState({
            stateActive:id
        })
        if(id==1)
        {
            if(this.state.wclDataList.length<=0)
            {
                this.onDataBind(1);
            }
        }
        if(id==2)
        {
            if(this.state.yclDataList.length<=0)
            {
                this.onDataBind(2);
            }
        }
    }
    getWclGridColumns=()=>{
        const listColumns=[
            {
                title:'姓名',
                name:'name',
            },
            {
                title:'电话',
                name:'tel',
                width:'200px'
            },
            {
                title:'所在城市',
                name:'city',
                width:'150px'
            },
            {
                title:'报名时间',
                name:'time',
                width:'170px'
            },
            {
                title:'是否会员',
                name:'vip',
                width:'150px',
                bodyRender:data => {
                    if(data.vip==1)
                    {
                        return <Tag theme={"blue"}>会员</Tag>
                    }
                    else
                    {
                        return <Tag>非会员</Tag>
                    }
                }
            },
            {
                title:'推荐人',
                name:'user',
                width:'300px'
            },
            {
                title:'操作',
                width:'150px',
                textAlign:'center',
                bodyRender:data=>{
                    return <Button type="primary" onClick={this.onGridItemStatusClick.bind(this,data.id)}>设为已抄录</Button>
                }
            }
        ];
        return listColumns;
    }
    getYclGridColumns=()=>{
        const listColumns=[
            {
                title:'姓名',
                name:'name',
            },
            {
                title:'电话',
                name:'tel',
                width:'200px'
            },
            {
                title:'所属店铺',
                name:'dianpu',
                width:'150px'
            },
            {
                title:'报名时间',
                name:'time',
                width:'170px'
            },
            {
                title:'是否会员',
                name:'vip',
                width:'150px',
                bodyRender:data => {
                    if(data.vip==1)
                    {
                        return <Tag theme={"blue"}>会员</Tag>
                    }
                    else
                    {
                        return <Tag>非会员</Tag>
                    }
                }
            },
            {
                title:'推荐人',
                name:'user',
                width:'300px'
            },
            {
                title:'抄录时间',
                name:"uptime",
                width:'170px'
            }
        ];
        return listColumns;
    }
    render() {
        const panels = [
            <TabPanel key="1" tab={"未抄录"} id="1">
                <SearchFormNoView parent={this} citylist={this.state.searchCityList}/>
                <div style={{paddingBottom:'10px'}}></div>
                <div className="page-right-body-bd">
                    <Grid
                        columns={this.getWclGridColumns()}
                        datasets={this.state.wclDataList}
                        pageInfo={{
                            current: this.state.wclGridPageCurrent,
                            pageSize: this.state.wclGridPageSize,
                            total: this.state.wclGridPageTotal,
                            pageSizeOptions: [this.state.wclGridPageSize],
                        }}
                        loading={this.state.wclGridLoading}
                        onChange={this.onWclGridChange.bind(this)}
                        ellipsis
                        size={"medium"}
                        rowKey="id"/>
                </div>
            </TabPanel>,
            <TabPanel key="2" tab={"已抄录"} id="2">
                <SearchFormYesView parent={this} dianpulist={this.state.searchDianpuList}/>
                <div style={{paddingBottom:'10px'}}></div>
                <div className="page-right-body-bd">
                    <Grid
                        columns={this.getYclGridColumns()}
                        datasets={this.state.yclDataList}
                        pageInfo={{
                            current: this.state.yclGridPageCurrent,
                            pageSize: this.state.yclGridPageSize,
                            total: this.state.yclGridPageTotal,
                            pageSizeOptions: [this.state.yclGridPageSize],
                        }}
                        loading={this.state.yclGridLoading}
                        onChange={this.onYclGridChange.bind(this)}
                        ellipsis
                        size={"medium"}
                        rowKey="id"/>
                </div>
            </TabPanel>
        ]
        return(
            <div>
                <Header data={[{name:'用户'},{name:'报名信息'}]}/>
                <div className="page-right-body">
                    <Tabs
                        activeId={this.state.stateActive}
                        onChange={this.onTabsChange}
                    >
                        {panels}
                    </Tabs>
                </div>
            </div>
        )
    }
}
function SearchFormNoView(props){
    const form=Form.useForm(FormStrategy.View);
    const cityData=props.citylist||[];
    const [loading,setLoading]=React.useState(false);
    const [showsearch,setShowsearch]=React.useState(true);
    const onSubmit=React.useCallback(form => {
        setLoading(true);
        const value = form.getSubmitValue();
        const text=value.text;
        const city=value.city;
        const vip=value.vip;
        const time=value.time;
        const searchData={
            text:text,
            city:city==null?0:city.key,
            vip:vip==null?0:vip.key,
            time:time
        }
        props.parent.setState({
            wclGridPageCurrent: 1,
            wclSearchData: searchData
        })
        props.parent.onDataBind(1,()=>{
            setLoading(false);
        })
    },[])
    const onShowSearchClick=()=>{
        setShowsearch(!showsearch);
    }
    return(
        <div className={showsearch?"page-right-body-search":"page-right-body-search packup"}>
            <Form form={form} layout="horizontal" onSubmit={onSubmit} disabled={loading}>
                <div className="page-right-body-search-cells">
                    <div className="page-right-body-search-box">
                        <div className="page-right-body-search-cell">
                            <FormInputField
                                name="text"
                                label="关键字："
                                props={{
                                    placeholder:"姓名、手机号，推荐人姓名、手机号。为空全部",
                                    width:'362px',
                                    showClear:true
                                }}/>
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="vip"
                                label="用户身份："
                                props={{
                                    width:'150px',
                                    options:[{key:1,text:'会员'},{key:2,text:'普通用户'}],
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                            />
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="city"
                                label="所属城市："
                                props={{
                                    width:'150px',
                                    options:cityData,
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                            />
                        </div>
                    </div>
                    <FormDateRangeQuickPickerField
                        name="time"
                        label="报名时间："
                        props={{
                            format:"YYYY-MM-DD HH:mm:ss",
                            chosenDays:7,
                            preset:[
                                {
                                    text: '今天',
                                    value: 0
                                },
                                {
                                    text: '昨天',
                                    value: 1
                                },
                                {
                                    text: '近7天',
                                    value: 7,
                                },
                                {
                                    text: '近30天',
                                    value: 30,
                                },
                            ]
                        }}
                        defaultValue={['','']}
                    />
                    <div className="page-right-body-search-buttons">
                        <Button icon={"refresh"}  htmlType="reset">重置</Button>
                        <Button icon="search" type="primary" htmlType={"submit"} loading={loading}>搜索</Button>
                    </div>
                </div>
            </Form>
            <div className="page-right-body-search-packup" onClick={onShowSearchClick}></div>
        </div>

    )
}
function SearchFormYesView(props){
    const form=Form.useForm(FormStrategy.View);
    const dianpuData=props.dianpulist||[];
    const [loading,setLoading]=React.useState(false);
    const [showsearch,setShowsearch]=React.useState(true);
    const onSubmit=React.useCallback(form => {
        setLoading(true);
        const value = form.getSubmitValue();
        const text=value.text;
        const dianpu=value.dianpu;
        const vip=value.vip;
        const time=value.time;
        const uptime=value.uptime;
        const searchData={
            text:text,
            dianpu:dianpu==null?0:dianpu.key,
            vip:vip==null?0:vip.key,
            time:time,
            uptime:uptime
        }
        props.parent.setState({
            yclGridPageCurrent: 1,
            yclSearchData: searchData
        })
        props.parent.onDataBind(2,()=>{
            setLoading(false);
        })
    },[])
    const onShowSearchClick=()=>{
        setShowsearch(!showsearch);
    }
    return(
        <div className={showsearch?"page-right-body-search":"page-right-body-search packup"}>
            <Form form={form} layout="horizontal" onSubmit={onSubmit} disabled={loading}>
                <div className="page-right-body-search-cells">
                    <div className="page-right-body-search-box">
                        <div className="page-right-body-search-cell">
                            <FormInputField
                                name="text"
                                label="关键字："
                                props={{
                                    placeholder:"姓名、手机号，推荐人姓名、手机号。为空全部",
                                    width:'362px',
                                    showClear:true
                                }}/>
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="vip"
                                label="用户身份："
                                props={{
                                    width:'150px',
                                    options:[{key:1,text:'会员'},{key:2,text:'普通用户'}],
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                            />
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="dianpu"
                                label="所属店铺："
                                props={{
                                    width:'220px',
                                    options:dianpuData,
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                            />
                        </div>
                    </div>
                    <FormDateRangeQuickPickerField
                        name="uptime"
                        label="抄录时间："
                        props={{
                            format:"YYYY-MM-DD HH:mm:ss",
                            chosenDays:7,
                            preset:[
                                {
                                    text: '今天',
                                    value: 0
                                },
                                {
                                    text: '昨天',
                                    value: 1
                                },
                                {
                                    text: '近7天',
                                    value: 7,
                                },
                                {
                                    text: '近30天',
                                    value: 30,
                                },
                            ]
                        }}
                        defaultValue={['','']}
                    />
                    <FormDateRangeQuickPickerField
                        name="time"
                        label="报名时间："
                        props={{
                            format:"YYYY-MM-DD HH:mm:ss",
                            chosenDays:7,
                            preset:[
                                {
                                    text: '今天',
                                    value: 0
                                },
                                {
                                    text: '昨天',
                                    value: 1
                                },
                                {
                                    text: '近7天',
                                    value: 7,
                                },
                                {
                                    text: '近30天',
                                    value: 30,
                                },
                            ]
                        }}
                        defaultValue={['','']}
                    />
                    <div className="page-right-body-search-buttons">
                        <Button icon={"refresh"}  htmlType="reset">重置</Button>
                        <Button icon="search" type="primary" htmlType={"submit"} loading={loading}>搜索</Button>
                    </div>
                </div>
            </Form>
            <div className="page-right-body-search-packup" onClick={onShowSearchClick}></div>
        </div>

    )
}
function ChaoLuSetView(props)
{
    const dianpuList=props.dianpulist;
    const dialogid='cldialog'+props.dataid;
    const form=Form.useForm(FormStrategy.View);
    const onSubmit=React.useCallback(form => {
        const value = form.getSubmitValue();
        const dianpu=value.dianpu.key;
        props.onChange(dianpu);
        closeDialog(dialogid);
    },[]);
    return(
        <Form form={form} layout="horizontal" onSubmit={onSubmit}>
            <FormSelectField
                label={"所属店铺"}
                name="dianpu"
                required={"请选择所属店铺"}
                props={{
                    options:dianpuList,
                    width:'320px',
                    placeholder:"选择所属店铺"
                }}
            />
            <div style={{textAlign:'right'}}>
                <Button onClick={()=>closeDialog(dialogid)}>取消</Button>
                <Button type="primary" htmlType={"submit"}>提交</Button>
            </div>
        </Form>
    )
}