import React from 'react';
import Header from '../../../../component/header'
import {
    Button,
    Grid,
    Notify,
    Sweetalert,
    Tag,
    Icon,
} from 'zent'
import Service from "../../../../config/Service";

export default class MainSystemMenuIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            gridPageCurrent:1,
            gridPageTotal:0,
            gridLoading:false,
            gridPageSize:20,
            dataList:[]
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(110700);
        this.dataBind(1);
    }
    dataBind=(pageindex)=>{
        const _this=this;
        const {gridPageSize}=this.state;
        this.setState({
            gridLoading:true
        })
        Service.Post({
            name:"adminmenus",
            action:"pagelist",
            data:{
                pageindex:pageindex,
                pagesize: gridPageSize
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        dataList:result.list,
                        gridPageTotal:result.count
                    })
                }
            },
            fail(){
                Notify.error("网络连接失败");
            },
            complete(){
                _this.setState({
                    gridLoading:false
                })
            }
        })

    }

    onAddMenuClick=()=>{
        this.props.history.push('menu/edit/0');
    }
    onGridItemEditClick=(id)=>{
        this.props.history.push('menu/edit/'+id);
    }
    onGridItemDeleteClick=(id,level)=>{
        const _this=this;
        Sweetalert.confirm({
            content:'操作成功后将无法恢复，请确认',
            title: '请确认操作',
            onConfirm: () => new Promise((resolve,reject) => {
                Service.Post({
                    name:"adminmenus",
                    action:"delete",
                    data:{
                        mid:id,
                        level:level
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("已删除");
                            resolve();
                            _this.dataBind(_this.state.gridPageCurrent)
                        }
                        else
                        {
                            Notify.warn(result.retmsg);
                            reject();
                        }
                    },
                    fail(){
                        Notify.error("网络链接失败");
                        reject();
                    }
                })
            }),
            parentComponent:this
        });
    }
    onGridChange=(e)=>{
        const current=e.current;
        this.setState({
            gridPageCurrent:current
        })
        this.dataBind(current)
    }
    getGridColumns=()=>{
        const listColumns=[
            {
                title:'编码',
                name:'mid',
                width: '100px'
            },
            {
                title:'名称',
                name:'name',
                width: '150px'
            },
            {
                title:'地址',
                name:'url',
            },
            {
                title:'图标',
                width: '100px',
                bodyRender:(data)=>{
                    let statusView=null;
                    if(data.icon.length>0)
                    {
                        statusView=<Icon type={data.icon}/>
                    }
                    return <div className="font20">
                        {statusView}
                    </div>
                }
            },
            {
                title:'等级',
                name:'level',
                width: '100px'
            },
            {
                title:'上级菜单',
                name:'class',
                width: '150px'
            },
            {
                title:'是否导航',
                width: '100px',
                bodyRender:(data)=>{
                    let statusView=null;
                    if(data.nav==1)
                    {
                        statusView=<Tag theme={"green"}>是</Tag>
                    }
                    else
                    {
                        statusView=<Tag theme={"yellow"}>否</Tag>
                    }
                    return <div className="font12">
                        {statusView}
                    </div>
                }
            },
            {
                title:'排序',
                name:'sort',
                width: '80px'
            },
            {
                title:'操作',
                width:'250px',
                textAlign:'center',
                bodyRender:(data,res)=>{
                    return <div className="font12">
                        <Button type="default" onClick={this.onGridItemEditClick.bind(this,data.id)}>编辑</Button>
                        <Button type="error" onClick={this.onGridItemDeleteClick.bind(this,data.mid,data.level)}>删除</Button>
                    </div>
                }
            }
        ];
        return listColumns;
    }
    render() {
        return(
            <div>
                <Header data={[{name:'系统'},{name:'菜单管理'}]}/>
                <div className="page-right-body">
                    <div className="page-right-body-hd">
                        <div className="page-right-body-hd-hd">
                        </div>
                        <div className="page-right-body-hd-bd">
                            <Button onClick={this.onAddMenuClick} type="primary">添加菜单</Button>
                        </div>
                    </div>
                    <div className="page-right-body-bd">
                        <Grid
                            columns={this.getGridColumns()}
                            datasets={this.state.dataList}
                            pageInfo={{
                                current: this.state.gridPageCurrent,
                                pageSize: this.state.gridPageSize,
                                total: this.state.gridPageTotal,
                                pageSizeOptions: [this.state.gridPageSize],
                            }}
                            loading={this.state.gridLoading}
                            onChange={this.onGridChange.bind(this)}
                            ellipsis
                            size={"medium"}
                            rowKey="id"/>
                    </div>
                </div>
            </div>
        )
    }
}