import React from 'react';
import Header from '../../../../component/header'
import { Form, FormStrategy, Notify, FormInputField,Button,Icon,
    ValidatorMiddlewares,Validators,
    FormNumberInputField,BlockLoading,FormSwitchField,FormSelectField,FormRadioGroupField,Radio} from 'zent';
import Service from "../../../../config/Service";
import Utils from "../../../../config/Utils";
export default class MainSystemMenuEdit extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            cityId:0,
            editData:{},
            menuList:[],
            loading:false
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(110700);
        const id=this.props.match.params.id||0;
        this.setState({
            cityId:id
        })
        this.menuBind();
        this.dataBind(id);
    }
    menuBind(){
        const _this=this;
        Service.Post({
            name:"adminmenus",
            action:"listbymenu",
            data:{},
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        menuList:result.list
                    })
                }
            }
        })
    }

    dataBind(id){
        if(id<=0)
        {
            return false;
        }
        const _this=this;
        this.setState({
            loading:true
        })
        Service.Post({
            name:"adminmenus",
            action:"news",
            data:{
                id
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        editData:result.data,
                        loading:false
                    })
                }
                else
                {
                    Notify.error("没有找到相关信息",1500,()=>{
                        _this.setState({
                            loading:false
                        })
                        _this.props.history.goBack();
                    });
                }

            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    loading:false
                })
            }
        })
    }
    iconBind=()=>{
        const list=[
            "summary",
            "shop",
            "goods",
            "order",
            "customer",
            "chart",
            "capital",
            "settings",
            "marketing",
            "inventory",
            "procurement",
            "finance",
            "cashier",
            "course",
            "admissions",
            "educational",
            "customer-service",
            "message",
            "hotel",
            "star",
            "bell",
            "mini-apps",
            "wechat",
            "clock",
            "calendar",
            "present",
            "ticket",
            "expand-customer",
            "checkin",
            "assess",
            "business",
            "hr",
            "approval",
            "report",
            "coupon",
            "hc-manage",
            "report-forms",
            "brush",
            "development-doc",
            "bookmark",
            "video"
        ]
        return list;
    }
    FormView=()=>{
        const _this=this;
        const editData=this.state.editData;
        const stateLoading=this.state.loading;
        const menulist=this.state.menuList;
        const form = Form.useForm(FormStrategy.View);
        const [loading,setLoading]=React.useState(false);
        const [idbetext,setIdbetext]=React.useState('');
        const [idaftext,setIdaftext]=React.useState('');
        const onSubmit = React.useCallback(form => {
            setLoading(true);
            const value = form.getSubmitValue();
            const nav=value.class.level==0?true:value.nav
            return new Promise(resolve => {
                const id=_this.state.cityId;
                Service.Post({
                    name:"adminmenus",
                    action:"edit",
                    data:{
                        id,
                        icon:value.icon?value.icon:'',
                        mid:value.mid,
                        name:value.name,
                        nav:nav?1:2,
                        url:value.url?value.url:'#',
                        sort:value.sort,
                        class:value.class.key,
                        level:value.class.level
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("提交成功",1000,()=>{
                                setLoading(false);
                                _this.props.history.goBack();
                            })
                        }
                        else
                        {
                            Notify.error(result.retmsg);
                            setLoading(false);
                        }
                    },
                    fail(){
                        Notify.error("网络连接失败");
                        setLoading(false);
                    }
                })
            });
        }, []);
        React.useEffect(() => {
            const fileclass=form.model.get('class');
            const $=fileclass.value$.subscribe(value => {
                if(value) {
                    const key=value.key+'';
                    if(value.level==0)
                    {
                        setIdbetext('');
                        setIdaftext('0000');
                    }
                    else if(value.level==1)
                    {
                        const idbe=key.substring(0,2);
                        setIdbetext(idbe);
                        setIdaftext('00');
                    }
                    else{
                        const idbe=key.substring(0,4);
                        setIdbetext(idbe);
                        setIdaftext('');
                    }
                }
            });
            return () =>{
                $.unsubscribe();
            };
        }, [form]);
        return(
            <Form
                disabled={loading}
                form={form}
                layout="horizontal"
                onSubmit={onSubmit}>
                <FormSelectField
                    name={"class"}
                    label={"上级："}
                    required
                    props={{
                        options:menulist
                    }}
                    initialValue={Utils.returnItem(editData.class,menulist)}
                />
                <FormNumberInputField
                    name={"mid"}
                    label={"编码："}
                    required
                    props={{
                        width:'200px',
                        addonBefore:idbetext,
                        addonAfter:idaftext,
                        max:99,
                        min:1,
                        maxLength:2
                    }}
                    helpDesc={"请按照同级菜单最后编号+1填写。最小1，最大99"}
                    initialValue={(editData.mid+'').substr((editData.level*2-2),2)}
                />
                <FormInputField
                    name="name"
                    label="名称："
                    required
                    props={{
                        maxLength:15,
                        placeholder:"请输入名称",
                    }}
                    initialValue={editData.name}
                />
                <Form.FieldValue name={"class"}>
                    {classdata=>{
                        let navView,iconView;
                        if(classdata!=null&&classdata.level>0)
                        {
                            navView=<>
                                    <FormSwitchField
                                        name="nav"
                                        label="导航："
                                        initialValue={editData.nav==2?false:true}
                                    />
                                    <Form.FieldValue name={"nav"}>
                                        {nav=>(
                                            nav&&(
                                                <FormInputField
                                                    name="url"
                                                    label="地址："
                                                    required
                                                    props={{
                                                        width:'400px',
                                                        maxLength:48,
                                                        placeholder:"请输入地址连接",
                                                    }}
                                                    validators={[
                                                        ValidatorMiddlewares.when(
                                                            ctx => ctx.getSectionValue("nav")?.nav
                                                        )(Validators.required('请输入地址')),
                                                    ]}
                                                    initialValue={editData.url}
                                                />
                                            )
                                        )}
                                    </Form.FieldValue>
                                </>
                        }
                        if(classdata!=null&&classdata.level==0)
                        {
                            iconView=<FormRadioGroupField
                                    name={"icon"}
                                    label={"图标："}
                                    validators={[
                                        ValidatorMiddlewares.when(
                                            ctx => ctx.getFormValue().class.key==0
                                        )(Validators.required('请选择图标')),
                                    ]}
                                    initialValue={editData.icon}
                                >
                                    {_this.iconBind().map((item,index)=>(
                                        <Radio value={item} key={index} className={"page-right-body-form-radio"}>
                                            <Icon type={item} />
                                        </Radio>
                                    ))}
                                </FormRadioGroupField>
                        }
                        return(
                            <>
                                {navView}
                                {iconView}
                            </>
                        )
                    }}
                </Form.FieldValue>
                <FormNumberInputField
                    name="sort"
                    label="排序："
                    required
                    props={{
                        max:99999,
                        min:0,
                        placeholder:"请输入排序数字"
                    }}
                    helpDesc={"数字越大排序越靠前"}
                    initialValue={editData.sort||0}
                />
                {!stateLoading&&(
                    <div className="form-submit app-fixed-container">
                        <Button type="default" onClick={()=>{_this.props.history.goBack();}}>取消</Button>
                        <Button htmlType="submit" type="primary" loading={loading}>保存</Button>
                    </div>)
                }
            </Form>
        )
    }

    render() {
        const FormView=this.FormView.bind();
        const {loading}=this.state;
        return(
            <div>
                <Header data={[{name:'系统'},{name:'菜单管理',href:'/main/system/menu'},{name: '编辑菜单'}]}/>
                <BlockLoading loading={loading}>
                    <div className="page-right-body">
                        <div className="page-right-body-hd"></div>
                        <div className="page-right-body-bd">
                            <FormView/>
                        </div>
                    </div>
                </BlockLoading>
            </div>
        )
    }
}
