import React from 'react';
import Header from '../../../../component/header'
import { Form, FormStrategy, Notify, FormInputField,Button,
    FormSelectField,BlockLoading,FormSwitchField} from 'zent';
import Service from "../../../../config/Service";
import Utils from "../../../../config/Utils";
export default class MainSystemYewuyuanEdit extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            yewuyuanId:0,
            editData:{},
            dianpuList:[],
            loading:false
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(110400);
        const id=this.props.match.params.id||0;
        this.setState({
            yewuyuanId:id
        })
        this.dianpuBind();
        this.dataBind(id);
    }
    dianpuBind(){
        const _this=this;
        Service.Post({
            name:"admindianpu",
            action:"listbyadmin",
            data:{},
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        dianpuList:result.list
                    })
                }
            }
        })
    }

    dataBind(id){
        if(id<=0)
        {
            return false;
        }
        const _this=this;
        this.setState({
            loading:true
        })
        Service.Post({
            name:"adminyewuyuan",
            action:"news",
            data:{
                id
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        editData:result.data,
                        loading:false
                    })
                }
                else
                {
                    Notify.error("没有找到相关信息",1500,()=>{
                        _this.setState({
                            loading:false
                        })
                        _this.props.history.goBack();
                    });
                }

            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    loading:false
                })
            }
        })
    }
    FormView=()=>{
        const _this=this;
        const editData=this.state.editData;
        const stateLoading=this.state.loading;
        const dianpulist=this.state.dianpuList;
        const form = Form.useForm(FormStrategy.View);
        const [loading,setLoading]=React.useState(false);
        const onSubmit = React.useCallback(form => {
            setLoading(true);
            const value = form.getSubmitValue();
            return new Promise(resolve => {
                const id=_this.state.yewuyuanId;
                Service.Post({
                    name:"adminyewuyuan",
                    action:"edit",
                    data:{
                        id,
                        name:value.name,
                        tel:value.tel,
                        dianpu:value.dianpu.key,
                        state:value.state?1:2,
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("提交成功",1000,()=>{
                                setLoading(false);
                                _this.props.history.goBack();
                            })
                        }
                        else
                        {
                            Notify.error(result.retmsg);
                            setLoading(false);
                        }
                    },
                    fail(){
                        Notify.error("网络连接失败");
                        setLoading(false);
                    }
                })
            });
        }, []);
        return(
            <Form
                disabled={loading}
                form={form}
                layout="horizontal"
                onSubmit={onSubmit}>
                <FormInputField
                    name="name"
                    label="姓名："
                    required
                    props={{
                        maxLength:15,
                        placeholder:"请输入业务员姓名",
                    }}
                    initialValue={editData.name}
                />
                <FormInputField
                    name="tel"
                    label="电话："
                    required
                    props={{
                        maxLength:11,
                        placeholder:"请输入业务员联系电话",
                    }}
                    initialValue={editData.tel}
                />
                <FormSelectField
                    name="dianpu"
                    label={"所属店铺"}
                    required
                    props={{
                        options:dianpulist,
                        placeholder:"请选择所属店铺",
                    }}
                    initialValue={Utils.returnItem(editData.dianpu,dianpulist)}
                />
                <FormSwitchField
                    name="state"
                    label="启用："
                    initialValue={editData.state==2?false:true}
                />
                {!stateLoading&&(
                    <div className="form-submit app-fixed-container">
                        <Button type="default" onClick={()=>{_this.props.history.goBack();}}>取消</Button>
                        <Button htmlType="submit" type="primary" loading={loading}>保存</Button>
                    </div>)
                }
            </Form>
        )
    }

    render() {
        const FormView=this.FormView.bind();
        const {loading}=this.state;
        return(
            <div>
                <Header data={[{name:'系统'},{name:'业务员管理',href:'/main/system/yewuyuan'},{name:'编辑业务员'}]}/>
                <BlockLoading loading={loading}>
                    <div className="page-right-body">
                        <div className="page-right-body-hd"></div>
                        <div className="page-right-body-bd">
                            <FormView/>
                        </div>
                    </div>
                </BlockLoading>
            </div>
        )
    }
}