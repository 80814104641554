import React from 'react';
import Header from '../../../../component/header'
import {
    Collapse,
    Tabs,
    Pagination,
    BlockLoading
} from 'zent'
import {Link} from 'react-router-dom'
import Service from "../../../../config/Service";
const TabPanel = Tabs.TabPanel;

export default class MainNoticeNoticeIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            tabsActive:'1',
            collapse1Key:[],
            collapse2Key:[],
            pageSize:15,
            toTal1:0,
            toTal2:0,
            current1:1,
            current2:1,
            dataList1:[],
            dataList2:[],
            loading1:false,
            loading2:false
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(0);
        this.onDataBind(1);
    }
    onDataBind(index){
        const _this=this;
        setTimeout(()=>{
            if(index==1)
            {
                _this.dataBind1();
            }
            if(index==2)
            {
                _this.dataBind2();
            }
        },200)
    }
    dataBind1(){
        const {current1,pageSize}=this.state;
        if(this.state.loading1)
        {
            return false;
        }
        this.setState({
            loading1:true
        })
        const _this=this;
        Service.Post({
            name:"AdminMessages",
            action:"pagelist",
            data:{
                pageindex:current1,
                pagesize:1000,
                state:2
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        dataList1:result.list,
                        toTal1:result.count,
                        loading1:false
                    })
                }
                else
                {
                    _this.setState({
                        dataList1:[],
                        toTal1:0,
                        loading1:false
                    })
                }
            },
            fail(){
                _this.setState({
                    dataList1:[],
                    toTal1:0,
                    loading1:false
                })
            }
        })
    }
    dataBind2(){
        const {current2,pageSize}=this.state;
        if(this.state.loading2)
        {
            return false;
        }
        this.setState({
            loading2:true
        })
        const _this=this;
        Service.Post({
            name:"AdminMessages",
            action:"pagelist",
            data:{
                pageindex:current2,
                pagesize:pageSize,
                state:1
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        dataList2:result.list,
                        toTal2:result.count,
                        loading2:false
                    })
                }
                else
                {
                    _this.setState({
                        dataList2:[],
                        toTal2:0,
                        loading2:false
                    })
                }
            },
            fail(){
                _this.setState({
                    dataList2:[],
                    toTal2:0,
                    loading2:false
                })
            }
        })
    }
    onTabsChange=id=>{
        this.setState({
            tabsActive: id,
        });
        this.onDataBind(id);
    }
    onCollapse1handleChange=(activeKey)=>{
        this.setState({
            collapse1Key:activeKey,
        });
        if(activeKey!=null)
        {
            const index=activeKey.split('|')[1];
            const id=activeKey.split('|')[0];
            if(!this.state.dataList1[index].state)
            {
                this.state.dataList1[index].state=1;
                Service.Post({
                    name:"AdminMessages",
                    action:"state",
                    data:{
                        id:id,
                        state:1
                    }
                })
            }
        }
    }
    onCollapse2handleChange=activeKey=>{
        this.setState({
            collapse2Key:activeKey,
        });
    }
    onPagin1Change=(option)=>{
        this.setState({
            current1:option.current
        });
        this.onDataBind(1);
    }
    onPagin2Change=(option)=>{
        this.setState({
            current2:option.current
        });
        this.onDataBind(2);
    }
    render() {
        const {tabsActive,collapse1Key,collapse2Key,toTal1,toTal2,current1,current2,pageSize,loading1,loading2,dataList1,dataList2}=this.state;
        return(
            <div>
                <Header data={[{name:'通知'},{name:'消息通知'}]}/>
                <div className="page-right-body">
                    <div className="page-right-body-bd" style={{paddingTop:'15px'}}>
                        <Tabs
                            activeId={tabsActive}
                            onChange={this.onTabsChange}
                            type="card"
                        >
                            <TabPanel key="1" tab={<span>未读</span>} id="1">
                                <div>
                                    <BlockLoading loading={loading1}>
                                        <Collapse
                                            panelTitleBackground="none"
                                            activeKey={collapse1Key}
                                            onChange={this.onCollapse1handleChange}
                                            bordered={false}
                                            accordion
                                        >
                                            {dataList1.map((item,index)=>(
                                                <Collapse.Panel title={item.title} key={item.id+'|'+index} extra={item.time}>
                                                   <span dangerouslySetInnerHTML={{__html: item.content.replace("[br]","<br/>")}}></span>
                                                    {item.url&&(
                                                        <span className="notice-more"><Link to={item.url}>查看详情</Link></span>
                                                    )}
                                                </Collapse.Panel>
                                            ))}
                                        </Collapse>
                                        {dataList1.length<=0&&(
                                            <div style={{textAlign:'center',color:'#999'}}>
                                                暂无未读消息
                                            </div>
                                        )}
                                        <div className="padding-top-20">
                                            <Pagination
                                                current={current1}
                                                pageSize={pageSize}
                                                total={toTal1}
                                                onChange={this.onPagin1Change}
                                            />
                                        </div>
                                    </BlockLoading>
                                </div>
                            </TabPanel>,
                            <TabPanel key="2" tab="已读" id="2">
                                <div>
                                    <BlockLoading loading={loading2}>
                                        <Collapse
                                            panelTitleBackground="none"
                                            activeKey={collapse2Key}
                                            onChange={this.onCollapse2handleChange}
                                            bordered={false}
                                            accordion
                                        >
                                            {dataList2.map((item)=>(
                                                <Collapse.Panel title={item.title} key={item.id} extra={<span style={{color:'#999'}}>{item.time}</span>}>
                                                    <span dangerouslySetInnerHTML={{__html: item.content.replace("[br]","<br/>")}}></span>
                                                    {item.url&&(
                                                       <span className="notice-more"><Link to={item.url}>查看详情</Link></span>
                                                    )}
                                                </Collapse.Panel>
                                            ))}
                                        </Collapse>
                                        {dataList2.length<=0&&(
                                            <div style={{textAlign:'center',color:'#999'}}>
                                                暂无已读消息
                                            </div>
                                        )}
                                        <div className="padding-top-20">
                                            <Pagination
                                                current={current2}
                                                pageSize={pageSize}
                                                total={toTal2}
                                                onChange={this.onPagin2Change}
                                            />
                                        </div>
                                    </BlockLoading>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}
