import React from 'react';
import Header from '../../../../component/header'
import {
    Button,
    Form,
    FormDateRangeQuickPickerField,
    FormInputField,
    FormSelectField,
    FormDatePickerField,
    BlockLoading,
    Timeline,
    Pop,
    FormStrategy,
    Grid,
    Notify,
    Sweetalert,
    Tag,
    Drawer,
    previewImage,
    Dialog,
    Tooltip, Validators,
    FormCheckboxGroupField,
    Checkbox
} from 'zent'
import Utils from '../../../../config/Utils'
import Service from "../../../../config/Service";
import "../../../../style/css/main/customer/user/index.css"
import WorkMesslistView from "../../../../component/WorkMesslistView";
import UserResultListView from "../../../../component/UserResultListView";
const {closeDialog,openDialog}=Dialog;
const {FieldValue}=Form;
const KysjData=[
    {
        name:'monday',
        text:'星期一',
        am:'mondayam',
        pm:'mondaypm'
    },
    {
        name:'tuesday ',
        text:'星期二',
        am:'tuesdayam',
        pm:'tuesdaypm'
    },
    {
        name:'wednesday ',
        text:'星期三',
        am:'wednesdayam',
        pm:'wednesdaypm'
    },
    {
        name:'thursday ',
        text:'星期四',
        am:'thursdayam',
        pm:'thursdaypm'
    },
    {
        name:'friday ',
        text:'星期五',
        am:'fridayam',
        pm:'fridaypm'
    },
    {
        name:'saturday',
        text:'星期六',
        am:'saturdayam',
        pm:'saturdaypm'
    },
    {
        name:'sunday',
        text:'星期日',
        am:'sundayam',
        pm:'sundaypm'
    },
]
export default class MainCustomerUserIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            gridPageCurrent:1,
            gridPageTotal:0,
            gridLoading:false,
            gridPageSize:10,
            dataList:[],
            dianpuList:[],
            searchCityList:[],
            searchData:{
                text:'',
                sex:0,
                identity:0,
                idimage:0,
                city:0,
                dianpu:0,
                school:0,
                vipstarttime:['',''],
                vipendtime:['',''],
                addtime:['',''],
                fanxian:0,
                kysj:[],
                sfggjz:0,
                sfgzm:0,
                sfgjqg:0,
                sfzsxdw:0
            },
            showWorkList:false,
            showJinkaList:false,
            worklistId:0,
            resultVisiable:false
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(120100);
        this.dianpuBind();
        this.searchCityBind();
        this.onDataBind({
            pageindex:this.state.gridPageCurrent,
            search:this.state.searchData
        });
    }
    onDataBind=(res)=>{
        const _this=this;
        const {gridPageSize}=this.state;
        this.setState({
            gridLoading:true
        })
        Service.Post({
            name:"webusers",
            action:"userpagelist",
            data:{
                pageindex:res.pageindex,
                pagesize: gridPageSize,
                search:res.search
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        dataList:result.list,
                        gridPageTotal:result.count
                    })
                }
            },
            fail(){
                Notify.error("网络连接失败");
            },
            complete(){
                _this.setState({
                    gridLoading:false
                })
                if(typeof res.com=="function")
                {
                    res.com();
                }
            }
        })

    }
    dianpuBind=()=>{
        const _this=this;
        Service.Post({
            name:"admindianpu",
            action:"listbyadmin",
            data:{
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        dianpuList:result.list
                    })
                }
                else
                {
                    _this.setState({
                        dianpuList:[]
                    })
                }
            },
            fail(){
                _this.setState({
                    dianpuList:[]
                })
            }
        })
    }
    searchCityBind=()=>{
        const _this=this;
        Service.Post({
            name:"admindianpu",
            action:"citylistbyadmin",
            data:{},
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        searchCityList:result.list
                    })
                }
            }
        })
    }
    onAddUserClick=()=>{
        this.props.history.push('user/edit/0');
    }
    onCallBackBind=()=>{
        this.onDataBind({
            pageindex:this.state.gridPageCurrent,
            search:this.state.searchData
        });
    }
    onGridItemEditClick=(id)=>{
        this.props.history.push('user/edit/'+id);
    }
    onGridItemNameClick=(id)=>{
        this.setState({
            showWorkList:true,
            worklistId:id
        })
    }
    onDrawerClose=()=>{
        this.setState({
            worklistId:0,
            showWorkList:false
        })
    }
    onJinkaDrawerClose=()=>{
         this.setState({
            worklistId:0,
            showJinkaList:false
        })
    }
    onGridItemImageClick=(url)=> {
        let images=[];
        images.push(url);
        previewImage({
            images: images,
            index: 0
        });
    }
    onGridChange=(e)=>{
        const current=e.current;
        this.setState({
            gridPageCurrent:current
        })
        this.onDataBind({
            pageindex: current,
            search: this.state.searchData
        })
    }
    onGridItemLSJKClick=(id)=>{
        const dialogid='dialog'+id;
        openDialog({
            parentComponent:this,
            title:'临时禁卡',
            dialogId:dialogid,
            maskClosable:false,
            closeBtn:false,
            children:(
                <LSJKFormView id={id} parent={this}/>
            )
        })
    }
    onGridItemOpenVipClick=(data,type)=>{
        const dialogid='dialogopenvip'+data.id;
        openDialog({
            parentComponent:this,
            title:type==1?'开通会员':'续费会员',
            dialogId:dialogid,
            maskClosable:false,
            closeBtn:false,
            children:(
                <OpenVipFormView data={data} parent={this}/>
            )
        })
    }
    onGridItemJCJKClick=(id)=>{
        const _this=this;
        Sweetalert.confirm({
            content:'请确认是否要解除该用户的禁卡限制',
            title: '请确认操作',
            onConfirm: () => new Promise((resolve,reject) => {
                Service.Post({
                    name:"webusersfoul",
                    action:"delete",
                    data:{
                        id:id
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("操作成功");
                            resolve();
                            _this.onDataBind({
                                pageindex: _this.state.gridPageCurrent,
                                search:_this.state.searchData
                            })
                        }
                        else
                        {
                            Notify.warn(result.retmsg);
                            reject();
                        }
                    },
                    fail(){
                        Notify.error("网络链接失败");
                        reject();
                    }
                })
            }),
            parentComponent:this
        });
    }
    onGridItemYJJKClick=(id)=>{
        const _this=this;
        Sweetalert.confirm({
            content:'永久禁卡后将无法恢复，请确认是否进行永久禁卡',
            title: '请确认操作',
            onConfirm: () => new Promise((resolve,reject) => {
                Service.Post({
                    name:"webusers",
                    action:"userjinka",
                    data:{
                        id:id
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("操作成功");
                            resolve();
                            _this.onDataBind({
                                pageindex: _this.state.gridPageCurrent,
                                search:_this.state.searchData
                            })
                        }
                        else
                        {
                            Notify.warn(result.retmsg);
                            reject();
                        }
                    },
                    fail(){
                        Notify.error("网络链接失败");
                        reject();
                    }
                })
            }),
            parentComponent:this
        });
    }
    onGridItemSWFXClick=(id)=>{
        const _this=this;
        Sweetalert.confirm({
            content:'是否将该用户的返现状态设为已返现，请确认',
            title: '请确认操作',
            onConfirm: () => new Promise((resolve,reject) => {
                Service.Post({
                    name:"webusers",
                    action:"userfanxian",
                    data:{
                        id:id,
                        fanxian:1
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("操作成功");
                            resolve();
                            _this.onDataBind({
                                pageindex: _this.state.gridPageCurrent,
                                search:_this.state.searchData
                            })
                        }
                        else
                        {
                            Notify.warn(result.retmsg);
                            reject();
                        }
                    },
                    fail(){
                        Notify.error("网络链接失败");
                        reject();
                    }
                })
            }),
            parentComponent:this
        });
    }
    onGridItemQXFXClick=(id)=>{
        const _this=this;
        Sweetalert.confirm({
            content:'是否将该用户的已返现状态撤销，请确认',
            title: '请确认操作',
            onConfirm: () => new Promise((resolve,reject) => {
                Service.Post({
                    name:"webusers",
                    action:"userfanxian",
                    data:{
                        id:id,
                        fanxian:2
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("操作成功");
                            resolve();
                            _this.onDataBind({
                                pageindex: _this.state.gridPageCurrent,
                                search:_this.state.searchData
                            })
                        }
                        else
                        {
                            Notify.warn(result.retmsg);
                            reject();
                        }
                    },
                    fail(){
                        Notify.error("网络链接失败");
                        reject();
                    }
                })
            }),
            parentComponent:this
        });
    }
    onGridItemSBJBClick=(id)=>{
        const _this=this;
        Sweetalert.confirm({
            content:'是否解绑该用户登录信息，解绑后用户将会重新进行登录，且可在任何设备登录，请确认',
            title: '请确认操作',
            onConfirm: () => new Promise((resolve,reject) => {
                Service.Post({
                    name:"webusers",
                    action:"userislogin",
                    data:{
                        id:id
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("操作成功");
                            resolve();
                            _this.onDataBind({
                                pageindex: _this.state.gridPageCurrent,
                                search:_this.state.searchData
                            })
                        }
                        else
                        {
                            Notify.warn(result.retmsg);
                            reject();
                        }
                    },
                    fail(){
                        Notify.error("网络链接失败");
                        reject();
                    }
                })
            }),
            parentComponent:this
        });
    }
    onGridItemResultClick=(id)=>{
        const dialogid='xfdialog'+id;
        openDialog({
            parentComponent:this,
            title:'消费记录',
            dialogId:dialogid,
            maskClosable:false,
            closeBtn:true,
            style:{
                width:'800px'
            },
            children:(
                <UserResultListView dataid={id}/>
            )
        })
    }
    onGridItemJinkaClick=(id)=>{
       this.setState({
            showJinkaList:true,
            worklistId:id
        })

    }
    getGridColumns=()=>{
        const listColumns=[
            {
                title:'',
                width:'100px',
                textAlign: 'center',
                fixed:true,
                bodyRender:(data)=>{
                    const classname="headtag tag"+data.warning;
                    return(
                        <div className={classname}>
                            {data.id}
                        </div>
                    )
                }
            },
            {
                title:'姓名',
                width:'240px',
                fixed:true,
                bodyRender:(data)=>{
                    return <div className="grid-body-col-name">
                        <div className="grid-body-col-name-title" onClick={this.onGridItemNameClick.bind(this,data.id)}>
                            {data.name}
                            {data.sex==1&&(
                                <img src={require("../../../../style/img/sex-nan.png")}/>
                            )}
                            {data.sex==2&&(
                                <img src={require("../../../../style/img/sex-nv.png")}/>
                            )}
                            {data.age&&(
                                <Tag
                                    outline
                                    className="margin-left-5"
                                    style={{
                                        color: '#fff',
                                        borderColor: '#008EFF',
                                        backgroundColor:'#008EFF'
                                    }}>
                                    {data.age}
                                </Tag>
                            )}
                        </div>
                        {data.idcardnumber&&(
                            <div>
                                <Tooltip title="身份证号码">
                                    <Tag
                                        outline
                                        style={{
                                            color: '#fff',
                                            borderColor: '#9370db',
                                            backgroundColor:'#9370db'
                                        }}>
                                        {data.idcardnumber}
                                    </Tag>
                                </Tooltip>
                            </div>
                        )}
                        {data.school.length>0&&(
                            <div>
                                <Tooltip trigger="hover" title="就读大学">
                                    {data.school}
                                </Tooltip>
                            </div>
                        )}
                    </div>
                }
            },
            {
                title:'手机',
                name:'tel',
                width: '120px',
                textAlign: 'center'
            },
            {
                title: '身份',
                width:'200px',
                bodyRender:(data)=>{
                    let statusView=null;
                    if(data.vip==1)
                    {
                        statusView=<div>
                            <div>
                                <Tag theme={"blue"}>会员身份</Tag><Tag theme={"blue"} outline>{data.vipcode}</Tag>
                            </div>
                            {data.vipendtime&&(
                                <div>
                                    <Tag theme={"green"}>
                                        到期时间：{data.vipendtime}
                                    </Tag>
                                </div>
                            )}
                        </div>
                    }
                    else
                    {
                        statusView=<div>
                            <Tag theme={data.isdj>2?'yellow':'grey'} outline={data.isdj>2?true:false}> {data.isdj>2?data.identity:'普通用户'}</Tag>
                            {data.vipcode&&(
                                <Tag theme={"blue"} outline>{data.vipcode}</Tag>
                            )}
                        </div>
                    }
                    return <div className="font12">
                        {statusView}
                    </div>
                }
            },
            {
                title:'缴费记录',
                width:'80px',
                textAlign: 'center',
                bodyRender:(data)=>{
                    if(data.resultcount>0)
                    {
                        return <Tag theme={"blue"} style={{cursor: 'pointer'}} onClick={this.onGridItemResultClick.bind(this,data.id)}>{data.resultcount}</Tag>
                    }
                    else
                    {
                        return <Tag>无</Tag>
                    }
                }
            },
            {
                title:'所属地区',
                width:'100px',
                bodyRender:(data)=>{
                    return <div>
                        <div>{data.city}</div>
                        {data.dianpu.length>0&&(
                            <div><Tag theme="blue" outline> {data.dianpu}</Tag></div>
                        )}
                    </div>
                }
            },
            {
                title:'工作信息',
                width: '150px',
                textAlign: 'center',
                bodyRender:(data)=>{
                    return <div>
                        <div>签到次数：<Tag theme="blue" style={{marginRight:'5px'}}>{data.workcount}</Tag>次</div>
                        <div>不良记录：<Tag theme="red" style={{marginRight:'5px'}}>{data.bljlcount}</Tag>条</div>
                    </div>
                }
            },
            {
                title:'其他信息',
                width:'180px',
                bodyRender:(data)=>{
                    return <div>
                        <div>来源渠道：{data.lyqd?data.lyqd:"未知"}</div>
                        {data.fanxian==1&&(
                            <Pop trigger="hover" content={"于 "+data.fanxiantime+" 返现"}>
                                <Tag theme={"blue"}>已返现</Tag>
                            </Pop>
                        )}
                        <div>
                            {data.idimage&&(
                                <Tag onClick={this.onGridItemImageClick.bind(this,data.idimage)} style={{cursor:'pointer',color:'#5AA7F9',borderColor:'#5AA7F9'}} outline>身份证照片</Tag>
                            )}
                        </div>
                    </div>
                }
            },
            {
                title:'限制信息',
                width:'100px',
                textAlign: 'center',
                bodyRender:(data)=>{
                    return <div>
                        {data.jinka==1&&(
                            <div><Tag theme={"red"}>永久禁卡</Tag></div>
                        )}

                        {data.vipdj==1&&(
                            <div>
                                <Pop trigger="hover" content={"于 "+data.vipdjtime+" 自主冻结"}><Tag theme={"blue"}>自主冻结</Tag></Pop>
                            </div>
                        )}
                        {data.viplsjk==1&&(
                            <Pop trigger="hover" content={
                                <div>
                                    <div>禁卡原因：{data.viplsjktext}</div>
                                    <div>起始时间：{data.viplsjkstarttime}</div>
                                    <div>结束时间：{data.viplsjkendtime}</div>
                                </div>
                            }><Tag theme={"yellow"}>临时禁卡</Tag></Pop>
                        )}
                        {data.jinka!=1&&data.vipdj!=1&&data.viplsjk!=1&&(
                            <div>无</div>
                        )}
                        <div><span className={"jinkajilu"} onClick={this.onGridItemJinkaClick.bind(this,data.id)}>临时禁卡记录</span></div>
                    </div>
                }
            },
            {
                title:'注册时间',
                name:'addtime',
                width:'100px',
                textAlign: 'center'
            },
            {
                title:'操作',
                textAlign:'center',
                width: '180px',
                fixed:'right',
                bodyRender:(data)=>{
                    let VipView=<></>;
                    if(data.vip==1)
                    {
                        VipView=<div>
                            <div className={"grid-body-col-buttons-cell"}>
                                <Button icon={"approval"} onClick={this.onGridItemOpenVipClick.bind(this,data,2)} type={"primary"}>续费会员</Button>
                            </div>
                            {data.viplsjk!=1&&(
                                <div className={"grid-body-col-buttons-cell"}>
                                    <Button icon={"info-circle"} onClick={this.onGridItemLSJKClick.bind(this,data.id)} type={"warning"}>临时禁卡</Button>
                                </div>
                            )}
                            {data.viplsjk==1&&(
                                <div className={"grid-body-col-buttons-cell"}>
                                    <Button icon={"info-circle-o"} onClick={this.onGridItemJCJKClick.bind(this,data.id)} type={"warning"} outline>解除禁卡</Button>
                                </div>
                            )}
                            {data.rolejinka&&data.jinka!=1&&(
                                <div className={"grid-body-col-buttons-cell"}>
                                    <Button icon={"subtract-circle"} onClick={this.onGridItemYJJKClick.bind(this,data.id)} type={"danger"}>永久禁卡</Button>
                                </div>
                            )}
                        </div>
                    }
                    else
                    {
                        VipView=<div className={"grid-body-col-buttons-cell"}>
                            <Button icon={"approval"} onClick={this.onGridItemOpenVipClick.bind(this,data,1)} type={"primary"}>开通会员</Button>
                        </div>
                    }
                    return <div className="font12">
                        <Button type="default" onClick={this.onGridItemEditClick.bind(this,data.id)}>编辑</Button>
                        <Pop trigger="click" style={{zIndex:1049}} position="left-top" content={
                            <div className={"grid-body-col-buttons"}>
                                {VipView}
                                {data.rolefanxian&&data.fanxian!=1&&(<div className={"grid-body-col-buttons-cell"}>
                                    <Button icon={"capital"} onClick={this.onGridItemSWFXClick.bind(this,data.id)} type={"success"}>设为返现</Button>
                                </div>)}
                                {data.rolefanxian&&data.fanxian==1&&(
                                    <Button icon={"capital"} onClick={this.onGridItemQXFXClick.bind(this,data.id)} type={"success"} outline>取消返现</Button>
                                )}
                                <div className={"grid-body-col-buttons-cell"}>
                                    <Button icon={"refresh"} onClick={this.onGridItemSBJBClick.bind(this,data.id)} type={"default"}>设备解绑</Button>
                                </div>
                            </div>
                        }>
                            <Button type="warning">更多</Button>
                        </Pop>
                    </div>
                }
            }
        ];
        return listColumns;
    }
    render() {
        return(
            <div>
                <Header data={[{name:'系统'},{name:'学生管理'}]}/>
                <div className="page-right-body">
                    <SearchFormView parent={this} city={this.state.searchCityList}/>
                    <div className="page-right-body-hd">
                        <div className="page-right-body-hd-hd">
                        </div>
                        <div className="page-right-body-hd-bd">
                            <Button onClick={this.onAddUserClick} type="primary">添加学生</Button>
                        </div>
                    </div>
                    <div className="page-right-body-bd">
                        <Grid
                            columns={this.getGridColumns()}
                            datasets={this.state.dataList}
                            pageInfo={{
                                current: this.state.gridPageCurrent,
                                pageSize: this.state.gridPageSize,
                                total: this.state.gridPageTotal,
                                pageSizeOptions: [this.state.gridPageSize],
                            }}
                            loading={this.state.gridLoading}
                            onChange={this.onGridChange.bind(this)}
                            ellipsis
                            size={"medium"}
                            scroll={{x:1600}}
                            rowKey="id"/>
                    </div>
                </div>
                <Drawer
                    title="报名列表"
                    visible={this.state.showWorkList}
                    onClose={this.onDrawerClose.bind(this)}
                    maskClosable
                    closeOnESC
                >
                    <WorkMesslistView viewtype={2} viewid={this.state.worklistId} onChange={this.onCallBackBind}/>
                </Drawer>
                <Drawer
                    visible={this.state.showJinkaList}
                    closeOnESC
                    maskClosable
                    size={"small"}
                    title="临时禁卡记录"
                    onClose={this.onJinkaDrawerClose.bind(this)}
                >
                    <UserJinKaMessTimeLine viewid={this.state.worklistId}/>
                </Drawer>
            </div>
        )
    }
}
function SearchFormView(props){
    const form=Form.useForm(FormStrategy.View);
    const cityData=props.city;
    const warningData=[{key:0,text:'正常'},{key:1,text:'黄色预警'},{key:2,text:'橙色预警'},{key:3,text:'红色预警'}]
    const [loading,setLoading]=React.useState(false);
    const [showtime,setShowtime]=React.useState(true);
    const [dianuData,setDianpuData]=React.useState([]);
    const [schoolData,setSchoolData]=React.useState([]);
    const [showsearch,setShowsearch]=React.useState(true);
    const [checked,setChecked]=React.useState(false);

    const WarningRrenderOptionContent=(item)=>{
            const { key, text,contact} = item;
            const classname="selectwarning tag"+key;
            return <div className={classname}>{text}</div>
        }

    const textRef=React.useRef(null);
    const onSubmit=React.useCallback(form => {
        setLoading(true);
        const value = form.getSubmitValue();
        const sex=value.sex;
        const identity=value.identity;
        const idimage=value.idimage;
        const city=value.city;
        const dianpu=value.dianpu;
        const school=value.school;
        const fanxian=value.fanxian;
        const kysj=value.kysj||[];
        const sfggjz=value.sfggjz||{key:0};
        const sfgzm=value.sfgzm||{key:0};
        const sfgjqg=value.sfgjqg||{key:0};
        const sfzsxdw=value.sfzsxdw||{key:0};
        const warning=value.warning||{key:0}
        return new Promise(resolve => {
            const pageindex=1;
            const searchdata={
                text:value.text,
                sex:sex==null?0:sex.key,
                fanxian:fanxian==null?0:fanxian.key,
                identity:identity==null?0:identity.key,
                idimage:idimage==null?0:idimage.key,
                city:city==null?0:city.key,
                dianpu:dianpu==null?0:dianpu.key,
                school:school==null?0:school.key,
                vipstarttime:value.vipstarttime,
                vipendtime:value.vipendtime,
                addtime:value.addtime,
                kysj:JSON.stringify(kysj),
                sfggjz:sfggjz.key,
                sfgzm:sfgzm.key,
                sfgjqg:sfgjqg.key,
                sfzsxdw:sfzsxdw.key,
                warning:warning.key
            }
            props.parent.setState({
                gridPageCurrent: pageindex,
                searchData:searchdata
            })

            props.parent.onDataBind({
                pageindex,
                search:searchdata,
                com(){
                    setLoading(false);
                    textRef.current.focus();
                }
            });
        });
    },[])
    const onShowSearchClick=()=>{
        setShowsearch(!showsearch);
    }
    const onCheckboxChange=()=>{
        form.patchValue({
            kysj:[],
            sfggjz:null,
            sfgzm:null,
            sfgjqg:null,
            sfzsxdw:null
        })
        setChecked(!checked);
    }
    React.useEffect(() => {
        const filecity=form.model.get('city');
        const $=filecity.value$.subscribe(value => {
            if(value&&value.key)
            {
                const city=value.key;
                return new Promise(resolve => {
                    Service.Post({
                        name:"AdminDianpu",
                        action:"listbycity",
                        data:{
                            city
                        },
                        success(result){
                            if(result.retcode==200)
                            {
                                setDianpuData(result.list);
                            }
                            else
                            {
                                setDianpuData([])
                            }
                        },
                        fail(){
                            setDianpuData([])
                        },
                        complete(){
                            form.patchValue({
                                dianpu:null
                            })
                        }
                    })
                    Service.Post({
                        name:"AdminSchool",
                        action:"listbycity",
                        data:{
                            city
                        },
                        success(result)
                        {
                            if(result.retcode==200)
                            {
                                setSchoolData(result.list)

                            }
                            else
                            {
                                setSchoolData([]);
                            }
                        },
                        fail()
                        {
                            setSchoolData([]);
                        },
                        complete(){
                            form.patchValue({
                                school:null
                            })
                        }
                    })
                });
            }
        });

        const fileidentity=form.model.get('identity');
        const $1=fileidentity.value$.subscribe(value=>{
            if(value&&value.key==1)
            {
                setShowtime(true)
            }
            else
            {
                setShowtime(false);
                form.model.get('vipstarttime').value=['',''];
                form.model.get('vipendtime').value=['',''];
            }
        })
        return () =>{
            $.unsubscribe();
            $1.unsubscribe();
        };
    }, [form]);
    return(
        <div className={showsearch?"page-right-body-search":"page-right-body-search packup"}>
            <Form form={form} layout="horizontal" onSubmit={onSubmit} disabled={loading}>
                <div className="page-right-body-search-cells">
                    <div className="page-right-body-search-box">
                        <div className="page-right-body-search-cell">
                            <FormInputField
                                name="text"
                                label="关键字："
                                props={{
                                    placeholder:"姓名、手机号，为空全部",
                                    width:'262px',
                                    showClear:true,
                                    autoFocus:true,
                                    ref:textRef,
                                    onPressEnter:()=>{
                                        form.submit();
                                    }
                                }}
                            />
                        </div>
                         <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="identity"
                                label="用户身份："
                                props={{
                                    width:'120px',
                                    options:[{key:3,text:'普通用户'},{key:1,text:'会员用户'},{key:2,text:'定金用户'}],
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                            />
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="idimage"
                                label="身份证："
                                props={{
                                    width:'100px',
                                    options:[{key:1,text:'已上传'},{key:2,text:'未上传'}],
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                            />
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="sex"
                                label="性别："
                                props={{
                                    width:'100px',
                                    options:[{key:1,text:'男'},{key:2,text:'女'}],
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                            />
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="fanxian"
                                label="返现状态："
                                props={{
                                    width:'100px',
                                    options:[{key:1,text:'已返现'},{key:2,text:'未返现'}],
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                            />
                        </div>
                    </div>
                    <div className="page-right-body-search-box">
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="city"
                                label="所属城市："
                                props={{
                                    width:'150px',
                                    options:cityData,
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                            />
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="dianpu"
                                label="所属店铺："
                                props={{
                                    width:'150px',
                                    options:dianuData,
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                            />
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="school"
                                label="所属学校："
                                props={{
                                    width:'200px',
                                    options:schoolData,
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                            />
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="warning"
                                label="预警颜色："
                                props={{
                                    width:'200px',
                                    options:warningData,
                                    clearable:true,
                                    placeholder:'为空全部',
                                    renderOptionContent:WarningRrenderOptionContent
                                }}
                            />
                        </div>
                    </div>
                    {showtime&&(
                        <div>
                            <FormDateRangeQuickPickerField
                                name="vipstarttime"
                                label="会员开通时间："
                                props={{
                                    width:'195px',
                                    format:"YYYY-MM-DD HH:mm:ss",
                                    chosenDays:7,
                                    preset:[
                                        {
                                            text: '今天',
                                            value: 0
                                        },
                                        {
                                            text: '昨天',
                                            value: 1
                                        },
                                        {
                                            text: '近7天',
                                            value: 7,
                                        },
                                        {
                                            text: '近30天',
                                            value: 30,
                                        },
                                    ]
                                }}
                                defaultValue={['','']}
                            />
                            <FormDateRangeQuickPickerField
                                name="vipendtime"
                                label="会员结束时间："
                                props={{
                                    width:'195px',
                                    format:"YYYY-MM-DD HH:mm:ss",
                                    chosenDays:7,
                                    preset:[
                                        {
                                            text: '今天',
                                            value: 0
                                        },
                                        {
                                            text: '昨天',
                                            value: 1
                                        },
                                        {
                                            text: '近7天',
                                            value: 7,
                                        },
                                        {
                                            text: '近30天',
                                            value: 30,
                                        },
                                    ]
                                }}
                                defaultValue={['','']}
                            />
                        </div>
                    )}
                    <FormDateRangeQuickPickerField
                        name="addtime"
                        label="注册时间："
                        props={{
                            width:'195px',
                            format:"YYYY-MM-DD HH:mm:ss",
                            chosenDays:7,
                            preset:[
                                {
                                    text: '今天',
                                    value: 0
                                },
                                {
                                    text: '昨天',
                                    value: 1
                                },
                                {
                                    text: '近7天',
                                    value: 7,
                                },
                                {
                                    text: '近30天',
                                    value: 30,
                                },
                            ]
                        }}
                        defaultValue={['','']}
                    />
                    {checked&&(
                        <div className="searchother">
                            <div className="searchother-box">
                                <FormCheckboxGroupField name="kysj" withoutLabel>
                                    <div className="kysjtable">
                                        <div className="kysjtable-cell">
                                                <div className="kysjtable-cell-cell">空余时间</div>
                                                <div className="kysjtable-cell-cell">
                                                    上午
                                                </div>
                                                <div className="kysjtable-cell-cell">
                                                    下午
                                                </div>
                                            </div>
                                        {KysjData.map((item,index)=>(
                                            <div className="kysjtable-cell" key={item.name}>
                                                <div className="kysjtable-cell-cell">{item.text}</div>
                                                <div className="kysjtable-cell-cell">
                                                    <Checkbox value={item.am}></Checkbox>
                                                </div>
                                                <div className="kysjtable-cell-cell">
                                                    <Checkbox value={item.pm}></Checkbox>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </FormCheckboxGroupField>
                                <div className="searchcells">
                                    <div className="searchcell">
                                        <div className="searchcell-title">兼职经验：</div>
                                        <FormSelectField
                                            name="sfggjz"
                                            withoutLabel
                                            props={{
                                                width:'120px',
                                                options:[{key:1,text:'从未干过'},{key:2,text:'干过几次'},{key:3,text:'放假经常干'}],
                                                clearable:true,
                                                placeholder:'为空全部'
                                            }}
                                        />
                                    </div>
                                    <div className="searchcell">
                                        <div className="searchcell-title">接受联系：</div>
                                        <FormSelectField
                                            name="sfgzm"
                                            withoutLabel
                                            props={{
                                                width:'105px',
                                                options:[{key:1,text:'是'},{key:2,text:'否'}],
                                                clearable:true,
                                                placeholder:'为空全部'
                                            }}
                                        />
                                    </div>
                                    <div className="searchcell">
                                        <div className="searchcell-title">假期兼职：</div>
                                        <FormSelectField
                                            name="sfgjqg"
                                            withoutLabel
                                            props={{
                                                width:'120px',
                                                options:[{key:1,text:'坚决不做'},{key:2,text:'不忙尝试'},{key:3,text:'假期必到'}],
                                                clearable:true,
                                                placeholder:'为空全部'
                                            }}
                                        />
                                    </div>
                                    <div className="searchcell">
                                        <div className="searchcell-title">推荐实习：</div>
                                        <FormSelectField
                                            name="sfzsxdw"
                                            withoutLabel
                                            props={{
                                                width:'120px',
                                                options:[{key:1,text:'是'},{key:2,text:'否'}],
                                                clearable:true,
                                                placeholder:'为空全部'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="page-right-body-search-buttons">
                        <Button icon={"refresh"}  htmlType="reset">重置</Button>
                        <Button icon="search" type="primary" htmlType={"submit"} loading={loading}>搜索</Button>
                        <div style={{paddingLeft:'30px'}}><Checkbox checked={checked} onChange={onCheckboxChange}>显示推荐搜索选项</Checkbox></div>
                    </div>
                </div>
            </Form>
            <div className="page-right-body-search-packup" onClick={onShowSearchClick}></div>
        </div>

    )
}
function LSJKFormView(props)
{
    const dialogid='dialog'+props.id;
    const form=Form.useForm(FormStrategy.View);
    const [loading,setLoading]=React.useState(false);
    const onSubmit=React.useCallback(form => {
        setLoading(true);
        const value = form.getSubmitValue();
        return new Promise(resolve => {
            Service.Post({
                name:"WebUsersFoul",
                action:"edit",
                data:{
                    id:props.id,
                    text:value.text,
                    time:value.time
                },
                success(result)
                {
                    if(result.retcode==200)
                    {
                        Notify.success("操作成功");
                        props.parent.onDataBind({
                            pageindex:props.parent.state.gridPageCurrent,
                            search:props.parent.state.searchData
                        });
                        closeDialog(dialogid);
                    }
                    else
                    {
                        Notify.warn(result.retmsg);
                    }
                },
                fail(){
                    Notify.error("网络连接失败");
                },
                complete(){
                    setLoading(false);
                }
            })
        });
    },[])
    return(
        <Form form={form} layout="horizontal"onSubmit={onSubmit} disabled={loading}>
            <FormInputField
                label={"禁卡原因："}
                name={"text"}
                required
                props={{
                    width:'240px',
                    maxLength:80,
                    showClear:true,
                    placeholder:"请输入禁卡原因，最多80字",
                }}
            />
            <FormDatePickerField
                label={"截止时间："}
                name={"time"}
                required
                props={{
                    format:"YYYY-MM-DD",
                    placeholder:"请选择截止日期",
                    disabledDate:{ min:new Date()}
                }}
                defaultValue={''}
            />
            <div style={{textAlign:'right'}}>
                <Button onClick={()=>closeDialog(dialogid)}>取消</Button>
                <Button type="primary" htmlType={"submit"} loading={loading}>提交</Button>
            </div>
        </Form>
    )
}
function OpenVipFormView(props)
{
    const userData=props.data;
    const dialogid='dialogopenvip'+userData.id;
    const dianpuList=props.parent.state.dianpuList;
    const form=Form.useForm(FormStrategy.View);
    const [loading,setLoading]=React.useState(false);
    const [yewuyuanList,setYewuyuanList]=React.useState([]);
    const [productList,setProductList]=React.useState([]);
    const [schoolList,setSchoolList]=React.useState([]);
    const [vipcodeList,setVipcodeList]=React.useState([]);
    const [vipcodekeyword,onVipcodekeywordChange]=React.useState('');
    const [vipcodeloading, setVipcodeLoading] = React.useState(false);
    const onSubmit=React.useCallback(form => {
        setLoading(true);
        const value = form.getSubmitValue();
        const dianpu=value.dianpu?value.dianpu.key:userData.dianpuid;
        const school=value.school&&value.school!==null?value.school.key:userData.schoolid;
        const resuleproduct=value.product.pid;
        return new Promise(resolve => {
            Service.Post({
                name:"webusers",
                action:"openvip",
                data:{
                    id:userData.id,
                    dianpu:dianpu,
                    yewuyuan:value.yewuyuan.key,
                    product:value.product.key,
                    resuleproduct:resuleproduct,
                    ljqd:value.qudao,
                    vipcode:value.vipcode.key,
                    name:value.name,
                    idcardnumber:value.idcardnumber,
                    school:school
                },
                success(result)
                {
                    if(result.retcode==200)
                    {
                        Notify.success("操作成功");
                        props.parent.onDataBind({
                            pageindex:props.parent.state.gridPageCurrent,
                            search:props.parent.state.searchData
                        });
                        closeDialog(dialogid);
                    }
                    else
                    {
                        Notify.warn(result.retmsg);
                    }
                },
                fail(){
                    Notify.error("网络连接失败");
                },
                complete(){
                    setLoading(false);
                }
            })
        });
    },[]);
    const yewuyuanListBind=(dianpu)=>{
        Service.Post({
            name:"adminyewuyuan",
            action:"list",
            data:{
                dianpu:dianpu
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    setYewuyuanList(result.list)
                }
                else
                {
                    setYewuyuanList([]);
                }
            },
            fail(){
                setYewuyuanList([])
            },
            complete(){
                form.patchValue({
                    yewuyuan:null
                })
            }
        })
    }
    const productListBind=(dianpu)=>{
        Service.Post({
            name:"adminproduct",
            action:"listbyuser",
            data:{
                dianpu:dianpu,
                vip:userData.vip,
                isdj:userData.isdj,
                resultcount:userData.resultcount
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    setProductList(result.list);
                    form.patchValue({
                        yewuyuan:result.yewuyuan
                    })
                }
                else
                {
                    setProductList([]);
                }
            },
            fail(){
                setProductList([])
            },
            complete(){
                form.patchValue({
                    product:null
                })
            }
        })
    }
    const schoolListBind=(city)=>{
        Service.Post({
            name:"AdminSchool",
            action:"listbycity",
            data:{
                city:city
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    setSchoolList(result.list)
                }
                else
                {
                    setSchoolList([])
                }
            },
            fail(){
                setSchoolList([])
            },
            complete(){
                form.patchValue({
                    school:null
                })
            }
        })
    }
    React.useEffect(() => {
        const filedianpu=form.model.get('dianpu');
        if(filedianpu)
        {
            const $=filedianpu.value$.subscribe(value => {
                if(value&&value.key)
                {
                    return new Promise(resolve => {
                        yewuyuanListBind(value.key);
                        productListBind(value.key);
                        schoolListBind(value.city);
                    });
                }
                setVipcodeList([]);
                form.patchValue({
                    vipcode:null
                })
            });
            return () =>{
                $.unsubscribe();
            };
        }
    }, [form]);
    React.useEffect(() => {
        if(vipcodekeyword.length>2)
        {
            const dianpu=form.getValue().dianpu.key;
            setVipcodeLoading(true);
            setVipcodeList([]);
            Service.Post({
                name: "adminvipcode",
                action:"listbytext",
                data: {
                    dianpu:dianpu,
                    text:vipcodekeyword
                },
                success(result)
                {
                    if(result.retcode==200)
                    {
                        setVipcodeList(result.list);
                    }
                    else
                    {
                        setVipcodeList([]);
                    }
                    setVipcodeLoading(false);
                },
                fail()
                {
                    setVipcodeList([]);
                    setVipcodeLoading(false)
                }
            })
        }
    }, [vipcodekeyword]);
    return(
        <Form form={form} layout="horizontal" onSubmit={onSubmit} disabled={loading}>
            <FormInputField
                label={<p className="mee__xx"><span>*</span>姓名：</p>}
                name={"name"}
                required
                props={{
                    width:'240px',
                    maxLength:20,
                    placeholder:"请输入姓名",
                }}
                initialValue={userData.name}
            />
            <FormInputField
                name="idcardnumber"
                label={<p className="mee__xx"><span>*</span>身份证号：</p>}
                required
                props={{
                    width:'240px',
                    maxLength:18,
                    placeholder:"请输入身份证号",
                }}
                validators={[
                    Validators.pattern(/^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, '请输入正确的身份证号')
                ]}
                initialValue={userData.idcardnumber}
            />
            <FormSelectField
                label={<p className="mee__xx"><span>*</span>所属店铺：</p>}
                name={"dianpu"}
                required
                props={{
                    disabled:userData.dianpuid>0?true:false,
                    options:dianpuList,
                    placeholder:"请选择所属店铺"
                }}
                initialValue={Utils.returnItem(userData.dianpuid,dianpuList)}
            />
            <FieldValue name={"dianpu"}>
                {dianpu=>(
                    <>
                        <FormSelectField
                                label={"所属学校："}
                                name={"school"}
                                props={{
                                    disabled:dianpu==null?true:false,
                                    options:schoolList,
                                    placeholder:"请选择所属学校"
                                }}
                                initialValue={Utils.returnItem(userData.schoolid,schoolList)}
                            />
                        <FormSelectField
                            name="vipcode"
                            label={<p className="mee__xx"><span>*</span>会员卡号：</p>}
                            props={{
                                options:vipcodeList,
                                disabled:dianpu==null?true:(userData.vipcode?true:false),
                                placeholder:"输入卡号搜索,最少3个字符",
                                keyword:vipcodekeyword,
                                loading:vipcodeloading,
                                onKeywordChange:onVipcodekeywordChange,
                                filter:false,
                                clearable:true,
                            }}
                            required
                            initialValue={userData.vipcode?{key:userData.vipcode,text:userData.vipcode}:null}
                        />
                        <FormSelectField
                            name="yewuyuan"
                            label={<p className="mee__xx"><span>*</span>业务员：</p>}
                            required
                            props={{
                                options:yewuyuanList,
                                disabled:dianpu==null?true:false,
                                placeholder:"请选择业务员"
                            }}
                        />
                        <FormSelectField
                            name="product"
                            label={<p className="mee__xx"><span>*</span>产品类型：</p>}
                            required
                            props={{
                                options:productList,
                                disabled:dianpu==null?true:false,
                                placeholder:"请选择产品类型"
                            }}
                        />
                    </>
                )}
            </FieldValue>
            <FormInputField
                label={<p className="mee__xx"><span>*</span>了解渠道：</p>}
                name={"qudao"}
                required
                props={{
                    disabled:userData.lyqd?true:false,
                    width:'240px',
                    maxLength:20,
                    showClear:true,
                    placeholder:"请输入了解渠道",
                }}
                initialValue={userData.lyqd}
            />
            <div style={{textAlign:'right'}}>
                <Button onClick={()=>closeDialog(dialogid)}>取消</Button>
                <Button type="primary" htmlType={"submit"} loading={loading}>提交</Button>
            </div>
        </Form>
    )
}
function UserJinKaMessTimeLine(props)
{
    const uid=props.viewid;
    const [messList,setMessList]=React.useState([]);
    const [drawerLoading,setDrawerLoading]=React.useState(true);
    const retSize=(item)=>{
        if(item.state==30&&item.badrecord==1)
        {
            return '90px';
        }
        else
        {
            return ''
        }
    }
    const dataBind=()=> {
        if(uid>0)
        {
            setDrawerLoading(true);
            Service.Post({
                name:"WebUsersFoul",
                action:"list",
                data:{
                    id:uid
                },
                success(result)
                {
                    if(result.retcode==200)
                    {
                        setMessList(result.list);
                    }
                    else
                    {
                        setMessList([]);
                    }
                },
                fail(){
                    setMessList([]);
                },
                complete(){
                    setDrawerLoading(false);
                }
            })
        }
    }
    React.useEffect(() => {
        dataBind();
    }, []);
    return(
        <BlockLoading loading={drawerLoading}>
            <div  style={{minHeight:'100px'}}>
                <Timeline type="vertical" size={"auto"}>
                    {messList.map((item,index)=>(
                        <Timeline.Item
                            size={(index+1)==messList.length?'0px':retSize(item)}
                            tip={item.time}
                            dotColor="#35bed4"
                            lineColor="#CBF5FA"
                            key={index}
                            label={
                                <div className={"mee__timeline-label"}>
                                    <div className="mee__timeline-label-hd">
                                        <div className="mee__timeline-label-hd-title">{item.time}</div>
                                        <div className="mee__timeline-label-hd-label">截止：{item.endtime}</div>
                                    </div>
                                    <div style={{lineHeight: '20px'}} className="mee__timeline-label-bd">{item.text}</div>
                                </div>
                            }/>
                    ))}
                </Timeline>
                {messList.length<=0&&(
                    <div style={{textAlign:'center',color:'#999',fontSize:'14px'}}>暂无记录</div>
                                    )}
            </div>
        </BlockLoading>
    )
}
