import React from 'react';
import {Grid, Tag} from "zent";
import Service from "../config/Service";
export default class YewuyuanResultListView extends React.Component{
    constructor(props, context) {
        super(props, context);
        this.state={
            gridPageCurrent:1,
            gridPageTotal:0,
            gridLoading:false,
            gridPageSize:10,
            dataList:[]
        }
    }
    componentWillMount() {
        this.onDataBind();
    }
    onDataBind=(pageindex)=>{
        const _this=this;
        if(this.props.dataid>0)
        {
            this.setState({
                gridLoading:true
            });
            Service.Post({
                name:"adminresults",
                action:"pagelistbyyewuyuan",
                data:{
                    id:_this.props.dataid,
                    startdate:_this.props.time[0],
                    enddate:_this.props.time[1],
                    pageindex:pageindex,
                    pagesize:_this.state.gridPageSize
                },
                success(result){
                    if(result.retcode==200)
                    {
                        _this.setState({
                            dataList:result.list,
                            gridPageTotal:result.count,
                            gridLoading:false
                        })
                    }
                    else
                    {
                        _this.setState({
                            dataList:[],
                            gridPageTotal:0,
                            gridLoading:false
                        })
                    }
                },
                fail(){
                    _this.setState({
                        dataList:[],
                        gridPageTotal:0,
                        gridLoading:false
                    })
                }
            })
        }else
        {
            _this.setState({
                dataList:[],
                gridPageTotal:0,
                gridLoading:false
            })
        }
    }

    onGridChange=(e)=>{
        const current=e.current;
        this.setState({
            gridPageCurrent:current
        })
        this.onDataBind(current)
    }

    getGridColumns(){
        const Columns=[
            {
                title:'产品名称',
                name:'product',
                bodyRender:data => {
                    if(data.exitunsub==1)
                    {
                        return<div>
                        {data.product}
                        <Tag theme={"red"} style={{marginLeft:'10px'}}>已退卡</Tag>
                    </div>
                    }
                    else
                    {
                        return<div>
                        {data.product}
                    </div>
                    }
                },

            },
            {
                title:'产品金额',
                name:'money',
                width:'100px'
            },
            {
                title:'奖金',
                name:'jjmoney',
                width:'100px'
            },
             {
                title:'用户姓名',
                name:'name',
                width:'120px'
            },
            {
                title:'用户手机',
                name:'tel',
                width:'120px'
            },
            {
                title:'会员卡号',
                width:'120px',
                name:'vipcode'
            },
            {
                title:'时间',
                width:'170px',
                name:'time',
                textAlign:'center'
            }
        ]
        return Columns;
    }
    render() {
        return(
            <div>
                <Grid
                    columns={this.getGridColumns()}
                    datasets={this.state.dataList}
                    pageInfo={{
                        current: this.state.gridPageCurrent,
                        pageSize:this.state.gridPageSize,
                        total: this.state.gridPageTotal,
                        pageSizeOptions: [this.state.gridPageSize],
                    }}
                    loading={this.state.gridLoading}
                    onChange={this.onGridChange}
                    ellipsis
                    size={"medium"}
                    rowKey="id"/>
            </div>
        )
    }
}
