import parameter from "./Config";
const Post = (obj) => {
    let url = parameter.server+"/api/" + obj.name+"/"+obj.action;
    const userData=JSON.parse(sessionStorage.getItem(parameter.usersession));
    if(userData)
    {
        obj.data.admin=userData.id;
        obj.data.token=userData.token;
        obj.data.role=userData.role;
    }
    fetch(url, {
        body: JSON.stringify(obj.data),
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
        },
        method: 'POST'
    })
        .then(response => response.json())
        .then((responseJson)=>{
            obj.success(responseJson);
            if(typeof obj.complete=="function")
            {
                obj.complete();
            }
        })
        .catch((error)=>{
            if(typeof obj.fail==="function")
            {
                obj.fail(error);
            }
            if(typeof obj.complete=="function")
            {
                obj.complete();
            }
        })
}
const Upload=(obj)=>{
    let url = parameter.uploadServer+"/api/upload/"+obj.action;
    const formData=new FormData();
    formData.append("file",obj.file);
    formData.append("path",obj.path);
    fetch(url, {
        body: formData,
        method: 'POST'
    })
        .then(response => response.json())
        .then((responseJson)=>{
            obj.success(responseJson);
            if(typeof obj.complete=="function")
            {
                obj.complete();
            }
        })
        .catch((error)=>{
            if(typeof obj.fail==="function")
            {
                obj.fail(error);
            }
            if(typeof obj.complete=="function")
            {
                obj.complete();
            }
        })
}
export default{
    Post:Post,
    Upload:Upload
}
