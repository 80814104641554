import React from 'react';
import Header from '../../../../component/header'
import { Form, FormStrategy, Notify, FormInputField,Button,FormSelectField,Validators,BlockLoading,FormCheckboxGroupField,Checkbox,FormSwitchField} from 'zent';
import Service from "../../../../config/Service";
import Utils from "../../../../config/Utils";
export default class MainSystemUserEdit extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            adminId:0,
            editData:{},
            usersList:[],
            loading:false
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(110200);
        const id=this.props.match.params.id||0;
        this.setState({
            adminId:id
        })
        this.usersBind();
        this.dataBind(id);
    }
    usersBind(){
        const _this=this;
        Service.Post({
            name:"Adminusers",
            action:"listbyids",
            data:{},
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        usersList:result.list
                    })
                }
            }
        })
    }

    dataBind(id){
        if(id<=0)
        {
            return false;
        }
        const _this=this;
        this.setState({
            loading:true
        })
        Service.Post({
            name:"adminusers",
            action:"news",
            data:{
                id
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        editData:result.data,
                        loading:false
                    })
                }
                else
                {
                    Notify.error("没有找到相关信息",1500,()=>{
                        _this.setState({
                            loading:false
                        })
                        _this.props.history.goBack();
                    });
                }

            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    loading:false
                })
            }
        })
    }
    FormView=()=>{
        const _this=this;
        const editData=this.state.editData;
        const stateLoading=this.state.loading;
        const usersList=this.state.usersList;
        const form = Form.useForm(FormStrategy.View);
        const [dianpulist,setDianpulist]=React.useState([]);
        const [rolelist,setRolelist]=React.useState([]);
        const [dianpuchecked,setDianpuchecked]=React.useState(false);
        const [loading,setLoading]=React.useState(false);
        const [visible,setVisible]=React.useState(true);
        const [indeterminate,setIndeterminate]=React.useState(false);
        const onSubmit = React.useCallback(form => {
            setLoading(true);
            const value = form.getSubmitValue();
            return new Promise(resolve => {

                Service.Post({
                    name:"adminusers",
                    action:"edit",
                    data:{
                        id:_this.state.adminId,
                        name:value.name,
                        tel:value.tel,
                        username:value.username,
                        userpwd:value.userpwd,
                        uuid:value.uuid.key,
                        uuids:value.uuid.uuids,
                        rid:value.role.key,
                        dianpu:value.dianpu+'',
                        state:value.state?1:2
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("提交成功",1000,()=>{
                                setLoading(false);
                                _this.props.history.goBack();
                            })
                        }
                        else
                        {
                            Notify.error(result.retmsg);
                            setLoading(false);
                        }
                    },
                    fail(){
                        Notify.error("网络连接失败");
                        setLoading(false);
                    }
                })
            });
        }, []);

        const dianpuonChange=(e)=>{
            setIndeterminate(false)
            const checked=e.target.checked;
            if(checked)
            {
                const items=dianpulist.map((item)=>{
                    return item.key;
                })
                form.patchValue({
                    dianpu:items
                })
                setDianpuchecked(true);
            }
            else
            {
                form.patchValue({
                    dianpu:[]
                })
                setDianpuchecked(false);
            }
        }
        const dianpuboxonChange=(e)=>{
            setIndeterminate(e.length>0&&e.length!==dianpulist.length)
            console.log(e.length,dianpulist.length);
            setDianpuchecked(e.length>0 && e.length === dianpulist.length);
        }
        React.useEffect(() => {
            const fileuuid=form.model.get('uuid');
            const filerole = form.model.get('role');
            const filedianpu=form.model.get('dianpu');
            const $=fileuuid.value$.subscribe(value => {
                if(value)
                {
                    const ids=value.dianpu;
                    const id=value.key;
                    setVisible(false);
                    return new Promise(resolve => {
                        Service.Post({
                            name:"AdminDianpu",
                            action:"listbyids",
                            data:{
                                ids,
                                id
                            },
                            success(result){
                                if(result.retcode==200)
                                {
                                    setDianpulist(result.list);
                                    if(editData.dianpu&&value.key==editData.uuid)
                                    {
                                         setTimeout(()=>{
                                           filedianpu.value=editData.dianpu;
                                            setIndeterminate(editData.dianpu.length>0&&editData.dianpu.length!==result.list.length)
                                            setDianpuchecked(editData.dianpu.length>0 && editData.dianpu.length === result.list.length);
                                        },100)
                                    }
                                }
                                else
                                {
                                    setDianpulist([])
                                }
                            },
                            fail(){
                                setDianpulist([])
                            },
                            complete(){
                                form.patchValue({
                                    dianpu:null
                                })
                                setDianpuchecked(false);
                            }
                        })
                        Service.Post({
                            name:"AdminRole",
                            action:"listbyuser",
                            data:{
                                id
                            },
                            success(result)
                            {
                                if(result.retcode==200)
                                {
                                    setRolelist(result.list)
                                    if(editData.role)
                                    {
                                        setTimeout(()=>{
                                            filerole.value=Utils.returnItem(editData.role,result.list);
                                        },100)
                                    }
                                }
                                else
                                {
                                    setRolelist([]);
                                }
                            },
                            fail()
                            {
                                setRolelist([]);
                            },
                            complete(){
                                form.patchValue({
                                    role:null
                                })
                            }
                        })
                        setVisible(true);
                    });
                }
            });
            return () =>{
                $.unsubscribe();
            };
        }, [form]);
        return(
            <Form
                disabled={loading}
                form={form}
                layout="horizontal"
                onSubmit={onSubmit}>
                <FormInputField
                    name="name"
                    label="姓名："
                    required
                    props={{
                        maxLength:10,
                        placeholder:"请输入真实姓名",
                    }}
                    initialValue={editData.name}
                    validators={[
                        Validators.pattern(/^[\u4E00-\u9FA5]+$/, '必须为汉字')
                    ]}
                />
                <FormInputField
                    name="tel"
                    label="电话："
                    helpDesc={"可作为账号进行登录"}
                    required
                    props={{
                        maxLength:11,
                        placeholder:"请输入手机号码"
                    }}
                    initialValue={editData.tel}
                    validators={[
                        Validators.pattern(/^(1[1-9])\d{9}$/, '手机号码错误')
                    ]}
                />
                <FormInputField
                    name="username"
                    label="账号："
                    required
                    props={{
                        maxLength:16,
                        placeholder:"请输入账号"
                    }}
                    initialValue={editData.username}
                    helpDesc={"账号长度为6-16位，格式必须为字母+数字，且以字母开头。例如：a123456，b456c23"}
                    validators={[
                        Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d).{6,16}$/, '字母开头可包含数字，6-16位')
                    ]}
                />
                <FormInputField
                    name="userpwd"
                    label="密码："
                    required={_this.state.adminId>0?false:true}
                    validators={[
                        Validators.pattern(/^((?=.*[a-zA-Z])(?=.*\d).{6,16})?$/, '字母开头可包含数字，6-16位')
                    ]}
                    notice={_this.state.adminId>0?"为空不修改":""}
                    helpDesc={"密码长度为6-16位，格式必须为字母+数字，且以字母开头。例如：a123456，b456c23"}
                    props={{
                        maxLength:16,
                        placeholder:"请输入密码"
                    }}
                />
                <FormSelectField
                    name="uuid"
                    label="上级领导："
                    required
                    initialValue={Utils.returnItem(editData.uuid,usersList)}
                    props={{
                        options: usersList,
                        width:'195px'
                    }}
                />
                {visible&&(
                    <div>
                        <FormSelectField
                            name="role"
                            label="角色："
                            required={"请选择角色"}
                            props={{
                                options: rolelist,
                                width:'195px'
                            }}
                        />
                        <div className="page-right-body-form">
                            <div className="page-right-body-form-hd">
                                负责店铺：
                            </div>
                            <div className="page-right-body-form-bd page-border-bottom">
                                <Checkbox
                                    checked={dianpuchecked}
                                    indeterminate={indeterminate}
                                    onChange={dianpuonChange}
                                >全选</Checkbox>
                            </div>
                        </div>
                        <FormCheckboxGroupField
                            name="dianpu"
                            required="请选择店铺"
                            validators={[Validators.minLength(1, '至少选择一个店铺')]}
                            onChange={dianpuboxonChange}
                        >
                            {dianpulist.map((item,index)=>(
                                <Checkbox key={index} value={item.key}>{item.text}</Checkbox>
                            ))}
                        </FormCheckboxGroupField>
                    </div>
                )}
                <FormSwitchField
                    name="state"
                    label="账号启用："
                    initialValue={editData.state==2?false:true}
                />
                {!stateLoading&&(
                    <div className="form-submit app-fixed-container">
                        <Button type="default" onClick={()=>{_this.props.history.goBack();}}>取消</Button>
                        <Button htmlType="submit" type="primary" loading={loading}>保存</Button>
                    </div>)
                }
            </Form>
        )
    }

    render() {
        const FormView=this.FormView.bind();
        const {loading}=this.state;
        return(
            <div>
                <Header data={[{name:'系统'},{name:'员工管理',href:'/main/system/user'},{name:'编辑员工'}]}/>
                <BlockLoading loading={loading}>
                    <div className="page-right-body">
                        <div className="page-right-body-hd"></div>
                        <div className="page-right-body-bd">
                            <FormView/>
                        </div>
                    </div>
                </BlockLoading>
            </div>
        )
    }
}
