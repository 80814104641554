const returnItem=(key,list)=>{
    let retItem=null;
    if(list&&list.length){
        list.map((item)=>{
            if(item.key==key)
            {
                retItem=item;
            }
        })
    }
    return retItem;
}
const returnKeys=(list,tpe='key')=> {
    let retkeys=[];
    if(list.length)
    {
        list.map((item)=>{
            retkeys.push(item[tpe])
        })
    }
    return retkeys;
}
const formatTime = (date,fmt='yyyy-MM-dd') => {
    var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
/*
* 返回时间组
* 1今天
* 2本周
* 3本月
* 4本年
* 5上月
*/
const returndates=(res,showtime=false)=>{
    const now=new Date();
    const nowTime=now.getTime();
    const oneDayTime=24*60*60*1000;
    const formatNowDate=formatTime(new Date());
    if(res===1)
    {
        if(showtime)
        {
            return [formatNowDate+' 00:00:00',formatNowDate+' 23:59:59'];
        }
        return [formatNowDate,formatNowDate];
    }
    else if(res===2)
    {
        const day=now.getDay()>0?now.getDay():7;
        const MondayTime=nowTime-(day-1)*oneDayTime;
        const formatDate=formatTime(new Date(MondayTime));
        if(showtime)
        {
            return [formatDate+' 00:00:00',formatNowDate+' 23:59:59'];
        }
        return [formatDate,formatNowDate];
    }
    else if(res===3)
    {
        const monthFirstDate=formatTime(now,'yyyy-MM-01');
        if(showtime)
        {
            return [monthFirstDate+' 00:00:00',formatNowDate+' 23:59:59'];
        }
        return [monthFirstDate,formatNowDate];
    }
    else if(res===4)
    {
        const monthFirstDate=formatTime(now,'yyyy-01-01');
        if(showtime)
        {
            return [monthFirstDate+' 00:00:00',formatNowDate+' 23:59:59'];
        }
        return [monthFirstDate,formatNowDate];
    }
    else if(res===5)
    {
        const yuedi=formatTime(new Date(now.getFullYear(),now.getMonth(),0));
        const yuechu=formatTime(new Date(yuedi),'yyyy-MM-01');
        if(showtime)
        {
            return [yuedi+' 00:00:00',yuechu+' 23:59:59'];
        }
        return [yuechu,yuedi];
    }
    else
    {
        return ['','']
    }
}
const getUrlParams=(name, str)=>{
    const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
    const r = str.substr(1).match(reg);
    if (r != null)
    {
        return  decodeURIComponent(r[2]);
    }
    return null;
}
export default{
    returnItem,
    returnKeys,
    formatTime,
    returndates,
    getUrlParams
}