import React from 'react';
import {Grid, CombinedDateRangePicker, Button, Notify, Tag} from "zent";
import Service from "../config/Service";
export default class DataAdminSignListView extends React.Component{
    constructor(props, context) {
        super(props, context);
        this.state={
            gridLoading:false,
            dataList:[],
            timeData:[]
        }
    }
    componentDidMount() {
        console.log(this.props.date);
        const date=this.props.date;
        this.setState({
            timeData:date
        })
        setTimeout(()=>{this.onDataBind()},100);
    }

    onDataBind=()=>{
        const _this=this;
        if(this.props.dianpu>0&&this.state.timeData.length===2)
        {
            this.setState({
                gridLoading:true
            });
            Service.Post({
                name:"DataStatistical",
                action:"signuserlist",
                data:{
                    dianpu:_this.props.dianpu,
                    youxiao:_this.props.youxiao,
                    startdate:_this.state.timeData[0],
                    enddate:_this.state.timeData[1],
                },
                success(result){
                    if(result.retcode==200)
                    {
                        _this.setState({
                            dataList:result.list,
                            gridLoading:false
                        })
                    }
                    else
                    {
                        _this.setState({
                            dataList:[],
                            gridLoading:false
                        })
                    }
                },
                fail(){
                    _this.setState({
                        dataList:[],
                        gridLoading:false
                    })
                }
            })
        }else
        {
            _this.setState({
                dataList:[],
                gridLoading:false
            })
        }
    }

    getGridColumns(){
        const Columns=[
            {
                title:'发布人',
                name:'name',
                textAlign:'center'
            },
            {
                title:'发布人手机号',
                name:'tel',
                textAlign:'center'
            },
            {
                title:'报名人数',
                name:'count',
                textAlign:'center'
            }
        ]
        return Columns;
    }
    onTimeChange=val=>{
        this.setState({
            timeData:val
        })
    }
    onSearchClick=()=>{
        if(this.state.timeData.length!=2)
        {
            Notify.warn("请选择日期");
            return false;
        }
        this.onDataBind();
    }
    render() {
        return(
            <div>
                <div className="datamerchant-header">
                    <div className="datamerchant-header-hd">
                        <CombinedDateRangePicker
                            className="zent-datepicker-demo"
                            dateSpan={62}
                            value={this.state.timeData}
                            onChange={this.onTimeChange}
                        />
                    </div>
                    <div className="datamerchant-header-bd">
                        <Button type={"primary"} icon={"search"} onClick={this.onSearchClick}>搜索</Button>
                    </div>
                </div>
                <div className="datamerchant-body">
                    <Grid
                    columns={this.getGridColumns()}
                    datasets={this.state.dataList}
                    onChange={this.onGridChange}
                    loading={this.state.gridLoading}
                    ellipsis
                    size={"medium"}
                    rowKey="id"/>
                </div>
            </div>
        )
    }
}
