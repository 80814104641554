import React from 'react';
import Header from '../../../../component/header'
import {
    Button,
    Grid,
    Notify,
    Form,
    FormStrategy,
    FormSelectField,
    FormInputField,
    FormDateRangeQuickPickerField,
    Tabs,
    ClampLines,
    Dialog,
} from 'zent'
import Service from "../../../../config/Service";
const TabPanel = Tabs.TabPanel;
const {openDialog,closeDialog}=Dialog
export default class MainCustomerTousuIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            wclGridPageCurrent:1,
            wclGridPageTotal:0,
            wclGridLoading:false,
            wclGridPageSize:20,
            wclDataList:[],

            yclGridPageCurrent:1,
            yclGridPageTotal:0,
            yclGridLoading:false,
            yclGridPageSize:20,
            yclDataList:[],

            stateActive:'1',
            searchDianpuList:[],
            searchData:{
                text:'',
                time:['',''],
                dianpu:0
            }
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(120400);
        this.wclDataBind();
        this.dianpuBind();
    }
    dianpuBind=()=>{
        const _this=this;
        Service.Post({
            name:"admindianpu",
            action:"listbyadmin",
            data:{},
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        searchDianpuList:result.list
                    })
                }
            }
        })
    }
    onDataBind=(index,com)=>{
        if(index==1)
        {
            setTimeout(()=>{
                this.wclDataBind(com);
            },100)
        }
        if(index==2)
        {
            setTimeout(()=>{
                this.yclDataBind(com);
            },100)
        }
    }
    wclDataBind=(com)=>{
        const _this=this;
        const {wclGridPageSize,wclGridPageCurrent,searchData,stateActive}=this.state;
        this.setState({
            wclGridLoading:true
        })
        Service.Post({
            name:"systemtousu",
            action:"pagelist",
            data:{
                pageindex:wclGridPageCurrent,
                pagesize: wclGridPageSize,
                search:searchData,
                state:2
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        wclDataList:result.list,
                        wclGridPageTotal:result.count
                    })
                }
                else
                {
                    _this.setState({
                        wclDataList:[],
                        wclGridPageTotal:0
                    })
                }
            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    wclDataList:[],
                    wclGridPageTotal:0
                })
            },
            complete(){
                _this.setState({
                    wclGridLoading:false
                })
                if(typeof com=="function")
                {
                    com();
                }
            }
        })

    }
    yclDataBind=(com)=>{
        const _this=this;
        const {yclGridPageSize,yclGridPageCurrent,searchData,stateActive}=this.state;
        this.setState({
            yclGridLoading:true
        })
        Service.Post({
            name:"systemtousu",
            action:"pagelist",
            data:{
                pageindex:yclGridPageCurrent,
                pagesize: yclGridPageSize,
                search:searchData,
                state:1
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        yclDataList:result.list,
                        yclGridPageTotal:result.count
                    })
                }
                else
                {
                    _this.setState({
                        yclDataList:[],
                        yclGridPageTotal:0
                    })
                }
            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    yclDataList:[],
                    yclGridPageTotal:0
                })
            },
            complete(){
                _this.setState({
                    yclGridLoading:false
                })
                if(typeof com=="function")
                {
                    com();
                }
            }
        })
    }
    onGridItemStatusClick=(id)=>{
        this.setState({
            wclGridLoading:true
        })
        const _this=this;
        Service.Post({
            name:"systemtousu",
            action:"state",
            data:{
                id
            },
            success(result)
            {
                _this.setState({
                    wclGridLoading:false
                })
                if(result.retcode==200)
                {
                    Notify.success("操作成功");
                    _this.onDataBind(_this.state.wclGridPageCurrent);
                    _this.setState({
                        yclDataList:[]
                    })
                }
                else
                {
                    Notify.warn(result.retmsg);
                }
            },
            fail(){
                _this.setState({
                    wclGridLoading:false
                })
            }
        })
    }
    onWclGridChange=(e)=>{
        const current=e.current;
        this.setState({
            wclGridPageCurrent:current
        })
        this.onDataBind(1)
    }
    onYclGridChange=(e)=>{
        const current=e.current;
        this.setState({
            yclGridPageCurrent:current
        })
        this.onDataBind(2)
    }
    onTabsChange=(id)=>{
        this.setState({
            stateActive:id
        })
        if(id==1)
        {
            if(this.state.wclDataList.length<=0)
            {
                this.onDataBind(1);
            }
        }
        if(id==2)
        {
            if(this.state.yclDataList.length<=0)
            {
                this.onDataBind(2);
            }
        }
    }
    getWclGridColumns=()=>{
        const listColumns=[
            {
                title:'被投诉方',
                name:'name',
                width:'300px'
            },
            {
                title:'投诉内容',
                name:'text',
                bodyRender:data => {
                    return(
                        <ClampLines
                            lines={1}
                            showPop={true}
                            popWidth={"500px"}
                            text={data.text}
                            extra={(
                                <a>更多</a>
                            )}
                        />
                    )
                }
            },
            {
                title:'所属店铺',
                name:'dianpu',
                width:'120px'
            },
            {
                title:'投诉人',
                name:'user',
                width:'170px'
            },
            {
                title:'投诉时间',
                name:'time',
                width:'170px'
            },
            {
                title:'操作',
                width:'150px',
                textAlign:'center',
                bodyRender:data=>{
                    return <Button type="primary" onClick={this.onGridItemStatusClick.bind(this,data.id)}>设为已处理</Button>
                }
            }
        ];
        return listColumns;
    }
    getYclGridColumns=()=>{
        const listColumns=[
            {
                title:'被投诉方',
                name:'name',
                width:'300px'
            },
            {
                title:'投诉内容',
                name:'text',
                bodyRender:data => {
                    return(
                        <ClampLines
                            lines={1}
                            showPop={true}
                            popWidth={"500px"}
                            text={data.text}
                            extra={(
                                <a>更多</a>
                            )}
                        />
                    )
                }
            },
            {
                title:'所属店铺',
                name:'dianpu',
                width:'120px'
            },
            {
                title:'投诉人',
                name:'user',
                width:'170px'
            },
            {
                title:'投诉时间',
                name:'time',
                width:'170px'
            },
            {
                title:'处理时间',
                name:"uptime",
                width:'170px'
            }
        ];
        return listColumns;
    }
    onSearchChange=(res)=>{
        if(this.state.stateActive==1)
        {
            this.setState({
                yclDataList:[],
                searchData:{
                    text:res.text,
                    time:res.time,
                    dianpu:res.dianpu
                }
            })
        }
        else
        {
            this.setState({
                wclDataList:[],
                searchData:{
                    text:res.text,
                    time:res.time,
                    dianpu:res.dianpu
                }
            })
        }
        this.onDataBind(this.state.stateActive,res.com)
    }
    render() {
        const panels = [
            <TabPanel key="1" tab={"未处理"} id="1">
                <Grid
                    columns={this.getWclGridColumns()}
                    datasets={this.state.wclDataList}
                    pageInfo={{
                        current: this.state.wclGridPageCurrent,
                        pageSize: this.state.wclGridPageSize,
                        total: this.state.wclGridPageTotal,
                        pageSizeOptions: [this.state.wclGridPageSize],
                    }}
                    loading={this.state.wclGridLoading}
                    onChange={this.onWclGridChange.bind(this)}
                    ellipsis
                    size={"medium"}
                    rowKey="id"/>
            </TabPanel>,
            <TabPanel key="2" tab={"已处理"} id="2">
                <Grid
                    columns={this.getYclGridColumns()}
                    datasets={this.state.yclDataList}
                    pageInfo={{
                        current: this.state.yclGridPageCurrent,
                        pageSize: this.state.yclGridPageSize,
                        total: this.state.yclGridPageTotal,
                        pageSizeOptions: [this.state.yclGridPageSize],
                    }}
                    loading={this.state.yclGridLoading}
                    onChange={this.onYclGridChange.bind(this)}
                    ellipsis
                    size={"medium"}
                    rowKey="id"/>
            </TabPanel>
        ]
        return(
            <>
                <Header data={[{name:'用户'},{name:'投诉管理'}]}/>
                <div className="page-right-body">
                    <SearchFormView onSearch={this.onSearchChange} dianpulist={this.state.searchDianpuList}/>
                    <div style={{paddingBottom:'10px'}}></div>
                    <div className="page-right-body-bd">
                        <Tabs
                            activeId={this.state.stateActive}
                            onChange={this.onTabsChange}
                        >
                            {panels}
                        </Tabs>
                    </div>
                </div>
            </>
        )
    }
}
function SearchFormView(props){
    const form=Form.useForm(FormStrategy.View);
    const dianpuList=props.dianpulist||[];
    const [loading,setLoading]=React.useState(false);
    const [showsearch,setShowsearch]=React.useState(true);
    const onSubmit=React.useCallback(form => {
        setLoading(true);
        const value = form.getSubmitValue();
        const text=value.text;
        const dianpu=value.dianpu;
        const time=value.time;
        if(typeof props.onSearch=="function")
        {
            props.onSearch({
                time:time,
                dianpu:dianpu==null?0:dianpu.key,
                text:text,
                com:()=>{
                    setLoading(false)
                }
            })
        }
    },[])
    const onShowSearchClick=()=>{
        setShowsearch(!showsearch);
    }
    return(
        <div className={showsearch?"page-right-body-search":"page-right-body-search packup"}>
            <Form form={form} layout="horizontal" onSubmit={onSubmit} disabled={loading}>
                <div className="page-right-body-search-cells">
                    <div className="page-right-body-search-box">
                        <div className="page-right-body-search-cell">
                            <FormInputField
                                name="text"
                                label="关键字："
                                props={{
                                    placeholder:"投诉人姓名、电话、全部为空",
                                    width:'362px',
                                    showClear:true
                                }}/>
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="dianpu"
                                label="所属店铺："
                                props={{
                                    width:'200px',
                                    options:dianpuList,
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                            />
                        </div>
                    </div>
                    <FormDateRangeQuickPickerField
                        name="time"
                        label="投诉时间："
                        props={{
                            format:"YYYY-MM-DD HH:mm:ss",
                            chosenDays:7,
                            preset:[
                                {
                                    text: '今天',
                                    value: 0
                                },
                                {
                                    text: '昨天',
                                    value: 1
                                },
                                {
                                    text: '近7天',
                                    value: 7,
                                },
                                {
                                    text: '近30天',
                                    value: 30,
                                },
                            ]
                        }}
                        defaultValue={['','']}
                    />
                    <div className="page-right-body-search-buttons">
                        <Button icon={"refresh"}  htmlType="reset">重置</Button>
                        <Button icon="search" type="primary" htmlType={"submit"} loading={loading}>搜索</Button>
                    </div>
                </div>
            </Form>
            <div className="page-right-body-search-packup" onClick={onShowSearchClick}></div>
        </div>

    )
}