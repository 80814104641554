import React from 'react';
import Header from '../../../../component/header'
import {Button, Grid, Notify, Tag,Sweetalert,Input} from 'zent'
import Service from "../../../../config/Service";
import "../../../../style/css/main/system/role/index.css"
export default class MainSystemRoleIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            gridPageCurrent:1,
            gridPageTotal:0,
            gridLoading:false,
            gridPageSize:20,
            dataList:[],
            searchInputValue:'',
            searchDisabled:false
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(110100);
        this.dataBind(this.state.gridPageCurrent,'');
    }
    dataBind(pageindex,search){
        this.setState({
            gridLoading:true,
            searchDisabled:true
        })
        const _this=this;
        const {gridPageSize}=this.state;
        Service.Post({
            name:"adminrole",
            action:"pagelist",
            data:{
                pageindex,
                pagesize:gridPageSize,
                search
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        dataList:result.list,
                        gridPageTotal:result.count
                    })
                }
            },
            fail(){
                Notify.error("网络连接失败");
            },
            complete(){
                _this.setState({
                    gridLoading:false,
                    searchDisabled:false
                })
            }
        })
    }
    onAddRoleClick=()=>{
        this.props.history.push('role/edit/0');
    }
    onGridItemEditClick=(id)=>{
        this.props.history.push('role/edit/'+id);
    }
    onGridItemDeleteClick=(id,index)=>{
        const _this=this;
        Sweetalert.confirm({
            content:'操作成功后将无法恢复，请确认',
            title: '请确认操作',
            onConfirm: () => new Promise((resolve,reject) => {
                Service.Post({
                    name:"adminrole",
                    action:"delete",
                    data:{
                        id:id
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            let list=JSON.parse(JSON.stringify(_this.state.dataList));
                            list.splice(index,1);
                            if(list.length<=0)
                            {
                                const current=_this.state.gridPageCurrent;
                                if(current<=1)
                                {
                                     _this.dataBind(1,_this.state.searchInputValue);
                                }
                                else
                                {
                                    const nowcurrent=current-1;
                                    _this.setState({
                                        gridPageCurrent:nowcurrent
                                    })
                                    _this.dataBind(nowcurrent,_this.state.searchInputValue);
                                }
                            }
                            else
                            {
                                _this.setState({
                                    dataList:list
                                })
                            }
                            Notify.success("已删除");
                            resolve();
                        }
                        else
                        {
                            Notify.warn(result.retmsg);
                            reject();
                        }
                    },
                    fail(){
                        Notify.error("网络链接失败");
                        reject();
                    }
                })
            }),
            parentComponent:this
        });
    }

    onGridItemStatusClick=(id,state)=>{
        this.setState({
            gridLoading:true
        })
        const _this=this;
        Service.Post({
            name:"adminrole",
            action:"state",
            data:{
                id,
                state
            },
            success(result)
            {
                _this.setState({
                    gridLoading:false
                })
                if(result.retcode==200)
                {
                    Notify.success("操作成功");
                    _this.dataBind(_this.state.gridPageCurrent,_this.state.searchInputValue);
                }
                else
                {
                    Notify.warn(result.retmsg);
                }
            },
            fail(){
                _this.setState({
                    gridLoading:false
                })
            }
        })
    }
    onGridChange=(e)=>{
        const current=e.current;
        this.setState({
            gridPageCurrent:current
        })
        this.dataBind(current,this.state.searchInputValue);
    }
    onSearchInput=(e)=>{
        const value=e.target.value;
        this.setState({
            searchInputValue:value
        })
    }
    onSearchClick=()=>{
        const searchValue=this.state.searchInputValue;
         this.setState({
                gridPageCurrent:1
         })
        this.dataBind(1,searchValue);
    }
    getGridColumns=()=>{
        const listColumns=[
            {
                title:'角色名称',
                name:'name',
                width: '150px'
            },
            {
                title:'描述',
                name:'note',
            },
            {
                title:'创建人',
                width:'150px',
                bodyRender:(data)=>{
                    let statusView=null;
                    if(data.uname.length<=0)
                    {
                        statusView=<div style={{color:'#ff0000'}}>【不存在】</div>
                    }
                    else
                    {
                        statusView=<div>{data.uname}</div>
                    }
                    return <div className="font12">
                        {statusView}
                    </div>
                }
            },
            {
                title:'状态',
                width: '100px',
                bodyRender:(data)=>{
                    let statusView=null;
                    if(data.status==1)
                    {
                        statusView=<Tag theme={"green"}>正常</Tag>
                    }
                    else
                    {
                        statusView=<Tag theme={"yellow"}>禁用</Tag>
                    }
                    return <div className="font12">
                        {statusView}
                    </div>
                }
            },
            {
                title:'操作',
                width:'250px',
                textAlign:'center',
                bodyRender:(data,res)=>{
                    let statusView=null;
                    if(data.status==1)
                    {
                        statusView=<Button type="warning" onClick={this.onGridItemStatusClick.bind(this,data.id,2)}>禁用</Button>
                    }
                    else
                    {
                        statusView=<Button type="success" onClick={this.onGridItemStatusClick.bind(this,data.id,1)}>启用</Button>
                    }
                    return <div className="font12">
                        <Button type="default" onClick={this.onGridItemEditClick.bind(this,data.id)}>编辑</Button>
                        {statusView}
                        <Button type="error" onClick={this.onGridItemDeleteClick.bind(this,data.id,res.row)}>删除</Button>
                    </div>
                }
            }
        ];
        return listColumns;
    }
    render() {
        return(
            <div>
                <Header data={[{name:'系统'},{name:'角色管理'}]}/>
                <div className="page-right-body">
                    <div className="page-right-body-hd">
                        <div className="page-right-body-hd-hd">
                            <div className="page-right-body-search-panel">
                                    <div className="page-right-body-search-panel-hd">
                                        <Input
                                        placeholder={"角色名称"}
                                        icon="search" iconPosition="front"
                                        value={this.state.searchInputValue}
                                        maxLength={20}
                                        disabled={this.state.searchDisabled}
                                        onInput={this.onSearchInput}
                                    />
                                    </div>
                                    <div className="page-right-body-search-panel-bd">
                                        <Button icon="search" type="primary" disabled={this.state.searchDisabled} onClick={this.onSearchClick}>搜索</Button>
                                    </div>
                                </div>
                        </div>
                        <div className="page-right-body-hd-bd">
                            <Button onClick={this.onAddRoleClick} type="primary">添加角色</Button>
                        </div>
                    </div>
                    <div className="page-right-body-bd">
                        <Grid
                            columns={this.getGridColumns()}
                            datasets={this.state.dataList}
                            pageInfo={{
                                current: this.state.gridPageCurrent,
                                pageSize: this.state.gridPageSize,
                                total: this.state.gridPageTotal,
                                pageSizeOptions: [this.state.gridPageSize],
                            }}
                            loading={this.state.gridLoading}
                            onChange={this.onGridChange.bind(this)}
                            ellipsis
                            size={"medium"}
                            rowKey="id"/>
                    </div>
                </div>
            </div>
        )
    }
}
