import React from 'react';
import Header from '../../../../component/header'
import { Form,
    FormStrategy,
    Notify,
    FormInputField,
    Button,
    Checkbox,
    FormCheckboxGroupField,
    FormSelectField,
    Validators,
    BlockLoading,
    FormCheckboxField,
    FormImageUploadField,
    ValidatorMiddlewares,
    Icon,
    previewImage,
    FormRadioGroupField,
    Radio
} from 'zent';

import Service from "../../../../config/Service";
import Utils from "../../../../config/Utils";
const {FieldValue}=Form;
const KysjData=[
    {
        name:'monday',
        text:'星期一',
        am:'mondayam',
        pm:'mondaypm'
    },
    {
        name:'tuesday ',
        text:'星期二',
        am:'tuesdayam',
        pm:'tuesdaypm'
    },
    {
        name:'wednesday ',
        text:'星期三',
        am:'wednesdayam',
        pm:'wednesdaypm'
    },
    {
        name:'thursday ',
        text:'星期四',
        am:'thursdayam',
        pm:'thursdaypm'
    },
    {
        name:'friday ',
        text:'星期五',
        am:'fridayam',
        pm:'fridaypm'
    },
    {
        name:'saturday',
        text:'星期六',
        am:'saturdayam',
        pm:'saturdaypm'
    },
    {
        name:'sunday',
        text:'星期日',
        am:'sundayam',
        pm:'sundaypm'
    },
]

export default class MainCustomerUserEdit extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            userId:0,
            editData:{},
            dianpuList:[],
            loading:false,
            kysjData:[
                {
                    name:''
                }
            ]
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(120100);
        const id=this.props.match.params.id||0;
        this.setState({
            userId:id
        })
        this.dianpuBind();
        this.dataBind(id);
    }
    dianpuBind(){
        const _this=this;
        Service.Post({
            name:"admindianpu",
            action:"listbyadmin",
            data:{},
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        dianpuList:result.list
                    })
                }
            }
        })
    }

    dataBind(id){
        if(id<=0)
        {
            return false;
        }
        const _this=this;
        this.setState({
            loading:true
        })
        Service.Post({
            name:"webusers",
            action:"usernews",
            data:{
                id
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        editData:result.data,
                        loading:false
                    })
                }
                else
                {
                    Notify.error("没有找到相关信息",1500,()=>{
                        _this.setState({
                            loading:false
                        })
                        _this.props.history.goBack();
                    });
                }

            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    loading:false
                })
            }
        })
    }
    FormView=()=>{
        const form = Form.useForm(FormStrategy.View);
        const _this=this;
        const editData=this.state.editData;
        const stateLoading=this.state.loading;
        const dianpulist=this.state.dianpuList;
        const [schoollist,setSchoolList]=React.useState([]);
        const [yewuyuanlist,setYewuyuanList]=React.useState([]);
        const [productlist,setProductList]=React.useState([]);
        const [showimg,setShowImg]=React.useState(editData.idcardimg?true:false);
        const [loading,setLoading]=React.useState(false);
        const [vipcodeList,setVipcodeList]=React.useState([]);
        const [vipcodekeyword,onVipcodekeywordChange]=React.useState('');
        const [vipcodeloading, setVipcodeLoading] = React.useState(false);
        const onSubmit = React.useCallback(form => {
            setLoading(true);
            const value = form.getSubmitValue();
            const openvip=value.openvip?value.openvip:false;
            const yewuyuan=value.yewuyuan?value.yewuyuan.key:0;
            const product=value.product?value.product.key:0;
            const resuleproduct=value.product?value.product.pid:0;
            const vipcode=value.vipcode?value.vipcode.key:'';
            const school=value.school==null?0:value.school.key;
            const kysj=JSON.stringify(value.kysj);//空余时间
            const sfggjz=value.sfggjz==null?0:value.sfggjz;//之前是否干过兼职？
            const sfgzm=value.sfgzm==null?0:value.sfgzm;//周末有急招或高薪工作是否愿意客服主动联系你去挣钱？
            const sfgjqg=value.sfgjqg==null?0:value.sfgjqg;//寒暑假是否有干假期工的打算？
            const sfzsxdw=value.sfzsxdw==null?0:value.sfzsxdw;//（大三、大四）毕业前是否打算找实习单位？
            let idcardimg=editData.idcardimg||'';
            if(value.idcardimg&&value.idcardimg.length>0)
            {
                idcardimg=value.idcardimg[0].src;
            }
            return new Promise(resolve => {
                Service.Post({
                    name:"webusers",
                    action:"useredit",
                    data:{
                        id:_this.state.userId,
                        name:value.name,
                        tel:value.tel,
                        pwd:value.userpwd,
                        dianpu:value.dianpu.key,
                        city:value.dianpu.city,
                        school:school,
                        ljqd:value.ljqd,
                        idcardnumber:value.idcardnumber,
                        idcardimg:idcardimg,
                        openvip:openvip==true?1:0,
                        vipcode:openvip==true?vipcode:'',
                        yewuyuan:openvip==true?yewuyuan:0,
                        product:openvip==true?product:0,
                        resuleproduct:openvip==true?resuleproduct:0,
                        kysj:kysj,
                        sfggjz:sfggjz,
                        sfgzm:sfgzm,
                        sfgjqg:sfgjqg,
                        sfzsxdw:sfzsxdw
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("提交成功",1000,()=>{
                                setLoading(false);
                                _this.props.history.goBack();
                            })
                        }
                        else if(result.retcode==201)
                        {
                            Notify.success(result.regmsg,1500,()=>{
                                setLoading(false);
                                _this.props.history.goBack();
                            })
                        }
                        else
                        {
                            Notify.warn(result.retmsg);
                            setLoading(false);
                        }
                    },
                    fail(){
                        Notify.error("网络连接失败");
                        setLoading(false);
                    },
                })
            });
        }, []);
        const openvipdataBind=(dianpu)=>{
            Service.Post({
                name:"adminyewuyuan",
                action:"list",
                data:{
                    dianpu:dianpu
                },
                success(result){
                    if(result.retcode==200)
                    {
                        setYewuyuanList(result.list);
                    }
                    else
                    {
                        setYewuyuanList([]);
                    }
                },
                fail(){
                    setYewuyuanList([]);
                }
            });
            Service.Post({
                name:"adminproduct",
                action:"listbyuser",
                data:{
                    dianpu:dianpu,
                    vip:0,
                    isdj:0
                },
                success(result)
                {
                    if(result.retcode==200)
                    {
                        setProductList(result.list);
                    }
                    else
                    {
                        setProductList([]);
                    }
                },
                fail(){
                    setProductList([])
                }
            })
        }
        const onImageUpload=(file, report)=>{
            return new Promise((resolve, reject) => {
                report(40)
                Service.Upload({
                    action:"uploadimage",
                    file:file,
                    path:'idcardimg',
                    success(result){
                        if(result.retcode==200)
                        {
                            report(100);
                            resolve(result.path);
                        }
                        else
                        {
                            reject();
                        }
                    },
                    fail()
                    {
                        reject();
                    }
                })
            });
        }
        const onImageClick=(e)=>{
            let images=[];
            images.push(e.target.src);
            previewImage({
                images: images,
                index: 0,
                parentComponent: this
            });
        }
        const onImageDeleteClick=()=>{
            setShowImg(false);
        }
        React.useEffect(() => {
            const fileopenvip=form.model.get('openvip');
            let $=null;
            if(fileopenvip)
            {
                $=fileopenvip.value$.subscribe(value => {
                    if(value&&form.getValue().openvip)
                    {
                        if(form.getValue().dianpu!=null)
                        {
                            openvipdataBind(form.getValue().dianpu.key);
                        }
                    }
                    form.patchValue({
                        yewuyuan:null,
                        vipcode:'',
                        product:null
                    })
                });
            }
            const filedianpu=form.model.get('dianpu');
            const $dianpu=filedianpu.value$.subscribe(value=>{
                if(value&&value.key)
                {
                    return new Promise(resolve => {
                        Service.Post({
                            name:"adminschool",
                            action:"listbycity",
                            data:{
                                city:value.city
                            },
                            success(result){
                                if(result.retcode==200)
                                {
                                    setSchoolList(result.list);
                                }
                                else
                                {
                                    setSchoolList([])
                                }
                            },
                            fail(){
                                setSchoolList([])
                            }
                        })
                        if(form.getValue().openvip)
                        {
                            openvipdataBind(value.key);
                        }
                        setVipcodeList([]);
                        form.patchValue({
                            school:null,
                            yewuyuan:null,
                            product:null,
                            vipcode:null
                        })
                    });
                }
            })
            return () =>{
                if($!=null)
                {
                    $.unsubscribe();
                }
                $dianpu.unsubscribe();
            };
        }, [form]);

        React.useEffect(() => {
            if(vipcodekeyword.length>2)
            {
                const dianpu=form.getValue().dianpu.key;
                setVipcodeLoading(true);
                setVipcodeList([]);
                Service.Post({
                    name: "adminvipcode",
                    action:"listbytext",
                    data: {
                        dianpu:dianpu,
                        text:vipcodekeyword
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            setVipcodeList(result.list);
                        }
                        else
                        {
                            setVipcodeList([]);
                        }
                        setVipcodeLoading(false);
                    },
                    fail()
                    {
                        setVipcodeList([]);
                        setVipcodeLoading(false)
                    }
                })
            }

        }, [vipcodekeyword]);
        return(
            <Form
                disabled={loading}
                form={form}
                layout="horizontal"
                onSubmit={onSubmit}>
                <div className="form-main">
                    <div className="form-main-hd">
                        <FormInputField
                            name="name"
                            label={<p className="mee__xx"><span>*</span>姓名：</p>}
                            required
                            props={{
                                maxLength:10,
                                placeholder:"请输入真实姓名",
                            }}
                            validators={[
                                Validators.pattern(/^[\u4E00-\u9FA5]+$/, '必须为汉字')
                            ]}
                            initialValue={editData.name}
                        />
                        <FormInputField
                            name="tel"
                            label={<p className="mee__xx"><span>*</span>手机号：</p>}
                            required
                            props={{
                                maxLength:11,
                                placeholder:"请输入手机号码,做为账号登录"
                            }}
                            validators={[
                                Validators.pattern(/^(1[1-9])\d{9}$/, '手机号码错误')
                            ]}
                            initialValue={editData.tel}
                        />
                        <FormInputField
                            name="userpwd"
                            label={<p className="mee__xx">{_this.state.userId<=0&&(<span>*</span>)}密码：</p>}
                            required={_this.state.userId>0?false:true}
                            notice={_this.state.userId>0?"为空不修改":""}
                            helpDesc={"密码长度为6-16位"}
                            props={{
                                maxLength:16,
                                placeholder:"请输入密码"
                            }}
                        />
                        <FormSelectField
                            name="dianpu"
                            label={<p className="mee__xx"><span>*</span>所属店铺：</p>}
                            required
                            props={{
                                options:dianpulist,
                                placeholder:"请选择所属店铺"
                            }}
                            initialValue={Utils.returnItem(editData.dianpu,dianpulist)}
                        />
                        <FormInputField
                            name={"ljqd"}
                            label={<p className="mee__xx"><span>*</span>了解渠道：</p>}
                            required
                            props={{
                                maxLength:20,
                                disabled:(editData.ljqd&&editData.ljqd.length>0)?true:false,
                                placeholder:"请输入了解渠道"
                            }}
                            initialValue={editData.ljqd}
                        />
                        <FormInputField
                            name="idcardnumber"
                            label={<p className="mee__xx"><span>*</span>身份证号：</p>}
                            required
                            props={{
                                maxLength:18,
                                placeholder:"请输入身份证号",
                            }}
                            validators={[
                                Validators.pattern(/^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, '请输入正确的身份证号')
                            ]}
                            initialValue={editData.idcardnumber}
                        />
                        {!showimg&&(
                            <FormImageUploadField
                                label={"身份证照片："}
                                name={"idcardimg"}
                                props={{
                                    tips: '单个文件不超过 5M',
                                    maxAmount: 1,
                                    maxSize: 1024 * 1024 * 5,
                                    onUpload:onImageUpload,
                                }}
                            />
                        )}

                        {showimg&&(
                            <div className="page-right-body-form">
                                <div className="page-right-body-form-hd">
                                    身份证照片：
                                </div>
                                <div className="page-right-body-form-bd">
                                    <div className={"page-right-body-form-upimg"}>
                                        <img src={editData.idcardimg} onClick={onImageClick}/>
                                        <div className={"delete"} onClick={onImageDeleteClick}>
                                            <Icon type="reset" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <FormSelectField
                            name="school"
                            label="所属学校："
                            props={{
                                options:schoollist,
                                placeholder:"请选择学校"
                            }}
                            initialValue={Utils.returnItem(editData.school,schoollist)}
                        />
                        {_this.state.userId==0&&(
                            <FormCheckboxField
                                name={"openvip"}
                                label={"开通会员："}
                            />
                        )}
                        <FieldValue name="openvip">
                            {openvip=>(
                                openvip&&(
                                    <div>
                                         <FieldValue name={"dianpu"}>
                                            {dianpu=>(
                                                <>
                                                    <FormSelectField
                                                        name="vipcode"
                                                        label={<p className="mee__xx"><span>*</span>会员卡号</p>}
                                                        props={{
                                                            options:vipcodeList,
                                                            disabled:dianpu==null?true:false,
                                                            placeholder:"输入卡号搜索,最少3个字符",
                                                            keyword:vipcodekeyword,
                                                            loading:vipcodeloading,
                                                            onKeywordChange:onVipcodekeywordChange,
                                                            filter:false,
                                                            clearable:true,
                                                        }}
                                                         validators={[
                                                            ValidatorMiddlewares.when(
                                                                ctx => ctx.getSectionValue("openvip")?.openvip
                                                            )(Validators.required('请选择会员卡号')),
                                                        ]}
                                                    />
                                                    <FormSelectField
                                                        name="yewuyuan"
                                                        label={<p className="mee__xx"><span>*</span>业务员：</p>}
                                                        validators={[
                                                            ValidatorMiddlewares.when(
                                                                ctx => ctx.getSectionValue("openvip")?.openvip
                                                            )(Validators.required('请选择业务员')),
                                                        ]}
                                                        props={{
                                                            options:yewuyuanlist,
                                                            disabled:dianpu==null?true:false,
                                                            placeholder:"请选择业务员"
                                                        }}
                                                    />
                                                    <FormSelectField
                                                        name="product"
                                                        label={<p className="mee__xx"><span>*</span>产品类型：</p>}
                                                        validators={[
                                                            ValidatorMiddlewares.when(
                                                                ctx => ctx.getSectionValue("openvip")?.openvip
                                                            )(Validators.required('请选择产品类型')),
                                                        ]}
                                                        props={{
                                                            options:productlist,
                                                            disabled:dianpu==null?true:false,
                                                            placeholder:"请选择产品类型"
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </FieldValue>

                                    </div>
                                )
                            )}
                        </FieldValue>
                    </div>
                    <div className="form-main-bd">
                        <FormCheckboxGroupField
                            name="kysj"
                            withoutLabel
                            initialValue={editData.kysj}
                          >
                            <div className="kysjtable">
                                <div className="kysjtable-cell">
                                        <div className="kysjtable-cell-cell">空余时间</div>
                                        <div className="kysjtable-cell-cell">
                                            上午
                                        </div>
                                        <div className="kysjtable-cell-cell">
                                            下午
                                        </div>
                                    </div>
                                {KysjData.map((item,index)=>(
                                    <div className="kysjtable-cell" key={item.name}>
                                        <div className="kysjtable-cell-cell">{item.text}</div>
                                        <div className="kysjtable-cell-cell">
                                            <Checkbox value={item.am}></Checkbox>
                                        </div>
                                        <div className="kysjtable-cell-cell">
                                            <Checkbox value={item.pm}></Checkbox>
                                        </div>
                                    </div>
                                ))}
                            </div>
                          </FormCheckboxGroupField>
                        <div className="form-main-bd-title">
                            之前是否干过兼职？
                        </div>
                        <FormRadioGroupField
                            name="sfggjz"
                            withoutLabel
                            initialValue={editData.sfggjz}
                        >
                            <Radio value={1}>从未干过</Radio>
                            <Radio value={2}>干过几次</Radio>
                            <Radio value={3}>放假经常干</Radio>
                        </FormRadioGroupField>
                        <div className="form-main-bd-title">
                            周末有急招或高薪工作是否愿意客服主动联系你去挣钱？
                        </div>
                        <FormRadioGroupField
                            name="sfgzm"
                            withoutLabel
                            initialValue={editData.sfgzm}
                        >
                            <Radio value={1}>是</Radio>
                            <Radio value={2}>否</Radio>
                        </FormRadioGroupField>
                         <div className="form-main-bd-title">
                            寒暑假是否有干假期工的打算？
                        </div>
                        <FormRadioGroupField
                            name="sfgjqg"
                            withoutLabel
                            initialValue={editData.sfgjqg}
                        >
                            <Radio value={1}>假期是不可能打工的</Radio>
                            <Radio value={2}>不忙会尝试</Radio>
                            <Radio value={3}>给我留着假期必到</Radio>
                        </FormRadioGroupField>
                        <div className="form-main-bd-title">
                            （大三、大四）毕业前是否打算找实习单位？
                        </div>
                        <FormRadioGroupField
                            name="sfzsxdw"
                            withoutLabel
                            initialValue={editData.sfzsxdw}
                        >
                            <Radio value={1}>是（期望行业）</Radio>
                            <Radio value={2}>否（家里早安排好）</Radio>
                        </FormRadioGroupField>
                    </div>
                </div>
                {!stateLoading&&(
                    <div className="form-submit app-fixed-container">
                        <Button type="default" onClick={()=>{_this.props.history.goBack();}}>取消</Button>
                        <Button htmlType="submit" type="primary" loading={loading}>保存</Button>
                    </div>)
                }
            </Form>
        )
    }

    render() {
        const FormView=this.FormView.bind();
        const {loading}=this.state;
        return(
            <div>
                <Header data={[{name:'用户'},{name:'学生管理',href:'/main/customer/user'},{name:'编辑学生'}]}/>
                <BlockLoading loading={loading}>
                    <div className="page-right-body">
                        <div className="page-right-body-hd"></div>
                        <div className="page-right-body-bd">
                            <FormView/>
                        </div>
                    </div>
                </BlockLoading>
            </div>
        )
    }
}
