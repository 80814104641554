import React from 'react';
import Header from '../../../../component/header'
import {
    Form, Notify, FormInputField, Button,
    Radio,
    FormRadioGroupField,
    Validators,
    FormImageUploadField,
    FormSelectField, BlockLoading, FormSwitchField,
    FieldSet,
    ValidateOption, FormError, ValidatorMiddlewares, Checkbox,FormCheckboxGroupField
} from 'zent';
import Service from "../../../../config/Service";
import Utils from "../../../../config/Utils";
import {max} from "zent/es/form/formulr/validators";
const { form, field, array, set ,FieldValue} = Form;
export default class MainCustomerMerchantEdit extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            merchantId:0,
            editData:{},
            dianpuList:[],
            classList:[],
            loading:false
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(120200);
        const id=this.props.match.params.id||0;
        this.setState({
            merchantId:id
        })
        this.dianpuBind();
        this.classBind();
        this.dataBind(id);
    }
    dianpuBind(){
        const _this=this;
        Service.Post({
            name:"admindianpu",
            action:"listbyadmin",
            data:{},
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        dianpuList:result.list
                    })
                }
            }
        })
    }
    classBind(){
        const _this=this;
        Service.Post({
            name:"adminzhiweiclass",
            action:"list",
            data:{},
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        classList:result.list
                    })
                }
            }
        })
    }
    dataBind(id){
        if(id<=0)
        {
            return false;
        }
        const _this=this;
        this.setState({
            loading:true
        })
        Service.Post({
            name:"webmerchants",
            action:"news",
            data:{
                id
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        editData:result.data,
                        loading:false
                    })
                }
                else
                {
                    Notify.error("没有找到相关信息",1500,()=>{
                        _this.setState({
                            loading:false
                        })
                        _this.props.history.goBack();
                    });
                }

            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    loading:false
                })
            }
        })
    }
    onCancel=()=>{
        this.props.history.goBack();
    }
    render() {
        const {loading,dianpuList,merchantId,editData,classList}=this.state;
        return(
            <div>
                <Header data={[{name:'用户'},{name:'商户管理',href:'/main/customer/merchant'},{name:'编辑商户'}]}/>
                <BlockLoading loading={loading}>
                    <div className="page-right-body">
                        <div className="page-right-body-hd"></div>
                        <div className="page-right-body-bd">
                            <FormApp onCancel={this.onCancel} loading={loading} id={merchantId} data={editData} dianpuList={dianpuList} classList={classList}/>
                        </div>
                    </div>
                </BlockLoading>
            </div>
        )
    }
}
function ContactInfo({ remove, index, model }){
    const { name, type, content } = model.children;
    const onRemoveClick = React.useCallback(() => {
        remove(index);
    }, [index, remove]);
    return(
        <FieldSet model={model}>
            <div className="formaddview-cell">
                <div className="formaddview-cell-hd">
                    <div className="formaddview-cell-hd-title">联系方式 {index + 1}</div>
                    <div className="formaddview-cell-hd-remove">
                        <Button type={"text"} icon={"close"} onClick={onRemoveClick}>删除</Button>
                    </div>
                </div>
                <div className="formaddview-cell-bd">
                    <FormInputField
                        model={name}
                        label={"联系人："}
                        required
                        props={{
                            maxLength:20,
                            placeholder:"请输入联系人姓名"
                        }}
                    />
                    <FormRadioGroupField model={type} label="联系类型：" required>
                        <Radio value="1" checked>电话</Radio>
                        <Radio value="2">微信</Radio>
                    </FormRadioGroupField>
                    <FieldValue name={"type"}>
                        {value=>(
                            <FormInputField
                                name={"content"}
                                label={value==1?"联系电话：":"联系微信："}
                                required
                                props={{
                                    maxLength:20,
                                    placeholder:value==1?"请输入电话号码":"请输入微信号码"
                                }}
                            />
                        )}
                    </FieldValue>
                </div>
            </div>
        </FieldSet>
    )
}
function ContactMember({ model: propsModel }){
    const model = Form.useFieldArray(propsModel);
    const add = React.useCallback(() => {
        model.push({});
        model.validate(ValidateOption.IncludeUntouched);
    }, [model]);
    const remove = React.useCallback(
        index => {
            model.splice(index, 1);
            model.validate(ValidateOption.IncludeUntouched);
        },
        [model]
    );
    return(
        <div className="formaddview">
            {model.children.map((child, index) => (
                <ContactInfo key={child.id} model={child} index={index} remove={remove} />
            ))}
            {model.children.length < 5 && (
                <div className="formaddview-button">
                    <Button onClick={add} type={"text"}>
                        +添加联系方式
                    </Button>
                </div>
            )}
            <FormError style={{marginLeft:'8px'}}>{model.error?.message}</FormError>
        </div>
    )
}
function ImageUploadMember(props){
    const model = Form.useField(props.model);
    const maxAmount=props.maxAmount||1;
    const tips=props.tips||'';
    const multiple=props.multiple||false;
    const sortable=props.sortable||false;
    const onUpload=React.useCallback((file,report)=>{
        return new Promise((resolve, reject) => {
            report(40)
            Service.Upload({
                action:"uploadimage",
                file:file,
                path:'merchant',
                success(result){
                    if(result.retcode==200)
                    {
                        report(100);
                        resolve(result.path);
                    }
                    else
                    {
                        reject();
                    }
                },
                fail()
                {
                    reject();
                }
            })
        });
    },[model])
    return(
        <FormImageUploadField
            name={model}
            withoutLabel
            props={{
                maxAmount:maxAmount,
                multiple:multiple,
                sortable:sortable,
                tips:tips,
                onUpload:onUpload
            }}
        />
    )
}
const formBuilder = form({
    name: field('').validators(Validators.required('请输入商家名称')),
    dianpu:field(null).validators(Validators.required('请选择所属店铺')),
    contacts:array(
        set({
            name: field('').validators(
                Validators.required('请输入联系人')
            ),
            type: field('1').validators(
                Validators.required('请选择联系类型')
            ),
            content: field('').validators(
                ValidatorMiddlewares.message(ctx => {
                    return ctx.getSectionValue().type==1
                        ? '请填写联系电话'
                        : '请填写联系微信';
                })(Validators.required('必填')),
                ValidatorMiddlewares.when(
                    ctx => {
                        return ctx.getSectionValue().type==1
                    }
                )(Validators.pattern(/^(((\d{3,4}-)?[0-9]{7,8})|(1(3|4|5|6|7|8|9)\d{9}))$/, '请填写正确的电话号码'))
            )
        })
    ).validators(
        Validators.required("至少需要一个联系方式")
    ).defaultValue([{}]),
    idcardnumber:field(''),
    idcardimg:field([]),
    sqimg:field([]),
    images:field([]),
    state:field(true),
    classtype:field('2'),
    classtypevalue:field([]).validators(
        ValidatorMiddlewares.when(
            ctx => {
                return ctx.getSectionValue().classtype==2
            }
        )(Validators.minLength(1, '至少选择1个')),Validators.maxLength(3, '最多选择3个')
    )
});
function FormApp({loading:propLoading,id:merchantId,data:editData,dianpuList,classList,onCancel}){
    const form = Form.useForm(formBuilder);
    const [loading,setLoading]=React.useState(false);
    const {name,dianpu,idcardnumber,idcardimg,sqimg,contacts,state,classtype,classtypevalue,images} = form.model.children;
    const onSubmit=React.useCallback(()=>{
        const value = form.getSubmitValue();
        const idcardimgValue=value.idcardimg.length>0?value.idcardimg[0].src:'';
        const sqimgValue=value.sqimg.length>0?value.sqimg[0].src:'';
        const classValue=value.classtype==1?0:JSON.stringify(value.classtypevalue);
        let imagesValue="";
        if(value.images)
        {
            value.images.map(item=>{
                imagesValue+=item.src+',';
            })
        }
        setLoading(true)
        Service.Post({
            name:"webmerchants",
            action:"edit",
            data:{
                id:merchantId,
                name:value.name,
                dianpu:value.dianpu.key,
                city:value.dianpu.city,
                contacts:JSON.stringify(value.contacts),
                idcardnumber:value.idcardnumber,
                idcardimg:idcardimgValue,
                sqimg:sqimgValue,
                images:imagesValue,
                state:value.state?1:2,
                class:classValue
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    Notify.success("提交成功",1000,()=>{
                        setLoading(false);
                        onCancel();
                    })
                }
                else
                {
                    Notify.error(result.retmsg);
                    setLoading(false);
                }
            },
            fail(){
                Notify.error("网络连接失败");
                setLoading(false);
            }
        })
    },[form])
    React.useEffect(() => {
        if(editData.name)
        {
            form.patchValue({
                name: editData.name,
                dianpu:Utils.returnItem(editData.dianpu,dianpuList),
                contacts:editData.contacts,
                idcardnumber:editData.idcardnumber,
                idcardimg:editData.idcardimg.length>0?[{id:1,src:editData.idcardimg}]:[],
                sqimg:editData.sqimg.length>0?[{id:1,src:editData.sqimg}]:[],
                images:editData.images,
                classtype:editData.classtype,
                classtypevalue:editData.classtypevalue
            })
        }
    }, [editData,dianpuList]);
    return(
        <Form layout="horizontal" disabled={loading} form={form} onSubmit={onSubmit}>
            <FormInputField
                name={name}
                label={<p className="mee__xx"><span>*</span>商户名称：</p>}
                required
                props={{
                    width:'300px',
                    maxLength:50,
                    placeholder:"请输入商户名称",
                }}
            />
            <FormSelectField
                name={dianpu}
                label={<p className="mee__xx"><span>*</span>所属店铺：</p>}
                required
                props={{
                    options:dianpuList,
                    placeholder:"请选择所属店铺",
                }}
            />
            <FormRadioGroupField required name={classtype} label={<p className="mee__xx"><span>*</span>商户类型：</p>}>
                <Radio value="1" checked>人力资源</Radio>
                <Radio value="2">普通商家</Radio>
            </FormRadioGroupField>
            <FieldValue name={"classtype"}>
                {value=>(
                    <div className="me-form-cell margin-left-5" style={{display:(value==2?'flex':'none')}}>
                        <div className="me-form-cell-hd"></div>
                        <div className="me-form-cell-bd">
                            <FormCheckboxGroupField
                                name={classtypevalue}
                                withoutLabel
                            >
                                {classList.map((item,index)=>(
                                    <Checkbox value={item.key+''} key={item.key}>{item.text}</Checkbox>
                                ))}
                            </FormCheckboxGroupField>
                        </div>
                    </div>
                )}

            </FieldValue>
            <div className="me-form-cell">
                <div className="me-form-cell-hd"><p className="mee__xx"><span>*</span>联系方式：</p></div>
                <div className="me-form-cell-bd">
                    <ContactMember model={contacts}/>
                </div>
            </div>
            <FormInputField
                name={idcardnumber}
                label="营业执照编号："
                props={{
                    maxLength:30,
                    placeholder:"请输入营业执照编号",
                }}
            />
            <div className="me-form-cell" style={{marginBottom:'0px'}}>
                <div className="me-form-cell-hd"><p className="mee__xx">营业执照：</p></div>
                <div className="me-form-cell-bd">
                    <ImageUploadMember model={idcardimg}/>
                </div>
            </div>
            <div className="me-form-cell"  style={{marginBottom:'0px'}}>
                <div className="me-form-cell-hd">授权文件：</div>
                <div className="me-form-cell-bd">
                    <ImageUploadMember model={sqimg}/>
                </div>
            </div>
            <div className="me-form-cell"  style={{marginBottom:'0px'}}>
                <div className="me-form-cell-hd">店内环境：</div>
                <div className="me-form-cell-bd">
                    <ImageUploadMember model={images} maxAmount={9} tips={"最多 9 张"} multiple={true} sortable={true}/>
                </div>
            </div>
            <FormSwitchField
                name={state}
                label="启用："
            />
            {!propLoading&&(
                <div className="form-submit app-fixed-container">
                    <Button type="default" onClick={onCancel}>取消</Button>
                    <Button htmlType="submit" type="primary" loading={loading}>保存</Button>
                </div>
            )}
        </Form>
    )
}
