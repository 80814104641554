import React from 'react';
import {Icon,BlockLoading,Notify} from "zent";
import Service from "../config/Service";
import "../style/css/main/work/work/edit.css"
export default class WorkNewsView extends React.Component{
    constructor(props, context) {
        super(props, context);
        this.state={
            newsData:{},
            loading:true
        }
    }
    componentWillMount() {
        this.onDataBind();
    }
    onDataBind=()=>{
        const _this=this;
        if(this.props.viewid>0) {
            const id=this.props.viewid;
            this.setState({
                loading:true
            })
            Service.Post({
                name:"adminzhiwei",
                action:"news",
                data:{
                    id
                },
                success(result)
                {
                    if(result.retcode==200)
                    {
                        _this.setState({
                            newsData:result.data,
                            loading:false
                        })
                    }
                    else
                    {
                        Notify.error("没有找到相关信息",1500,()=>{
                            _this.props.onClose();
                        });
                    }

                },
                fail(){
                    Notify.error("网络连接失败",1500,()=>{
                        _this.props.onClose();
                    });
                }
            })
        }
        else
        {
            Notify.error("网络连接失败",1500,()=>{
                _this.props.onClose();
            });
        }
    }
    render() {
        const {newsData}=this.state;
        return(
            <BlockLoading loading={this.state.loading}>
                <div className="page-right-body-bd">
                    <div className="phonemain">
                        <div className="phonemain-header">
                            <div className="phonemain-header-title">
                                {newsData.title}
                            </div>
                            <div className="phonemain-header-money">
                                <div className="phonemain-header-money-hd">
                                    {newsData.money}<span>元/
                                    {newsData.moneyunit&&(newsData.moneyunit.text)}</span>
                                </div>
                                <div className="phonemain-header-money-bd">
                                    {newsData.jiesuantime&&(newsData.jiesuantime.text)}
                                </div>
                                <div className="phonemain-header-money-ft">
                                    {newsData.sex&&(newsData.sex.text)}
                                </div>
                                <div className="phonemain-header-money-bd">
                                    {newsData.zeren?'无责底薪':''}
                                </div>
                            </div>
                            {newsData.tags&&(
                                <div className="phonemain-header-tags">
                                    {newsData.tags.map((item)=>(
                                        <div className="phonemain-header-tags-cell">
                                            {item.text}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="phonemain-body">
                            <div className="phonemain-body-box">
                                <div className="phonemain-body-box-cell">
                                    <div className="phonemain-body-box-cell-hd">
                                        <Icon type="checkin-o" style={{color:'#ED8384'}}/>
                                    </div>
                                    <div className="phonemain-body-box-cell-bd">
                                        <div className="phonemain-body-box-cell-bd-hd">报名日期</div>
                                        <div className="phonemain-body-box-cell-bd-bd">
                                            {newsData.signdate&&(newsData.signdate[0]+'~'+newsData.signdate[1])}
                                        </div>
                                    </div>
                                </div>
                                <div className="phonemain-body-box-cell">
                                    <div className="phonemain-body-box-cell-hd">
                                        <Icon type="clock-o" style={{color:'#18CBF1'}}/>
                                    </div>
                                    <div className="phonemain-body-box-cell-bd">
                                        <div className="phonemain-body-box-cell-bd-hd">报名时间段</div>
                                        <div className="phonemain-body-box-cell-bd-bd">
                                            {newsData.signtimestart}
                                            -
                                            {newsData.signtimeend}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="phonemain-body-box">
                                <div className="phonemain-body-box-cell">
                                    <div className="phonemain-body-box-cell-hd">
                                        <Icon type="checkin-o" style={{color:'#ED8384'}}/>
                                    </div>
                                    <div className="phonemain-body-box-cell-bd">
                                        <div className="phonemain-body-box-cell-bd-hd">工作日期</div>
                                        <div className="phonemain-body-box-cell-bd-bd">
                                            {newsData.workdate&&(newsData.workdate[0]+'~'+newsData.workdate[1])}
                                        </div>
                                    </div>
                                </div>
                                <div className="phonemain-body-box-cell">
                                    <div className="phonemain-body-box-cell-hd">
                                        <Icon type="clock-o" style={{color:'#18CBF1'}}/>
                                    </div>
                                    <div className="phonemain-body-box-cell-bd">
                                        <div className="phonemain-body-box-cell-bd-hd">工作时间段</div>
                                        <div className="phonemain-body-box-cell-bd-bd">
                                            {newsData.worktimestart}
                                            -
                                            {newsData.worktimeend}
                                        </div>
                                    </div>
                                </div>
                                <div className="phonemain-body-box-cell">
                                    <div className="phonemain-body-box-cell-hd">
                                        <Icon type="expand-customer-o" style={{color:'#867AE3'}}/>
                                    </div>
                                    <div className="phonemain-body-box-cell-bd">
                                        <div className="phonemain-body-box-cell-bd-hd">
                                            {newsData.address}
                                        </div>
                                        <div className="phonemain-body-box-cell-bd-bd">
                                            <span>距我 00.00km</span>
                                        </div>
                                    </div>
                                    <div className="phonemain-body-box-cell-ft">
                                        <Icon type="right" />
                                    </div>
                                </div>
                            </div>
                            {newsData.mianshi==true&&(
                                <>
                                    <div className="phonemain-body-title">
                                        需面试
                                    </div>
                                    <div className="phonemain-body-box">
                                        <div className="phonemain-body-box-cell">
                                            <div className="phonemain-body-box-cell-hd">
                                                <Icon type="clock" style={{color:'#F79B15'}}/>
                                            </div>
                                            <div className="phonemain-body-box-cell-bd">
                                                <div className="phonemain-body-box-cell-bd-hd">面试时间</div>
                                                <div className="phonemain-body-box-cell-bd-bd">
                                                    {newsData.mianshitime}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="phonemain-body-box-cell">
                                            <div className="phonemain-body-box-cell-hd">
                                                <Icon type="expand-customer" style={{color:'#00A9E7'}}/>
                                            </div>
                                            <div className="phonemain-body-box-cell-bd">
                                                <div className="phonemain-body-box-cell-bd-hd">面试地址</div>
                                                <div className="phonemain-body-box-cell-bd-bd">
                                                    {newsData.mianshiaddress}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="phonemain-body-title">
                                联系信息
                            </div>
                            <div className="phonemain-body-box">
                                <div className="phonemain-body-box-cell">
                                    <div className="phonemain-body-box-cell-hd">
                                        <Icon type="customer-o" style={{color:'#9DB8FC'}}/>
                                    </div>
                                    <div className="phonemain-body-box-cell-bd">
                                        <div className="phonemain-body-box-cell-bd-hd">联系人</div>
                                        <div className="phonemain-body-box-cell-bd-bd">
                                            {newsData.lxrname}
                                        </div>
                                    </div>
                                </div>
                                {newsData.lxrtype==2&&(
                                    <div className="phonemain-body-box-cell">
                                        <div className="phonemain-body-box-cell-hd">
                                            <Icon type="wechat" style={{color:'#43AD0D'}}/>
                                        </div>
                                        <div className="phonemain-body-box-cell-bd">
                                            <div className="phonemain-body-box-cell-bd-hd">商家微信</div>
                                            <div className="phonemain-body-box-cell-bd-bd" style={{color:'#666AFF'}}>
                                                {newsData.lxrtel}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {newsData.lxrtype==1&&(
                                    <div className="phonemain-body-box-cell">
                                        <div className="phonemain-body-box-cell-hd">
                                            <Icon type="hotline-o" style={{color:'#00A9E7'}}/>
                                        </div>
                                        <div className="phonemain-body-box-cell-bd">
                                            <div className="phonemain-body-box-cell-bd-hd">联系电话</div>
                                            <div className="phonemain-body-box-cell-bd-bd" style={{color:'#666AFF'}}>
                                                {newsData.lxrtel}
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </div>
                            <div className="phonemain-body-title">
                                职位详情
                            </div>
                            <div className="phonemain-body-box">
                                <div className="phonemain-body-box-content">
                                    <pre>{newsData.text}</pre>
                                </div>
                            </div>
                            {(newsData.workhits==false||newsData.xianzhi==false)&&(
                                <div className="phonemain-body-warning">
                                    <div className="phonemain-body-warning-title">
                                        <Icon type="voice" style={{color:'orange',fontSize:'20px'}}/>
                                        <span>报名提醒</span>
                                    </div>
                                    {newsData.workhits==false&&(
                                        <div className="phonemain-body-warning-cell">
                                            <div></div>本职位不计算工作次数与不良记录，无需签到。
                                        </div>
                                    )}
                                    {newsData.xianzhi==false&&(
                                        <div className="phonemain-body-warning-cell">
                                            <div></div>本职位不会与已报名职位产生时间段冲突的情况。
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className="phonemain-body-tishi">
                                <div className="phonemain-body-tishi-hd">
                                    <div className="phonemain-body-tishi-hd-hd">
                                        <Icon type="bell" style={{color:'#fff'}} />
                                    </div>
                                    <div className="phonemain-body-tishi-hd-bd">小贴士</div>
                                </div>
                                <div className="phonemain-body-tishi-bd">兼职过程请拒绝向商家缴纳任何费用。若收费请<span>及时电话联系客服</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </BlockLoading>
        )
    }
}

