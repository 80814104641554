import React from 'react';
import Header from '../../../../component/header'
import {
    Button,
    Grid,
    Notify,
    Tag,
    previewImage,
    Sweetalert,
    Dialog,
    Form,
    FormStrategy,
    Input,
    AutoComplete
} from 'zent'
import Service from "../../../../config/Service";
const {openDialog,closeDialog}=Dialog
export default class MainAuditMerchantIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            gridPageCurrent:1,
            gridPageTotal:0,
            gridLoading:false,
            gridPageSize:15,
            dataList:[],
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(170100);
        this.onDataBind();
    }
    onDataBind=()=>{
        setTimeout(()=>{
            this.onShzDataBind()
        },100)
    }
    onShzDataBind=(com)=>{
        const _this=this;
        const {gridPageSize,gridPageCurrent}=this.state;
        this.setState({
            gridLoading:true
        })
        Service.Post({
            name:"webmerchants",
            action:"shenhepagelist",
            data:{
                pageindex:gridPageCurrent,
                pagesize: gridPageSize
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        dataList:result.list,
                        gridPageTotal:result.count,
                        gridLoading:false
                    })
                }
                else
                {
                    _this.setState({
                        dataList:[],
                        gridPageTotal:0,
                        gridLoading:false
                    })
                }
            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    dataList:[],
                    gridPageTotal:0,
                    gridLoading:false
                })
            }
        })
    }
    onGridItemImageClick=(src)=>{
        let images=[];
        if(typeof src =="string")
        {
            images.push(src);
        }
        else
        {
            images=src;
        }
        previewImage({
            images: images,
            index: 0,
            parentComponent: this
        });
    }
    onGridItemStatusClick=(resData,state)=>{
        if(state==2)
        {
            const _this=this;
            const dialogid='shdialog'+resData.id;
            let notsuccessText="";
            openDialog({
                parentComponent:this,
                title:'审核驳回',
                dialogId:dialogid,
                maskClosable:false,
                closeBtn:false,
                children:(
                    <div>
                        <AutoComplete
                            width={500}
                            placeholder="请输入驳回原因,最多40个字"
                            onChange={(e)=>{
                               notsuccessText=e;
                            }}
                            maxLength={40}
                            data={['商户名称不规范','商户类型不符','联系人不规范','联系人电话不规范','商户名称与营业执照不符','商户类型与营业执照不符','营业执照不规范','授权文件无效']}
                          />
                    </div>
                ),
                footer:(
                    <div>
                        <Button onClick={()=>{closeDialog(dialogid)}}>取消</Button>
                        <Button type={"primary"} onClick={()=>{
                            if(notsuccessText.length<=0)
                            {
                                Notify.warn("请输入驳回原因");
                            }
                            else
                            {
                                _this.onShenheStateChange({
                                    id:resData.id,
                                    state:2,
                                    text:notsuccessText,
                                    name:resData.name,
                                    dianpu:resData.dianpuid,
                                    uid:resData.adminid
                                });
                            }
                        }}>确认驳回</Button>
                    </div>
                )
            })
        }
        if(state==1)
        {
            this.onShenheStateChange({
                id:resData.id,
                state:1,
                text:'',
                name:resData.name,
                dianpu:resData.dianpuid,
                uid:resData.adminid
            })
        }
    }
    onShenheStateChange=(res)=>{
        const _this=this;
        Sweetalert.confirm({
            content:'本次操作无法撤回，请确认',
            title: '请确认操作',
            onConfirm: () => new Promise((resolve,reject) => {
                Service.Post({
                    name:"webmerchants",
                    action:"shenhe",
                    data:{
                        id:res.id,
                        shenhe:res.state,
                        text:res.text,
                        name:res.name,
                        dianpu:res.dianpu,
                        uid:res.uid
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            closeDialog("shdialog"+res.id)
                            Notify.success("操作成功");
                            resolve();
                            _this.onDataBind();
                        }
                        else
                        {
                            Notify.warn(result.retmsg);
                            reject();
                        }
                    },
                    fail(){
                        Notify.error("网络链接失败");
                        reject();
                    }
                })
            }),
            parentComponent:this
        });
    }
    onGridChange=(index,e)=>{
        const current=e.current;
        this.setState({
            gridPageCurrent:current
        })
        this.onDataBind();
    }

    getGridColumns=()=>{
        const listColumns=[
            {
                title:'商户名称',
                name:'name'
            },
            {
                title:'联系方式',
                bodyRender:data=>{
                    return(
                        <div>
                            {data.contacts.map((item,index)=>(
                                <div key={index}><span>联系人：{item.name}</span><span style={{paddingLeft:'15px'}}>{item.type==1?"电话":"微信"}：{item.content}</span></div>
                            ))}
                        </div>
                    )
                }
            },
            {
                title:'所属店铺',
                name:'dianpu',
                width: '100px'
            },
            {
                title:'商户类型',
                name:'class',
                width: '240px'
            },
            {
                title:'营业执照',
                width: '200px',
                textAlign: 'center',
                bodyRender:(data)=>{
                    return(
                        <div>
                            {data.idcardnumber.length>0&&(
                                <div><Tag>{data.idcardnumber}</Tag></div>
                            )}
                            {data.idcardimg.length>0&&(
                                <div><Tag onClick={this.onGridItemImageClick.bind(this,data.idcardimg)} style={{cursor:'pointer'}} theme={"blue"} outline>已上传</Tag></div>
                            )}
                            {data.idcardimg.length<=0&&(
                                <div><Tag outline>未上传</Tag></div>
                            )}
                        </div>
                    )
                }
            },
            {
                title:'授权书',
                width: '100px',
                textAlign: 'center',
                bodyRender:(data)=>{
                    if(data.sqimg.length>0)
                    {
                        return <Tag onClick={this.onGridItemImageClick.bind(this,data.sqimg)} style={{cursor:'pointer'}} theme={"blue"} outline>已上传</Tag>
                    }
                    else
                    {
                        return <Tag>未上传</Tag>
                    }
                }
            },
            {
                title:'店内环境',
                width: '100px',
                textAlign: 'center',
                bodyRender:(data)=>{
                    if(data.images.length>0)
                    {
                        return <Tag onClick={this.onGridItemImageClick.bind(this,data.images)} style={{cursor:'pointer'}} theme={"blue"} outline> {data.images.length}张</Tag>
                    }
                    else
                    {
                        return <Tag>未上传</Tag>
                    }
                }
            },
            {
                title:'注册时间',
                name:'time',
                width: '140px',
                textAlign: 'center'
            },
            {
                title:'操作',
                width:'180px',
                textAlign:'center',
                bodyRender:(data)=>{
                    return <div className="font12">
                        <Button type="warning" onClick={this.onGridItemStatusClick.bind(this,data,2)}>驳回</Button>
                        <Button type="success" onClick={this.onGridItemStatusClick.bind(this,data,1)}>通过</Button>
                    </div>
                }
            }
        ];
        return listColumns;
    }
    render() {
        return(
            <div>
                <Header data={[{name:'审核'},{name:'商户审核'}]}/>
                <div className="page-right-body">
                    <div className="page-right-body-bd" style={{paddingTop:'15px'}}>
                        <Grid
                            columns={this.getGridColumns()}
                            datasets={this.state.dataList}
                            pageInfo={{
                                current: this.state.gridPageCurrent,
                                pageSize: this.state.gridPageSize,
                                total: this.state.gridPageTotal,
                                pageSizeOptions: [this.state.gridPageSize],
                            }}
                            loading={this.state.gridLoading}
                            onChange={this.onGridChange.bind(this,1)}
                            ellipsis
                            size={"medium"}
                            rowKey="id"/>
                    </div>
                </div>
            </div>
        )
    }
}
function BHShenheView(props){
    const form=Form.useForm(FormStrategy.View);

    const onSubmit=React.useCallback(form => {
        //setLoading(true);
        const value = form.getSubmitValue();
        return new Promise(resolve => {

        });
    },[]);
    return(<></>)
}
