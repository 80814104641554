import React from 'react';
import Header from '../../../../component/header'
import {
    Button,
    Grid,
    Notify,
    Sweetalert,
    Tag, FormDateRangeQuickPickerField,FormNumberInputField,
    FormSelectField, Form, FormStrategy, FormInputField, openDialog, Validators, closeDialog
} from 'zent'
import Service from "../../../../config/Service";

export default class MainFinancialVipcodeIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            gridPageCurrent:1,
            gridPageTotal:0,
            gridLoading:false,
            gridPageSize:20,
            dataList:[],
            dianpuList:[],
            searchData:{
                mincode:'',
                maxcode:'',
                dianpu:null,
                status:null,
                uptime:['',''],
                addtime:['','']
            }
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(140400);
        this.dataBind();
        this.dianpuBind();
    }
    dataBind=(retchange=null)=>{
        const _this=this;
        const {gridPageSize,gridPageCurrent,searchData}=this.state;
        this.setState({
            gridLoading:true
        })
        Service.Post({
            name:"adminvipcode",
            action:"pagelist",
            data:{
                pageindex:gridPageCurrent,
                pagesize: gridPageSize,
                search: searchData
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        dataList:result.list,
                        gridPageTotal:result.count
                    })
                }
            },
            fail(){
                Notify.error("网络连接失败");
            },
            complete(){
                _this.setState({
                    gridLoading:false
                })
                if(typeof retchange=="function")
                {
                    retchange();
                }
            }
        })

    }
    dianpuBind(){
        const _this=this;
        Service.Post({
            name:"admindianpu",
            action:"listbyadmin",
            data:{},
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        dianpuList:result.list
                    })
                }
            }
        })
    }
    searchChange=(obj)=>{
        this.setState({
            searchData:obj.data,
            gridPageCurrent:1,
            gridPageTotal:0
        })
        setTimeout(()=>{
            this.dataBind(obj.retchange);
        },100)
    }
    onAddVipCoceClick=()=>{
        const dialogId="addvipcodedialog"
        const onChange=(data)=>{
            Notify.success('添加成功,应添加'+data.count+'条,实添加'+data.successcount+'条');
            closeDialog(dialogId);
            this.setState({
                gridPageCurrent:1,
                gridPageTotal:0
            })
            setTimeout(()=>{
                this.dataBind();
            },100)
        }
         openDialog({
             dialogId:dialogId,
             parentComponent: this,
             title: '批量添加会员卡',
             maskClosable:false,
             children: (
                 <AddVipCodeView data={this.state.dianpuList} onChange={onChange}/>
             )
         });
    }
    onGridItemDeleteClick=(id)=>{
        const _this=this;
        Sweetalert.confirm({
            content:'操作成功后将无法恢复，请确认',
            title: '请确认操作',
            onConfirm: () => new Promise((resolve,reject) => {
                Service.Post({
                    name:"adminvipcode",
                    action:"delete",
                    data:{
                        id:id
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("已删除");
                            resolve();
                            _this.dataBind()
                        }
                        else
                        {
                            Notify.warn(result.retmsg);
                            reject();
                        }
                    },
                    fail(){
                        Notify.error("网络链接失败");
                        reject();
                    }
                })
            }),
            parentComponent:this
        });
    }
    onGridChange=(e)=>{
        const current=e.current;
        this.setState({
            gridPageCurrent:current
        })
        setTimeout(()=>{
            this.dataBind()
        },100)
    }
    getGridColumns=()=>{
        const listColumns=[
            {
                title:'会员卡号',
                name:'code'
            },
            {
                title:'所属店铺',
                name:'dianpu',
                width: '150px'
            },
            {
                title:'状态',
                width: '150px',
                textAlign:'center',
                bodyRender:(data)=>{
                    let statusView=null;
                    if(data.status==1)
                    {
                        statusView=<Tag theme={"green"}>已使用</Tag>
                    }
                    else
                    {
                        statusView=<Tag >未使用</Tag>
                    }
                    return <div className="font12">
                        {statusView}
                    </div>
                }
            },
            {
                title:'使用时间',
                width:'200px',
                textAlign:'center',
                bodyRender:(data)=>{
                    let statusView=null;
                    if(data.status==1)
                    {
                        statusView=<span>{data.uptime}</span>
                    }
                    else
                    {
                        statusView=<Tag>未使用</Tag>
                    }
                    return <div className="font12">
                        {statusView}
                    </div>
                }
            },
            {
                title:'添加时间',
                name:'addtime',
                width:'200px',
                textAlign:'center'
            },
            {
                title:'操作',
                width:'120px',
                textAlign:'center',
                bodyRender:(data)=>{
                    if(data.status==1)
                    {
                        return <span>不可操作</span>
                    }
                    else
                    {
                        return <Button type={"error"} onClick={this.onGridItemDeleteClick.bind(this,data.id)}>删除</Button>
                    }
                }
            }
        ];
        return listColumns;
    }
    render() {
        return(
            <div>
                <Header data={[{name:'财务'},{name:'会员卡管理'}]}/>
                <div className="page-right-body">
                    <SearchFormView dianpu={this.state.dianpuList} data={this.state.searchData} onChange={this.searchChange}/>
                    <div className="page-right-body-hd" >
                        <div className="page-right-body-hd-hd">
                        </div>
                        <div className="page-right-body-hd-bd">
                            <Button onClick={this.onAddVipCoceClick} icon={"plus"} type="primary">批量添加会员卡</Button>
                        </div>
                    </div>
                    <div className="page-right-body-bd">
                        <Grid
                            columns={this.getGridColumns()}
                            datasets={this.state.dataList}
                            pageInfo={{
                                current: this.state.gridPageCurrent,
                                pageSize: this.state.gridPageSize,
                                total: this.state.gridPageTotal,
                                pageSizeOptions: [this.state.gridPageSize],
                            }}
                            loading={this.state.gridLoading}
                            onChange={this.onGridChange.bind(this)}
                            ellipsis
                            size={"medium"}
                            rowKey="id"/>
                    </div>
                </div>
            </div>
        )
    }
}
function SearchFormView({dianpu:dianpuList,data:searchData,onChange}){
    const form=Form.useForm(FormStrategy.View);
    const [loading,setLoading]=React.useState(false);
    const [showsearch,setShowsearch]=React.useState(true);
     const onShowSearchClick=()=>{
        setShowsearch(!showsearch);
    }
    const onSubmit=React.useCallback(form => {
        setLoading(true);
        const value = form.getSubmitValue();
        console.log(value);
        onChange({
            data:value,
            retchange(){
                setLoading(false);
            }
        });
    },[form])
    React.useEffect(() => {
        form.patchValue(searchData);
    }, [form]);
    return(
        <div className={showsearch?"page-right-body-search":"page-right-body-search packup"}>
            <Form form={form} layout="horizontal" onSubmit={onSubmit} disabled={loading}>
                <div className="page-right-body-search-cells">
                   <div className="formcells">
                       <div className="formcell">
                           <div className="formcelllabel">卡号范围：</div>
                       </div>
                       <div className="formcell">
                           <FormNumberInputField
                                name="mincode"
                                withoutLabel
                                props={{
                                    placeholder:"开始编号",
                                    width:'180px',
                                    maxLength:8,
                                    decimal:0
                                }}
                            />
                       </div>
                       <div className="formcell">
                           <div className="formcellspan">至</div>
                       </div>
                       <div className="formcell">
                           <FormNumberInputField
                                name="maxcode"
                                withoutLabel
                                props={{
                                    placeholder:"结尾编号",
                                    width:'180px',
                                    maxLength:8,
                                    decimal:0
                                }}
                            />
                       </div>
                    </div>
                    <div className="page-right-body-search-box">
                        <div className="page-right-body-search-cell"  style={{paddingRight:'15px'}}>
                            <FormSelectField
                                name="dianpu"
                                label="所属店铺："
                                props={{
                                    options:dianpuList,
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                            />
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="status"
                                label="使用状态："
                                props={{
                                    options:[{key:1,text:'已使用'},{key:2,text:'未使用'}],
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                            />
                        </div>
                    </div>
                    <FormDateRangeQuickPickerField
                                name="uptime"
                                label="使用时间："
                                props={{
                                    format:"YYYY-MM-DD HH:mm:ss",
                                    chosenDays:7,
                                    preset:[
                                        {
                                            text: '今天',
                                            value: 0
                                        },
                                        {
                                            text: '昨天',
                                            value: 1
                                        },
                                        {
                                            text: '近7天',
                                            value: 7,
                                        },
                                        {
                                            text: '近30天',
                                            value: 30,
                                        },
                                    ]
                                }}
                                defaultValue={['','']}
                            />
                        <FormDateRangeQuickPickerField
                                name="addtime"
                                label="添加时间："
                                props={{
                                    format:"YYYY-MM-DD HH:mm:ss",
                                    chosenDays:7,
                                    preset:[
                                        {
                                            text: '今天',
                                            value: 0
                                        },
                                        {
                                            text: '昨天',
                                            value: 1
                                        },
                                        {
                                            text: '近7天',
                                            value: 7,
                                        },
                                        {
                                            text: '近30天',
                                            value: 30,
                                        },
                                    ]
                                }}
                                defaultValue={['','']}
                            />
                    <div className="page-right-body-search-buttons">
                        <Button icon={"refresh"}  htmlType="reset">重置</Button>
                        <Button icon="search" type="primary" htmlType={"submit"} loading={loading}>搜索</Button>
                    </div>
                </div>
            </Form>
            <div className="page-right-body-search-packup" onClick={onShowSearchClick}></div>
        </div>

    )
}
function equalsCode(value, ctx){
    if (Number(value) < Number(ctx.getSectionValue('mincode').mincode))
    {
        return {message: '结尾编号必须大于等于开始编号'}
    }
    return null;
}
function AddVipCodeView({data:dianpuList,onChange}){
    const form = Form.useForm(FormStrategy.View);
    const [loading,setLoading]=React.useState(false);
    const onSubmit=React.useCallback((form)=>{
        const value = form.getSubmitValue();
        const mincode=value.mincode;
        const maxcode=value.maxcode;
        const dianpu=value.dianpu.key;
        setLoading(true);
        Service.Post({
            name:'adminvipcode',
            action:'add',
            data:{
                mincode,
                maxcode,
                dianpu
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    onChange(result.data);
                }
                else {
                    Notify.warn(result.retmsg)
                }
            },
            fail(er){
                console.log(er);
                Notify.error('网络连接失败');
            },
            complete(){
                setLoading(false);
            }
        })
    },[]);
    return(
        <Form layout="horizontal" onSubmit={onSubmit} form={form} disabled={loading}>
            <FormSelectField
                name={"dianpu"}
                label={"所属店铺："}
                required
                props={{
                    options:dianpuList,
                    placeholder:"请选择所属店铺"
                }}
                validators={[Validators.required('请选择店铺')]}
            />
            <FormNumberInputField
                name={"mincode"}
                label={"本次开始编号："}
                props={{
                    width:'240px',
                    decimal:0,
                    min:1,
                    max:99999999,
                    maxLength:8
                }}
                validators={[Validators.required('请输入开始编号')]}
            />
            <FormNumberInputField
                name={"maxcode"}
                label={"本次结尾编号："}
                props={{
                    width:'240px',
                    decimal:0,
                    showClear:true,
                    min:1,
                    max:99999999,
                    maxLength:8
                }}
                validators={[
                    Validators.required('请输入结尾编号'),
                    equalsCode
                ]}
            />
             <div style={{textAlign:'right'}}>
                <Button type="primary" htmlType={"submit"} loading={loading}>提交</Button>
            </div>
        </Form>
    )
}
