import React from 'react';
import Header from '../../../../component/header'
import { Form, FormStrategy, Notify, FormInputField,Button,
    FormSelectField,BlockLoading,FormSwitchField,FormNumberInputField} from 'zent';
import Service from "../../../../config/Service";
import Utils from "../../../../config/Utils";
export default class MainConstantWorkContentEdit extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            contentId:0,
            editData:{},
            classsList:[],
            loading:false
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(160500);
        const id=this.props.match.params.id||0;
        this.setState({
            contentId:id
        })
        this.classBind();
        this.dataBind(id);
    }
    classBind(){
        const _this=this;
        Service.Post({
            name:"adminzhiweiclass",
            action:"list",
            data:{},
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        classsList:result.list
                    })
                }
            }
        })
    }

    dataBind(id){
        if(id<=0)
        {
            return false;
        }
        const _this=this;
        this.setState({
            loading:true
        })
        Service.Post({
            name:"adminzhiweicontent",
            action:"news",
            data:{
                id
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        editData:result.data,
                        loading:false
                    })
                }
                else
                {
                    Notify.error("没有找到相关信息",1500,()=>{
                        _this.setState({
                            loading:false
                        })
                        _this.props.history.goBack();
                    });
                }

            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    loading:false
                })
            }
        })
    }
    FormView=()=>{
        const _this=this;
        const editData=this.state.editData;
        const stateLoading=this.state.loading;
        const classsList=this.state.classsList;
        const form = Form.useForm(FormStrategy.View);
        const [loading,setLoading]=React.useState(false);
        const onSubmit = React.useCallback(form => {
            setLoading(true);
            const value = form.getSubmitValue();
            return new Promise(resolve => {
                const id=_this.state.contentId;
                Service.Post({
                    name:"adminzhiweicontent",
                    action:"edit",
                    data:{
                        id,
                        text:value.text,
                        workclass:value.workclass.key,
                        state:value.state?1:2,
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("提交成功",1000,()=>{
                                setLoading(false);
                                _this.props.history.goBack();
                            })
                        }
                        else
                        {
                            Notify.error(result.retmsg);
                            setLoading(false);
                        }
                    },
                    fail(){
                        Notify.error("网络连接失败");
                        setLoading(false);
                    }
                })
            });
        }, []);
        return(
            <Form
                disabled={loading}
                form={form}
                layout="horizontal"
                onSubmit={onSubmit}>
                <FormSelectField
                    name="workclass"
                    label={"所属类别"}
                    required
                    props={{
                        options:classsList,
                        placeholder:"请选择所属类别",
                    }}
                    initialValue={Utils.returnItem(editData.workclass,classsList)}
                />
                <FormInputField
                    name="text"
                    label="内容："
                    required
                    props={{
                        maxLength:1000,
                        placeholder:"请输入内容",
                        width:'360px',
                        showCount:true,
                        type:"textarea",
                        autoSize:true
                    }}
                    initialValue={editData.text}
                />
                <FormSwitchField
                    name="state"
                    label="启用："
                    initialValue={editData.state==2?false:true}
                />
                {!stateLoading&&(
                    <div className="form-submit app-fixed-container">
                        <Button type="default" onClick={()=>{_this.props.history.goBack();}}>取消</Button>
                        <Button htmlType="submit" type="primary" loading={loading}>保存</Button>
                    </div>)
                }
            </Form>
        )
    }

    render() {
        const FormView=this.FormView.bind();
        const {loading}=this.state;
        return(
            <div>
                <Header data={[{name:'常量'},{name:'工作介绍模板',href:'/main/constant/workcontent'},{name:'编辑工作介绍模板'}]}/>
                <BlockLoading loading={loading}>
                    <div className="page-right-body">
                        <div className="page-right-body-hd"></div>
                        <div className="page-right-body-bd">
                            <FormView/>
                        </div>
                    </div>
                </BlockLoading>
            </div>
        )
    }
}