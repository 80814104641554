import React from 'react';
import Header from '../../../../component/header'
import {
    Form, FormStrategy, Notify, FormInputField, Button,
    FormNumberInputField,
    FormCheckboxGroupField,
    Checkbox,
    Validators,
    previewImage,
    Icon,
    FormImageUploadField,
    FieldSet,
    ImageUpload,
    FormSelectField, BlockLoading, FieldUtils
} from 'zent';
import Service from "../../../../config/Service";
import Utils from "../../../../config/Utils";
import {renderToStaticMarkup} from "react-dom/server";
import {queryByDisplayValue} from "@testing-library/react";
export default class MainFinancialReimbursementEdit extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            baoxiaoId:0,
            editData:{},
            dianpuList:[],
            classList:[],
            loading:false
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(140200);
        if(new Date().getHours()<17)
        {
            const id=this.props.match.params.id||0;
            this.setState({
                baoxiaoId:id
            })
            this.dianpuBind();
            this.classBind();
            this.dataBind(id);
        }
        else
        {
            const _this=this;
            Notify.error("17点之后不可操作报销",1500,()=>{
                        _this.props.history.goBack();
                    });
        }
    }
    dianpuBind(){
        const _this=this;
        Service.Post({
            name:"admindianpu",
            action:"listbyadmin",
            data:{
                type:0
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        dianpuList:result.list
                    })
                }
            }
        })
    }
    classBind=()=>{
        const _this=this;
        Service.Post({
            name:"adminbaoxiaoclass",
            action:"list",
            data:{},
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        classList:result.list
                    })
                }
            }
        })
    }
    dataBind(id){
        if(id<=0)
        {
            return false;
        }
        const _this=this;
        this.setState({
            loading:true
        })
        Service.Post({
            name:"adminbaoxiao",
            action:"news",
            data:{
                id
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        editData:result.data,
                        loading:false
                    })
                }
                else
                {
                    Notify.error("没有找到相关信息",1500,()=>{
                        _this.setState({
                            loading:false
                        })
                        _this.props.history.goBack();
                    });
                }

            },
            fail(er){
                console.log(er);
                Notify.error("网络连接失败");
                _this.setState({
                    loading:false
                })
            }
        })
    }
    FormView=()=>{
        const _this=this;
        const editData=this.state.editData;
        const stateLoading=this.state.loading;
        const dianpuList=this.state.dianpuList;
        const classList=this.state.classList;
        const form = Form.useForm(FormStrategy.View);
        const [loading,setLoading]=React.useState(false);
        const [dianpuchecked,setDianpuchecked]=React.useState(false);
        const [indeterminate,setIndeterminate]=React.useState(false);
        const onSubmit = React.useCallback(form => {
            setLoading(true);
            const value = form.getSubmitValue();
            const images=value.upload.imglist;
            return new Promise(resolve => {
                const id=_this.state.baoxiaoId;

                Service.Post({
                    name:"adminbaoxiao",
                    action:"edit",
                    data:{
                        id,
                        money:value.money,
                        classid:value.baoxiaoclass.key,
                        classname:value.baoxiaoclass.text,
                        images:JSON.stringify(images),
                        dianpu:value.dianpus,
                        text:value.text
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("提交成功",1000,()=>{
                                setLoading(false);
                                _this.props.history.goBack();
                            })
                        }
                        else
                        {
                            Notify.error(result.retmsg);
                            setLoading(false);
                        }
                    },
                    fail(){
                        Notify.error("网络连接失败");
                        setLoading(false);
                    }
                })
            });
        }, []);
        const dianpuonChange=React.useCallback((e)=>{
            setIndeterminate(false)
            const checked=e.target.checked;
            if(checked)
            {
                const items=dianpuList.map((item)=>{
                    return item.key;
                })
                form.patchValue({
                    dianpus:items
                })
                setDianpuchecked(true);
            }
            else
            {
                form.patchValue({
                    dianpus:[]
                })
                setDianpuchecked(false);
            }
        },[])
        React.useEffect(() => {
            const filedianpus=form.model.get('dianpus');
            const $=filedianpus.value$.subscribe(value => {
                if(value)
                {
                    setIndeterminate(value.length>0&&value.length!==dianpuList.length)
                    setDianpuchecked(value.length>0 && value.length === dianpuList.length);
                }
            });
            return () =>{
                $.unsubscribe();
            };
        }, [form]);

        return(
            <Form
                disabled={loading}
                form={form}
                layout="horizontal"
                onSubmit={onSubmit}>
                <FormSelectField
                    name="baoxiaoclass"
                    label={"报销类别："}
                    required={"请选择报销类别"}
                    props={{
                        options:classList,
                        placeholder:"报销类别",
                    }}
                    initialValue={Utils.returnItem(editData.baoxiaoclass,classList)}
                />
                <FormNumberInputField
                    name="money"
                    label="报销金额："
                    props={{
                        decimal:2,
                        maxLength:15,
                        min:0.01,
                        max:999999,
                        placeholder:"报销金额",
                    }}
                    required={"请输入报销金额"}
                    initialValue={editData.money}
                />
                <div className="page-right-body-form">
                    <div className="page-right-body-form-hd">
                        报销凭证：
                    </div>
                    <div className="page-right-body-form-bd">
                        <FieldSet name={"upload"} validators={[(values)=>{
                            if(values.imglist.length<=0)
                            {
                                return {
                                    name: 'error',
                                    message: '至少上传1张图片',
                                };
                            }
                            return null;
                        }]}>
                            <ImageUploadView filelist={editData.images}/>
                        </FieldSet>
                    </div>
                </div>
                <div className="page-right-body-form">
                    <div className="page-right-body-form-hd">
                        报销店铺：
                    </div>
                    <div className="page-right-body-form-bd page-border-bottom">
                        <Checkbox
                            checked={dianpuchecked}
                            indeterminate={indeterminate}
                            onChange={dianpuonChange}
                        >全部</Checkbox>
                    </div>
                </div>
                <FormCheckboxGroupField
                    name="dianpus"
                    initialValue={editData.dianpu}
                    validators={[Validators.minLength(1, '至少选择一个店铺')]}
                    defaultValue={()=>{
                        return dianpuList.map((item)=>{
                            return item.key;
                        })
                    }}>
                    {dianpuList.map((item,index)=>(
                        <Checkbox key={index} value={item.key}>{item.text}</Checkbox>
                    ))}
                </FormCheckboxGroupField>
                <FormInputField
                    label={"报销内容："}
                    name={"text"}
                    required={"请输入报销内容"}
                    props={{
                        placeholder:"详细介绍报销的内容",
                        type:"textarea",
                        autoSize:true,
                        width:'500px',
                        maxLength:800,
                        showCount:true
                    }}
                    initialValue={editData.text}
                />
                {!stateLoading&&(
                    <div className="form-submit app-fixed-container">
                        <Button type="default" onClick={()=>{_this.props.history.goBack();}}>取消</Button>
                        <Button htmlType="submit" type="primary" loading={loading}>保存</Button>
                    </div>)
                }
            </Form>
        )
    }
    render() {
        const FormView=this.FormView.bind();
        const {loading}=this.state;
        return(
            <div>
                <Header data={[{name:'系统'},{name:'幻灯片管理',href:'/main/system/flash'},{name:'编辑幻灯片'}]}/>
                <BlockLoading loading={loading}>
                    <div className="page-right-body">
                        <div className="page-right-body-hd"></div>
                        <div className="page-right-body-bd">
                            <FormView/>
                        </div>
                    </div>
                </BlockLoading>
            </div>
        )
    }
}
function ImageUploadView(props){
    const images=props.filelist||[];
    const option = Form.useField('imglist', []);
    const [fileList,setFileList]=React.useState(images);
    const onUpload=React.useCallback((file, report)=> {
        return new Promise((resolve, reject) => {
            report(40)
            Service.Upload({
                action:"uploadimage",
                file:file,
                path:'baoxiao',
                success(result){
                    if(result.retcode==200)
                    {
                        report(100);
                        resolve(result.path);
                    }
                    else
                    {
                        reject();
                    }
                },
                fail()
                {
                    reject();
                }
            })
        })
    },[option])
    const onError=React.useCallback((e)=>{
        if(e=="overMaxAmount")
        {
            Notify.warn("最多只可以上传50张图片");
        }
    },[option])
    const onChange= FieldUtils.useMulti(
        React.useCallback((file)=>{
            setFileList(file)
        },[fileList]),
        FieldUtils.usePipe(
            (files)=>{
                let list=[];
                files.map((item,index)=>{
                    if(item.src)
                    {
                        list.push(item.src)
                    }
                })
                return list
            },
            FieldUtils.useChangeHandler(option, Form.ValidateOption.Default)
        )
    );
    React.useEffect(() => {
    }, [fileList]);
    return(
        <ImageUpload
            {...FieldUtils.useCompositionHandler(option)}
            accept="image/*"
            tips= '单个文件不超过 10M,最多9张图片'
            maxAmount={50}
            multiple
            fileList={fileList}
            maxSize={1024 * 1024 * 10}
            onUpload={onUpload}
            onChange={onChange}
            onError={onError}
        />
    )
}
