import React from 'react';
import Header from '../../../../../component/header'
import {
    Button,
    Grid,
    Notify,
    Sweetalert,
    Tag,
    previewImage
} from 'zent'
import Service from "../../../../../config/Service";

export default class MainSystemHuomaListIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            huomaGroupId:0,
            gridPageCurrent:1,
            gridPageTotal:0,
            gridLoading:false,
            gridPageSize:15,
            dataList:[]
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(110600);
        const id=this.props.match.params.id||0;
        this.setState({
            huomaGroupId:id
        })
        this.dataBind(1,id);
    }
    dataBind=(pageindex,id)=>{
        const _this=this;
        if(id<=0)
        {
            Notify.error("没有找到相关信息",1500,()=>{
                _this.props.history.goBack();
            });
        }
        else
        {
            const {gridPageSize}=this.state;
            this.setState({
                gridLoading:true
            })
            Service.Post({
                name:"huomalist",
                action:"pagelist",
                data:{
                    id,
                    pageindex:pageindex,
                    pagesize: gridPageSize
                },
                success(result){
                    if(result.retcode==200)
                    {
                        _this.setState({
                            dataList:result.list,
                            gridPageTotal:result.count
                        })
                    }
                    else
                    {
                        Notify.error("没有找到相关信息",1500,()=>{
                            _this.props.history.goBack();
                        });
                    }
                },
                fail(){
                    Notify.error("网络连接失败");
                },
                complete(){
                    _this.setState({
                        gridLoading:false
                    })
                }
            })
        }

    }
    onImageClick=(e)=>{
        let images=[];
        images.push(e.target.src);
        previewImage({
            images: images,
            index: 0,
            parentComponent: this
        });
    }
    onAddHuomaListClick=()=>{
        this.props.history.push('edit/'+this.state.huomaGroupId);
    }
    onGridItemDeleteClick=(id)=>{
        const _this=this;
        Sweetalert.confirm({
            content:'操作成功后将无法恢复，请确认',
            title: '请确认操作',
            onConfirm: () => new Promise((resolve,reject) => {
                Service.Post({
                    name:"huomalist",
                    action:"delete",
                    data:{
                        id:id
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("已删除");
                            resolve();
                            _this.dataBind(_this.state.gridPageCurrent,_this.state.huomaGroupId)
                        }
                        else
                        {
                            Notify.warn(result.retmsg);
                            reject();
                        }
                    },
                    fail(){
                        Notify.error("网络链接失败");
                        reject();
                    }
                })
            }),
            parentComponent:this
        });
    }
    onGridChange=(e)=>{
        const current=e.current;
        this.setState({
            gridPageCurrent:current
        })
        this.dataBind(current,this.state.huomaGroupId)
    }
    getGridColumns=()=>{
        const listColumns=[
            {
                title:'二维码',
                bodyRender:(data)=>{
                    return<img style={{width:'30px',height:'30px'}} src={data.img} onClick={this.onImageClick}/>
                }
            },
            {
                title:'已扫次数',
                name:'hits',
                width: '150px'
            },
            {
                title:'类型',
                width: '150px',
                bodyRender:(data)=>{
                    let statusView=null;
                    if(data.type==1)
                    {
                        statusView=<Tag theme={"blue"}>个人</Tag>
                    }
                    else
                    {
                        statusView=<Tag theme={"green"}>群</Tag>
                    }
                    return <div className="font12">
                        {statusView}
                    </div>
                }
            },
            {
                title:'失效时间',
                width: '200px',
                bodyRender:data => {
                    if(data.type==0)
                    {
                        return <>{data.time}</>
                    }
                    else
                    {
                        return <>无失效期限</>
                    }
                }
            },
            {
                title:'状态',
                width: '150px',
                bodyRender:(data)=>{
                    let statusView=null;
                    if(data.state==1)
                    {
                        statusView=<Tag theme={"yellow"}>失效</Tag>
                    }
                    else
                    {
                        statusView=<Tag theme={"green"}>正常</Tag>
                    }
                    return <div className="font12">
                        {statusView}
                    </div>
                }
            },
            {
                title:'排序',
                name:'sort',
                width: '150px'
            },
            {
                title:'操作',
                width:'150px',
                textAlign:'center',
                bodyRender:(data)=>{
                    return <div className="font12">
                        <Button type="error" onClick={this.onGridItemDeleteClick.bind(this,data.id)}>删除</Button>
                    </div>
                }
            }
        ];
        return listColumns;
    }
    render() {
        return(
            <div>
                <Header data={[{name:'系统'},{name:'活码管理',href:'/main/system/huoma'},{name:'二维码列表'}]}/>
                <div className="page-right-body">
                    <div className="page-right-body-hd">
                        <div className="page-right-body-hd-hd">
                        </div>
                        <div className="page-right-body-hd-bd">
                            <Button onClick={this.onAddHuomaListClick} type="primary">添加二维码</Button>
                        </div>
                    </div>
                    <div className="page-right-body-bd">
                        <Grid
                            columns={this.getGridColumns()}
                            datasets={this.state.dataList}
                            pageInfo={{
                                current: this.state.gridPageCurrent,
                                pageSize: this.state.gridPageSize,
                                total: this.state.gridPageTotal,
                                pageSizeOptions: [this.state.gridPageSize],
                            }}
                            loading={this.state.gridLoading}
                            onChange={this.onGridChange.bind(this)}
                            ellipsis
                            size={"medium"}
                            rowKey="id"/>
                    </div>
                </div>
            </div>
        )
    }
}