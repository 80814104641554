import React from 'react';
import {Grid} from "zent";
import Service from "../config/Service";
export default class UserResultListView extends React.Component{
    constructor(props, context) {
        super(props, context);
        this.state={
            gridPageCurrent:1,
            gridPageTotal:0,
            gridLoading:false,
            gridPageSize:10,
            dataList:[]
        }
    }
    componentWillMount() {
        this.onDataBind();
    }
    onDataBind=(pageindex)=>{
        const _this=this;
        if(this.props.dataid>0)
        {
            this.setState({
                gridLoading:true
            });
            Service.Post({
                name:"adminresults",
                action:"pagelistbyuser",
                data:{
                    id:_this.props.dataid,
                    pageindex:pageindex,
                    pagesize:_this.state.gridPageSize
                },
                success(result){
                    if(result.retcode==200)
                    {
                        _this.setState({
                            dataList:result.list,
                            gridPageTotal:result.count,
                            gridLoading:false
                        })
                    }
                    else
                    {
                        _this.setState({
                            dataList:[],
                            gridPageTotal:0,
                            gridLoading:false
                        })
                    }
                },
                fail(){
                    _this.setState({
                        dataList:[],
                        gridPageTotal:0,
                        gridLoading:false
                    })
                }
            })
        }else
        {
            _this.setState({
                dataList:[],
                gridPageTotal:0,
                gridLoading:false
            })
        }
    }

    onGridChange=(e)=>{
        const current=e.current;
        this.setState({
            gridPageCurrent:current
        })
        this.onDataBind(current)
    }

    getGridColumns(){
        const Columns=[
            {
                title:'消费金额',
                name:'money'
            },
            {
                title:'产品名称',
                width:'130px',
                name:'product'
            },
            {
                title:'会员卡号',
                width:'130px',
                name:'vipcode'
            },
            {
                title:'业务员',
                width:'100px',
                name:'yewuyuan'
            },
            {
                title:'消费时间',
                width:'180px',
                name:'time',
                textAlign:'center'
            },
            {
                title:'所属店铺',
                width:'130px',
                name:'dianpu',
                textAlign: 'center'
            }
        ]
        return Columns;
    }
    render() {
        return(
            <div>
                <Grid
                    columns={this.getGridColumns()}
                    datasets={this.state.dataList}
                    pageInfo={{
                        current: this.state.gridPageCurrent,
                        pageSize:this.state.gridPageSize,
                        total: this.state.gridPageTotal,
                        pageSizeOptions: [this.state.gridPageSize],
                    }}
                    loading={this.state.gridLoading}
                    onChange={this.onGridChange}
                    ellipsis
                    size={"medium"}
                    rowKey="id"/>
            </div>
        )
    }
}
