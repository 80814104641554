import React from 'react';
import "../../style/css/main/index.css"
export default class Index extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        }
    }
    componentWillMount() {

    }
    render() {
        return (
            <div className="index-page">
                <div className="mainLogo">
                    兼途99
                </div>
                <div style={{fontSize:'60px'}}>后端管理系统</div>
            </div>
        )
    }

}
