import React from 'react';
import Header from '../../../../component/header'
import {
    Button, Form, FormStrategy,
    Grid,
    Notify,
    Sweetalert,
    Tabs,
    FormSelectField,
    FormDateRangeQuickPickerField
} from 'zent'
import Service from "../../../../config/Service";
import Utils from '../../../../config/Utils'
import {Link} from "react-router-dom";
const TabPanel = Tabs.TabPanel;
export default class MainExitProcessingIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            gridPageSize:15,
            dclgridPageCurrent:1,
            dclgridPageTotal:0,
            dclgridLoading:false,
            dcldataList:[],

            yclgridPageCurrent:1,
            yclgridPageTotal:0,
            yclgridLoading:false,
            ycldataList:[],

            dianpuList:[],
            activeID:'1',
            exitproData:{
                sign:0,
                work:0,
                user:0
            },
            searchData:{
                dianpu:0,
                state:0,
                time:['','']
            }
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(180100);
        this.onDianpuDataBind();
        this.onDataBind();
    }
    onDataBind=()=>{
        setTimeout(()=>{
            this.onDclDataBind()
        },100)
    }
    onExitDataBind=()=>{
        setTimeout(()=>{
            this.onYclDataBind()
            this.onExitProDataBind()
        },100)
    }
    onDianpuDataBind(){
        const _this=this;
        this.setState({
            loading:true
        })
        Service.Post({
            name:"admindianpu",
            action:"listbyadmin",
            data:{},
            success(result)
            {
                if(result.retcode==200)
                {
                    if(result.list.length>0)
                    {
                        _this.setState({
                            dianpuList:result.list
                        })
                        _this.onDataBind();
                    }
                    else
                    {
                        _this.setState({
                            dianpuList:[]
                        })
                    }
                }
                else
                {
                    _this.setState({
                        dianpuList:[]
                    })
                }
            },
            fail()
            {
                _this.setState({
                    dianpuList:[]
                })
            }
        })
    }
    onExitProDataBind(){
        const _this=this;
        this.setState({
            loading:true
        })
        const {searchData}=this.state;
        Service.Post({
            name:"WebUsersWorksMess",
            action:"exitprodata",
            data:{
                dianpu:searchData.dianpu,
                time:searchData.time
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        exitproData:result.data
                    })
                }
                else
                {
                    _this.setState({
                        exitproData:{
                            sign:0,
                            work:0,
                            user:0
                        }
                    })
                }
            },
            fail()
            {
                _this.setState({
                    exitproData:{
                        sign:0,
                        work:0,
                        user:0
                    }
                })
            }
        })
    }
    onDclDataBind=()=>{
        const _this=this;
        const {gridPageSize,dclgridPageCurrent,activeID}=this.state;
        this.setState({
            dclgridLoading:true
        })
        Service.Post({
            name:"WebUsersWorksMess",
            action:"exitpagelist",
            data:{
                pageindex:dclgridPageCurrent,
                pagesize: gridPageSize
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        dcldataList:result.list,
                        dclgridPageTotal:result.count,
                        dclgridLoading:false
                    })
                }
                else
                {
                    _this.setState({
                        dcldataList:[],
                        dclgridPageTotal:0,
                        dclgridLoading:false
                    })
                }
            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    dcldataList:[],
                    dclgridPageTotal:0,
                    dclgridLoading:false
                })
            }
        })
    }
    onYclDataBind=()=>{
        const _this=this;
        const {gridPageSize,yclgridPageCurrent,searchData}=this.state;
        this.setState({
            yclgridLoading:true
        })
        Service.Post({
            name:"WebUsersWorksMess",
            action:"exitpropagelist",
            data:{
                pageindex:yclgridPageCurrent,
                pagesize: gridPageSize,
                search:searchData
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        ycldataList:result.list,
                        yclgridPageTotal:result.count,
                        yclgridLoading:false
                    })
                }
                else
                {
                    _this.setState({
                        ycldataList:[],
                        yclgridPageTotal:0,
                        yclgridLoading:false
                    })
                }
            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    ycldataList:[],
                    yclgridPageTotal:0,
                    yclgridLoading:false
                })
            }
        })
    }
    onExitStateChange=(id,uid,status)=>{
        const _this=this;
        Sweetalert.confirm({
            content:'本次操作无法撤回，请确认',
            title: '请确认操作',
            onConfirm: () => new Promise((resolve,reject) => {
                Service.Post({
                    name:"WebUsersWorksMess",
                    action:"exitstatus",
                    data:{
                        id:id,
                        uid:uid,
                        status:status
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("操作成功");
                            resolve();
                            _this.onDataBind();
                        }
                        else
                        {
                            Notify.warn(result.retmsg);
                            reject();
                        }
                    },
                    fail(){
                        Notify.error("网络链接失败");
                        reject();
                    }
                })
            }),
            parentComponent:this
        });
    }
    onDCLGridChange=(index,e)=>{
        const current=e.current;
        this.setState({
            dclgridPageCurrent:current
        })
        this.onDataBind();
    }
    onYCLGridChange=(index,e)=>{
        const current=e.current;
        this.setState({
            yclgridPageCurrent:current
        })
        this.onExitDataBind();
    }
    onTabsChange=id=>{
        if(id=='1')
        {
            this.setState({
                activeID: id,
                dclgridPageCurrent:1
            });
            this.onDataBind();
        }
        else
        {
            this.setState({
                activeID:id,
                yclgridPageCurrent:1
            })
            this.onExitDataBind();
        }
    }
    getGridColumns=()=>{
        const listColumns=[
            {
                title:'报名职位',
                name:'ztitle',
                width:'300px',
                bodyRender:data=>{
                    return(
                        <div>
                            <Link href={"/main/work/work?text="+data.zid} to={"/main/work/work?text="+data.zid}>{data.ztitle}</Link>
                        </div>

                    )
                }
            },
            {
                title:'退订人',
                width:'200px',
                bodyRender:data=>{
                    return(
                        <div>
                            {data.uname}({data.utel})
                        </div>
                    )
                }
            },
            {
                title:'所属店铺',
                name:'dianpu',
                width: '100px'
            },
            {
                title:'退订时间',
                name:'time',
                width: '140px',
                textAlign: 'center'
            },
            {
                title:'退订详情',
                name:'text',
                textAlign: 'left'
            },
            {
                title:'操作',
                width:'340px',
                textAlign:'center',
                bodyRender:(data)=>{
                    if(data.status==1)
                    {
                        return(
                            <div>操作记录：职位已更新</div>
                        )
                    }
                    else if(data.status==2)
                    {
                        return(
                            <div>操作记录：职位已关闭</div>
                        )
                    }
                    else if(data.status==4)
                    {
                        return(
                            <div>操作记录：已核实，职位无异常</div>
                        )
                    }
                    else
                    {
                        return(
                            <div className="font12">
                                <Button type="primary" size={"small"} onClick={this.onExitStateChange.bind(this,data.id,data.uid,1)}>职位已更新</Button>
                                <Button type="success" size={"small"} onClick={this.onExitStateChange.bind(this,data.id,data.uid,2)}>职位已关闭</Button>
                                <Button type="warning" size={"small"} onClick={this.onExitStateChange.bind(this,data.id,data.uid,4)}>已核实，职位无异常</Button>
                            </div>
                        )
                    }
                }
            }
        ];
        return listColumns;
    }
    render() {
        const {exitproData,dianpuList,searchData}=this.state;
        return(
            <div>
                <Header data={[{name:'退订'},{name:'反馈处理'}]}/>
                <div className="page-right-body">
                    <Tabs
                        activeId={this.state.activeID}
                        onChange={this.onTabsChange}
                        type={"card"}
                    >
                        <TabPanel key="1" tab={<span>未处理</span>} id="1">
                            <div className="page-right-body-bd" style={{paddingTop:'15px'}}>
                                <Grid
                                    columns={this.getGridColumns()}
                                    datasets={this.state.dcldataList}
                                    pageInfo={{
                                        current: this.state.dclgridPageCurrent,
                                        pageSize: this.state.gridPageSize,
                                        total: this.state.dclgridPageTotal,
                                        pageSizeOptions: [this.state.gridPageSize],
                                    }}
                                    loading={this.state.dclgridLoading}
                                    onChange={this.onDCLGridChange.bind(this,1)}
                                    ellipsis
                                    size={"medium"}
                                    rowKey="id"/>
                            </div>
                        </TabPanel>
                        <TabPanel key="2" tab="已处理" id="2">
                            <div className="page-right-body-hd-hd">
                                <div className="page-financial-cells">
                                    <div className="page-financial-cell cursor-default">
                                        <div className="page-financial-cell-hd">报名人次</div>
                                        <div className="page-financial-cell-bd">
                                            <b>{exitproData.sign}</b>
                                        </div>
                                    </div>
                                    <div className="page-financial-cell cursor-default">
                                        <div className="page-financial-cell-hd">个人原因退订</div>
                                        <div className="page-financial-cell-bd">
                                            <b>{exitproData.user}</b>
                                        </div>
                                    </div>
                                    <div className="page-financial-cell cursor-default">
                                        <div className="page-financial-cell-hd">职位原因退订</div>
                                        <div className="page-financial-cell-bd">
                                            <b>{exitproData.work}</b>
                                        </div>
                                    </div>
                                    <div className="page-financial-cell cursor-default">
                                        <div className="page-financial-cell-hd">职位原因退订率</div>
                                        {exitproData.sign>0&&(
                                            <div className="page-financial-cell-bd"><b>{(exitproData.work/exitproData.sign*100).toFixed(2)}</b><span>%</span></div>
                                        )}
                                        {exitproData.sign<=0&&(
                                            <div className="page-financial-cell-bd"><b>0.00</b><span>%</span></div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <SearchFormView dianpulist={dianpuList} data={searchData} parent={this}/>
                            <div className="page-right-body-bd" style={{paddingTop:'15px'}}>
                                <Grid
                                    columns={this.getGridColumns()}
                                    datasets={this.state.ycldataList}
                                    pageInfo={{
                                        current: this.state.yclgridPageCurrent,
                                        pageSize: this.state.gridPageSize,
                                        total: this.state.yclgridPageTotal,
                                        pageSizeOptions: [this.state.gridPageSize],
                                    }}
                                    loading={this.state.yclgridLoading}
                                    onChange={this.onYCLGridChange.bind(this,1)}
                                    ellipsis
                                    size={"medium"}
                                    rowKey="id"/>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        )
    }
}
function SearchFormView(props){
    const form=Form.useForm(FormStrategy.View);
    const dianpuList=props.dianpulist;
    const editData=props.data;
    const onSubmit=React.useCallback(form => {
        const value = form.getSubmitValue();
        const state=value.state;
        const dianpu=value.dianpu;
        const time=value.time;
        return new Promise(resolve => {
            const pageindex=1;
            const searchdata={
                state:state==null?0:state.key,
                dianpu:dianpu==null?0:dianpu.key,
                time:time
            }
            props.parent.setState({
                yclgridPageCurrent: pageindex,
                searchData:searchdata,
            })
            props.parent.onExitDataBind();
        });
    },[])
    return(
        <div className="page-right-body-search" style={{paddingBottom:0}}>
            <Form form={form} layout="horizontal" onSubmit={onSubmit}>
                <div className="page-right-body-search-cells">
                    <div className="page-right-body-search-box">
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="dianpu"
                                label="所属店铺："
                                props={{
                                    width:'200px',
                                    options:dianpuList,
                                    clearable:true,
                                    placeholder:'为空全部'
                                }}
                                initialValue={Utils.returnItem(editData.dianpu,dianpuList)}
                            />
                        </div>
                        <div className="page-right-body-search-cell">
                            <FormSelectField
                                name="state"
                                label="处理结果："
                                props={{
                                    width:'220px',
                                    options:[{key:1,text:'职位已更新'},{key:2,text:'职位已关闭'},{key:4,text:'已核实，职位无异常'}],
                                    clearable:true,
                                    placeholder:'为空全部',
                                }}
                                initialValue={Utils.returnItem(editData.state,[{key:1,text:'职位已更新'},{key:2,text:'职位已关闭'},{key:4,text:'已核实，职位无异常'}])}
                            />
                        </div>
                        <div className="page-right-body-search-cell" style={{minWidth:'615px'}}>
                            <FormDateRangeQuickPickerField
                                name="time"
                                label="退订时间："
                                props={{
                                    width:'120px',
                                    format:"YYYY-MM-DD",
                                    canClear:true,
                                    preset:[
                                        {
                                            text: '今天',
                                            value: 0,
                                        },
                                        {
                                            text: '昨天',
                                            value: 1,
                                        },
                                        {
                                            text: '本月',
                                            value: Utils.returndates(3),
                                        },
                                        {
                                            text: '上月',
                                            value: Utils.returndates(5),
                                        }
                                    ],
                                    min:'2023-12-01 00:00:00'
                                }}
                                defaultValue={['','']}
                                initialValue={editData.time}

                            />
                        </div>
                        <Button icon="search" type="primary" htmlType={"submit"}>搜索</Button>
                    </div>
                </div>
            </Form>
        </div>

    )
}
