import React from 'react';
import Header from '../../../../component/header'
import {
    Notify,
    Button,
    NumberInput,
    BlockLoading,
    Select,
    Grid, Sweetalert
} from 'zent';

import Service from "../../../../config/Service";
import Utils from "../../../../config/Utils";
export default class MainCustomerSetUser extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            gridLoading:false,
            dataList:[],
            selectedRowKeys:[],
            messLoading:false,
            userIdValue:null,
            userId:0,
            userData:[],
            productList:[],
            productData:null
        }
    }
    componentDidMount() {
        this.props.parent.onMenuChange(120800);
    }
    onUserBind=(id)=>{
        const _this=this;
        const {messLoading}=this.state;
        if(messLoading)
        {
            return false;
        }
        this.setState({
            messLoading:true
        })
        Service.Post({
            name:"usermess",
            action:"news",
            data:{
                id:id
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    const userid=result.id;
                    const userDianpu=result.dianpu;
                    const userdata=result.list;
                    _this.setState({
                        messLoading:false,
                        userId:userid,
                        userData:userdata
                    })
                    _this.onUserResultBind(userid);
                    _this.onProductBind(userDianpu);
                }
                else
                {
                    Notify.warn(result.retmsg);
                    _this.setState({
                        messLoading:false
                    })
                }
            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    messLoading:false
                })
            }
        })
    }
    onUserResultBind=(id)=>{
        const _this=this;
        this.setState({
            gridLoading:true
        })
        Service.Post({
            name:"adminresults",
            action:"pagelistbyuser",
            data:{
                pageindex:1,
                pagesize:1000,
                id:id
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        dataList:result.list,
                        gridLoading:false
                    })
                }
                else
                {
                    _this.setState({
                        dataList:[],
                        gridLoading:false
                    })
                }
            },
            fail(){
                _this.setState({
                    dataList:[],
                    gridLoading:false
                })
            }
        })
    }
    onProductBind=(dianpu)=>{
        const _this=this;
        if(dianpu<=0)
        {
            return false;
        }
        Service.Post({
            name:"adminproduct",
            action:"listbydianpu",
            data:{
                dianpu:dianpu
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        productList:result.list
                    })
                }
                else
                {
                    _this.setState({
                        productList:[]
                    })
                }
            },
            fail(){
                _this.setState({
                    productList:[]
                })
            }
        })
    }
    onSelectButtonClick=()=>{

        const {userIdValue}=this.state;
        if(userIdValue!=null&&userIdValue>0)
        {
            this.onUserBind(userIdValue);
        }
        else
        {
            Notify.warn("请输入编号");
        }
    }
    onCloseSelectButtonClick=()=>{
        this.setState({
            dataList:[],
            selectedRowKeys:[],
            messLoading:false,
            userIdValue:null,
            userId:0,
            userData:[],
            productList:[]
        })
    }
    onSelectInputChange=(e)=>{
        this.setState({
            userIdValue:e,
        })
    }
    onSetMessClick=(action)=>{
        const _this=this;
        const {userId}=this.state;
        Sweetalert.confirm({
            content:'请确认是否执行该操作？',
            title: '请确认操作',
            onConfirm: () => new Promise((resolve,reject) => {
                Service.Post({
                    name:"usermess",
                    action:action,
                    data:{
                        id:userId,
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("操作成功");
                            resolve();
                            _this.onUserBind(userId);
                        }
                        else
                        {
                            Notify.warn(result.retmsg);
                            reject();
                        }
                    },
                    fail(){
                        Notify.error("网络链接失败");
                        reject();
                    }
                })
            }),
            parentComponent:this
        });
    }
    onSetMessInputClick=(action)=>{
        const _this=this;
        const {userId}=this.state;
        const value=this.refs["editinput"+action].innerHTML;
        if(value&&value.length>0)
        {
            Sweetalert.confirm({
                content:'请确认是否执行该操作？',
                title: '请确认操作',
                onConfirm: () => new Promise((resolve,reject) => {
                    Service.Post({
                        name:"usermess",
                        action:action,
                        data:{
                            id:userId,
                            text:value
                        },
                        success(result)
                        {
                            if(result.retcode==200)
                            {
                                Notify.success("操作成功");
                                resolve();
                                _this.onUserBind(userId);
                            }
                            else
                            {
                                Notify.warn(result.retmsg);
                                reject();
                            }
                        },
                        fail(){
                            Notify.error("网络链接失败");
                            reject();
                        }
                    })
                }),
                parentComponent:this
            });
        }
        else
        {
            Notify.warn("请输入新内容");
        }
    }
    onUpdateClick=()=>{
        const _this=this;
        const {productData,selectedRowKeys,userId}=this.state;
        if(selectedRowKeys.length>0&&userId>0&&productData!=null)
        {
            const rid=selectedRowKeys[0];
            Sweetalert.confirm({
                content:'请确认是否执行该操作？',
                title: '请确认操作',
                onConfirm: () => new Promise((resolve,reject) => {
                    Service.Post({
                        name:"usermess",
                        action:'edit',
                        data:{
                            id:userId,
                            rid:rid,
                            pid:productData.key
                        },
                        success(result)
                        {
                            if(result.retcode==200)
                            {
                                Notify.success("操作成功");
                                resolve();
                                _this.onUserBind(userId);
                            }
                            else
                            {
                                Notify.warn(result.retmsg);
                                reject();
                            }
                        },
                        fail(){
                            Notify.error("网络链接失败");
                            reject();
                        }
                    })
                }),
                parentComponent:this
            });
        }
        else
        {
            Notify.warn("请选择消费记录和要更改为的产品");
        }
    }
    onDeleteClick=()=>{
        const _this=this;
        const {selectedRowKeys,userId}=this.state;
        if(selectedRowKeys.length>0&&userId>0)
        {
            const rid=selectedRowKeys[0];
            Sweetalert.confirm({
                content:'删除后无法恢复，请确认是否执行该操作？',
                title: '请确认操作',
                onConfirm: () => new Promise((resolve,reject) => {
                    Service.Post({
                        name:"usermess",
                        action:'delete',
                        data:{
                            id:userId,
                            rid:rid
                        },
                        success(result)
                        {
                            if(result.retcode==200)
                            {
                                Notify.success("操作成功");
                                resolve();
                                _this.onUserBind(userId);
                            }
                            else
                            {
                                Notify.warn(result.retmsg);
                                reject();
                            }
                        },
                        fail(){
                            Notify.error("网络链接失败");
                            reject();
                        }
                    })
                }),
                parentComponent:this
            });
        }
        else
        {
            Notify.warn("请选择消费记录");
        }
    }
    onProductSelectChange=(e)=>{
        this.setState({
            productData:e
        })
    }
    getGridColumns=()=>{
        const columns=[
            {
                title:'编号',
                name:'id',
                width:'150px',
            },
            {
                title:'消费金额',
                name:'money',
                width:'200px'
            },
            {
                title:'产品名称',
                name:'product'
            },
            {
                title:'增加天数',
                name:'day',
                width:'100px'
            },
            {
                title:'业务员',
                name:'yewuyuan',
                width:"150px"
            },
            {
                title:'消费时间',
                name:'time',
                width:'200px'
            }
        ]
        return columns;
    }
    render() {
        const {messLoading,gridLoading,selectedRowKeys,dataList,userIdValue,userId,userData,productList,productData}=this.state;
        return(
            <div>
                <Header data={[{name:'用户'},{name:'用户变更'}]}/>
                <div className="page-right-body">
                    <div className="page-right-body-hd"></div>
                    <div className="page-right-body-bd">
                        <div className="page-right-body-form">
                            <div className="page-right-body-form-cell">
                                用户编号：
                            </div>
                            <div className="page-right-body-form-cell">
                                <NumberInput placeholder="请输入编号" onChange={this.onSelectInputChange} integer value={userIdValue}  maxLength={9}/>
                            </div>
                            <div className="page-right-body-form-cell">
                                <Button icon={"search"} type={"primary"} disabled={userId>0} loading={messLoading} onClick={this.onSelectButtonClick}>查询</Button>
                            </div>
                            {userId>0&&(
                                <div className="page-right-body-form-cell">
                                    <Button icon={"close"} type={"default"}  onClick={this.onCloseSelectButtonClick}>关闭查询</Button>
                                </div>
                            )}
                        </div>
                        <BlockLoading loading={messLoading} >
                            {userId>0&&(
                                <div className="padding-top-20">
                                    <div><b>用户信息</b></div>
                                    {userData.map((item,index)=>(
                                        <div className="page-body-me-cell" key={index}>
                                            <div className="page-body-me-cell-hd">
                                                {item.title}：
                                            </div>
                                            <div className="page-body-me-cell-bd">
                                                {item.content}
                                            </div>
                                            {item.button&&(
                                                <div className="page-body-me-cell-ft">
                                                    <Button type="error" size={"small"} onClick={this.onSetMessClick.bind(this,item.action)}>{item.button}</Button>
                                                </div>
                                            )}
                                            {item.input&&(
                                                <div className="page-body-me-cell-ft">
                                                    <div className="page-body-me-cell">
                                                        <div className="page-body-me-cell-bd">
                                                            <div className="me-editdiv" ref={"editinput"+item.action} contentEditable={true}></div>
                                                        </div>
                                                        <div className="page-body-me-cell-ft">
                                                            <Button type="error" size="small" onClick={this.onSetMessInputClick.bind(this,item.action)}>{item.input}</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </BlockLoading>
                        {dataList.length>0&&(
                            <div className="padding-top-20">
                                <div><b>缴费记录</b></div>
                                <div className="padding-topbottom-10">
                                    <Grid
                                        columns={this.getGridColumns()}
                                        datasets={dataList}
                                        loading={gridLoading}
                                        ellipsis
                                        size={"medium"}
                                        selection={{
                                            isSingleSelection: true,
                                            selectedRowKeys: selectedRowKeys,
                                            onSelect: (selectedRowKeys, selectedRows, currentRow) => {
                                                this.setState({
                                                    selectedRowKeys,
                                                })
                                            }
                                        }}
                                        rowKey="id"/>
                                </div>
                                <div className="padding-topbottom-10">
                                    <Select
                                        options={productList}
                                        placeholder={"变更请选择产品"}
                                        value={productData}
                                        onChange={this.onProductSelectChange}
                                    />
                                </div>
                                <div>
                                    <Button icon={"settings-o"} type={"warning"} onClick={this.onUpdateClick}>变更</Button>
                                    <Button icon={"remove-o"} type={"error"} onClick={this.onDeleteClick}>删除</Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}