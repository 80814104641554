import React from 'react';
import Header from '../../../../component/header'
import {
    Button,
    Grid,
    Notify,
    DateRangeQuickPicker,
    Drawer,
    Dialog
} from 'zent'
import Service from "../../../../config/Service";
import Utils from "../../../../config/Utils";
import DianpuResultView from "../../../../component/DianpuResultView";
import DianpuResultListView from "../../../../component/DianpuResultListView";
const {openDialog}=Dialog;
export default class MainFinancialResultsIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            gridLoading:false,
            dataList:[],
            oldDataList:[],
            searchTimeValue:['',''],
            dayMoney:'0.00',
            weekMoney:'0.00',
            monthMoney:'0.00',
            yearMoney:'0.00',
            searchMoney:'0.00',
            drawerDianpu:0,
            drawerDianpuName:'',
            drawerVisible:false,
            sortBy:'id',
            sortType:''
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(140100);
        const nowtime=Utils.formatTime(new Date());
        const datetimes=[nowtime,nowtime]
        this.setState({
            searchTimeValue:datetimes
        })
        this.moneyBind();
        this.dataBind(datetimes);
    }
    moneyBind=()=>{
        const _this=this;
        Service.Post({
            name:"adminresults",
            action:"moneybytime",
            data:{},
            success(result)
            {
                if(result.retcode==200)
                {
                    const data=result.data;
                    _this.setState({
                        dayMoney:data.day,
                        weekMoney:data.week,
                        monthMoney:data.month,
                        yearMoney:data.year
                    })
                }
            }
        })
    }
    dataBind=(time)=>{
        const _this=this;
        this.setState({
            gridLoading:true,
            sortBy:'id',
            sortType:''
        })
        Service.Post({
            name:"adminresults",
            action:"listbytime",
            data:{
                startdate:time[0],
                enddate:time[1]
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        dataList:result.list,
                        oldDataList:result.list,
                        searchMoney:result.money,
                        gridLoading:false
                    })
                }
                else
                {
                    _this.setState({
                        dataList:[],
                        gridLoading:false,
                        searchMoney:'0.00'
                    })
                }
            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    dataList:[],
                    gridLoading:false,
                    searchMoney:'0.00'
                })
            }
        })

    }
    getTime(res) {
        const now=new Date();
        const nowTime=now.getTime();
        const oneDayTime=24*60*60*1000;
        const formatNowDate=Utils.formatTime(new Date());
        if(res===1)
        {
            return [formatNowDate,formatNowDate];
        }
        else if(res===2)
        {
            const day=now.getDay()>0?now.getDay():7;
            const MondayTime=nowTime-(day-1)*oneDayTime;
            const formatDate=Utils.formatTime(new Date(MondayTime));
            return [formatDate,formatNowDate];
        }
        else if(res===3)
        {
            const monthFirstDate=Utils.formatTime(now,'yyyy-MM-01');
            return [monthFirstDate,formatNowDate];
        }
        else if(res===4)
        {
            const monthFirstDate=Utils.formatTime(now,'yyyy-01-01');
            return [monthFirstDate,formatNowDate];
        }
        else if(res===5)
        {
            const yuedi=Utils.formatTime(new Date(now.getFullYear(),now.getMonth(),0));
            const yuechu=Utils.formatTime(new Date(yuedi),'yyyy-MM-01');
            return [yuechu,yuedi];
        }
        else
        {
            return ['','']
        }
    }
    onTimeMoneyClick=(e)=>{
        const datetime=this.getTime(e);
        this.setState({
            searchTimeValue:datetime
        })
        this.dataBind(datetime);
    }
    onSearchTimeChange=(e)=>{
        this.setState({
            searchTimeValue:e,
        })
    }
    onSearchButtonClick=(e)=>{
        const datetime=this.state.searchTimeValue;
        this.dataBind(datetime)
    }
    onGridChange=(conf)=>{
        const { dataList,oldDataList } = this.state;
        const { sortType, sortBy } = conf;
        let sortDataList = JSON.parse(JSON.stringify(dataList));
        if (sortType === 'asc') {
            sortDataList = dataList.sort((a, b) => a[sortBy] - b[sortBy]);
        } else if (sortType === 'desc') {
            sortDataList = dataList.sort((a, b) => b[sortBy] - a[sortBy]);
        }
        else {
           sortDataList=JSON.parse(JSON.stringify(oldDataList));;
        }
        this.setState({
            sortType:conf.sortType,
            sortBy:conf.sortBy,
            dataList:sortDataList
        })
    }
    onGridItemClick=(id,name)=>{
        this.setState({
            drawerVisible:true,
            drawerDianpu:id,
            drawerDianpuName:name
        })
    }
    onGridItemNameClick=(id,name)=>{
        const dialogid='dianpudialog'+id;
        openDialog({
            parentComponent:this,
            title:'【'+name+'】业绩总览【'+this.state.searchTimeValue[0]+' 至 '+this.state.searchTimeValue[1]+'】',
            dialogId:dialogid,
            maskClosable:false,
            closeBtn:true,
            style:{
                width:'1050px'
            },
            children:(
                <DianpuResultListView dataid={id} time={this.state.searchTimeValue}/>
            )
        })
    }
    onDrawerClose=()=>{
        this.setState({
            drawerVisible:false,
            drawerDianpu:0,
            drawerDianpuName:''
        })
    }
    getGridColumns=()=>{
        const listColumns=[
            {
                title:'店铺名称',
                name:'id',
                needSort: true,
                width:'200px',
                bodyRender:data => {
                    return(
                        <span className="color-link-black font16" onClick={this.onGridItemNameClick.bind(this,data.id,data.name)}>{data.name}</span>
                    )
                }
            },
            {
                title:'业绩总额',
                name:'money',
                textAlign: 'center',
                width: '200px',
                needSort: true,
                bodyRender:data => {
                    return<span className={"font16"}>￥{data.money}</span>
                }
            },
            {
                title:'总订单数',
                name:'count',
                textAlign: 'center',
                width: '200px',
                needSort: true,
                bodyRender:data => {
                    return<span className={"font16"}>{data.count}</span>
                }
            },
            {
                title:'整卡单数',
                name:'zkcount',
                textAlign: 'center',
                width: '150px',
            },
            {
                title:'定金单数',
                name:'djcount',
                textAlign: 'center',
                width: '150px',
            },
            {
                title:'补费单数',
                name:'bfcount',
                textAlign: 'center',
                width: '150px',
            },
            {
                title:'退卡单数',
                name:'tkcount',
                textAlign: 'center',
                width: '150px',
            },
            {
                title:'操作',
                textAlign:'right',
                bodyRender:(data)=>{
                    return <Button type="text" onClick={this.onGridItemClick.bind(this,data.id,data.name)}>查看详情</Button>
                }
            }
        ];
        return listColumns;
    }
    render() {
        const {dayMoney,weekMoney,monthMoney,yearMoney,searchMoney}=this.state;
        return(
            <>
                <Header data={[{name:'财务'},{name:'业绩详情'}]}/>
                <div className="page-right-body">
                    <div className="page-right-body-hd">
                        <div className="page-right-body-hd-hd">
                            <div className="page-financial-cells">
                                <div className="page-financial-cell" onClick={this.onTimeMoneyClick.bind(this,1)}>
                                    <div className="page-financial-cell-hd">今日业绩</div>
                                    <div className="page-financial-cell-bd">
                                        <span>￥</span><b>{dayMoney}</b>
                                    </div>
                                </div>
                                <div className="page-financial-cell" onClick={this.onTimeMoneyClick.bind(this,2)}>
                                    <div className="page-financial-cell-hd">本周业绩</div>
                                    <div className="page-financial-cell-bd">
                                        <span>￥</span><b>{weekMoney}</b>
                                    </div>
                                </div>
                                <div className="page-financial-cell" onClick={this.onTimeMoneyClick.bind(this,3)}>
                                    <div className="page-financial-cell-hd">本月业绩</div>
                                    <div className="page-financial-cell-bd">
                                        <span>￥</span><b>{monthMoney}</b>
                                    </div>
                                </div>
                                <div className="page-financial-cell" onClick={this.onTimeMoneyClick.bind(this,4)}>
                                    <div className="page-financial-cell-hd">本年业绩</div>
                                    <div className="page-financial-cell-bd">
                                        <span>￥</span><b>{yearMoney}</b>
                                    </div>
                                </div>
                                <div className="page-financial-cell" style={{cursor:'default'}}>
                                    <div className="page-financial-cell-hd">时间范围业绩</div>
                                    <div className="page-financial-cell-bd">
                                        <span>￥</span><b>{searchMoney}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-right-body-bd">
                        <div className="page-financial-cells">
                            <DateRangeQuickPicker
                                onChange={this.onSearchTimeChange}
                                value={this.state.searchTimeValue}
                                format="YYYY-MM-DD"
                                canClear={false}
                                preset={[
                                    {
                                        text: '昨天',
                                        value: 1,
                                    },
                                    {
                                        text: '近7天',
                                        value: 7,
                                    },
                                    {
                                        text: '近30天',
                                        value: 30,
                                    },
                                    {
                                        text: '上月',
                                        value: Utils.returndates(5),
                                    }
                                ]}
                            />
                            <Button type={"primary"} onClick={this.onSearchButtonClick.bind()} className="margin-left-50" icon={"search"}>搜索</Button>
                        </div>
                    </div>
                    <div className="page-right-body-bd">
                        <Grid
                            columns={this.getGridColumns()}
                            datasets={this.state.dataList}
                            loading={this.state.gridLoading}
                            onChange={this.onGridChange}
                            sortBy={this.state.sortBy}
                            sortType={this.state.sortType}
                            ellipsis
                            size={"medium"}
                            rowKey="id"/>
                    </div>
                </div>
                <Drawer
                    visible={this.state.drawerVisible}
                    title={"【"+this.state.drawerDianpuName+"】店铺业绩 【"+this.state.searchTimeValue[0]+" 至 "+this.state.searchTimeValue[1]+"】"}
                    onClose={this.onDrawerClose.bind(this)}
                    maskClosable
                    closeOnESC
                >
                    <DianpuResultView dianpu={this.state.drawerDianpu} time={this.state.searchTimeValue}/>
                </Drawer>
            </>
        )
    }
}
