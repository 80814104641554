import React from 'react';
import {Grid,Dialog,Button,Notify,Sweetalert,Tag,Drawer,Form,FormSelectField,FormSwitchField,FormStrategy,BlockLoading,Timeline,Link} from "zent";
import Service from "../config/Service";
const {closeDialog,openDialog}=Dialog;
export default class WorkMesslistView extends React.Component{
    constructor(props, context) {
        super(props, context);
        this.state={
            gridPageCurrent:1,
            gridPageTotal:0,
            gridLoading:false,
            gridPageSize:15,
            dataList:[],
            drawerData:{},
            drawerVisible:false
        }
    }
    componentWillMount() {
        this.onDataBind();
    }
    onDataBind=(pageindex)=>{
        const _this=this;
        if(this.props.viewid>0)
        {
            this.setState({
                gridLoading:true
            });
            const isday=!_this.props.isday?0:_this.props.isday;
            Service.Post({
                name:"webusersworks",
                action:_this.props.viewtype==1?"listbyzhiwei":"listbyuser",
                data:{
                    id:_this.props.viewid,
                    pageindex:pageindex,
                    pagesize:_this.state.gridPageSize,
                    isday:isday
                },
                success(result){
                    if(result.retcode==200)
                    {
                        _this.setState({
                            dataList:result.list,
                            gridPageTotal:result.count,
                            gridLoading:false
                        })
                    }
                    else
                    {
                        _this.setState({
                            dataList:[],
                            gridPageTotal:0,
                            gridLoading:false
                        })
                    }
                },
                fail(){
                    _this.setState({
                        dataList:[],
                        gridPageTotal:0,
                        gridLoading:false
                    })
                }
            })
        }else
        {
            _this.setState({
                dataList:[],
                gridPageTotal:0,
                gridLoading:false
            })
        }
    }
    onCallBack=()=>{
        this.onDataBind(this.state.gridPageCurrent);
        if(typeof this.props.onChange=="function")
        {
            this.props.onChange()
        }
    }
    onGridChange=(e)=>{
        const current=e.current;
        this.setState({
            gridPageCurrent:current
        })
        this.onDataBind(current)
    }
    onGridItemMoreClick=(data)=>{
        this.setState({
            drawerData:data,
            drawerVisible:true
        })
    }
    onGridItemJGBtnClick=(data)=>{
        const dialogid='jgdialog'+data.id;
        openDialog({
            parentComponent:this,
            title:'员工解雇',
            dialogId:dialogid,
            maskClosable:false,
            closeBtn:false,
            children:(
                <JieGuFormView data={data} onChange={this.onCallBack}/>
            )
        })
    }
    onGridItemJJLQBtnClick=(data)=>{
        const _this=this;
        Sweetalert.confirm({
            content:'操作成功后将无法恢复，请确认',
            title: '请确认操作',
            onConfirm: () => new Promise((resolve,reject) => {
                Service.Post({
                    name:"WebUsersWorks",
                    action:"edit",
                    data:{
                        id:data.id,
                        uid:data.uid,
                        zid:data.zid,
                        state:29,
                        text:'商家拒绝录取',
                        badrecord:0
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("操作成功");
                            resolve();
                            _this.onCallBack();
                        }
                        else
                        {
                            Notify.warn(result.retmsg);
                            reject();
                        }
                    },
                    fail(er){
                        console.log(er);
                        Notify.error("网络链接失败");
                        reject();
                    }
                })
            }),
            parentComponent:this
        });
    }
    onDrawerClose=(e)=>{
        this.setState({
            drawerData:{},
            drawerVisible:false
        })
    }
    getGridColumns(){
        const zhiweiColumns=[
            {
                title:'姓名',
                name:'name',
                width:'100px',
                textAlign: 'center',
                bodyRender:data => {
                    return(
                        <div>
                            <div><b>{data.name}</b></div>
                            <div>
                                <Tag theme={"blue"}>{data.sex}</Tag>
                                <Tag theme={"green"} className={"margin-left-5"}>{data.age}</Tag>
                            </div>
                        </div>
                    )
                }
            },
            {
                title:'电话',
                name:'tel',
            },
            {
                title:'签到',
                width:'80px',
                textAlign: 'center',
                bodyRender:(data)=>{
                    return<div>
                        <Tag theme={"blue"}> {data.qdcount}</Tag>次
                    </div>
                }
            },
            {
                title:'不良记录',
                width:'80px',
                textAlign: 'center',
                bodyRender:(data)=>{
                    return<div>
                        <Tag theme={"yellow"}> {data.blcount}</Tag>条
                    </div>
                }
            },
            {
                title:'报名时间',
                name:'time',
                width:'135px',
                textAlign: 'center',
            },
            {
                title:'当前状态',
                width:'100px',
                textAlign: 'center',
                bodyRender:(data)=>{
                    return<div>
                        {data.state==10&&(<Tag theme={"green"}>报名成功</Tag>)}
                        {data.state==20&&(<Tag theme={"blue"}>成功录取</Tag>)}
                        {data.state==29&&(<Tag theme={"yellow"}>拒绝录取</Tag>)}
                        {data.state==98&&(<Tag theme={"yellow"}>已解雇</Tag>)}
                        {data.state==99&&(<Tag theme={"red"}>已退订</Tag>)}
                    </div>
                }
            },
            {
                title:'操作',
                width:'170px',
                textAlign: 'center',
                bodyRender:(data)=>{
                    return(
                        <div>
                            <Button onClick={this.onGridItemMoreClick.bind(this,data)} type={"text"}>详情</Button>
                            {data.state==20&&(
                                <Button type={"error"} onClick={this.onGridItemJGBtnClick.bind(this,data)}>解雇</Button>
                            )}
                            {data.state==10&&(
                                <Button type={"warning"} onClick={this.onGridItemJJLQBtnClick.bind(this,data)}>拒绝录取</Button>
                            )}
                        </div>
                    )
                }
            }]
        const userColumns=[
            {
                title:'编号',
                width:'90px',
                bodyRender:(data)=>{
                    return <Link href={"/main/work/work?text="+data.zid} to={"/main/work/work?text="+data.zid}>{data.zid}</Link>
                }
            },
            {
                title:'职位',
                name:'title',
            },
            {
                title:'签到',
                width:'80px',
                textAlign: 'center',
                bodyRender:(data)=>{
                    return<div>
                        <Tag theme={"blue"}> {data.qdcount}</Tag>次
                    </div>
                }
            },
            {
                title:'不良记录',
                width:'80px',
                textAlign: 'center',
                bodyRender:(data)=>{
                    return<div>
                        <Tag theme={"yellow"}> {data.blcount}</Tag>条
                    </div>
                }
            },
            {
                title:'报名时间',
                name:'time',
                width:'200px',
                textAlign: 'center',
            },
            {
                title:'当前状态',
                width:'100px',
                textAlign: 'center',
                bodyRender:(data)=>{
                    return<div>
                        {data.state==10&&(<Tag theme={"green"}>报名成功</Tag>)}
                        {data.state==20&&(<Tag theme={"blue"}>成功录取</Tag>)}
                        {data.state==21&&(<Tag theme={"yellow"}>拒绝录取</Tag>)}
                        {data.state==98&&(<Tag theme={"yellow"}>已解雇</Tag>)}
                        {data.state==99&&(<Tag theme={"red"}>已退订</Tag>)}
                    </div>
                }
            },
            {
                title:'操作',
                width:'80px',
                textAlign: 'center',
                bodyRender:(data)=>{
                    return <Button onClick={this.onGridItemMoreClick.bind(this,data)} type={"text"}>详情</Button>
                }
            }
        ]
        if(this.props.viewtype==1)
        {
            return zhiweiColumns;
        }
        else
        {
            return userColumns;
        }
    }
    render() {
        return(
            <div>
                <div className="page-right-body-hd">
                    <div className="page-right-body-hd-hd">

                    </div>
                    <div className="page-right-body-hd-bd">

                    </div>
                </div>
                <div className="page-right-body-bd">
                    <Grid
                        columns={this.getGridColumns()}
                        datasets={this.state.dataList}
                        pageInfo={{
                            current: this.state.gridPageCurrent,
                            pageSize:this.state.gridPageSize,
                            total: this.state.gridPageTotal,
                            pageSizeOptions: [this.state.gridPageSize],
                        }}
                        loading={this.state.gridLoading}
                        onChange={this.onGridChange}
                        ellipsis
                        size={"medium"}
                        rowKey="id"/>
                </div>
                <Drawer
                    visible={this.state.drawerVisible}
                    onClose={this.onDrawerClose}
                    closeOnESC
                    maskClosable
                    size={"small"}
                    title="工作详情"
                >
                    <UserMessTimeLine data={this.state.drawerData} onChange={this.onCallBack}/>
                </Drawer>
            </div>
        )
    }
}
function UserMessTimeLine(props)
{
    const uid=props.data.uid||0;
    const zid=props.data.zid||0;
    const [messList,setMessList]=React.useState([]);
    const [drawerLoading,setDrawerLoading]=React.useState(true);
    const retSize=(item)=>{
        if(item.state==30&&item.badrecord==1)
        {
            return '90px';
        }
        else
        {
            return ''
        }
    }
    const dataBind=()=> {
        if(uid>0&&zid>0)
        {
            setDrawerLoading(true);
            Service.Post({
                name:"webusersworksmess",
                action:"list",
                data:{
                    id:uid,
                    zid:zid
                },
                success(result)
                {
                    if(result.retcode==200)
                    {
                        setMessList(result.list);
                    }
                    else
                    {
                        setMessList([]);
                    }
                },
                fail(){
                    setMessList([]);
                },
                complete(){
                    setDrawerLoading(false);
                }
            })
        }
    }
    const onButtonClick=(id)=>{
        Sweetalert.confirm({
            content:'操作成功后将无法恢复，请确认',
            title: '请确认操作',
            onConfirm: () => new Promise((resolve,reject) => {
                Service.Post({
                    name:"webusersworksmess",
                    action:"badrecord",
                    data:{
                        id:id
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("已撤销");
                            resolve();
                            dataBind();
                            if(typeof props.onChange=="function")
                            {
                                props.onChange();
                            }
                        }
                        else
                        {
                            Notify.warn(result.retmsg);
                            reject();
                        }
                    },
                    fail(){
                        Notify.error("网络链接失败");
                        reject();
                    }
                })
            }),
            parentComponent:this
        });
    }
    React.useEffect(() => {
        dataBind();
    }, []);
    return(
        <BlockLoading loading={drawerLoading}>
            <div  style={{minHeight:'100px'}}>
                <Timeline type="vertical" size={"auto"}>
                    {messList.map((item,index)=>(
                        <Timeline.Item
                            size={(index+1)==messList.length?'0px':retSize(item)}
                            tip={item.title}
                            dotColor="#35bed4"
                            lineColor="#CBF5FA"
                            key={index}
                            label={
                                <div className={"mee__timeline-label"}>
                                    <div className="mee__timeline-label-hd">
                                        <div className="mee__timeline-label-hd-title">{item.title}</div>
                                        <div className="mee__timeline-label-hd-label">{item.time}</div>
                                    </div>
                                    <div className="mee__timeline-label-bd">{item.text}</div>
                                    {(item.state==30&&item.badrecord==1)&&(
                                        <div className="mee__timeline-label-ft">
                                            <Button type={"warning"} onClick={onButtonClick.bind(this,item.id)}>撤销本次不良记录</Button>
                                        </div>
                                    )}
                                    {(item.state==98&&item.badrecord==1&&item.kgrole)&&(
                                        <div className="mee__timeline-label-ft">
                                            <Button type={"warning"} onClick={onButtonClick.bind(this,item.id)}>撤销本次不良记录</Button>
                                        </div>
                                    )}
                                </div>
                            }/>
                    ))}
                </Timeline>
            </div>
        </BlockLoading>
    )
}
function JieGuFormView(props) {
    const dialogid='jgdialog'+props.data.id;
    const form=Form.useForm(FormStrategy.View);
    const [loading,setLoading]=React.useState(false);
    const onSubmit=React.useCallback(form => {
        setLoading(true);
        const value = form.getSubmitValue();
        return new Promise(resolve => {
            Service.Post({
                name:"WebUsersWorks",
                action:"edit",
                data:{
                    id:props.data.id,
                    uid:props.data.uid,
                    zid:props.data.zid,
                    state:98,
                    text:'商家解雇；原因：'+value.yuanyin.key,
                    badrecord:value.badrecord==true?1:2
                },
                success(result)
                {
                    if(result.retcode==200)
                    {
                        Notify.success("操作成功");
                        props.onChange();
                        closeDialog(dialogid);
                    }
                    else
                    {
                        Notify.warn(result.retmsg);
                    }
                },
                fail(err){
                    Notify.error("网络连接失败");
                },
                complete(){
                    setLoading(false);
                }
            })
        });
    },[])
    return(
        <Form form={form} layout="horizontal"onSubmit={onSubmit} disabled={loading}>
            <FormSelectField
                label={"解雇原因："}
                name={"yuanyin"}
                required
                props={{
                    width:'240px',
                    options:[{key:'旷工',text:'旷工'},{key:'早退',text:'早退'},{key:'迟到',text:'迟到'},{key:'不符合职位要求',text:'不符合职位要求'}]
                }}
            />
            <FormSwitchField
                label={"不良记录："}
                name={"badrecord"}
                defaultValue={false}
            />
            <div style={{textAlign:'right'}}>
                <Button onClick={()=>closeDialog(dialogid)}>取消</Button>
                <Button type="primary" htmlType={"submit"} loading={loading}>提交</Button>
            </div>
        </Form>
    )
}
