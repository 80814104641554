import React from 'react';
import Header from '../../../../component/header'
import {Select, BlockLoading, Dialog, CombinedDateRangePicker} from 'zent'
import Service from "../../../../config/Service";
import "../../../../style/css/main/data/simple/index.css"
import DataMerchantListView from "../../../../component/DataMerchantListView";
import DataWorkListView from "../../../../component/DataWorkListView";
import DataAdminSignListView from "../../../../component/DataAdminSignListView";
const {closeDialog,openDialog}=Dialog
const _NowDate=new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate();
export default class MainDataSimpleIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            dianpuList:[],
            dianpuData: {},
            loading:false,
            merchantLoading:false,
            workLoading:false,
            merchantData:{
                all:0,
                state:0,
                yyzz:0,
                sqs:0,
                day:0,
                week:0,
                month:0,
                shz:0,
                wtg:0
            },
            workData:{
                day:0,
                week:0,
                month:0,
                yxday:0,
                yxweek:0,
                yxmonth:0,
                zp:0,
                xz:0,
                yz:0
            },
            zpData:{
                zp:0,
                xz:0,
                yb:0,
            },
            yxzpData:{
                zp:0,
                xz:0,
                yb:0,
            },
            zpsjDate:[_NowDate,_NowDate],
            yxzpsjDate:[_NowDate,_NowDate],
            zpLoading:false,
            yxzpLoading:false,
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(150100);
        this.onDianpuDataBind();
    }
    onDianpuDataBind=()=>{
        const _this=this;
        this.setState({
            loading:true
        })
        Service.Post({
            name:"admindianpu",
            action:"listbyadmin",
            data:{},
            success(result)
            {
                if(result.retcode==200)
                {
                    if(result.list.length>0)
                    {
                        const dianpudata=result.list[0];
                        _this.setState({
                            dianpuData:dianpudata,
                            dianpuList:result.list,
                            loading:false
                        })
                        _this.onDataBind();
                    }
                    else
                    {
                        _this.setState({
                            dianpuList:[],
                            loading:false
                        })
                    }
                }
                else
                {
                    _this.setState({
                        dianpuList:[],
                        loading:false
                    })
                }
            },
            fail()
            {
                _this.setState({
                    dianpuList:[],
                    loading:false
                })
            }
        })
    }
    onDataBind=()=>{
        setTimeout(()=>{
            this.onMerchantDataBind();
            this.onWorkDataBind();
            this.onZpDataBind();
            this.onYxzpDataBind();
        },100)
    }
    onMerchantDataBind=()=>{
        const {dianpuData}=this.state;
        const _this=this;
        if(dianpuData.key)
        {
            this.setState({
                merchantLoading:true
            })
            Service.Post({
                name:"DataStatistical",
                action:"merchant",
                data:{
                    dianpu:dianpuData.key
                },
                success(result)
                {
                    if(result.retcode==200)
                    {
                        _this.setState({
                            merchantData:result.data,
                            merchantLoading:false
                        })
                    }
                    else
                    {
                        _this.setState({
                            merchantData:{
                                all:0,
                                state:0,
                                yyzz:0,
                                sqs:0,
                                day:0,
                                week:0,
                                month:0,
                                shz:0,
                                wtg:0
                            },
                            merchantLoading:false
                        })
                    }
                },
                fail(){
                    _this.setState({
                        merchantData:{
                            all:0,
                            state:0,
                            yyzz:0,
                            sqs:0,
                            day:0,
                            week:0,
                            month:0,
                            shz:0,
                            wtg:0
                        },
                        merchantLoading:false
                    })
                }
            })
        }
    }
    onWorkDataBind=()=>{
        const {dianpuData}=this.state;
        const _this=this;
        if(dianpuData.key)
        {
            this.setState({
                workLoading:true
            })
            Service.Post({
                name:"DataStatistical",
                action:"work",
                data:{
                    dianpu:dianpuData.key
                },
                success(result)
                {
                    if(result.retcode==200)
                    {
                        _this.setState({
                            workData:result.data,
                            workLoading:false
                        })
                    }
                    else
                    {
                        _this.setState({
                            workData:{
                                day:0,
                                week:0,
                                month:0,
                                yxday:0,
                                yxweek:0,
                                yxmonth:0,
                                zp:0,
                                xz:0,
                                yz:0
                            },
                            workLoading:false
                        })
                    }
                },
                fail(){
                    _this.setState({
                        workData:{
                            day:0,
                            week:0,
                            month:0,
                            yxday:0,
                            yxweek:0,
                            yxmonth:0,
                            zp:0,
                            xz:0,
                            yz:0
                        },
                        workLoading:false
                    })
                }
            })
        }
    }
    onZpDataBind(){
        const {dianpuData,zpsjDate}=this.state;
        const starttime=zpsjDate[0];
        const endtime=zpsjDate[1];
        const _this=this;
        if(dianpuData.key)
        {
            this.setState({
                zpLoading:true
            })
            Service.Post({
                name:"DataStatistical",
                action:"zpdata",
                data:{
                    dianpu:dianpuData.key,
                    start:starttime,
                    end:endtime
                },
                success(result)
                {
                    if(result.retcode==200)
                    {
                        _this.setState({
                            zpData:result.data,
                            zpLoading:false
                        })
                    }
                    else
                    {
                        _this.setState({
                            zpData:{
                                zp:0,
                                xz:0,
                                yb:0
                            },
                            zpLoading:false
                        })
                    }
                },
                fail(){
                    _this.setState({
                            zpData:{
                                zp:0,
                                xz:0,
                                yb:0
                            },
                            zpLoading:false
                        })
                }
            })
        }
    }
    onYxzpDataBind(){
        const {dianpuData,yxzpsjDate}=this.state;
        const starttime=yxzpsjDate[0];
        const endtime=yxzpsjDate[1];
        const _this=this;
        if(dianpuData.key)
        {
            this.setState({
                yxzpLoading:true
            })
            Service.Post({
                name:"DataStatistical",
                action:"yxzpdata",
                data:{
                    dianpu:dianpuData.key,
                    start:starttime,
                    end:endtime
                },
                success(result)
                {
                    if(result.retcode==200)
                    {
                        _this.setState({
                            yxzpData:result.data,
                            yxzpLoading:false
                        })
                    }
                    else
                    {
                        _this.setState({
                            yxzpData:{
                                zp:0,
                                xz:0,
                                yb:0
                            },
                            zpLoading:false
                        })
                    }
                },
                fail(){
                    _this.setState({
                            yxzpData:{
                                zp:0,
                                xz:0,
                                yb:0
                            },
                            yxzpLoading:false
                        })
                }
            })
        }
    }
    onSelectChange=(e)=>{
        this.setState({
            dianpuData:e,
            zpsjDate:[_NowDate,_NowDate],
            yxzpsjDate:[_NowDate,_NowDate]
        })
        this.onDataBind();
    }
    onZpDateChange=(e)=>{
         this.setState({
            zpsjDate:e,
        })
         setTimeout(()=>{
            this.onZpDataBind();
        },100)
    }
    onYxzpDateChange=(e)=>{
        this.setState({
            yxzpsjDate:e,
        })
         setTimeout(()=>{
            this.onYxzpDataBind();
        },100)
    }
    onToPage=(url,param)=>{
        const {dianpuData}=this.state;
        const zurl="/main/"+url+"?dianpu="+dianpuData.key+"&"+param;
        this.props.history.push(zurl);
    }
    onMoreClick=(tp)=>{
        if(tp==0)
        {
            const dialogid='sjdialog';
            openDialog({
                parentComponent:this,
                title:'新增商家',
                dialogId:dialogid,
                maskClosable:false,
                closeBtn:true,
                style:{
                    width:'700px'
                },
                children:(
                    <DataMerchantListView dianpu={this.state.dianpuData.key}/>
                )
            })
        }
        else if(tp==1)
        {
            const dialogid='zwdialog';
            openDialog({
                parentComponent:this,
                title:'新增职位',
                dialogId:dialogid,
                maskClosable:false,
                closeBtn:true,
                style:{
                    width:'700px'
                },
                children:(
                    <DataWorkListView youxiao={0} dianpu={this.state.dianpuData.key}/>
                )
            })
        }
        else if(tp==2)
        {
            const dialogid='yxzwdialog';
            openDialog({
                parentComponent:this,
                title:'新增有效职位',
                dialogId:dialogid,
                maskClosable:false,
                closeBtn:true,
                style:{
                    width:'700px'
                },
                children:(
                    <DataWorkListView youxiao={1} dianpu={this.state.dianpuData.key}/>
                )
            })
        }
        else if(tp==3)
        {
            const {zpsjDate}=this.state;
            const dialogid='zwsigndialog';
            openDialog({
                parentComponent:this,
                title:'职位已报名',
                dialogId:dialogid,
                maskClosable:false,
                closeBtn:true,
                style:{
                    width:'700px'
                },
                children:(
                    <DataAdminSignListView youxiao={0} dianpu={this.state.dianpuData.key} date={zpsjDate}/>
                )
            })
        }
        else if(tp==4)
        {
            const {yxzpsjDate}=this.state;
            const dialogid='yxzwsigndialog';
            openDialog({
                parentComponent:this,
                title:'有效职位已报名',
                dialogId:dialogid,
                maskClosable:false,
                closeBtn:true,
                style:{
                    width:'700px'
                },
                children:(
                    <DataAdminSignListView youxiao={1} dianpu={this.state.dianpuData.key} date={yxzpsjDate}/>
                )
            })
        }
    }
    render() {
        const {loading,merchantData,merchantLoading,dianpuData,dianpuList,workData,workLoading,zpData,yxzpData,zpLoading,yxzpLoading}=this.state;
        return(
            <div>
                <Header data={[{name:'数据'},{name:'招聘数据'}]}/>
                <div className="page-right-body me-not-bottom">
                    <BlockLoading loading={loading}>
                        <div className="simple">
                            <div className="simple-header">
                                <div className="simple-header-hd">
                                    当前商家：
                                </div>
                                <div className="simple-header-bd">
                                    <Select
                                        width={"200px"}
                                        className="simple-header-bd-select"
                                        placeholder={"请选择商家"}
                                        options={dianpuList}
                                        value={dianpuData}
                                        onChange={this.onSelectChange}
                                    />
                                </div>
                            </div>
                            <div className="simple-row">
                                <div className="simple-col">
                                    <BlockLoading loading={merchantLoading}>
                                        <div className="simplecard">
                                            <div className="simplecard-box">
                                                <div className="simplecard-box-header">商家总量<span>已审核</span></div>
                                                <div className="simplecard-box-body">
                                                    <div className="simplecard-box-body-cell">
                                                        <span>总数</span>
                                                        <span className="simplecard-box-body-cell-content">
                                                            <span className="simplecard-num link-ss" onClick={this.onToPage.bind(this,"customer/merchant","shenhe=3")}>
                                                                {merchantData.all}
                                                            </span>个
                                                        </span>
                                                        <span></span>
                                                    </div>
                                                    <div className="simplecard-box-body-x"></div>
                                                    <div className="simplecard-box-body-cell">
                                                        <span>启用量</span>
                                                        <span className="simplecard-box-body-cell-content">
                                                            <span className="simplecard-num link-ss" onClick={this.onToPage.bind(this,"customer/merchant","shenhe=3&type=state")}>
                                                                {merchantData.state}
                                                            </span>个
                                                        </span>
                                                        <span></span>
                                                    </div>
                                                    <div className="simplecard-box-body-x"></div>
                                                    <div className="simplecard-box-body-cell">
                                                        <span>营业执照</span>
                                                        <span className="simplecard-box-body-cell-content">
                                                            <span className="simplecard-num link-ss" onClick={this.onToPage.bind(this,"customer/merchant","shenhe=3&type=yyzz")}>
                                                                {merchantData.yyzz}
                                                            </span>个
                                                        </span>
                                                        <span></span>
                                                    </div>
                                                    <div className="simplecard-box-body-x"></div>
                                                    <div className="simplecard-box-body-cell">
                                                        <span>授权书</span>
                                                        <span className="simplecard-box-body-cell-content">
                                                            <span className="simplecard-num link-ss" onClick={this.onToPage.bind(this,"customer/merchant","shenhe=3&type=sqs")}>
                                                                {merchantData.sqs}
                                                            </span>个
                                                        </span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </BlockLoading>
                                </div>
                                <div className="simple-col">
                                    <BlockLoading loading={workLoading}>
                                        <div className="simplecard">
                                            <div className="simplecard-box">
                                                <div className="simplecard-box-header">当前在招数据</div>
                                                <div className="simplecard-box-body">
                                                    <div className="simplecard-box-body-cell">
                                                        <span>在招职位</span>
                                                        <span className="simplecard-box-body-cell-content">
                                                    <span className="simplecard-num link-ss" onClick={this.onToPage.bind(this,"work/work","type=zp")}>
                                                        {workData.zp}</span>个
                                                </span>
                                                        <span></span>
                                                    </div>
                                                    <div className="simplecard-box-body-x"></div>
                                                    <div className="simplecard-box-body-cell">
                                                        <span>需招人数</span>
                                                        <span className="simplecard-box-body-cell-content">
                                                    <span className="simplecard-num">{workData.xz}</span>人
                                                </span>
                                                        <span></span>
                                                    </div>
                                                    <div className="simplecard-box-body-x"></div>
                                                    <div className="simplecard-box-body-cell">
                                                        <span>已报名人数</span>
                                                        <span className="simplecard-box-body-cell-content">
                                                    <span className="simplecard-num">{workData.yz}</span>人
                                                </span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </BlockLoading>
                                </div>
                            </div>
                            <div className="simple-row">
                                <div className="simple-col">
                                    <BlockLoading loading={merchantLoading}>
                                        <div className="simplecard">
                                            <div className="simplecard-box">
                                                <div className="simplecard-box-header">
                                                    <div className="simplecard-box-header-hd">
                                                        新增商家<span>已审核</span>
                                                    </div>
                                                    <div onClick={this.onMoreClick.bind(this,0)} className="simplecard-box-header-bd link-ss">
                                                        查看更多
                                                    </div>
                                                </div>
                                                <div className="simplecard-box-body">
                                                    <div className="simplecard-box-body-x"></div>
                                                    <div className="simplecard-box-body-cell">
                                                        <span>今日新增</span>
                                                        <span className="simplecard-box-body-cell-content">
                                                    <span className="simplecard-num link-ss" onClick={this.onToPage.bind(this,"customer/merchant","shenhe=3&type=day")}>
                                                        {merchantData.day}
                                                    </span>个
                                                </span>
                                                        <span></span>
                                                    </div>
                                                    <div className="simplecard-box-body-x"></div>
                                                    <div className="simplecard-box-body-cell">
                                                        <span>本周新增</span>
                                                        <span className="simplecard-box-body-cell-content">
                                                    <span className="simplecard-num link-ss" onClick={this.onToPage.bind(this,"customer/merchant","shenhe=3&type=week")}>
                                                        {merchantData.week}
                                                    </span>个
                                                </span>
                                                        <span></span>
                                                    </div>
                                                    <div className="simplecard-box-body-x"></div>
                                                    <div className="simplecard-box-body-cell">
                                                        <span>本月新增</span>
                                                        <span className="simplecard-box-body-cell-content">
                                                            <span className="simplecard-num link-ss" onClick={this.onToPage.bind(this,"customer/merchant","shenhe=3&type=month")}>
                                                                {merchantData.month}</span>个
                                                        </span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </BlockLoading>
                                </div>
                                <div className="simple-col">
                                    <BlockLoading loading={merchantLoading}>
                                        <div className="simplecard">
                                            <div className="simplecard-box">
                                                <div className="simplecard-box-header">异常商家</div>
                                                <div className="simplecard-box-body">
                                                    <div className="simplecard-box-body-cell">
                                                        <span>待审核</span>
                                                        <span className="simplecard-box-body-cell-content">
                                                    <span className="simplecard-num link-ss" onClick={this.onToPage.bind(this,"customer/merchant","shenhe=1")}>
                                                        {merchantData.shz}</span>个
                                                </span>
                                                        <span></span>
                                                    </div>
                                                    <div className="simplecard-box-body-x"></div>
                                                    <div className="simplecard-box-body-x"></div>
                                                    <div className="simplecard-box-body-cell">
                                                        <span>审核未通过</span>
                                                        <span className="simplecard-box-body-cell-content">
                                                    <span className="simplecard-num link-ss" onClick={this.onToPage.bind(this,"customer/merchant","shenhe=2")}>
                                                        {merchantData.wtg}</span>个
                                                </span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </BlockLoading>
                                </div>
                            </div>
                            <div className="simple-row">
                                <div className="simple-col">
                                    <BlockLoading loading={workLoading}>
                                        <div className="simplecard">
                                            <div className="simplecard-box">
                                                <div className="simplecard-box-header">
                                                    <div className="simplecard-box-header-hd">
                                                        新增职位
                                                    </div>
                                                    <div onClick={this.onMoreClick.bind(this,1)} className="simplecard-box-header-bd link-ss">
                                                        查看更多
                                                    </div>
                                                </div>
                                                <div className="simplecard-box-body">
                                                    <div className="simplecard-box-body-cell">
                                                        <span>今日新增</span>
                                                        <span className="simplecard-box-body-cell-content">
                                                    <span className="simplecard-num link-ss" onClick={this.onToPage.bind(this,"work/work","type=day")}>
                                                        {workData.day}</span>个
                                                </span>
                                                        <span></span>
                                                    </div>
                                                    <div className="simplecard-box-body-x"></div>
                                                    <div className="simplecard-box-body-cell">
                                                        <span>本周新增</span>
                                                        <span className="simplecard-box-body-cell-content">
                                                    <span className="simplecard-num link-ss" onClick={this.onToPage.bind(this,"work/work","type=week")}>
                                                        {workData.week}</span>个
                                                </span>
                                                        <span></span>
                                                    </div>
                                                    <div className="simplecard-box-body-x"></div>
                                                    <div className="simplecard-box-body-cell">
                                                        <span>本月新增</span>
                                                        <span className="simplecard-box-body-cell-content">
                                                        <span className="simplecard-num link-ss" onClick={this.onToPage.bind(this,"work/work","type=month")}>
                                                        {workData.month}</span>个
                                                </span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </BlockLoading>
                                </div>
                                <div className="simple-col">
                                    <BlockLoading loading={workLoading}>
                                        <div className="simplecard">
                                            <div className="simplecard-box">
                                                <div className="simplecard-box-header">
                                                    <div className="simplecard-box-header-hd">
                                                        有效职位
                                                    </div>
                                                    <div onClick={this.onMoreClick.bind(this,2)} className="simplecard-box-header-bd link-ss">
                                                        查看更多
                                                    </div>
                                                </div>
                                                <div className="simplecard-box-body">
                                                    <div className="simplecard-box-body-cell">
                                                        <span>今日新增</span>
                                                        <span className="simplecard-box-body-cell-content">
                                                    <span className="simplecard-num link-ss" onClick={this.onToPage.bind(this,"work/work","type=day&youxiao=1")}>
                                                        {workData.yxday}</span>个
                                                </span>
                                                        <span></span>
                                                    </div>
                                                    <div className="simplecard-box-body-x"></div>
                                                    <div className="simplecard-box-body-cell">
                                                        <span>本周新增</span>
                                                        <span className="simplecard-box-body-cell-content">
                                                    <span className="simplecard-num link-ss" onClick={this.onToPage.bind(this,"work/work","type=week&youxiao=1")}>
                                                        {workData.yxweek}</span>个
                                                </span>
                                                        <span></span>
                                                    </div>
                                                    <div className="simplecard-box-body-x"></div>
                                                    <div className="simplecard-box-body-cell">
                                                        <span>本月新增</span>
                                                        <span className="simplecard-box-body-cell-content">
                                                    <span className="simplecard-num link-ss" onClick={this.onToPage.bind(this,"work/work","type=month&youxiao=1")}>
                                                        {workData.yxmonth}</span>个
                                                </span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </BlockLoading>
                                </div>
                            </div>
                            <div className="simple-row">
                                <div className="simple-col">
                                    <BlockLoading loading={zpLoading}>
                                        <div className="simplecard">
                                            <div className="simplecard-box">
                                                <div className="simplecard-box-header">
                                                    <div className="simplecard-box-header-hd">
                                                        招聘数据
                                                    </div>
                                                    <div className="simplecard-box-header-bd">
                                                       <CombinedDateRangePicker
                                                           value={this.state.zpsjDate}
                                                           dateSpan={90}
                                                           onChange={this.onZpDateChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="simplecard-box-body">
                                                    <div className="simplecard-box-body-cell">
                                                        <span>在招职位</span>
                                                        <span className="simplecard-box-body-cell-content">
                                                    <span className="simplecard-num">
                                                        {zpData.zp}</span>个
                                                </span>
                                                        <span></span>
                                                    </div>
                                                    <div className="simplecard-box-body-x"></div>
                                                    <div className="simplecard-box-body-cell">
                                                        <span>需招人数</span>
                                                        <span className="simplecard-box-body-cell-content">
                                                    <span className="simplecard-num">{zpData.xz}</span>人
                                                </span>
                                                        <span></span>
                                                    </div>
                                                    <div className="simplecard-box-body-x"></div>
                                                    <div className="simplecard-box-body-cell">
                                                        <span>已报名</span>
                                                        <span className="simplecard-box-body-cell-content link-ss" onClick={this.onMoreClick.bind(this,3)}>
                                                            <span className="simplecard-num">{zpData.yb}</span>人
                                                        </span>
                                                        <span></span>
                                                    </div>
                                                    <div className="simplecard-box-body-x"></div>
                                                    <div className="simplecard-box-body-cell">
                                                        <span>报满率</span>
                                                        <span className="simplecard-box-body-cell-content">
                                                            <span className="simplecard-num">{zpData.yb>0?(zpData.yb/zpData.xz*100).toFixed(2):0}</span>%
                                                        </span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </BlockLoading>
                                </div>
                                <div className="simple-col">
                                    <BlockLoading loading={yxzpLoading}>
                                        <div className="simplecard">
                                            <div className="simplecard-box">
                                                <div className="simplecard-box-header">
                                                    <div className="simplecard-box-header-hd">
                                                        有效职位招聘数据
                                                    </div>
                                                    <div className="simplecard-box-header-bd">
                                                        <CombinedDateRangePicker
                                                            value={this.state.yxzpsjDate}
                                                            dateSpan={90}
                                                            onChange={this.onYxzpDateChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="simplecard-box-body">
                                                    <div className="simplecard-box-body-cell">
                                                        <span>在招职位</span>
                                                        <span className="simplecard-box-body-cell-content">
                                                    <span className="simplecard-num">
                                                        {yxzpData.zp}</span>个
                                                </span>
                                                        <span></span>
                                                    </div>
                                                    <div className="simplecard-box-body-x"></div>
                                                    <div className="simplecard-box-body-cell">
                                                        <span>需招人数</span>
                                                        <span className="simplecard-box-body-cell-content">
                                                    <span className="simplecard-num">{yxzpData.xz}</span>人
                                                </span>
                                                        <span></span>
                                                    </div>
                                                    <div className="simplecard-box-body-x"></div>
                                                    <div className="simplecard-box-body-cell">
                                                        <span>已报名</span>
                                                        <span className="simplecard-box-body-cell-content link-ss" onClick={this.onMoreClick.bind(this,4)}>
                                                            <span className="simplecard-num">{yxzpData.yb}</span>人
                                                        </span>
                                                        <span></span>
                                                    </div>
                                                    <div className="simplecard-box-body-x"></div>
                                                    <div className="simplecard-box-body-cell">
                                                        <span>报满率</span>
                                                        <span className="simplecard-box-body-cell-content">
                                                            <span className="simplecard-num">{yxzpData.yb>0?(yxzpData.yb/yxzpData.xz*100).toFixed(2):0}</span>%
                                                        </span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </BlockLoading>
                                </div>
                            </div>
                        </div>
                    </BlockLoading>
                </div>
            </div>
        )
    }
}
