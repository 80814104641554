import React from 'react';
import Header from '../../../../component/header'
import {
    Button,
    Grid,
    Notify,
    Sweetalert,
    Tag,
} from 'zent'
import Service from "../../../../config/Service";

export default class MainSystemProductIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            gridPageCurrent:1,
            gridPageTotal:0,
            gridLoading:false,
            gridPageSize:20,
            dataList:[]
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(110300);
        this.dataBind(1);
    }
    dataBind=(pageindex)=>{
        const _this=this;
        const {gridPageSize}=this.state;
        this.setState({
            gridLoading:true
        })
        Service.Post({
            name:"adminproduct",
            action:"pagelist",
            data:{
                pageindex:pageindex,
                pagesize: gridPageSize
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        dataList:result.list,
                        gridPageTotal:result.count
                    })
                }
            },
            fail(){
                Notify.error("网络连接失败");
            },
            complete(){
                _this.setState({
                    gridLoading:false
                })
            }
        })

    }

    onAddProductClick=()=>{
        this.props.history.push('product/edit/0');
    }
    onGridItemEditClick=(id)=>{
        this.props.history.push('product/edit/'+id);
    }
    onGridItemDeleteClick=(id)=>{
        const _this=this;
        Sweetalert.confirm({
            content:'操作成功后将无法恢复，请确认',
            title: '请确认操作',
            onConfirm: () => new Promise((resolve,reject) => {
                Service.Post({
                    name:"adminproduct",
                    action:"delete",
                    data:{
                        id:id
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("已删除");
                            resolve();
                            _this.dataBind(_this.state.gridPageCurrent)
                        }
                        else
                        {
                            Notify.warn(result.retmsg);
                            reject();
                        }
                    },
                    fail(){
                        Notify.error("网络链接失败");
                        reject();
                    }
                })
            }),
            parentComponent:this
        });
    }
    onGridItemStatusClick=(id,state)=>{
        this.setState({
            gridLoading:true
        })
        const _this=this;
        Service.Post({
            name:"adminproduct",
            action:"state",
            data:{
                id,
                state
            },
            success(result)
            {
                _this.setState({
                    gridLoading:false
                })
                if(result.retcode==200)
                {
                    Notify.success("操作成功");
                    _this.dataBind(_this.state.gridPageCurrent);
                }
                else
                {
                    Notify.warn(result.retmsg);
                }
            },
            fail(){
                _this.setState({
                    gridLoading:false
                })
            }
        })
    }
    onGridChange=(e)=>{
        const current=e.current;
        this.setState({
            gridPageCurrent:current
        })
        this.dataBind(current)
    }
    getGridColumns=()=>{
        const listColumns=[
            {
                title:'名称',
                width: '150px',
                name:'name'
            },
            {
                title:'金额',
                name:'money',
                width: '100px'
            },
            {
                title:'天数',
                name:'time',
                width: '100px'
            },
            {
                title:'市场奖金',
                name:'jjmoney',
                width:'100px',
                bodyRender:(data)=>{
                    if(data.type==1&&data.jjmoney>0)
                    {
                        return(
                            <Tag theme={"blue"}>{data.jjmoney}</Tag>
                        )
                    }
                    else
                    {
                        return(
                            <>无</>
                        )
                    }
                }
            },
            {
                title:'客服奖金',
                width:'100px',
                bodyRender:(data)=>{
                    if(data.type==1&&data.kfmoney>0)
                    {
                        return(
                            <Tag theme={"blue"}>{data.kfmoney}</Tag>
                        )
                    }
                    else
                    {
                        return(
                            <>无</>
                        )
                    }
                }
            },
            {
                title:'售卖店铺',
                name:'dianpu',
            },
            {
                title:'类型',
                width: '100px',
                bodyRender:(data)=>{
                    let statusView=null;
                    if(data.type==1)
                    {
                        statusView=<Tag outline theme={"blue"}>商品</Tag>
                    }
                    else if(data.type==2)
                    {
                        statusView=<Tag outline theme={"yellow"}>定金</Tag>
                    }
                    else if(data.type==3)
                    {
                        statusView=<Tag outline theme={"green"}>补费</Tag>
                    }
                    return <div className="font12">
                        {statusView}
                    </div>
                }
            },
            {
                title:'排序',
                name:'sort',
                width: '60px'
            },
            {
                title:'状态',
                width: '80px',
                bodyRender:(data)=>{
                    let statusView=null;
                    if(data.state==1)
                    {
                        statusView=<Tag theme={"green"}>正常</Tag>
                    }
                    else
                    {
                        statusView=<Tag theme={"yellow"}>停用</Tag>
                    }
                    return <div className="font12">
                        {statusView}
                    </div>
                }
            },
            {
                title:'已售',
                width: '80px',
                name:'count'
            },
            {
                title:'操作',
                width:'250px',
                textAlign:'center',
                bodyRender:(data,res)=>{
                    let statusView=null;
                    if(data.state==1)
                    {
                        statusView=<Button type="warning" onClick={this.onGridItemStatusClick.bind(this,data.id,2)}>停用</Button>
                    }
                    else
                    {
                        statusView=<Button type="success" onClick={this.onGridItemStatusClick.bind(this,data.id,1)}>启用</Button>
                    }
                    return <div className="font12">
                        <Button type="default" onClick={this.onGridItemEditClick.bind(this,data.id)}>编辑</Button>
                        {statusView}
                        <Button type="error" onClick={this.onGridItemDeleteClick.bind(this,data.id)}>删除</Button>
                    </div>
                }
            }
        ];
        return listColumns;
    }
    render() {
        return(
            <div>
                <Header data={[{name:'系统'},{name:'产品管理'}]}/>
                <div className="page-right-body">
                    <div className="page-right-body-hd">
                        <div className="page-right-body-hd-hd">
                        </div>
                        <div className="page-right-body-hd-bd">
                            <Button onClick={this.onAddProductClick} type="primary">添加产品</Button>
                        </div>
                    </div>
                    <div className="page-right-body-bd">
                        <Grid
                            columns={this.getGridColumns()}
                            datasets={this.state.dataList}
                            pageInfo={{
                                current: this.state.gridPageCurrent,
                                pageSize: this.state.gridPageSize,
                                total: this.state.gridPageTotal,
                                pageSizeOptions: [this.state.gridPageSize],
                            }}
                            loading={this.state.gridLoading}
                            onChange={this.onGridChange.bind(this)}
                            ellipsis
                            size={"medium"}
                            rowKey="id"/>
                    </div>
                </div>
            </div>
        )
    }
}
