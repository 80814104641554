import React from 'react';
import Header from '../../../../../component/header'
import {
    Form, FormStrategy, Notify, Button,
    FormNumberInputField,
    FormImageUploadField,
    BlockLoading,
    FormRadioGroupField,
    Radio,
    Alert
} from 'zent';
import Service from "../../../../../config/Service";
export default class MainSystemHuomaListEdit extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            huomaGroupId:0,
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(110600);
        const id=this.props.match.params.id||0;
        this.setState({
            huomaGroupId:id
        })
    }
    FormView=()=>{
        const _this=this;
        const form = Form.useForm(FormStrategy.View);
        const [loading,setLoading]=React.useState(false);
        const onSubmit = React.useCallback(form => {
            setLoading(true);
            const value = form.getSubmitValue();
            return new Promise(resolve => {
                const id=_this.state.huomaGroupId;
                Service.Post({
                    name:"huomalist",
                    action:"add",
                    data:{
                        id,
                        img:value.img[0].src,
                        sort:value.sort,
                        type:value.type,
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("提交成功",1000,()=>{
                                setLoading(false);
                                _this.props.history.goBack();
                            })
                        }
                        else
                        {
                            Notify.error(result.retmsg);
                            setLoading(false);
                        }
                    },
                    fail(){
                        Notify.error("网络连接失败");
                        setLoading(false);
                    }
                })
            });
        }, []);
        const onImageUpload=(file, report)=>{
            return new Promise((resolve, reject) => {
                report(40)
                Service.Upload({
                    action:"uploadimage",
                    file:file,
                    path:'qrcode/list',
                    success(result){
                        if(result.retcode==200)
                        {
                            report(100);
                            resolve(result.path);
                        }
                        else
                        {
                            reject();
                        }
                    },
                    fail()
                    {
                        reject();
                    }
                })
            });
        }
        return(
            <Form
                disabled={loading}
                form={form}
                layout="horizontal"
                onSubmit={onSubmit}>
                <FormImageUploadField
                    label={"二维码："}
                    name={"img"}
                    required
                    props={{
                        tips: '单个文件不超过 5M,建议尺寸500x500',
                        maxAmount: 1,
                        maxSize: 1024 * 1024 * 5,
                        onUpload:onImageUpload,
                    }}
                />
                <FormRadioGroupField
                    label={"类型："}
                    name={"type"}
                    required
                    defaultValue={"1"}
                    >
                    <Radio value="1">个人</Radio>
                    <Radio value="0">群</Radio>
                </FormRadioGroupField>
                <FormNumberInputField
                    name="sort"
                    label="排序："
                    required
                    props={{
                        max:99999,
                        min:0,
                        placeholder:"请输入排序数字"
                    }}
                    defaultValue={"0"}
                    helpDesc={"数值越大越靠前展示"}
                />
                <div className="form-submit app-fixed-container">
                    <Button type="default" onClick={()=>{_this.props.history.goBack();}}>取消</Button>
                    <Button htmlType="submit" type="primary" loading={loading}>保存</Button>
                </div>
            </Form>
        )
    }
    render() {
        const FormView=this.FormView.bind();
        const {loading}=this.state;
        return(
            <div>
                <Header data={[{name:'系统'},{name:'活码管理',href:'/main/system/huoma'},{name:'二维码列表',href:'/main/system/huoma/list/'+this.state.huomaGroupId},{name:'添加二维码'}]}/>
                <BlockLoading loading={loading}>
                    <div className="page-right-body">
                        <Alert title="温馨提示" type={"info"} description={
                            <div>
                                <p>微信个人二维码每天超过100次加好友请求后当天将会失效！</p>
                                <p>微信群二维码加满100人后将会永久失效，且有效期为7天！</p>
                            </div>
                        } />
                        <div className="page-right-body-hd">
                        </div>
                        <div className="page-right-body-bd">
                            <FormView/>
                        </div>
                    </div>
                </BlockLoading>
            </div>
        )
    }
}